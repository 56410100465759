import type { Report } from 'types'
import type { CreateReportData, UpdateReportData } from 'api/reports'
import { reportsToReview, reportsCounts } from 'stores'
import { reportsAPI } from 'api'
import { handleDeletedMessage } from './messages'
import { handleDeletedThread } from './threads'
import { handleDeletedCommunity } from './communities'

const perPage = 30

export async function checkReportsToReview(): Promise<void> {
  reportsCounts.set(await reportsAPI.getReportsCounts().catch(() => ({})))
}

export async function loadReports(): Promise<void> {
  const data = await reportsAPI.getReports({
    toReview: true,
    limit: perPage,
    offset: reportsToReview.get()?.length || 0,
  })
  reportsToReview.update(reportsToReview => [...reportsToReview, ...data])
}

export async function createReport(data: CreateReportData): Promise<Report> {
  const report = await reportsAPI.createReport(data)

  if (report.type === 'message' && report.message) {
    handleDeletedMessage(report.message.threadId, report.message.id)
  } else if (report.type === 'thread' && report.thread) {
    handleDeletedThread(report.thread.id)
  } else if (report.type === 'community' && report.community) {
    handleDeletedCommunity(report.community.id)
  }

  return report
}

export async function updateReport(report: Report, data: UpdateReportData): Promise<void> {
  const updatedReport = await reportsAPI.updateReport(report.id, data)
  reportsToReview.update(reports => reports.map(d => (d.id === report.id ? updatedReport : d)))
}

export async function approveReport(report: Report, comment?: string): Promise<void> {
  await reportsAPI.approveReport(report.id, comment)
  reportsToReview.update(reports => reports.filter(d => d.id !== report.id))
  updateCounts(report)
}

export async function rejectReport(report: Report, comment?: string): Promise<void> {
  await reportsAPI.rejectReport(report.id, comment)
  reportsToReview.update(reports => reports.filter(d => d.id !== report.id))
  updateCounts(report)
}

export async function deleteReport(reportId: string): Promise<void> {
  await reportsAPI.deleteReport(reportId)
  reportsToReview.update(reports => reports.filter(d => d.id !== reportId))
}

function updateCounts(reviewReport: Report) {
  const threadId = reviewReport.message?.threadId
  if (!threadId) return

  reportsCounts.update(counts => {
    const newCounts = { ...counts }
    newCounts[threadId] = (newCounts[threadId] || 0) - 1
    return newCounts
  })
}
