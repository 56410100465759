import type { Report, ReportState, ReportType, ReportReason } from 'types'
import * as api from './api'

export type CreateReportData = {
  type: ReportType
  reason: ReportReason
  comment?: string
  userId?: string
  messageId?: string
  threadId?: string
  communityId?: string
}

export type UpdateReportData = {
  reason?: ReportReason
  comment?: string
}

type ReportsFilters = {
  toReview?: boolean
  state?: ReportState
  type?: ReportType
  limit?: number
  offset?: number
  page?: number
  [key: string]: any
}

export async function getReports(filters: ReportsFilters = {}): Promise<Report[]> {
  const queryParams = Object.entries(filters).map(p => p.join('='))
  const query = queryParams.length ? `?${queryParams.join('&')}` : ''

  return (await api.get<{ reports: Report[] }>(`/reports${query}`)).reports
}

export async function getReportsCounts(): Promise<{ [key: string]: number }> {
  return (await api.get<{ counts: { [key: string]: number } }>('/reports/counts')).counts
}

export async function getReport(reportId: string): Promise<Report> {
  return (await api.get<{ report: Report }>(`/reports/${reportId}`)).report
}

export async function createReport(data: CreateReportData): Promise<Report> {
  return (await api.post<{ report: Report }>('/reports', data)).report
}

export async function updateReport(reportId: string, data: UpdateReportData): Promise<Report> {
  return (await api.put<{ report: Report }>(`/reports/${reportId}`, data)).report
}

export async function approveReport(reportId: string, comment?: string): Promise<void> {
  return await api.post<void>(
    `/reports/${reportId}/approve`,
    comment ? { reviewComment: comment } : undefined,
  )
}

export async function rejectReport(reportId: string, comment?: string): Promise<void> {
  return await api.post<void>(
    `/reports/${reportId}/reject`,
    comment ? { reviewComment: comment } : undefined,
  )
}

export async function deleteReport(reportId: string): Promise<void> {
  return api.del<void>(`/reports/${reportId}`)
}
