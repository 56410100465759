<script lang="ts">
  import type { Thread } from 'types'
  import { SidebarState, ModalType } from 'types'
  import Router, { querystring, location } from 'svelte-spa-router'
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import ShowMenu from 'assets/icons/hide-menu.svg'
  import BoomAnimation from 'components/BoomAnimation.svelte'
  import { goToThread, isNewMessagePage, clearQuery } from 'actions/router'
  import {
    loadThreads,
    loadCommunities,
    setSidebarState,
    setSidebarHovered,
    checkReportsToReview,
    removeBoomAnimation,
    openModal,
  } from 'actions'
  import {
    settings,
    playerSettings,
    threads as threadsStore,
    communities,
    currentThreadId,
    isAppFocused,
    unwatchedMessagesCount,
  } from 'stores'
  import Sidebar from './components/Sidebar/Sidebar.svelte'
  import ThreadComponent from './scenes/Thread/Thread.svelte'
  import Feed from './scenes/Feed/Feed.svelte'
  import SharedMessage from './scenes/SharedMessage.svelte'
  import Drafts from './scenes/Drafts.svelte'
  import AIGenerator from './scenes/AIGenerator.svelte'
  import Reports from './scenes/Reports.svelte'
  import NewMessage from './scenes/NewMessage.svelte'
  import Modals from './modals/Modals.svelte'

  let error: string | undefined
  let sidebarError: string | undefined
  let isThreadsPage = false
  let isLoading = false
  let isSidebarInactive = false
  let isSidebarHovered = false
  let isSidebarWasActive = false
  let isAppWasFocused = true
  let sidebarInactivityTimeout: any
  let sidebarState: SidebarState
  const unsubscribes: (() => void)[] = []

  $: updateSidebarState(isSidebarHovered, sidebarState, isAppWasFocused)
  $: $threadsStore && $communities && sidebarError && setSidebarError(undefined)

  if ((threadsStore.get() || []).length === 0) {
    isLoading = true
    loadThreads()
      .finally(() => (isLoading = false))
      .catch(setSidebarError)
  } else {
    isLoading = false
  }
  loadCommunities().catch(setSidebarError)
  checkReportsToReview()

  unsubscribes.push(querystring.subscribe(query => handleQuery(query)))
  unsubscribes.push(
    location.subscribe(location => {
      isThreadsPage = /\/threads/.test(location)
    }),
  )
  unsubscribes.push(
    settings.subscribe(settings => {
      if (sidebarState !== settings.sidebarState) {
        sidebarState = settings.sidebarState

        if (sidebarState === SidebarState.AUTO) {
          isSidebarInactive = true
        } else if (isSidebarInactive) {
          isSidebarInactive = false
        }
      }

      if (isSidebarHovered !== settings.isSidebarHovered) {
        isSidebarHovered = settings.isSidebarHovered
      }
    }),
  )
  unsubscribes.push(
    unwatchedMessagesCount.subscribe(count =>
      window.ipc?.send('SET_BADGE', count ? count.toString() : ''),
    ),
  )
  unsubscribes.push(
    currentThreadId.subscribe(threadId => {
      checkCurrentThread(threadsStore.get() || [], threadId)
    }),
  )
  unsubscribes.push(
    threadsStore.subscribe(threads => {
      const threadId = currentThreadId.get()

      if (
        threads &&
        threads.length > 0 &&
        (!threadId || !threads.find(t => t.id === threadId)) &&
        !isNewMessagePage() &&
        isThreadsPage
      ) {
        if (checkCurrentThread(threads, threadId)) {
          goToThread(threads[0], true)
        }
      }
    }),
  )
  unsubscribes.push(
    isAppFocused.subscribe(isFocused => {
      if (isFocused) {
        isAppWasFocused = isAppWasFocused || !playerSettings.get()?.shouldPlay
      }
    }),
  )

  function checkCurrentThread(threads: Thread[], threadId?: string): boolean {
    if (threads && threads.length > 0 && threadId && !threads.find(t => t.id === threadId)) {
      error = "Thread not found or you don't have access to it."
    } else {
      error = undefined
    }

    return !error
  }

  function setSidebarError(e: any) {
    sidebarError = e ? e.message || e.error : undefined
  }

  function updateSidebarState(
    isSidebarHovered: boolean,
    sidebarState: SidebarState,
    isAppWasFocused: boolean,
  ) {
    if (sidebarState === SidebarState.AUTO) {
      clearTimeout(sidebarInactivityTimeout)

      if (isAppWasFocused) {
        showSidebarOnFocus()
      } else {
        if (isSidebarHovered) {
          isSidebarInactive = false
          isSidebarWasActive = false
        } else {
          sidebarInactivityTimeout = setTimeout(() => {
            if (sidebarState === SidebarState.AUTO && !isSidebarHovered) {
              isSidebarInactive = true
              isSidebarWasActive = true
              sidebarInactivityTimeout = setTimeout(() => {
                isSidebarWasActive = false
              }, 800)
            }
          }, 500)
        }
      }
    }
  }

  function handleQuery(query?: string) {
    const params = new URLSearchParams(query || get(querystring))
    const communityId = params.get('communityId')

    if (communityId) {
      openModal({
        type: ModalType.COMMUNITY_PROFILE,
        onClose: () => clearQuery(),
        arguments: {
          community: (communities.get() || []).find(c => c.id === communityId),
        },
      })
    }
  }

  function showSidebarOnFocus() {
    isSidebarInactive = false
    isSidebarWasActive = false
    isAppWasFocused = false
    clearTimeout(sidebarInactivityTimeout)

    if (sidebarState === SidebarState.AUTO) {
      sidebarInactivityTimeout = setTimeout(() => {
        if (sidebarState === SidebarState.AUTO && !isSidebarHovered) {
          isSidebarInactive = true
          isSidebarWasActive = true
          sidebarInactivityTimeout = setTimeout(() => {
            isSidebarWasActive = false
          }, 800)
        }
      }, 5000)
    }
  }

  function toggleSidebar(value: boolean) {
    setSidebarState(value ? SidebarState.AUTO : SidebarState.HIDE)
    isSidebarWasActive = !isSidebarWasActive
    isSidebarInactive = false
    clearTimeout(sidebarInactivityTimeout)
    sidebarInactivityTimeout = setTimeout(() => {
      isSidebarWasActive = false
    }, 800)
  }

  onDestroy(() => {
    unsubscribes.forEach(un => un && un())
  })

  ; // prettier-ignore
</script>

<div
  class="root"
  class:active-sidebar={sidebarState !== SidebarState.HIDE && !isSidebarInactive}
  class:inactive-sidebar={isSidebarInactive}
  class:sidebar-was-active={isSidebarWasActive}
  class:active-thumbs={$settings.isThumbsActive ||
    (sidebarState !== SidebarState.HIDE && !isSidebarInactive)}>
  <Modals />
  {#each $settings.boomAnimations as info}
    <BoomAnimation {info} on:finished={() => removeBoomAnimation(info)} />
  {/each}
  <Sidebar
    {isLoading}
    bind:error={sidebarError}
    on:toggleSidebar={({ detail }) => toggleSidebar(detail)} />
  <div class="body">
    <div
      class="sidebar-hover-area"
      on:mouseenter={() => setTimeout(() => setSidebarHovered(true))}
      on:mouseleave={() => setSidebarHovered(false)} />
    <div class="toggler" on:click={() => toggleSidebar(true)}>
      <ShowMenu />
    </div>
    {#if error}
      <div class="error">{error}</div>
    {/if}
    <Router
      routes={{
        '/threads/new': NewMessage,
        '/threads/:threadId': ThreadComponent,
        '/threads/:threadId/*': ThreadComponent,
        '/feed': Feed,
        '/feed/*': Feed,
        '/drafts': Drafts,
        '/drafts/:draftId': Drafts,
        '/ai': AIGenerator,
        '/ai/:itemId': AIGenerator,
        '/reports': Reports,
        '/reports/:reportId': Reports,
        '/share/:token': SharedMessage,
      }} />
  </div>
</div>

<style lang="scss">
  :root {
    --sidebar-transition-duration: 0.8s;
    --sidebar-transition-delay: 0.5s;
    --thumbs-transition-duration: 0.3s;
  }

  .root {
    position: relative;
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    overflow: hidden;

    :global(.screen-recording) & {
      background: none;

      .toggler {
        display: none;
      }

      .body {
        left: 0;
        transition: none;
      }
    }

    &.active-sidebar {
      --sidebar-transition-duration: 0.3s;
      --sidebar-transition-delay: 0s;
    }

    &.sidebar-was-active {
      --thumbs-transition-duration: 0.8s;
    }
  }

  .sidebar-hover-area {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 50px;
    transition: bottom 0.3s, step-end width var(--sidebar-transition-duration);
    z-index: 1;
    width: 0;

    .root.active-thumbs:not(.active-sidebar) & {
      bottom: 160px;
    }
  }

  .body {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    transition: left var(--sidebar-transition-duration) ease;

    .active-sidebar & {
      left: 240px;
    }

    .inactive-sidebar & {
      left: 18px;

      .sidebar-hover-area {
        width: 220px;
      }
    }

    .toggler {
      position: absolute;
      top: 9px;
      left: 12px;
      cursor: pointer;
      opacity: 1;
      transition: z-index 0.2s step-start,
        opacity calc(var(--sidebar-transition-duration) - var(--sidebar-transition-delay))
          var(--sidebar-transition-delay) ease;
      z-index: 3;

      :global(svg) {
        transform: rotate(180deg);
      }

      :global(svg path) {
        fill: #fff;
      }
    }

    :global(.mac) & .toggler {
      left: 76px;
    }

    :global(.mac) .inactive-sidebar & .toggler {
      left: 64px;
    }

    .active-sidebar:not(.inactive-sidebar) & .toggler {
      opacity: 0;
      z-index: -1;
      transition: z-index 0.2s step-end,
        opacity calc(var(--sidebar-transition-duration) - var(--sidebar-transition-delay))
          var(--sidebar-transition-delay) ease;
    }
  }

  .error {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    line-height: 1.4;
  }
</style>
