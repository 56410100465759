<script lang="ts">
  import type { MessageSearchResult } from 'types'
  import SearchIcon from 'assets/icons/search.svg'
  import CrossIcon from 'assets/icons/cross.svg'
  import UserBadge from 'components/UserBadge.svelte'
  import TimeAgo from 'components/TimeAgo.svelte'
  import Highlight from 'components/Highlight.svelte'
  import Spinner from 'components/Spinner.svelte'
  import { threads } from 'stores'
  import { addError, goToMessage } from 'actions'
  import { searchMessages } from 'api/messages'
  import { debounce } from 'utils'

  let results: MessageSearchResult[] = []
  let resultsQuery = ''
  let inputEl: HTMLInputElement
  let query = ''
  let active = false
  let focusedIndex = -1
  let loading = false
  let debouncedSearch = debounce(search, 500)

  $: debouncedSearch(query)

  function toggleActive(value: boolean) {
    if (active !== value) {
      active = value
      active && setTimeout(() => inputEl.focus())
    }
  }

  async function search(_query?: string) {
    resultsQuery = typeof _query === 'undefined' ? query : _query

    if (!resultsQuery || resultsQuery.length < 2) {
      results = []
      return
    }

    loading = true
    try {
      results = await searchMessages(resultsQuery)
    } catch (e) {
      results = []
      addError(e)
    }
    loading = false
  }

  function goToResult(result: MessageSearchResult) {
    const thread = $threads?.find(t => t.id === result.message.threadId)
    if (!thread) return

    goToMessage(thread, result.message, result.matchedLines[0].startTime)
    toggleActive(false)
  }

  function hanleKeyPress(event: KeyboardEvent) {
    const key = event.key.toLowerCase()

    if (key === 'escape') {
      toggleActive(false)
    } else if (key === 'enter') {
      if (focusedIndex > -1) {
        const result = results[focusedIndex]
        result && goToResult(result)
      } else {
        search()
      }
    } else if (key === 'arrowup' || key === 'arrowdown') {
      const direction = key === 'arrowup' ? -1 : 1
      focusedIndex = Math.max(0, Math.min(results.length - 1, focusedIndex + direction))
    }
  }
</script>

<div class="search" class:active on:click={() => toggleActive(true)}>
  <SearchIcon />
  <div class="body">
    <div class="form">
      <input
        bind:this={inputEl}
        bind:value={query}
        on:keydown={hanleKeyPress}
        placeholder="Search anything" />
      <div class="loader" class:active={loading}><Spinner color="black" /></div>
      <div class="close" on:click|stopPropagation={() => toggleActive(false)}><CrossIcon /></div>
    </div>
    {#if results.length > 0}
      <div class="results">
        {#each results as result, index (result.message.id)}
          <div
            class="result"
            class:active={focusedIndex === index}
            on:click|stopPropagation={() => goToResult(result)}>
            <div class="image">
              <!-- svelte-ignore a11y-missing-attribute -->
              <img
                src={`${result.message.thumbnailURL}${
                  /^blob/.test(result.message.thumbnailURL || '') ? '' : '?size=240'
                }`} />
            </div>
            <div class="text">
              <Highlight text={result.matchedLines[0].text} query={resultsQuery} />
            </div>
            <div class="user">
              <UserBadge user={result.message.sender} size="xsmall" />
            </div>
            <div class="date">
              <TimeAgo date={result.message.createdAt} />
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .search {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 4px;
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(12px);
    border-radius: 6px;
    display: flex;
    cursor: pointer;
    z-index: 4;
    transition: left 0.2s ease;

    &.active {
      left: 8px;

      .body {
        display: block;
      }

      > :global(svg) {
        margin: 5px 7px;
      }
    }

    :global(.mac .inactive-sidebar) &.active {
      left: 64px;
    }

    > :global(svg) {
      transform: scale(1.5);
      flex: 0 0 auto;
      margin: 5px;
    }

    :global(svg path) {
      fill: rgba(0, 0, 0, 0.7);
    }
  }

  .body {
    display: none;
    flex: 1 1 auto;
  }

  .form {
    display: flex;
    align-items: center;
    height: 26px;

    input {
      flex-grow: 1;
      padding: 0 8px;
      margin-top: -2px;
      font-size: 15px;
      border: none;
      background: none;
      outline: none;

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    .close {
      margin: 2px 6px 0 8px;
      cursor: pointer;
    }

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      margin-right: 4px;
      transition: opacity 0.2s ease;

      &.active {
        opacity: 1;
      }
    }
  }

  .results {
    margin: 4px -4px 0 -32px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    padding-top: 5px;
    max-height: 80vh;
    overflow-y: scroll;
  }

  .result {
    display: flex;
    align-items: center;
    padding: 4px 10px;

    &:hover,
    &.active {
      background: rgba(255, 228, 85, 0.5);
    }

    .image {
      width: 40px;
      height: 28px;
      margin-right: 12px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 40px;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }

    .text {
      flex: 1 1 auto;
      font-size: 16px;
      line-height: 1.4;

      :global(.highlight) {
        font-weight: bold;
      }
    }

    .user {
      flex: 0 0 auto;
      margin-left: 12px;
      margin-top: 2px;
      white-space: nowrap;
    }

    .date {
      flex: 0 0 auto;
      margin-left: 12px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.4);
      white-space: nowrap;
    }
  }
</style>
