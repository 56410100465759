import type { Thread, Message, NewMessage } from 'types'
import { derived } from 'svelte/store'
import store from './store'
import { session } from './session'

export const threads = store<Thread[] | null>(null)

export const feedMessages = store<Message[] | null>(null)
export const noMoreFeedMessages = store(false)

export const currentThreadId = store<string | undefined>()

export const currentThread = derived<[typeof threads, typeof currentThreadId], Thread | undefined>(
  [threads, currentThreadId],
  ([threads, currentThreadId], set) => {
    set(threads?.find(thread => thread.id === currentThreadId))
  },
)

export const currentMessageId = store<string | undefined>()

export const currentMessage = derived<
  [typeof currentThread, typeof currentMessageId, typeof feedMessages],
  Message | undefined
>(
  [currentThread, currentMessageId, feedMessages],
  ([currentThread, currentMessageId, feedMessages], set) => {
    set(
      currentThread?.messages?.find(m => m.id === currentMessageId) ||
        feedMessages?.find(m => m.id === currentMessageId),
    )
  },
)

export const currentReplyId = store<string | undefined>()

export const currentReply = derived<
  [typeof currentMessage, typeof currentReplyId],
  Message | undefined
>([currentMessage, currentReplyId], ([currentMessage, currentReplyId], set) => {
  set(currentMessage?.replies?.find(reply => reply.id === currentReplyId))
})

export const newMessages = store<{ [key: string]: NewMessage }>({})

export const unwatchedMessagesCount = derived<[typeof threads], number>(
  [threads],
  ([threads], set) => {
    set((threads || []).reduce((sum, thread) => sum + (thread.unwatchedMessagesCount || 0), 0))
  },
)

export const lastWatchedMessages = store<{ [key: string]: string }>({})

session.subscribe(session => {
  if (session === null) {
    feedMessages.set(null)
    noMoreFeedMessages.set(false)
    threads.set(null)
  }
})
