<script lang="ts">
  type CodeNumber = {
    input?: HTMLInputElement
    value: string
  }

  export let length = 6
  export let value = ''
  export const focus = (): void => codeNumbers[0]?.input?.focus()

  const codeNumbers: CodeNumber[] = Array.from({ length }, () => ({ value: '' }))

  $: {
    value = codeNumbers.map(c => c.value).join('')
  }

  function onKeyDown(index: number, event: KeyboardEvent) {
    const key = event.key.toLowerCase()
    const value = codeNumbers[index].value
    let nextIndex = -1

    if (key === 'backspace') {
      if (value) {
        ;(event.target as HTMLInputElement).value = ''
        codeNumbers[index].value = ''
      }

      nextIndex = Math.max(0, index - 1)

      event.preventDefault()
    } else if (key === 'arrowleft') {
      nextIndex = Math.max(0, index - 1)
    } else if (key === 'arrowright') {
      nextIndex = Math.min(index + 1, codeNumbers.length - 1)
    }

    if (nextIndex >= 0) {
      codeNumbers[nextIndex].input!.focus()
    }
  }

  function onKeyPress(index: number, event: KeyboardEvent) {
    const key = event.key.toLowerCase()

    if (/\d/.test(key)) {
      ;(event.target as HTMLInputElement).value = key
      codeNumbers[index].value = key
      codeNumbers[Math.min(index + 1, codeNumbers.length - 1)].input!.focus()
    }

    key !== 'enter' && event.preventDefault()
  }

  function onPaste(index: number, event: ClipboardEvent) {
    const paste = event.clipboardData?.getData('text').replace(/\D/g, '') || ''

    Array.from(paste).some(number => {
      codeNumbers[index].value = number
      codeNumbers[index].input!.value = number
      index++
      return index >= codeNumbers.length
    })
  }
</script>

<div class="code">
  {#each codeNumbers as number, index}
    <input
      bind:this={number.input}
      on:keydown={event => onKeyDown(index, event)}
      on:keypress={event => onKeyPress(index, event)}
      on:paste|preventDefault={event => onPaste(index, event)}
      type="number"
      placeholder="0" />
  {/each}
</div>

<style lang="scss">
  .code {
    width: 100%;
    display: flex;
    margin: 60px 0 30px;
  }

  input {
    text-align: center;
    width: 100%;
    height: 60px;
    border: none;
    border-bottom: 1px solid #cacaca;
    display: block;
    outline: none;
    box-sizing: border-box;
    transition: border-bottom-color 0.2s ease;
    padding: 6px 0;
    margin: 0 5px;
    font: bold 50px/60px var(--mono-font);

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
      border-bottom-color: var(--primary-color);
      border-bottom-width: 2px;
      padding-bottom: 5px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
</style>
