<script lang="ts">
  import LogoIcon from 'assets/icons/logo-min.svg'
  import MinimizeIcon from 'assets/icons/windows-minimize.svg'
  import MaximizeIcon from 'assets/icons/windows-maximize.svg'
  import CloseIcon from 'assets/icons/windows-close.svg'
</script>

<div class="title-bar">
  <h1>
    <LogoIcon />
    Boom
  </h1>
  <div class="draggable" />
  <div class="btns">
    <div class="btn" on:click={() => window.ipc?.send('MINIMIZE')}><MinimizeIcon /></div>
    <div class="btn" on:click={() => window.ipc?.send('MAXIMIZE')}><MaximizeIcon /></div>
    <div class="btn close" on:click={() => window.ipc?.send('CLOSE')}><CloseIcon /></div>
  </div>
</div>

<style lang="scss">
  .title-bar {
    display: flex;
    width: 100%;
    height: 24px;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
    z-index: 10;
    user-select: none;
  }

  h1 {
    font-size: 14px;
    margin: 0;
    font-weight: normal;
    padding: 0 10px;
    line-height: 24px;

    :global(svg) {
      width: 18px;
      height: 18px;
      margin: 3px 8px 0 0;
      float: left;
    }
  }

  .draggable {
    flex-grow: 2;
    -webkit-app-region: drag;
  }

  .btns {
    display: flex;
    flex-direction: row;
  }

  .btn {
    position: relative;
    width: 32px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex: 0 0 auto;
    z-index: 1;
    transition: background-color 0.2s, color 0.2s;

    &:hover {
      background: rgba(0, 0, 0, 0.075);
    }

    &.close:hover {
      background: red;
      color: #fff;
    }
  }
</style>
