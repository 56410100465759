<script lang="ts">
  import type { Message, User, ContactPoint } from 'types'
  import { onDestroy } from 'svelte'
  import { updateMessageRecipients } from 'actions'
  import { isContactPointsEqual, getContactPointsKey, getThreadRecipients } from 'utils'
  import { contacts, threads, session } from 'stores'
  // import { fetchContacts } from 'actions/contacts'
  import Button from 'components/Button.svelte'
  import CloseIcon from 'assets/icons/cross.svg'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'

  type SuggestionsGroup = {
    suggestions: ContactPoint[]
    type: 'user' | 'topic'
    createNew?: boolean
  }

  export let message: Message | undefined = undefined

  let field: HTMLInputElement
  let fieldValue = ''
  let users: User[] = []
  let channels = $threads?.filter(t => t.channel) || []
  let suggestedUsers: ContactPoint[] = []
  let suggestedContacts: ContactPoint[] = []
  let suggestedChannels: ContactPoint[] = []
  let suggestionsGroups: SuggestionsGroup[] = []
  let activeSuggestionIndex = -1
  let recipients = getRecipients()
  let orginalRecipients = recipients
  let error = ''
  let isSaving = false
  const unsubscribes: (() => void)[] = []

  $: usersFirst = orginalRecipients.find(r => r.user)
  $: canSave = getContactPointsKey(recipients) !== getContactPointsKey(orginalRecipients)
  $: (users || recipients) && filterSuggestions(fieldValue)

  // fetchContacts()

  unsubscribes.push(contacts.subscribe(() => filterSuggestions(fieldValue)))
  unsubscribes.push(
    threads.subscribe(threads => {
      const map: { [key: string]: boolean } = {}
      users = (threads || [])
        .flatMap(thread => thread.members.filter(p => p.id !== session.get()?.profile.id))
        .filter(user => {
          if (map[user.id]) {
            return false
          } else {
            map[user.id] = true
            return true
          }
        })
      channels = threads?.filter(t => t.channel) || []
    }),
  )

  function addRecipient(recipient: ContactPoint) {
    if (!recipient.user && !recipient.thread) return
    if (!recipients.find(r => isContactPointsEqual(r, recipient))) {
      if (recipient.thread) {
        recipients = [recipient]
      } else {
        recipients = [...recipients, recipient].filter(r => !!r.user)
      }
    }
  }

  function addNewTopic() {
    if (!fieldValue) return
    addRecipient({
      thread: {
        id: 'new-topic',
        title: fieldValue,
        isGroupThread: false,
        membersCount: 0,
        members: [],
        admins: [],
        channel: {
          isPublic: true,
        },
        imageURL: '',
        createdAt: new Date(),
        modifiedAt: new Date(),
      },
    })
  }

  function getRecipients(): ContactPoint[] {
    let thread = message?.threadId ? $threads?.find(t => t.id === message!.threadId) : undefined
    if (thread) {
      if (thread.channel) {
        return [{ thread }]
      } else {
        return getThreadRecipients(thread, $session?.profile).map(user => ({ user }))
      }
    }

    return []
  }

  function removeRecipient(recipient: ContactPoint) {
    recipients = recipients.filter(r => !isContactPointsEqual(r, recipient))
  }

  async function save() {
    if (!message || isSaving) return

    isSaving = true
    try {
      const threadId = recipients.find(r => r.thread && r.thread.id !== 'new-topic')?.thread?.id
      const newTopicName = recipients.find(r => r.thread && r.thread.id === 'new-topic')?.thread
        ?.title
      const userIds = recipients.filter(r => r.user).map(r => r.user!.id)

      message = await updateMessageRecipients(message, {
        ...(userIds.length ? { userIds } : {}),
        ...(threadId ? { threadId } : {}),
        ...(newTopicName ? { newTopicName } : {}),
      })
      recipients = orginalRecipients = getRecipients()
    } catch (e: any) {
      error = e.message || e
    }
    isSaving = false
  }

  function handleKeyUp(event: KeyboardEvent) {
    const key = event?.key?.toLowerCase()
    const suggestionsCount =
      suggestedUsers.length +
      suggestedContacts.length +
      suggestedChannels.length +
      (fieldValue ? 1 : 0)

    if (key === 'enter') {
      const activeSuggestion = getSuggestion(activeSuggestionIndex)
      const createNew =
        indexToGroupIndex(
          Math.max(
            0,
            suggestionsGroups.findIndex(g => g.createNew),
          ),
          activeSuggestionIndex,
        ) === -1

      console.log('activeSuggestion', activeSuggestionIndex, activeSuggestion, createNew)

      if (activeSuggestionIndex >= 0 && activeSuggestion) {
        addRecipient(activeSuggestion)
      } else if (fieldValue && createNew) {
        addNewTopic()
      } else {
        return
      }
      field.value = ''
      fieldValue = ''
      filterSuggestions('')
    }
    if (key === 'arrowup' && suggestionsCount > 0) {
      activeSuggestionIndex = Math.max(0, activeSuggestionIndex - 1)
    } else if (key === 'arrowdown' && suggestionsCount > 0) {
      activeSuggestionIndex = Math.min(suggestionsCount - 1, activeSuggestionIndex + 1)
    }
  }

  function filterSuggestions(query: string) {
    const activeSuggestion = getSuggestion(activeSuggestionIndex)

    suggestedUsers = []
    suggestedContacts = []
    suggestedChannels = []

    for (const user of users) {
      if (suggestedUsers.length > 8) {
        break
      }
      if (recipients.find(contactPoint => isContactPointsEqual(contactPoint, { user }))) {
        continue
      }

      if (
        [
          ...(user.email ? [user.email] : []),
          ...(user.phoneNumber ? [user.phoneNumber] : []),
          ...(user.fullName ? [user.fullName] : []),
          ...(user.username ? [user.username] : []),
        ].reduce(
          (result: boolean, str: string) => result || str.toLowerCase().indexOf(query) !== -1,
          false,
        )
      ) {
        suggestedUsers.push({ user })
      }
    }

    for (const channel of channels) {
      if (suggestedChannels.length > 8) {
        break
      }
      if (
        recipients.find(contactPoint => isContactPointsEqual(contactPoint, { thread: channel }))
      ) {
        continue
      }

      if (channel.title.toLowerCase().indexOf(query) !== -1) {
        suggestedChannels.push({ thread: channel })
      }
    }

    // if (query) {
    //   for (const contact of contacts.get() || []) {
    //     if (suggestedContacts.length > 8) {
    //       break
    //     }
    //     if (
    //       recipients.find(contactPoint => contactPoint.contact?.identifier === contact.identifier)
    //     ) {
    //       continue
    //     }

    //     if (
    //       [
    //         ...contact.emailAddresses,
    //         ...contact.phoneNumbers,
    //         ...(contact.firstName && [contact.firstName]),
    //         ...(contact.lastName && [contact.lastName]),
    //         ...(contact.nickname && [contact.nickname]),
    //       ].reduce(
    //         (result: boolean, str: string) => result || str.toLowerCase().indexOf(query) !== -1,
    //         false,
    //       )
    //     ) {
    //       suggestedContacts.push({ contact })
    //     }
    //   }
    // }

    const newActiveSuggestion = getSuggestion(activeSuggestionIndex)

    if (
      !newActiveSuggestion ||
      !activeSuggestion ||
      !isContactPointsEqual(activeSuggestion, newActiveSuggestion)
    ) {
      activeSuggestionIndex = -1
    }

    suggestionsGroups = [
      { suggestions: suggestedChannels, type: 'topic', createNew: !!fieldValue },
      { suggestions: [...suggestedUsers, ...suggestedContacts], type: 'user' },
    ]
    usersFirst && suggestionsGroups.reverse()
  }

  function getSuggestion(index: number): ContactPoint | null {
    for (const group of suggestionsGroups) {
      if (index < group.suggestions.length + (group.createNew ? 1 : 0)) {
        return group.suggestions[index - (group.createNew ? 1 : 0)]
      } else {
        index -= group.suggestions.length + (group.createNew ? 1 : 0)
      }
    }

    return null
  }

  function indexToGroupIndex(groupIndex: number, index: number): number {
    const indexShift = suggestionsGroups.reduce(
      (result, group, index) =>
        result + (index < groupIndex ? group.suggestions.length + (group.createNew ? 1 : 0) : 0),
      0,
    )
    const group = suggestionsGroups[groupIndex]
    return index - indexShift - (group.createNew ? 1 : 0)
  }

  onDestroy(() => unsubscribes.forEach(un => un()))

  ; // prettier-ignore
</script>

<div class="recipients">
  <div class="title">
    <h1>{orginalRecipients.length === 0 ? 'Add' : 'Edit'} message recipients</h1>
  </div>
  <div class="list">
    <input
      bind:this={field}
      bind:value={fieldValue}
      on:keyup={handleKeyUp}
      class="search"
      placeholder="Type any email, phone number, user or topic name" />
    <div class="members">
      {#each recipients as contactPoint, index (index)}
        <div class="member">
          <ContactPointBadge color="white" {contactPoint} showFullInfo={true} />
          {#if contactPoint}
            <div class="remove-member" on:click={() => removeRecipient(contactPoint)}>
              <CloseIcon />
            </div>
          {/if}
        </div>
      {/each}
    </div>
    <div class="scroll-box">
      {#each suggestionsGroups as group, groupIndex}
        {#if group.suggestions.length > 0 || group.createNew}
          <div class="suggestions">
            <div class="suggestions-title">{group.type === 'topic' ? 'Topics' : 'Users'}</div>
            {#if group.createNew}
              <div
                class="suggestion new"
                class:active={indexToGroupIndex(groupIndex, activeSuggestionIndex) === -1}
                on:mousedown|preventDefault={() => addNewTopic()}>
                Create new topic
              </div>
            {/if}
            {#each group.suggestions as suggestion, index (index)}
              <div
                class="suggestion"
                class:active={index === indexToGroupIndex(groupIndex, activeSuggestionIndex)}
                on:mousedown|preventDefault={() => addRecipient(suggestion)}>
                <ContactPointBadge
                  contactPoint={suggestion}
                  color={index === indexToGroupIndex(groupIndex, activeSuggestionIndex)
                    ? 'white'
                    : 'black'}
                  showFullInfo={true} />
              </div>
            {/each}
          </div>
        {/if}
      {/each}
    </div>
  </div>
  <div class="error">{error}</div>
  <Button color="red" on:click={save} disabled={!canSave || isSaving} loading={isSaving}>
    Save
  </Button>
</div>

<style lang="scss">
  .recipients {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 12px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.85);
    max-height: calc(100vh - 40px);
    font-size: 15px;
    color: #000;
    line-height: 1.2;
    height: 480px;
    max-height: calc(100vh - 96px);
  }

  .error {
    color: red;
  }

  .title {
    position: relative;
    display: flex;
    width: 100%;
    margin: -16px -16px 0;
    padding: 10px 16px;
    align-items: center;
    justify-content: center;
    height: 28px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex: 0 0 auto;

    h1 {
      font-size: 15px;
      margin: 0;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 -16px;
    padding: 8px 16px 0;
    flex: 1 1 auto;
    overflow: hidden;
  }

  .search {
    display: block;
    position: relative;
    height: 32px;
    width: 100%;
    margin: 0;
    background: #f5f5f5;
    box-sizing: border-box;
    border-radius: 17px;
    padding: 4px 10px;
    border: none;
    font: 14px/24px var(--main-font);
    transition: box-shadow 0.3s ease;
    outline: none;
    flex: 0 0 auto;

    &:focus {
      box-shadow: 0 0 0 2px var(--blue-color);
    }
  }

  .members {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    flex: 0 0 auto;
  }

  .member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 8px 0 0;
    padding: 3px 12px 3px 12px;
    background: var(--blue-color);
    border-radius: 16px;
    color: #fff;

    :global(.spinner) {
      transform: scale(0.8);
      margin-right: -5px;
      margin-left: 6px;
    }

    :global(.user-badge) {
      margin-left: -7px;
    }
  }

  .remove-member {
    transform: scale(0.75);
    cursor: pointer;
    margin-left: 8px;
    margin-bottom: -2px;
  }

  .scroll-box {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1 1 auto;
    margin: 0 -16px;
    padding: 0 16px;
  }

  .suggestions {
    display: flex;
    flex-direction: column;
    margin: 0 -32px;
  }

  .suggestions-title {
    margin: 4px 32px;
    font: bold 12px/16px var(--main-font);
    opacity: 0.5;
  }

  .suggestion {
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px 56px 5px 32px;
    cursor: pointer;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    flex: 1 1 auto;

    &.new::before {
      content: '+';
      width: 24px;
      height: 24px;
      border-radius: 100%;
      font-size: 18px;
      margin: 0px 8px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.1);
    }

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &.active {
      background: var(--blue-color);
      color: #fff;
    }
  }
</style>
