<script lang="ts">
  import type { User } from 'types'
  import Avatar from './Avatar.svelte'
  import { getUserName } from 'utils'

  export let user: User
  export let currentUser: User | undefined = undefined
  export let color: 'white' | 'black' = 'black'
  export let size: 'large' | 'medium' | 'small' | 'xsmall' = 'small'
  export let showExtraInfo = false
  export let showFullInfo = false

  $: name = getUserName(user, currentUser)
  $: fullInfo = user.email || user.phoneNumber

  ; // prettier-ignore
</script>

<div class={`user-badge ${color}`}>
  <Avatar {size} {user} {color} />
  <div class="user-info">
    <span>{name}</span>
    {#if showExtraInfo}
      <span class="user-sub-info"><slot name="extraInfo" /></span>
    {:else if showFullInfo && fullInfo && fullInfo !== name}
      <span class="user-sub-info">{fullInfo}</span>
    {/if}
  </div>
</div>

<style lang="scss">
  .user-badge {
    display: inline-flex;
    align-items: center;
  }

  .user-info {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
  }

  .user-sub-info {
    font-size: 10px;
    padding-top: 1px;
    opacity: 0.7;
    white-space: pre;
  }

  .white {
    color: #fff;
  }

  span {
    line-height: 1;
  }
</style>
