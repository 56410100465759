<script lang="ts">
  import { addAppleAccount, addGoogleAccount, addError, openAuthWindow } from 'actions'
  import { session } from 'stores'
  import GoogleIcon from 'assets/icons/google.svg'
  import AppleIcon from 'assets/icons/apple.svg'
  import Button from 'components/Button.svelte'

  let connectingAppleAccount = false
  let connectingGoogleAccount = false

  async function connectAccount(provider: 'google' | 'apple') {
    try {
      if (provider === 'apple') {
        const { code, idToken } = await openAuthWindow(provider)
        connectingAppleAccount = true
        await addAppleAccount(code, idToken)
      } else {
        const { idToken } = window.ipc
          ? await window.ipc.request('GOOGLE_AUTH')
          : await openAuthWindow('google')
        connectingGoogleAccount = true
        await addGoogleAccount(idToken)
      }
    } catch (error: any) {
      addError(error.message || error.toString())
    }

    if (provider === 'apple') {
      connectingAppleAccount = false
    } else {
      connectingGoogleAccount = false
    }
  }
</script>

{#if $session?.profile}
  <div class="accounts">
    <div class="account">
      <div class="account-name">
        <GoogleIcon /> Google
      </div>
      <div class="account-btn">
        {#if $session.profile.providers?.includes('Google')}
          Connected
        {:else}
          <Button loading={connectingGoogleAccount} on:click={() => connectAccount('google')}
            >Connect</Button>
        {/if}
      </div>
    </div>
    <div class="account apple">
      <div class="account-name">
        <AppleIcon /> Apple
      </div>
      <div class="account-btn">
        {#if $session.profile.providers?.includes('Apple')}
          Connected
        {:else}
          <Button loading={connectingAppleAccount} on:click={() => connectAccount('apple')}
            >Connect</Button>
        {/if}
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .accounts {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .account {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.05);
  }

  .account-name {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    flex: 2 1 auto;

    > :global(svg) {
      margin-right: 6px;
    }

    .apple & > :global(svg path) {
      fill: black;
    }
  }

  .account-btn {
    width: 96px;
    text-align: center;

    > :global(*) {
      background: rgba(0, 0, 0, 0.1);
      height: 32px;
      width: 100%;
      margin: 0;
    }
  }
</style>
