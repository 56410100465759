<script lang="ts">
  import type { User, Community, ContactPoint } from 'types'
  import { onDestroy, createEventDispatcher } from 'svelte'
  import Dropzone from 'svelte-file-dropzone'
  import {
    createCommunity,
    updateCommunity,
    uploadCommunityPhoto,
    addMembers,
    removeMembers,
    addError,
  } from 'actions'
  import { isContactPointsEqual, mapContactPoints, contactPointToMember, convertImage } from 'utils'
  import CameraIcon from 'assets/icons/photo-camera-blue.svg'
  import CloseIcon from 'assets/icons/cross.svg'
  import PlusIcon from 'assets/icons/plus.svg'
  import BackIcon from 'assets/icons/back.svg'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'
  import Switch from 'components/Switch.svelte'
  import Spinner from 'components/Spinner.svelte'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'
  import ContactPointList from 'components/ContactPointList.svelte'
  import { session } from 'stores'

  export let community: Community | undefined = undefined

  const dispatch = createEventDispatcher<{ close: undefined }>()

  let currentUser: User | undefined = undefined
  let isNew = true
  let isPrivate = false
  let members: ContactPoint[] = []
  let imageURL: string | undefined
  let title = ''
  let dropzoneEl: HTMLDivElement
  let isMainScreen = true
  let isDragged = false
  let isPhotoUploading = false
  let isSaving = false
  let canCreate = false
  let canSave = false
  let error = ''
  let imageFile: Blob | undefined
  const unsubscribes: (() => void)[] = []

  $: canCreate = isNew && !!title && members.length > 0
  $: {
    if (community) {
      canSave = community.title !== title || community?.isPublic === isPrivate
    }
  }
  $: members = community?.members
    ? community.members.map(user => ({ user }))
    : [{ user: currentUser }]
  $: updateInfo(community)

  unsubscribes.push(
    session.subscribe(session => {
      currentUser = session?.profile
    }),
  )

  function updateInfo(community: Community | undefined) {
    isNew = community === undefined
    isPrivate = !(community?.isPublic || false)
    members = community?.members
      ? community.members.map(user => ({ user }))
      : [{ user: currentUser }]
    imageURL = community?.imageURL
    title = community?.title || ''
  }

  async function handleFilesSelect(e: any) {
    const { acceptedFiles }: { acceptedFiles: File[] } = e.detail

    isDragged = false

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]

      imageFile = await convertImage(file)

      if (community) {
        isPhotoUploading = true
        try {
          const updatedCommunity = await uploadCommunityPhoto(community.id, imageFile)
          updateInfo({ ...community, ...updatedCommunity })
        } catch (e) {
          addError(e)
        }
        isPhotoUploading = false
      }
    }
  }

  function canRemoveMember(member: ContactPoint) {
    return isNew
      ? member.user?.id !== currentUser?.id
      : community!.roles[member.user?.id || ''] !== 'owner'
  }

  async function addMember(contact: ContactPoint) {
    if (!members.find(r => isContactPointsEqual(r, contact))) {
      members = [...members, contact]

      const member = contactPointToMember(contact)
      if (community && member) {
        try {
          await addMembers(community.id, [member])
        } catch (e) {
          addError(e)
          members = members.filter(r => !isContactPointsEqual(r, contact))
        }
      }
    }
  }

  async function removeMember(member: ContactPoint) {
    members = members.filter(r => !isContactPointsEqual(r, member))

    if (community && member.user) {
      try {
        await removeMembers(community.id, [member.user.id])
      } catch (e) {
        addError(e)
        members = [...members, member]
      }
    }
  }

  function back() {
    isMainScreen = true
  }

  async function save() {
    isSaving = true
    error = ''
    try {
      if (isNew) {
        const community = await createCommunity(
          {
            title,
            isPublic: !isPrivate,
            roles: {
              ...(currentUser && { [currentUser?.id]: 'owner' }),
            },
            members: mapContactPoints(members, true),
          },
          imageFile,
        )
        console.log('New community', community)
        dispatch('close')
      } else {
        isMainScreen = true

        if (community) {
          await updateCommunity(community.id, {
            title,
            isPublic: !isPrivate,
            roles: community!.roles,
            members: mapContactPoints(members, true),
          })
        }
      }
    } catch (e: any) {
      error = e.message || e.error || e
    }
    isSaving = false
  }

  onDestroy(() => unsubscribes.forEach(un => un()))

  ; // prettier-ignore
</script>

<div class="settings" class:main={isMainScreen}>
  <div class="screens">
    <div class="screen">
      <div class="title">
        {#if isNew}
          <h1>Create {isPrivate ? 'Private' : 'Public'} Community</h1>
        {:else}
          <h1>#{title}</h1>
        {/if}
      </div>
      <div class="photo" class:dragged={isDragged}>
        {#if imageURL}
          <div class="photo-image">
            <img src={imageURL} alt="" referrerpolicy="no-referrer" />
          </div>
        {/if}
        <div class="photo-dropzone" bind:this={dropzoneEl}>
          <Dropzone
            accept="image/png,image/jpg,image/jpeg"
            disableDefaultStyles={true}
            multiple={false}
            on:dragenter={() => {
              isDragged = true
            }}
            on:dragleave={() => {
              isDragged = false
            }}
            on:drop={handleFilesSelect}>
            {#if !imageURL}
              <div class="photo-blank">
                <CameraIcon />
              </div>
            {/if}
          </Dropzone>
        </div>
        {#if isPhotoUploading}
          <div class="photo-spinner"><Spinner color="#fff" /></div>
        {/if}
      </div>
      <div class="photo-change" on:click={() => dropzoneEl.querySelector('input')?.click()}>
        {imageURL ? 'Change' : 'Add'} a community photo
      </div>
      <div class="inputs">
        <Input bind:value={title} name="title" label=" " placeholder="Community name (required)" />
        <div class="public">
          <div>
            <h3>Make Private</h3>
            When a community is set to private, it can<br />
            only be view or joined by invitation
          </div>
          <Switch bind:checked={isPrivate} />
        </div>
        <div class="members">
          <h3>Members</h3>
          {#each members as member}
            <div class="member">
              <ContactPointBadge
                size="large"
                showFullInfo={true}
                contactPoint={member}
                {currentUser} />
              {#if canRemoveMember(member)}
                <div class="remove-member" on:click={() => removeMember(member)}>
                  <CloseIcon />
                </div>
              {/if}
            </div>
          {/each}
          <div
            class="member add"
            on:click={() => {
              isMainScreen = false
            }}>
            <PlusIcon />
            <div class="name">add members</div>
          </div>
        </div>
        <div class="error">{error}</div>
        <div class="btns">
          <div class="info-btn">What is a Boom Community?</div>
          {#if isNew}
            <Button loading={isSaving} disabled={!canCreate} on:click={save}>Create</Button>
          {:else}
            <Button loading={isSaving} disabled={!canSave} on:click={save}>Save</Button>
          {/if}
        </div>
      </div>
    </div>
    <div class="screen">
      <div class="title">
        <div class="back" on:click={back}>
          <BackIcon />
        </div>
        <h1>Choose Members</h1>
        {#if canSave}
          <Button loading={isSaving} on:click={save}>Save</Button>
        {/if}
      </div>
      <ContactPointList
        bind:contactPoints={members}
        addContactPoint={addMember}
        canRemoveContactPoint={canRemoveMember}
        removeContactPoint={removeMember} />
    </div>
  </div>
</div>

<style lang="scss">
  .settings {
    border-radius: 12px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.85);
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    font-size: 15px;
    color: #000;
    line-height: 1.2;
  }

  .screens {
    height: 100%;
    width: 200%;
    display: flex;
    transition: transform 0.3s ease;

    .settings:not(.main) & {
      transform: translateX(-50%);
    }
  }

  .screen {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 32px 32px 16px;
    width: 50%;
  }

  .inputs {
    width: 100%;

    > :global(.input) {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 12px;
      background: rgba(255, 255, 255, 0.25);
    }

    > :global(*) {
      margin-bottom: 12px;
    }
  }

  .error {
    color: red;
  }

  .title {
    position: relative;
    display: flex;
    width: 100%;
    margin: -32px -32px 8px;
    padding: 10px 16px 16px;
    align-items: center;
    height: 28px;

    h1 {
      font-size: 15px;
      margin: 0;
    }

    .back {
      position: relative;
      margin: 0 0 -4px -24px;
      cursor: pointer;
    }
  }

  .photo {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .photo-dropzone,
  .photo-image,
  .photo-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .photo-dropzone {
    > :global(*) {
      width: 100%;
      height: 100%;
    }
  }

  .photo-spinner {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photo-change {
    padding: 6px 0 10px;
    color: var(--blue-color);
    text-align: center;
    font-size: 15px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .photo-blank {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: 15px;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-align: center;
    transition: background 0.3s ease;

    .photo.dragged & {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  h3 {
    font-size: 15px;
    margin: 0;
  }

  .public {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 0 4px;
    margin: 16px 0;

    div {
      flex: 0 0 auto;
    }

    :global(.switch) {
      flex: 0 0 auto;
    }
  }

  .members {
    margin: 16px 0;
    padding: 0 4px;
  }

  .member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    &.add {
      cursor: pointer;

      :global(svg) {
        margin: 1px 7px 0px 2px;
      }

      .name {
        flex: 2 0 auto;
      }
    }
  }

  .remove-member {
    transform: scale(0.75);
    cursor: pointer;

    :global(svg path) {
      fill: black;
    }
  }

  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

    :global(button) {
      width: 128px;
      height: 40px;
      padding: 8px 12px;
      margin: 0;
      border-radius: 20px;
      box-sizing: border-box;
      background: var(--blue-color);
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      transition: background 0.2s;

      :global(.spinner div) {
        background-color: #fff;
      }

      &:disabled {
        background: #afafaf;
      }
    }
  }

  .info-btn {
    font-size: 12px;
    color: var(--blue-color);
    cursor: pointer;
  }
</style>
