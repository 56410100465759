<script lang="ts">
  import type { Thread, Message } from 'types'
  import { onMount, onDestroy, createEventDispatcher, tick } from 'svelte'
  import hotkeys from 'hotkeys-js'
  import { newMessages, session } from 'stores'
  import { setThumbsActive } from 'actions'
  import { animateProperty, getThreadRecipients, hasEmoji } from 'utils'
  import UserBadge from 'components/UserBadge.svelte'
  import Spinner from 'components/Spinner.svelte'
  import TimeAgo from 'components/TimeAgo.svelte'

  type Group = {
    thread?: Thread
    messages: Message[]
  }

  export let threads: Thread[] = []
  export let messages: Message[] = []
  export let position: 'bottom' | 'right' = 'bottom'
  export let activeThumbId: string | undefined = undefined
  export let loadingPrevious = false
  export let loadingNext = false
  export let disableNotWatched = false

  let thumbsScrollBox: HTMLDivElement
  let verticalMessagesMap: { [key: string]: boolean } = {}
  let dontScrollToActiveThumb = false
  let previousLoader: HTMLDivElement
  let nextLoader: HTMLDivElement
  let firstThumb: HTMLDivElement | undefined
  let firstThumbPosition = 0

  const observers: IntersectionObserver[] = []

  const dispatch = createEventDispatcher<{
    activeChanged: string
    activeThreadChanged: Thread
    addRecipientsFor: Message
    reachedStart: string
    reachedEnd: string
  }>()

  $: activeThumbId && scrollToActiveThumbIfNeeded()
  $: handleLoadingNext(loadingNext)
  $: groups = updateGroups(threads, messages)
  $: activeGroupIndex = groups.findIndex(group => group.messages.find(m => m.id === activeThumbId))

  export function scrollToActiveThumbIfNeeded(withAnimation = true): void {
    !dontScrollToActiveThumb && setTimeout(() => scrollToActiveThumb(withAnimation))
    dontScrollToActiveThumb = false
  }

  function updateGroups(threads: Thread[], messages: Message[]): Group[] {
    const groups: Group[] = []

    for (const message of messages) {
      const group = groups[groups.length - 1]

      if (group && group.thread && group.thread?.id === message.threadId) {
        group.messages.push(message)
      } else {
        groups.push({
          thread: threads.find(t => t.id === message.threadId),
          messages: [message],
        })
      }
    }

    return groups
  }

  async function handleLoadingNext(loadingNext: boolean) {
    await tick()

    if (loadingNext) {
      firstThumb = thumbsScrollBox.querySelector('.thumb') as HTMLDivElement
      firstThumbPosition = firstThumb ? firstThumb.offsetLeft : 0
    } else if (firstThumb) {
      thumbsScrollBox.scrollLeft = firstThumb.offsetLeft - firstThumbPosition
      firstThumb = undefined
    }
  }

  function scrollToActiveThumb(withAnimation = true) {
    if (!thumbsScrollBox) return
    const activeThumb = thumbsScrollBox.querySelector('.active') as HTMLElement
    if (!activeThumb) return

    const scrollLeft =
      activeThumb.offsetLeft - (thumbsScrollBox.offsetWidth - activeThumb.offsetWidth) / 2

    if (withAnimation) {
      animateProperty(thumbsScrollBox, 'scrollLeft', scrollLeft)
    } else {
      thumbsScrollBox.scrollLeft = scrollLeft
    }
  }

  function updateImageOrientation(event: Event, id: string) {
    const image = event.target as HTMLImageElement

    if (image.naturalWidth && image.naturalHeight) {
      verticalMessagesMap = {
        ...verticalMessagesMap,
        [id]: image.naturalHeight > image.naturalWidth,
      }
    }
  }

  function moveToThumb(step: number) {
    const group = groups[activeGroupIndex]
    const messageIndex = group.messages.findIndex(message => message.id === activeThumbId)

    if (messageIndex + step >= 0 && messageIndex + step < group.messages.length) {
      dispatch('activeChanged', group.messages[messageIndex + step].id)
    } else if (step > 0 && activeGroupIndex + 1 < groups.length) {
      dispatch('activeChanged', groups[activeGroupIndex + 1].messages[0].id)
    } else if (step < 0 && activeGroupIndex - 1 >= 0) {
      dispatch(
        'activeChanged',
        groups[activeGroupIndex - 1].messages[groups[activeGroupIndex - 1].messages.length - 1].id,
      )
    }
  }

  function goToThumb(message: Message) {
    dontScrollToActiveThumb = true
    dispatch('activeChanged', message.id)
  }

  function observeLoader(loader: HTMLDivElement, event: 'reachedStart' | 'reachedEnd') {
    const observer = new IntersectionObserver(
      ([target]) => target.isIntersecting && dispatch(event),
      { root: thumbsScrollBox, threshold: 0.5 },
    )
    observer.observe(loader)
    observers.push(observer)
  }

  function getRatio(message: Message) {
    return (
      (message.width || message.video?.width || 16) / (message.height || message.video?.height || 9)
    )
  }

  function handleGroupClick(group: { thread?: Thread; messages: Message[] }, isActive: boolean) {
    if (!group.thread && group.messages[0].description) {
      return dispatch('addRecipientsFor', group.messages[0])
    }

    if (isActive && group.thread) {
      dispatch('activeThreadChanged', group.thread)
    } else {
      dispatch('activeChanged', group.messages[0].id)
    }
  }

  onMount(() => {
    if (position === 'bottom') {
      hotkeys('left', () => moveToThumb(-1))
      hotkeys('right', () => moveToThumb(1))
    }

    observeLoader(previousLoader, 'reachedStart')
    observeLoader(nextLoader, 'reachedEnd')
  })

  onDestroy(() => {
    if (position === 'bottom') {
      hotkeys.unbind('left')
      hotkeys.unbind('right')
    }

    observers.forEach(observer => observer.disconnect())
  })

  ; // prettier-ignore
</script>

<div
  class={`thumbs ${position}`}
  on:mouseenter={() => setThumbsActive(true)}
  on:mouseleave={() => setTimeout(() => setThumbsActive(false))}>
  <div class="scrollbox" bind:this={thumbsScrollBox}>
    <div class="loader" class:active={loadingNext} bind:this={nextLoader}>
      <Spinner color="white" />
    </div>
    {#each groups as group, groupIndex}
      <div class="group" class:active={activeGroupIndex === groupIndex}>
        <div
          class="group-name"
          on:click={handleGroupClick.bind(null, group, activeGroupIndex === groupIndex)}>
          {#if group.thread}
            {#if group.thread.title}
              {#if !hasEmoji(group.thread.title)}
                #
              {/if}
              {group.thread.title}
            {:else}
              {#each getThreadRecipients(group.thread, $session) as user}
                <UserBadge {user} size="xsmall" color="white" />
              {/each}
            {/if}
          {:else if group.messages[0].description}
            Add recipients
          {:else}
            <Spinner color="white" />Processing
          {/if}
        </div>
        <div class="group-thumbs">
          {#each group.messages || [] as thumb (thumb.id)}
            <div
              class="thumb"
              class:not-watched={thumb.watched === false && !disableNotWatched}
              class:not-watched-replies={(thumb.unwatchedRepliesCount || 0) > 0 &&
                !disableNotWatched}
              class:with-replies={(thumb.repliesCount || 0) > 0 && !disableNotWatched}
              class:active={thumb.id === activeThumbId}
              class:square={getRatio(thumb) === 1}
              class:vertical={verticalMessagesMap[thumb.id] || getRatio(thumb) < 1}
              on:click={() => goToThumb(thumb)}>
              {#if thumb.sender}
                <div class="thumb-info">
                  <UserBadge
                    user={thumb.sender}
                    size="xsmall"
                    currentUser={$session?.profile}
                    showExtraInfo={true}
                    color="white">
                    <span slot="extraInfo">
                      {#if $newMessages[thumb.id]}
                        Uploading...
                      {:else}
                        <TimeAgo date={thumb.createdAt} withTime={true} />
                      {/if}
                    </span>
                  </UserBadge>
                </div>
              {/if}
              <div class="thumb-image">
                {#if thumb.thumbnailURL}
                  <img
                    src={`${thumb.thumbnailURL}${
                      /^blob/.test(thumb.thumbnailURL) ? '' : '?size=240'
                    }`}
                    alt={`Message ${thumb.id}`}
                    on:load={event => updateImageOrientation(event, thumb.id)} />
                {/if}
              </div>
            </div>
          {/each}
        </div>
      </div>
    {/each}
    <div class="loader" class:active={loadingPrevious} bind:this={previousLoader}>
      <Spinner color="white" />
    </div>
  </div>
</div>

<style lang="scss">
  .thumbs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 121px;
    opacity: 0.9;
    transform: translateY(80px);
    transition: transform var(--thumbs-transition-duration) ease,
      opacity var(--thumbs-transition-duration) ease;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      height: 200px;
      left: -300px;
      right: 0;
      bottom: 0;
      transition: height var(--thumbs-transition-duration) ease;
      background: linear-gradient(
        transparent 0,
        rgba(0, 0, 0, 0.1) 10%,
        rgba(0, 0, 0, 0.2) 15%,
        rgba(0, 0, 0, 0.3) 20%,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.5) 40%,
        rgba(0, 0, 0, 0.6) 100%
      );
      z-index: 2;
    }

    .scrollbox {
      position: relative;
      display: flex;
      height: 100%;
      align-items: flex-start;
      overflow-x: auto;
      white-space: nowrap;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overscroll-behavior: contain;
      -webkit-overflow-scrolling: touch;
      z-index: 2;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    :global(.active-thumbs) & {
      transform: translateY(0);
      height: 144px;
      opacity: 1;

      // &::before {
      //   height: 236px;
      // }
    }
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0 0;
    position: relative;
    margin: 0 4px 8px;
  }

  .group-name {
    color: #fff;
    height: 22px;
    padding: 4px 12px;
    margin-bottom: 4px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;

    & > :global(.user-badge:first-child) {
      margin-left: -4px;
    }

    & > :global(.user-badge:not(:first-child)::before) {
      content: ', ';
      margin-right: 4px;
    }

    .active > & {
      background: rgba(0, 0, 0, 0.75);
    }

    & > :global(.spinner) {
      margin: 0 4px 0 -4px;
    }
  }

  .group-thumbs {
    display: flex;
    flex-direction: row;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    padding: 6px 3px;

    .active > & {
      background: rgba(255, 255, 255, 0.2);
      border-color: rgba(255, 255, 255, 0.5);
    }
  }

  .thumb {
    position: relative;
    width: 140px;
    height: 80px;
    margin: 0;
    padding: 0 3px;
    box-sizing: content-box;
    flex: 0 0 auto;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease;

    // &:hover {
    //   width: 178px;
    //   height: 100px;
    //   z-index: 2;

    //   &.vertical {
    //     width: 56px;
    //   }

    //   &.square {
    //     width: 100px;
    //   }
    // }

    &.vertical {
      width: 45px;
    }

    &.square {
      width: 80px;
    }

    &.active {
      z-index: 1;
    }

    &.not-watched::after {
      content: 'new';
      position: absolute;
      top: 4px;
      right: 8px;
      padding: 0 6px;
      height: 16px;
      border-radius: 4px;
      background: var(--primary-color);
      z-index: 2;
      color: #fff;
      font-size: 8px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
    }

    // &.with-replies::after {
    //   content: '';
    //   width: 16px;
    //   padding: 0 4px;
    //   background: url('assets/icons/reply.svg') var(--blue-color) 6px 4px no-repeat;
    //   background-size: 12px;
    // }

    &.with-replies.not-watched-replies::after {
      content: 'new';
    }

    // &.not-watched-replies::after {
    //   background: url('assets/icons/reply.svg') red 28px 4px no-repeat;
    //   background-size: 12px;
    //   padding-right: 24px;
    // }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .thumb-image {
    position: relative;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.7);

    .active > & {
      box-shadow: 0 0 0 1px #fff;
    }
  }

  .thumb-info {
    position: absolute;
    bottom: 0px;
    left: 3px;
    z-index: 1;
    transform-origin: 0 100%;
    right: 3px;
    padding: 12px 4px 2px;
    border-radius: 0 0 4px 4px;
    overflow: hidden;
    background: linear-gradient(
      transparent 0,
      rgba(0, 0, 0, 0.1) 10%,
      rgba(0, 0, 0, 0.2) 15%,
      rgba(0, 0, 0, 0.3) 20%,
      rgba(0, 0, 0, 0.4) 30%,
      rgba(0, 0, 0, 0.5) 40%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }

  .loader {
    width: 12px;
    height: 80px;
    margin: 48px 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &:not(.active) > :global(*) {
      display: none;
    }

    &.active {
      width: 80px;
    }
  }
</style>
