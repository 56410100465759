import { ReportReason } from '../types'

export const REPORT_REASON_TITLES = {
  [ReportReason.spam]: "It's spam",
  [ReportReason.nudity]: 'Nudity or sexual activity',
  [ReportReason.hateSpeech]: 'Hate speech or symbols',
  [ReportReason.violence]: 'Violence or dangerous organizations',
  [ReportReason.harassment]: 'Harassment or bullying',
  [ReportReason.sellingIllegalGoods]: 'Sell of illegal or regulated goods',
  [ReportReason.intellectualPropertyViolation]: 'Intellectual property violation',
  [ReportReason.suicide]: 'Suicide or self-injury',
  [ReportReason.eatingDisorder]: 'Eating disorders',
  [ReportReason.scam]: 'Scam or fraud',
  [ReportReason.falseInformation]: 'False information',
}
