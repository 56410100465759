// import { isElectron } from 'env'
import { isSameMediaDevice } from 'utils'

export enum CaptureMode {
  CAMERA = 'CAMERA',
  SCREEN = 'SCREEN',
  SCREEN_AND_CAMERA = 'SCREEN_AND_CAMERA',
}

export default class CaptureModule {
  mode: CaptureMode = CaptureMode.CAMERA

  videoTrack?: MediaStreamTrack
  audioTrack?: MediaStreamTrack
  screenVideoTrack?: MediaStreamTrack

  videoDevice?: MediaDeviceInfo
  audioDevice?: MediaDeviceInfo | null
  screenId?: string

  // outputVideoTrack: MediaStreamTrack

  outputStream?: MediaStream
  previewStream?: MediaStream

  _canvasVideo: HTMLVideoElement
  _canvas: HTMLCanvasElement
  _ctx: CanvasRenderingContext2D
  _dx = 0
  _dy = 0
  _dw = 0
  _dh = 0
  _renderRequest = 0
  _destroyed = false

  sizes = { width: 1920, height: 1080 }
  // sizes = { width: 1280, height: 720 }

  constructor() {
    this._canvasVideo = document.createElement('video')
    this._canvas = document.createElement('canvas')
    this._canvas.width = this.sizes.width
    this._canvas.height = this.sizes.height

    this._ctx = this._canvas.getContext('2d', { alpha: false })!

    // this._ctx.fillStyle = 'black'
    // this._ctx.fillRect(0, 0, this.sizes.width, this.sizes.height)
    // this._ctx.translate(this._canvas.width, 0)
    // this._ctx.scale(-1, 1)
    // this._ctx.imageSmoothingEnabled = true
    // this._ctx.imageSmoothingQuality = 'high'
    // this._ctx.filter = isElectron ? 'saturate(1.05) contrast(1.05)' : ''

    // this.render()
    // this.outputVideoTrack = this._canvas.captureStream!(30).getVideoTracks()[0]
    // this.outputStream = new MediaStream([this.outputVideoTrack])
    // this.previewStream = this.outputStream
  }

  render(): void {
    if (!this._canvasVideo || !this._ctx) return
    this._ctx.drawImage(this._canvasVideo, this._dx, this._dy, this._dw, this._dh)
    this._renderRequest = requestAnimationFrame(this.render.bind(this))
  }

  stopRender(): void {
    cancelAnimationFrame(this._renderRequest)
  }

  async capture(
    mode: CaptureMode,
    videoDevice?: MediaDeviceInfo,
    audioDevice?: MediaDeviceInfo | null,
    screenId?: string,
  ): Promise<{ output: MediaStream; preview?: MediaStream }> {
    this.mode = mode

    if (!isSameMediaDevice(this.videoDevice, videoDevice)) {
      this.videoDevice = videoDevice
      this.videoTrack?.stop()
      this.videoTrack = await this.getVideoTrack()
    }

    if (!isSameMediaDevice(this.audioDevice, audioDevice)) {
      this.audioDevice = audioDevice
      this.audioTrack?.stop()

      if (this.audioDevice !== null) {
        this.audioTrack = await this.getAudioTrack()
      }
    }

    if (mode === CaptureMode.SCREEN || mode === CaptureMode.SCREEN_AND_CAMERA) {
      if (this.screenId !== screenId || !this.screenVideoTrack) {
        this.screenId = screenId
        this.screenVideoTrack?.stop()

        let stream: MediaStream | undefined

        if (this.screenId === 'browser' && navigator.mediaDevices.getDisplayMedia) {
          stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
              ...this.sizes,
            },
          })
        } else {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
              mandatory: {
                chromeMediaSource: 'desktop',
                chromeMediaSourceId: screenId,
              },
            } as any,
          })
        }

        const [videoTrack] = stream ? stream.getVideoTracks() : []
        if (!videoTrack) throw new Error('Failed to capture screen')

        this.screenVideoTrack = videoTrack
      }
    } else {
      this.screenId = undefined
      this.screenVideoTrack?.stop()
    }

    console.log('CAPTURE', this.mode, this.videoTrack, this.audioTrack, this.screenVideoTrack)

    let output: MediaStream
    let preview: MediaStream | undefined

    if (mode === CaptureMode.CAMERA) {
      // this.outputVideoTrack = this._canvas.captureStream!(30).getVideoTracks()[0]
      output = new MediaStream([this.videoTrack!, ...(this.audioTrack ? [this.audioTrack] : [])])
      preview = output
      // this.videoTrack && this.captureVideoTrack(this.videoTrack)
    } else {
      output = new MediaStream([
        this.screenVideoTrack!,
        ...(this.audioTrack ? [this.audioTrack] : []),
      ])
      // this.outputVideoTrack.stop()
      // this.screenVideoTrack && this.captureVideoTrack(this.screenVideoTrack)
      preview = this.videoTrack ? new MediaStream([this.videoTrack]) : preview
    }

    this.outputStream = output
    this.previewStream = preview
    // this.updateStream(this.outputStream, output)

    // If component was destroyed during capturing, clean it up once again
    if (this._destroyed) {
      this.destroy()
      throw new Error('Module is destroyed')
    }

    return { output: this.outputStream, preview: this.previewStream }
  }

  async getVideoTrack(): Promise<MediaStreamTrack> {
    console.log(
      'constraints',
      {
        ...this.sizes,
        ...(this.videoDevice ? { deviceId: { exact: this.videoDevice.deviceId } } : {}),
      },
      this.videoDevice,
    )

    const [videoTrack] = (
      await navigator.mediaDevices.getUserMedia({
        video: {
          ...this.sizes,
          ...(this.videoDevice ? { deviceId: { exact: this.videoDevice.deviceId } } : {}),
        },
        audio: false,
      })
    ).getVideoTracks()

    if (!videoTrack) throw new Error('Failed to capture video stream')

    return videoTrack
  }

  async getAudioTrack(): Promise<MediaStreamTrack> {
    const [audioTrack] = (
      await navigator.mediaDevices.getUserMedia({
        audio: this.audioDevice ? { deviceId: { exact: this.audioDevice.deviceId } } : true,
        video: false,
      })
    ).getAudioTracks()
    if (!audioTrack) throw new Error('Failed to capture audio stream')

    if (audioTrack && audioTrack.getCapabilities) {
      const capabilities = audioTrack.getCapabilities()
      const advanced: any = []

      capabilities.autoGainControl && advanced.push({ autoGainControl: true })
      capabilities.echoCancellation && advanced.push({ echoCancellation: true })
      capabilities.noiseSuppression && advanced.push({ noiseSuppression: true })

      advanced.length > 0 && (await audioTrack.applyConstraints({ advanced }))
    }

    return audioTrack
  }

  captureVideoTrack(videoTrack: MediaStreamTrack): void {
    const videoSettings = videoTrack.getSettings()
    const videoWidth = videoSettings.width!
    const videoHeight = videoSettings.height!
    const { width, height } = this.sizes

    const videoRatio = videoWidth / videoHeight

    this._dx = 0
    this._dy = 0

    if (videoRatio > width / height === (this.mode !== CaptureMode.CAMERA)) {
      this._dw = width
      this._dh = this._dw / videoRatio
      this._dy = (height - this._dh) / 2
    } else {
      this._dh = height
      this._dw = this._dh * videoRatio
      this._dx = (width - this._dw) / 2
    }

    this._canvasVideo.srcObject = new MediaStream([videoTrack])
    this._canvasVideo.play()

    this.stopRender()
    this.render()

    console.log(
      [
        'New video stream',
        `Mode: ${this.mode}`,
        `Resolution: ${videoWidth}x${videoHeight}`,
        `Adjustmens: x: ${this._dx}, y: ${this._dy}, width: ${this._dw}, height: ${this._dh}`,
      ].join(', '),
      videoTrack,
    )
  }

  updateStream(current: MediaStream, updated: MediaStream): void {
    const currentVideo = current.getVideoTracks()
    const updatedVideo = updated.getVideoTracks()

    if (this.tracksKey(currentVideo) !== this.tracksKey(updatedVideo)) {
      console.log('video tracks update', currentVideo, updatedVideo)
      updatedVideo.forEach(t => current.addTrack(t))
      currentVideo.forEach(track => {
        track.stop()
        current.removeTrack(track)
      })
    }

    const currentAudio = current.getAudioTracks()
    const updatedAudio = updated.getAudioTracks()

    if (this.tracksKey(currentAudio) !== this.tracksKey(updatedAudio)) {
      console.log('audio tracks update', currentAudio, updatedAudio)
      updatedAudio.forEach(track => current.addTrack(track))
      currentAudio.forEach(track => {
        track.stop()
        current.removeTrack(track)
      })
    }
  }

  tracksKey(track: MediaStreamTrack[]): string {
    return track
      .map(t => t.id)
      .sort()
      .join('')
  }

  destroy(): void {
    this._destroyed = true
    this.videoTrack?.stop()
    this.audioTrack?.stop()
    this.screenVideoTrack?.stop()
    // this.outputVideoTrack?.stop()
    this.outputStream?.getTracks().forEach(track => track.stop())
    this.previewStream?.getTracks().forEach(track => track.stop())
    this.stopRender()
  }
}
