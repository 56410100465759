import type { ProfileData } from 'api/users'
import type { PasswordsData } from 'api/auth'
import { session } from 'stores'
import { usersAPI, authAPI, uploadsAPI } from 'api'

export async function updateProfile(data: Partial<ProfileData>): Promise<void> {
  const profile = await usersAPI.updateProfile(data)
  session.update(session => (session ? { ...session, profile } : null))
}

export async function changePassword(
  data: PasswordsData | Omit<PasswordsData, 'currentPassword'>,
): Promise<void> {
  if ((data as PasswordsData).currentPassword) {
    await authAPI.changePassword(data as PasswordsData)
  } else {
    await authAPI.setPassword(data)
  }

  session.update(session =>
    session ? { ...session, profile: { ...session.profile, hasPassword: true } } : null,
  )
}

export async function uploadAvatar(file: Blob): Promise<void> {
  const { id } = await uploadsAPI.upload(file)
  const profile = await usersAPI.updateProfile({ imageId: id })
  session.update(session => (session ? { ...session, profile } : null))
}

export async function addAppleAccount(authCode: string, idToken: string): Promise<void> {
  const profile = await usersAPI.addAppleAccount(authCode, idToken)
  session.update(session => (session ? { ...session, profile } : null))
}

export async function addGoogleAccount(idToken: string): Promise<void> {
  const profile = await usersAPI.addGoogleAccount(idToken)
  session.update(session => (session ? { ...session, profile } : null))
}

export async function deleteAccount(): Promise<void> {
  await usersAPI.deleteAccount()
  session.set(null)
}

export async function savePhoneNumber(phoneNumber: string, code: string): Promise<void> {
  const profile = await authAPI.addPhone(phoneNumber, code)
  session.update(session => (session ? { ...session, profile } : null))
}
