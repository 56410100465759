<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { RecordingState } from 'vendors/videokit'
  import { formatTime } from 'utils'
  import { draggable } from 'utils/dragAction'
  import SendIcon from 'assets/icons/record-send.svg'
  import RecordCameraIcon from 'assets/icons/record-face.svg'
  import RecordScreenIcon from 'assets/icons/record-screen.svg'
  import RecordScreenFaceIcon from 'assets/icons/record-screen-face.svg'
  import ReplyImageIcon from 'assets/icons/image.svg'
  import PlayIcon from 'assets/icons/record-play.svg'
  import PauseIcon from 'assets/icons/record-pause.svg'
  import SettingsIcon from 'assets/icons/settings.svg'
  import RestartIcon from 'assets/icons/restart.svg'
  import SaveIcon from 'assets/icons/record-save.svg'
  import TrashIcon from 'assets/icons/record-trash.svg'
  import UploadIcon from 'assets/icons/record-upload.svg'
  import CloseIcon from 'assets/icons/cross.svg'
  import { isElectron } from 'env'
  import Tooltip from 'components/Tooltip.svelte'
  import Spinner from 'components/Spinner.svelte'
  import Countdown from 'components/Countdown.svelte'
  import SourceSettings from './SourceSettings.svelte'

  export let root: HTMLElement
  export let sourceSettings: SourceSettings
  export let videoDevice: MediaDeviceInfo | undefined
  export let audioDevice: MediaDeviceInfo | undefined | null
  export let recordingState: RecordingState
  export let screenRecordingMode = false
  export let recordingTime = 0
  export let previewTime = 0
  export let isPreviewPlaying = false
  export let showCameraCapture = false
  export let isPreparingRecorder = false
  export let isSending = false
  export let captureError: Error | undefined
  export let withAIButton = false
  export let showCountdown = false

  const _dispatch = createEventDispatcher<{
    toggleCameraCapture: boolean | undefined
    toggleRecord: undefined
    startScreenRecordingMode: boolean
    stopScreenRecordingMode: undefined
    close: undefined
    upload: File
    restart: undefined
    remove: undefined
    finish: undefined
    goToAI: undefined
    send: { x: number; y: number }
  }>()
  const dispatch = (...args: any[]) => !isDragging && _dispatch.apply(null, args as any)

  let fileInput: HTMLInputElement
  let isSettingsActive = false
  let controlsOnTop = false
  let tooltipPosition: 'top' | 'bottom' = 'top'
  let isHovered = false
  let compactMode = false
  let isDragging = false
  let tooltipDisabled = false

  $: isCapturing = [RecordingState.IDLE, RecordingState.CAPTURING].indexOf(recordingState) !== -1
  $: isRecording = [RecordingState.RECORDING, RecordingState.PAUSED].indexOf(recordingState) !== -1
  $: isRecordingPaused = recordingState === RecordingState.PAUSED
  $: isPreview = recordingState === RecordingState.PREVIEW
  $: {
    if (!screenRecordingMode) {
      root?.removeAttribute('style')
      controlsOnTop = false
    }
  }
  $: tooltipPosition = controlsOnTop ? 'bottom' : 'top'
  $: compactMode = recordingState === RecordingState.RECORDING && !isHovered && isElectron
  $: tooltipDisabled = isDragging || compactMode

  function onControlsDragEnd({ detail }: { detail: { offsetX: number; offsetY: number } }) {
    controlsOnTop = -detail.offsetY > (root.parentElement?.offsetHeight || 0) / 2
    setTimeout(() => {
      isDragging = false
    }, 200)
  }

  function onFileSelect() {
    if (isDragging) return
    const file = fileInput.files?.item(0)
    file && dispatch('upload', file)
  }

  function send(event: Event) {
    if (isSending) return

    const { x, y, width, height } = (event.target as HTMLElement).getBoundingClientRect()

    dispatch('send', {
      x: x + width / 2,
      y: y + height / 2,
    })
  }

  function setIsHovered(value: boolean) {
    isHovered = value
  }

  function startScreenRecordingMode(withCamera: boolean) {
    dispatch('startScreenRecordingMode', withCamera)
    withCamera && setIsHovered(false)
  }
</script>

<div
  bind:this={root}
  use:draggable={{ disabled: !screenRecordingMode }}
  class="recorder-controls"
  on:svelte-drag:start={() =>
    setTimeout(() => {
      isDragging = true
    }, 200)}
  on:svelte-drag:end={onControlsDragEnd}
  class:top={controlsOnTop}
  on:mouseenter={() => setIsHovered(true)}
  on:mouseleave={() => setIsHovered(false)}
  class:compact={compactMode}>
  <button
    class="recorder-button border small shrinkable"
    on:mousedown|stopPropagation
    on:click={() => dispatch('close')}>
    <CloseIcon />
    <Tooltip title="Close" position={tooltipPosition} disabled={tooltipDisabled} />
  </button>
  <div class="separator shrinkable" />
  {#if !isPreview}
    {#if isCapturing}
      <button
        class="recorder-button square"
        on:mousedown|stopPropagation
        on:click={() => fileInput.click()}>
        <UploadIcon />
        <Tooltip title="Upload Video" position={tooltipPosition} />
        <input
          bind:this={fileInput}
          type="file"
          accept="video/mp4,video/webm,video/x-matroska"
          multiple={false}
          on:change={onFileSelect}
          style="display: none" />
      </button>
      <div class="separator" />
      <button class="recorder-button">
        <SettingsIcon />
        <SourceSettings
          bind:this={sourceSettings}
          bind:audioDevice
          bind:videoDevice
          bind:error={captureError}
          bind:isActive={isSettingsActive}
          disabled={isRecording || isDragging}
          bottom={controlsOnTop} />
        <Tooltip
          title="Settings"
          disabled={isSettingsActive || tooltipDisabled}
          position={tooltipPosition} />
      </button>
    {:else}
      <button
        class="recorder-button transparent thin shrinkable"
        on:mousedown|stopPropagation
        on:click={() => dispatch('save')}>
        <SaveIcon />
        <Tooltip title="Save to Drafts" position={tooltipPosition} />
      </button>
      <div class="separator shrinkable" />
      <button
        class="recorder-button shrinkable"
        on:mousedown|stopPropagation
        on:click={() => dispatch('remove')}>
        <TrashIcon />
        <Tooltip title="Delete" position={tooltipPosition} disabled={tooltipDisabled} />
      </button>
      <button
        class="recorder-button shrinkable"
        on:mousedown|stopPropagation
        on:click={() => dispatch('restart')}>
        <RestartIcon />
        <Tooltip title="Restart" position={tooltipPosition} disabled={tooltipDisabled} />
      </button>
    {/if}
    <div
      class="recorder-button time"
      class:recording={isRecording}
      class:paused={isRecordingPaused}>
      {formatTime(recordingTime)}
    </div>
    {#if screenRecordingMode && isElectron}
      <button
        class="recorder-button crossed scaled-down shrinkable"
        class:crossed-active={showCameraCapture}
        on:mousedown|stopPropagation
        on:click={() => dispatch('toggleCameraCapture')}>
        <RecordScreenFaceIcon />
        <Tooltip
          title={`${showCameraCapture ? 'Hide' : 'Show'} camera`}
          position={tooltipPosition}
          disabled={tooltipDisabled} />
      </button>
    {/if}
    {#if !screenRecordingMode}
      <div class="shrinkable">
        {#if isElectron}
          <div class="recorder-buttons-stack">
            <button
              class="recorder-button large red"
              on:mousedown|stopPropagation
              on:click={() => startScreenRecordingMode(false)}>
              <RecordScreenIcon />
            </button>
            <button
              class="recorder-button large red"
              on:mousedown|stopPropagation
              on:click={() => startScreenRecordingMode(true)}>
              <RecordScreenFaceIcon />
            </button>
          </div>
        {:else}
          <button
            class="recorder-button large red"
            on:mousedown|stopPropagation
            on:click={() => startScreenRecordingMode(false)}>
            <RecordScreenIcon />
            <Tooltip title="Record screen" position={tooltipPosition} />
          </button>
        {/if}
      </div>
    {/if}
    <button
      class="recorder-button large record"
      class:red={isCapturing || isRecordingPaused}
      class:recording={isRecording}
      class:paused={isRecordingPaused}
      on:mousedown|stopPropagation
      on:click={() => dispatch('toggleRecord')}>
      {#if isCapturing}
        {#if showCountdown}
          <div class="countdown"><Countdown /></div>
        {:else if isPreparingRecorder}
          <Spinner color="#fff" />
        {:else if screenRecordingMode}
          <RecordScreenIcon />
        {:else}
          <RecordCameraIcon />
        {/if}
        <Tooltip title="Record" position={tooltipPosition} disabled={tooltipDisabled} />
      {:else}
        {#if isRecordingPaused}
          {#if screenRecordingMode}
            <RecordScreenIcon />
          {:else}
            <RecordCameraIcon />
          {/if}
        {:else}
          <PauseIcon />
        {/if}
        <div class="inner">
          <button
            class="recorder-button small preview"
            on:mousedown|stopPropagation
            on:click|stopPropagation={() => dispatch('finish')}>
            <PlayIcon />
          </button>
          <Tooltip title="" position={tooltipPosition} disabled={tooltipDisabled} />
        </div>
      {/if}
    </button>
    {#if withAIButton && isCapturing}
      <button
        class="recorder-button large red"
        on:mousedown|stopPropagation
        on:click={() => dispatch('goToAI')}>
        <ReplyImageIcon width="28" height="28" />
        <Tooltip title="Generate AI image" position={tooltipPosition} />
      </button>
    {/if}
    {#if screenRecordingMode}
      <button
        class="recorder-button large red shrinkable"
        on:mousedown|stopPropagation
        on:click={() => dispatch('stopScreenRecordingMode')}>
        <RecordCameraIcon />
        <Tooltip title="Record camera" position={tooltipPosition} disabled={tooltipDisabled} />
      </button>
    {/if}
    {#if isRecording}
      <button
        class="recorder-button red border border-active send"
        on:mousedown|stopPropagation
        on:click={send}>
        {#if isSending}
          <Spinner color="#fff" />
        {:else}
          <SendIcon />
        {/if}
        <Tooltip
          title={isSending ? 'Sending' : 'Send'}
          position={tooltipPosition}
          disabled={tooltipDisabled} />
      </button>
    {/if}
  {:else}
    <button
      class="recorder-button transparent"
      on:mousedown|stopPropagation
      on:click={() => dispatch('save')}>
      <SaveIcon />
      <Tooltip title="Save to Drafts" position={tooltipPosition} />
    </button>
    <div class="separator" />
    <button
      class="recorder-button"
      on:mousedown|stopPropagation
      on:click={() => dispatch('remove')}>
      <TrashIcon />
      <Tooltip title="Delete" position={tooltipPosition} disabled={tooltipDisabled} />
    </button>
    <button
      class="recorder-button"
      on:mousedown|stopPropagation
      on:click={() => dispatch('restart')}>
      <RestartIcon />
      <Tooltip title="Restart" position={tooltipPosition} disabled={tooltipDisabled} />
    </button>
    <div class="recorder-button time">{formatTime(previewTime)}</div>
    <button
      class="recorder-button large"
      on:mousedown|stopPropagation
      on:click={() => {
        isPreviewPlaying = !isPreviewPlaying
      }}>
      {#if isPreviewPlaying}
        <PauseIcon />
        <Tooltip title="Pause" position={tooltipPosition} disabled={tooltipDisabled} />
      {:else}
        <PlayIcon />
        <Tooltip title="Play" position={tooltipPosition} disabled={tooltipDisabled} />
      {/if}
    </button>
    <button
      class="recorder-button red border border-active send"
      on:mousedown|stopPropagation
      on:click={send}>
      {#if isSending}
        <Spinner color="#fff" />
      {:else}
        <SendIcon />
      {/if}
      <Tooltip
        title={isSending ? 'Sending' : 'Send'}
        position={tooltipPosition}
        disabled={tooltipDisabled} />
    </button>
  {/if}
</div>

<style lang="scss">
  .recorder-controls {
    display: flex;
    position: absolute;
    bottom: 16px;
    right: 16px;
    height: 76px;
    background: rgba(0, 0, 0, 0.65);
    border-radius: 8px;
    padding: 0 8px;
    align-items: center;
    transition: all 0.5s ease, transform 0s;
    z-index: 2;

    &.compact {
      .shrinkable,
      :global(.recipients) {
        opacity: 0 !important;
        width: 0 !important;
        height: 0 !important;
        padding: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        transition: all 0.3s ease;
      }

      :global(.tooltip) {
        display: none;
      }
    }

    &.top {
      .recorder-button {
        :global(.tooltip > *) {
          margin-top: 10px;
          margin-bottom: 0;
        }

        &.large :global(.tooltip > *) {
          margin-top: 5px;
        }

        &.small :global(.tooltip > *) {
          margin-top: 15px;
        }
      }
    }
  }

  .recorder-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 100%;
    margin: 0 8px;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    user-select: none;
    flex: 0 0 auto;
    transition: all 0.3s ease;

    :global(.tooltip > *) {
      margin-bottom: 10px;
    }

    :global(.tooltip .body::before) {
      height: 21px;
    }

    & > :global(svg) {
      transition: all 0.5s ease;
    }

    &.border {
      &::before {
        content: '';
        position: absolute;
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
        border: 4px solid #fff;
        border-radius: 100%;
        opacity: 0;
      }
    }

    &.border-active {
      width: 44px;
      height: 44px;
      margin: 0 14px;

      &::before {
        opacity: 1;
      }
    }

    &.red {
      background-color: #e30000;

      &::before {
        border-color: #e30000;
      }
    }

    &.large {
      width: 60px;
      height: 60px;

      :global(.tooltip > *) {
        margin-bottom: 5px;
      }
    }

    &.small {
      width: 40px;
      height: 40px;

      :global(.tooltip > *) {
        margin-bottom: 15px;
      }
    }

    &.thin {
      margin: 0 2px;
    }

    &.time {
      position: relative;
      min-width: 65px;
      height: 28px;
      border-radius: 4px;
      color: #fff;
      cursor: default;

      &.recording {
        &::before {
          content: '';
          width: 6px;
          height: 6px;
          margin-right: 4px;
          border-radius: 100%;
          background: var(--primary-color);
        }

        &.paused::before {
          background: none;
          border-style: double;
          border-width: 0 7px 0 0;
          border-color: red;
          border-radius: 0;
          margin-left: -4px;
          height: 10px;
        }
      }

      .recorder-controls.compact & {
        transform: scale(0.7);
        transform-origin: 100% 100%;
        margin: 0 -70px -32px -12px;
      }
    }

    &.crossed::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 0;
      width: 1px;
      background: #fff;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: height 0.2s ease;
    }

    &.square {
      border-radius: 8px;
    }

    &.transparent {
      background: none;
    }

    &.crossed-active::before {
      height: calc(100% - 4px);
    }

    &.scaled-down :global(svg) {
      transform: scale(0.7);
    }

    &.preview {
      border: 5px solid #fff;
      margin: 0 auto;
    }

    &.record {
      transition: all 0.2s ease;

      .recorder-controls.compact & {
        transform: scale(0.6);
        transform-origin: 100% 0;
        margin-top: -7px;
      }

      &.recording {
        box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.5);
        width: 50px;
        height: 50px;
        margin: 0 13px;
      }

      &:not(.red) > :global(svg) {
        transform: scale(1.4);
      }

      .inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0;
        transition: top 0.2s step-end, opacity 0.2s;

        :global(.tooltip .body:after) {
          content: 'Pause';
        }
      }

      &.paused .inner :global(.tooltip .body:after) {
        content: 'Resume';
      }

      &:hover {
        .inner {
          top: -64px;
          transition: top 0.2s step-start, opacity 0.2s;
          opacity: 1;
        }

        .preview {
          opacity: 0.6;

          &:hover {
            opacity: 1;

            & + :global(.tooltip .body:after) {
              content: 'Preview';
            }
          }
        }
      }
    }
  }

  .shrinkable {
    transition: all 0.5s ease, transform 0s, margin-bottom 0s;
  }

  .separator {
    width: 1px;
    height: 50px;
    margin: 0 8px;
    background: rgba(255, 255, 255, 0.2);
  }

  .recorder-buttons-stack {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    width: 60px;
    height: 60px;
    transform: translateY(-50%);
    margin: 0 8px -60px;
    transition: height 0.2s step-end;

    &:hover {
      height: 136px;
      transition: height 0.2s step-start;

      .recorder-button {
        opacity: 0.6;

        &:not(:first-child) {
          opacity: 0.6;
          height: 60px;
          transition: height 0.2s step-start, opacity 0.2s;
        }

        &:hover {
          opacity: 1;
        }
      }
    }

    .recorder-button {
      overflow: hidden;
      margin: 0;
      transition: opacity 0.2s;

      &:not(:first-child) {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        height: 0;
        transition: height 0.2s step-end, opacity 0.2s;
      }

      &:hover + :global(.tooltip .body:after) {
        content: ' + Camera';
      }
    }
  }

  .countdown {
    font-size: 24px;
    color: #fff;
  }
</style>
