<script lang="ts">
  import { onMount, onDestroy } from 'svelte'
  import hotkeys from 'hotkeys-js'
  import { playerSettings } from 'stores'
  import { setShouldPlay } from 'actions/settings'
  import CloseIcon from 'assets/icons/cross.svg'

  export let title = ''
  export let transparent = false
  export let active = false
  export let scrollable = true
  export let noClose = false
  export let size: 'large' | 'medium' | 'small' | 'xsmall' = 'medium'
  export let onClose: () => void = () => (active = false)

  let wasPlaying = false
  let visible = false
  let visibleTimeout: any

  $: {
    if (active) {
      visibleTimeout && clearTimeout(visibleTimeout)
      visible = true
      wasPlaying = playerSettings.get()?.shouldPlay || false
      wasPlaying && setShouldPlay(false)
    } else {
      wasPlaying && setShouldPlay(true)
      visibleTimeout = setTimeout(() => {
        visible = false
      }, 200)
    }
  }

  onMount(() => {
    hotkeys('esc', onClose)
  })

  onDestroy(() => {
    hotkeys.unbind('esc', onClose)
    wasPlaying && setShouldPlay(true)
  })

  ; // prettier-ignore
</script>

<div class={`modal ${size}`} class:active class:scrollable class:transparent on:click={onClose}>
  <div class="body" on:click|stopPropagation>
    {#if title}
      <h1>{title}</h1>
    {/if}
    {#if !noClose}
      <div class="close" on:click={onClose}><CloseIcon /></div>
    {/if}
    {#if visible}
      <slot />
    {/if}
  </div>
</div>

<style lang="scss">
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: calc(100% - 32px);
    max-width: 640px;
    transform: translate(-50%, calc(-50% + 10px));
    opacity: 0;
    z-index: -1;
    transition: z-index 0.2s step-end, opacity 0.2s, transform 0.2s;

    &.large {
      max-width: 800px;
    }

    &.small {
      max-width: 420px;
    }

    &.xsmall {
      max-width: 320px;
    }

    &::before {
      content: '';
      position: absolute;
      top: -1000%;
      left: -1000%;
      right: -1000%;
      bottom: -1000%;
      background: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }

    &.active {
      transform: translate(-50%, -50%);
      opacity: 1;
      z-index: 100;
      transition: z-index 0.2s step-start, opacity 0.2s, transform 0.2s;
    }

    &.scrollable .body {
      overflow-y: auto;
    }
  }

  .body {
    position: relative;
    background: #fff;
    padding: 16px;
    border-radius: 8px;
    max-height: calc(100vh - 32px);
    z-index: 1;

    &::-webkit-scrollbar {
      display: none;
    }

    .transparent & {
      background: none;
      padding: 0;
    }
  }

  h1 {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 16px;
  }

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    cursor: pointer;

    > :global(svg path) {
      fill: black;
    }
  }
</style>
