import type { Thread, Message, MessageSearchResult } from 'types'
import * as api from './api'
import { getQueryString } from 'utils'

type CreateMessageData = {
  type: 'video' | 'image'
  videoId?: string
  thumbnailId?: string
  description?: string
  aiImageId?: string
}

type UpdateMessageData = {
  state?: string
  thumbnailId?: string
}

type MessageRecipients = {
  threadIds?: string[]
  userIds?: string[]
  usernames?: string[]
  emails?: string[]
  phoneNumbers?: string[]
}

export type MessagesFilters = {
  threadId?: string | null
  state?: 'available' | 'pending'
  feed?: true
  limit?: number
  offset?: number
  page?: number
  watched?: boolean
  before?: Date
  after?: Date
  [key: string]: any
}

export async function getMessages(filters: MessagesFilters = {}): Promise<Message[]> {
  const queryParams = Object.entries(filters).map(p => p.join('='))
  const query = queryParams.length ? `?${queryParams.join('&')}` : ''

  return (await api.get<{ messages: Message[] }>(`/messages${query}`)).messages
}

export async function getFeed(filters: { limit?: number; offset?: number } = {}): Promise<{
  messages: Message[]
  threads: Thread[]
}> {
  return api.get<{ messages: Message[]; threads: Thread[] }>(
    `/feed/messages${getQueryString(filters)}`,
  )
}

export async function getMessage(messageId: string): Promise<Message> {
  return (await api.get<{ message: Message }>(`/messages/${messageId}`)).message
}

export async function searchMessages(query: string): Promise<MessageSearchResult[]> {
  return (await api.get<{ results: MessageSearchResult[] }>(`/search/messages?query=${query}`))
    .results
}

export async function getMessageReplies(
  messageId: string,
  filters: MessagesFilters = {},
): Promise<Message[]> {
  const queryParams = Object.entries(filters).map(p => p.join('='))
  const query = queryParams.length ? `?${queryParams.join('&')}` : ''

  return (await api.get<{ messages: Message[] }>(`/messages/${messageId}/replies${query}`)).messages
}

export async function getNotWatchedMessages(): Promise<Message[]> {
  return (await api.get<{ messages: Message[] }>('/messages?watched=false&limit=100')).messages
}

export async function createMessage(
  recipients: MessageRecipients,
  data: CreateMessageData,
): Promise<Message[]> {
  return (await api.post<{ messages: Message[] }>('/messages', { recipients, ...data })).messages
}

export async function createReplyMessage(
  messageId: string,
  data: CreateMessageData,
): Promise<Message> {
  return (await api.post<{ message: Message }>(`/messages/${messageId}/reply`, data)).message
}

export async function updateMessage(messageId: string, data: UpdateMessageData): Promise<Message> {
  return (await api.put<{ message: Message }>(`/messages/${messageId}`, data)).message
}

export async function updateMessageRecipients(
  messageId: string,
  recipients: {
    userIds?: string[]
    threadId?: string
    newTopicName?: string
  },
): Promise<Message> {
  return (await api.put<{ message: Message }>(`/messages/${messageId}/recipients`, recipients))
    .message
}

export async function forward(
  messageId: string,
  recipients: MessageRecipients,
): Promise<Message[]> {
  return (await api.post<{ messages: Message[] }>(`/messages/${messageId}/forward`, { recipients }))
    .messages
}

export async function markMessageAsWatched(messageId: string): Promise<void> {
  return api.post<void>(`/messages/${messageId}/mark-as-watched`)
}

export async function like(messageId: string): Promise<{ likesCount: number }> {
  return api.post<{ likesCount: number }>(`/messages/${messageId}/like`)
}

export async function unlike(messageId: string): Promise<{ likesCount: number }> {
  return api.post<{ likesCount: number }>(`/messages/${messageId}/unlike`)
}

export async function shareMessage(messageId: string): Promise<string> {
  return (await api.post<{ url: string }>(`/messages/${messageId}/share`)).url
}

export async function getSharedMessage(token: string): Promise<Message> {
  return (await api.get<{ message: Message }>(`/share/${token}`)).message
}

export async function deleteMessage(messageId: string): Promise<void> {
  return api.del<void>(`/messages/${messageId}`)
}
