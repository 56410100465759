<script lang="ts">
  import type { User } from 'types'
  import { createEventDispatcher } from 'svelte'
  import { changePassword } from 'actions/profile'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'

  export let profile: User

  const dispatch = createEventDispatcher<{
    close: undefined
    error: string
  }>()

  let currentPassword = ''
  let newPassword = ''
  let repeatNewPassword = ''
  let error = ''
  let loading = false
  let canSubmit = false

  $: {
    canSubmit =
      (!!currentPassword || !profile.hasPassword) &&
      !!newPassword &&
      newPassword === repeatNewPassword
  }

  async function onSubmit() {
    if (loading || !canSubmit) return

    loading = true
    error = ''
    try {
      await changePassword({
        newPassword,
        repeatNewPassword,
        ...(currentPassword && { currentPassword }),
      })
      dispatch('close')
    } catch (e: any) {
      error = e.message || e.error || e
    }
    loading = false
  }
</script>

<form on:submit|preventDefault={onSubmit}>
  <div class="inputs">
    {#if profile.hasPassword}
      <Input
        bind:value={currentPassword}
        label="Current password"
        name="current_password"
        type="password" />
    {/if}
    <Input bind:value={newPassword} label="New password" name="new_password" type="password" />
    <Input
      bind:value={repeatNewPassword}
      label="Repeat new password"
      name="repeat_new_password"
      type="password" />
    <div class="error">{error}</div>
  </div>
  <div class="buttons">
    <Button color="red" type="submit" disabled={!canSubmit} {loading}>Change Password</Button>
  </div>
</form>

<style lang="scss">
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .inputs {
    width: 100%;

    > :global(.input) {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 12px;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;

    > :global(button) {
      width: auto;
      padding-left: 16px;
      padding-right: 16px;
      min-width: 100px;

      &:last-child {
        width: 164px;
      }
    }
  }

  .error {
    color: red;
    padding: 12px 0;
  }
</style>
