import type { Session } from 'types'
import { session as sessionStore } from 'stores'
import { authAPI } from 'api'
import { refreshToken } from 'api/api'
import { parseJwt } from 'utils'
import config from 'config'

let removeAuthWindowListener: (() => void) | null = null

export async function loginUsingEmail(data: {
  email: string
  password: string
  recaptchaToken?: string
  code?: string
}): Promise<void> {
  onAuth(await authAPI.login(data))
}

export async function loginUsingGoogle(idToken: string): Promise<void> {
  onAuth(await authAPI.loginUsingGoogle(idToken))
}

export async function loginUsingApple(authCode: string, idToken: string): Promise<void> {
  onAuth(await authAPI.loginUsingApple(authCode, idToken))
}

export async function loginUsingPhone(phoneNumber: string, code: string): Promise<void> {
  onAuth(await authAPI.loginUsingPhone(phoneNumber, code))
}

export async function register(data: {
  email: string
  password: string
  fullName: string
  recaptchaToken?: string
}): Promise<void> {
  onAuth(await authAPI.register(data))
}

export async function logout(): Promise<void> {
  const { id, code } = sessionStore.get()?.refreshToken || {}

  if (id && code) {
    try {
      await authAPI.invalidateRefreshToken(id, code)
    } catch (e) {
      // ignore
    }
  }

  sessionStore.set(null)
}

export async function validateSession(): Promise<number> {
  const session = sessionStore.get()

  if (session && session.authToken) {
    const payload = parseJwt(session.authToken)

    if (payload && payload.exp) {
      const secondsToExpire = payload.exp - new Date().getTime() / 1000

      if (secondsToExpire < 60 && session.refreshToken) {
        try {
          await refreshToken(session.refreshToken)
          // Lifespan of fresh token is 1 hour
          return 60 * 60
        } catch (e) {
          return 0
        }
      } else {
        return secondsToExpire
      }
    }
  }

  return 0
}

export async function getRecaptchaToken(): Promise<string | undefined> {
  if (window.grecaptcha) {
    return new Promise((resolve, reject) => {
      window.grecaptcha.enterprise.ready(() => {
        window.grecaptcha.enterprise
          .execute(config.recaptchaKeyId, { action: 'login' })
          .then(resolve)
          .catch(reject)
      })
    })
  } else {
    return Promise.resolve(undefined)
  }
}

export async function openAuthWindow(provider: 'google' | 'apple'): Promise<{
  idToken: string
  code: string
}> {
  return new Promise((resolve, reject) => {
    const authWindow = window.open(
      `${config.apiUrl}/auth/${provider}`,
      'Auth',
      'width=640,height=640',
    )
    const oauthCallbackHandler = async function (event: any) {
      let message = event.data

      try {
        message = JSON.parse(message)
      } catch (e) {}

      if (typeof message === 'object' && message.type === 'oauth_callback') {
        if (message.data && message.data.id_token) {
          resolve({
            idToken: message.data.id_token,
            code: message.data.code,
          })
        }

        removeAuthWindowListener && removeAuthWindowListener()
        removeAuthWindowListener = null
      }
    }

    removeAuthWindowListener && removeAuthWindowListener()

    if (authWindow) {
      window.addEventListener('message', oauthCallbackHandler)
      removeAuthWindowListener = () => window.removeEventListener('message', oauthCallbackHandler)
    } else {
      reject(new Error('Google Auth failed'))
    }
  })
}

function onAuth(session: Session) {
  sessionStore.set(session)
}
