<script lang="ts">
  import { onDestroy, createEventDispatcher } from 'svelte'
  import Dropzone from 'svelte-file-dropzone'
  import { updateProfile, uploadAvatar, logout, addError } from 'actions'
  import { session } from 'stores'
  import { getUserName, userImageUrl, validatePhoneNumber, convertImage } from 'utils'
  import CameraIcon from 'assets/icons/photo-camera.svg'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'
  import Spinner from 'components/Spinner.svelte'
  import ChangePassword from './components/ChangePassword.svelte'
  import ChangePhone from './components/ChangePhone.svelte'
  import LinkedAccounts from './components/LinkedAccounts.svelte'
  import DeleteAccount from './components/DeleteAccount.svelte'

  const dispatch = createEventDispatcher<{ close: undefined }>()
  const transitionDuration = 300

  type Screen = 'main' | 'phone' | 'password' | 'accounts' | 'delete-account'

  export let activeScreen: Screen = 'main'

  let nextActiveScreen: Screen = activeScreen
  let imageURL = ''
  let email = ''
  let phoneNumber = ''
  let fullName = ''
  let canSaveName = false
  let savingName = false
  let canChangePhone = false
  let isDragged = false
  let isAvatarUploading = false
  let error = ''
  const unsubscribes: (() => void)[] = []

  $: canSaveName = fullName !== (session.get()?.profile?.fullName || '')
  $: {
    canChangePhone =
      phoneNumber !== (session.get()?.profile?.phoneNumber || '') &&
      validatePhoneNumber(phoneNumber, true)
  }

  unsubscribes.push(
    session.subscribe(session => {
      const { profile } = session || {}

      fullName = profile?.fullName || ''
      email = profile?.email || ''
      phoneNumber = profile?.phoneNumber || ''
      imageURL = (profile && userImageUrl(profile)) || ''
      canSaveName = false
      canChangePhone = false
    }),
  )

  function setActiveScreen(screen: Screen) {
    nextActiveScreen = screen
    if (nextActiveScreen !== 'phone' && activeScreen !== 'phone') {
      activeScreen = nextActiveScreen
    } else {
      setTimeout(() => {
        activeScreen = nextActiveScreen
      }, transitionDuration)
    }
  }

  async function saveName() {
    savingName = true
    error = ''
    try {
      await updateProfile({ fullName })
    } catch (e: any) {
      error = e.message || e.error || e
    }
    savingName = false
  }

  async function handleFilesSelect(e: any) {
    const { acceptedFiles }: { acceptedFiles: File[] } = e.detail

    isDragged = false

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]
      isAvatarUploading = true
      try {
        await uploadAvatar(await convertImage(file))
      } catch (e) {
        addError(e)
      }
      isAvatarUploading = false
    }
  }

  function _logout() {
    logout().catch(addError)
    dispatch('close')
  }

  onDestroy(() => unsubscribes.forEach(un => un()))

  ; // prettier-ignore
</script>

{#if $session?.profile}
  <div class="profile">
    <section class="body">
      <div class="screens" class:next={nextActiveScreen === 'phone'}>
        <div class="screen">
          <div
            class="screen-item"
            class:active={activeScreen === 'main' ||
              nextActiveScreen === 'main' ||
              activeScreen === 'phone'}>
            <div class="avatar" class:dragged={isDragged}>
              {#if imageURL}
                <div class="avatar-image">
                  <img
                    src={imageURL}
                    alt={`${getUserName($session.profile)}'s avatar`}
                    referrerpolicy="no-referrer" />
                  <div class="avatar-change">Change</div>
                </div>
              {/if}
              <div class="avatar-dropzone">
                <Dropzone
                  accept="image/png,image/jpg,image/jpeg"
                  disableDefaultStyles={true}
                  multiple={false}
                  on:dragenter={() => {
                    isDragged = true
                  }}
                  on:dragleave={() => {
                    isDragged = false
                  }}
                  on:drop={handleFilesSelect}>
                  {#if !imageURL}
                    <div class="avatar-blank">
                      <CameraIcon />
                      {isDragged ? 'Drop' : 'Drag'} Avatar Here
                    </div>
                  {/if}
                </Dropzone>
              </div>
              {#if isAvatarUploading}
                <div class="avatar-spinner"><Spinner color="#fff" /></div>
              {/if}
            </div>
            <div class="inputs">
              <Input bind:value={email} label="Email" name="email" disabled />
              <Input
                bind:value={fullName}
                label="Name"
                loading={savingName}
                action={canSaveName && !savingName ? { name: 'Save', fn: saveName } : undefined}
                name="name" />
              <Input
                bind:value={phoneNumber}
                label="Phone"
                mask="+0 000 000-00-00"
                type="tel"
                action={canChangePhone
                  ? { name: 'Save', fn: () => setActiveScreen('phone') }
                  : undefined}
                name="phone" />
              <div class="error">{error}</div>
            </div>
          </div>
          <div
            class="screen-item"
            class:active={activeScreen === 'password' || nextActiveScreen === 'password'}>
            <ChangePassword profile={$session.profile} on:close={() => setActiveScreen('main')} />
          </div>
          <div
            class="screen-item"
            class:active={activeScreen === 'accounts' || nextActiveScreen === 'accounts'}>
            <LinkedAccounts />
          </div>
          <div
            class="screen-item"
            class:active={activeScreen === 'delete-account' ||
              nextActiveScreen === 'delete-account'}>
            <DeleteAccount />
          </div>
        </div>
        <div class="screen space-between">
          <ChangePhone
            bind:phoneNumber
            isActive={activeScreen === 'phone'}
            on:close={() => setActiveScreen('main')} />
        </div>
      </div>
    </section>
    <aside class="sidebar">
      <div>
        <div class="menu">
          <div
            class="item"
            class:active={activeScreen === 'main' || activeScreen === 'phone'}
            on:click={() => setActiveScreen('main')}>
            My Profile
          </div>
          <div
            class="item"
            class:active={activeScreen === 'password'}
            on:click={() => setActiveScreen('password')}>
            Change Password
          </div>
          <div
            class="item"
            class:active={activeScreen === 'accounts'}
            on:click={() => setActiveScreen('accounts')}>
            Linked Accounts
          </div>
          <div
            class="item red"
            class:active={activeScreen === 'delete-account'}
            on:click={() => setActiveScreen('delete-account')}>
            Delete Account
          </div>
        </div>
      </div>
      <Button color="red" on:click={_logout}>Sign Out</Button>
    </aside>
  </div>
{/if}

<style lang="scss">
  .profile {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    width: 480px;
    height: 440px;
  }

  .sidebar {
    background: rgba(255, 255, 255, 0.95);
    box-sizing: border-box;
    padding: 32px 24px 24px;
    flex: 0 0 172px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    overflow: hidden;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  .avatar {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .avatar-dropzone,
  .avatar-image,
  .avatar-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .avatar-dropzone {
    > :global(*) {
      width: 100%;
      height: 100%;
    }
  }

  .avatar-spinner {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .avatar-change {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 6px 0 10px;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 600;
    transform: translateY(100%);
    transition: transform 0.2s;

    .avatar:hover &,
    .avatar.dragged & {
      transform: translateY(0);
    }
  }

  .avatar-blank {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: rgba(0, 0, 0, 0.2);
    border: 2px solid rgba(255, 255, 255, 0.8);
    box-sizing: border-box;
    padding: 15px;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    text-align: center;
    transition: background 0.3s ease;

    .avatar.dragged & {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .body {
    position: relative;
    flex: 2 1 auto;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden;
  }

  .screens {
    height: 100%;
    width: 200%;
    display: flex;
    transition: transform 0.3s ease;

    &.next {
      transform: translateX(-50%);
    }
  }

  .screen {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
    padding: 32px 24px 24px;
  }

  .inputs {
    width: 100%;

    > :global(.input) {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 12px;
    }
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  .screen-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // justify-content: space-between;
    align-items: center;
    flex-direction: column;
    display: none;
    padding: 24px;
    box-sizing: border-box;
    overflow: auto;

    &.active {
      display: flex;
    }
  }

  .error {
    color: red;
  }

  .menu {
    display: flex;
    flex-direction: column;
    margin: 12px -24px 0;
  }

  .item {
    font-size: 16px;
    cursor: pointer;
    text-align: right;
    height: 32px;
    line-height: 32px;
    padding: 0 16px;

    &.red {
      color: var(--primary-color);
    }

    &.active {
      background: rgba(0, 0, 0, 0.07);
    }
  }
</style>
