import type { Draft } from 'types'
import { derived } from 'svelte/store'
import store from './store'
import { session } from './session'

export const drafts = store<Draft[]>([])

export const noMoreDrafts = store<boolean>(false)

export const currentDraftId = store<string | undefined>()

export const currentDraft = derived<[typeof drafts, typeof currentDraftId], Draft | undefined>(
  [drafts, currentDraftId],
  ([drafts, currentDraftId], set) => {
    set(drafts?.find(draft => draft.id === currentDraftId))
  },
)

export const uploadingDrafts = store<{ [key: string]: number }>({})

session.subscribe(session => {
  if (session === null) {
    drafts.set([])
    noMoreDrafts.set(false)
  }
})
