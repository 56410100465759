<script lang="ts">
  import type { BoomAnimationInfo } from 'types'
  import { onMount, createEventDispatcher } from 'svelte'
  import lottie from 'lottie-web'
  import boomAnimationData from 'assets/boom-animation.json'

  export let info: BoomAnimationInfo

  const dispatch = createEventDispatcher<{ finished: undefined }>()
  let container: HTMLDivElement

  onMount(() => {
    if (!container) return
    const animation = lottie.loadAnimation({
      container,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: boomAnimationData,
    })
    animation.addEventListener('complete', () => {
      animation.destroy()
      dispatch('finished')
    })
  })

  ; // prettier-ignore
</script>

<div
  bind:this={container}
  class="boom"
  style={`left:${info.x}px; top:${info.y}px; width:${info.size}px; height:${info.size}px;`} />

<style lang="scss">
  .boom {
    position: fixed;
    top: 0;
    left: 0;
    width: 320px;
    height: 320px;
    transform: translate(-50%, -50%);
    z-index: 10;

    :global(svg) {
      transform: scale(2.5) !important;
    }
  }
</style>
