<script lang="ts">
  import type { Message, Thread, User } from 'types'
  import { ModalType } from 'types'
  import Router, { location } from 'svelte-spa-router'
  import { swipe } from 'svelte-gestures'
  import { onDestroy } from 'svelte'
  import PrevBtnIcon from 'assets/icons/prev-btn.svg'
  import NextBtnIcon from 'assets/icons/next-btn.svg'
  import Navigation from 'components/Navigation.svelte'
  import UserBadge from 'components/UserBadge.svelte'
  import UploadIndicator from 'components/UploadIndicator.svelte'
  import Button from 'components/Button.svelte'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'
  import MessageSearch from '../Thread/components/MessageSearch.svelte'
  import {
    leaveThread,
    addError,
    loadFeed,
    goToFeed,
    goToThread,
    openModal,
    joinThread,
  } from 'actions'
  import { getThreadRecipients, hasEmoji } from 'utils'
  import { get } from 'svelte/store'
  import {
    threads,
    currentThread,
    currentThreadId,
    currentMessage,
    currentMessageId,
    newMessages,
    session,
    feedMessages,
    noMoreFeedMessages,
  } from 'stores'
  import TimeAgo from 'components/TimeAgo.svelte'
  import Thumbs from './components/Thumbs.svelte'
  import Recorder from '../../components/Recorder/Recorder.svelte'
  import MessageComponent from '../Thread/scenes/Message.svelte'

  let thumbs: Thumbs
  let messages: Message[] = []

  let noMessages = false
  let loading = false
  let error: string | undefined
  let nextMessage: Message | undefined
  let prevMessage: Message | undefined
  let canEditChannel = false
  let pendingInvitationAction: string | undefined

  $: messages = $feedMessages || []
  $: updateCurrentThread($threads, $currentMessageId)
  $: updateSiblingMessages($currentMessage)
  $: {
    const noCurrentMessage = !$currentMessage && /^\/feed(\/)?$/.test($location)
    const currentMessageNotFound = $currentMessageId && $feedMessages && !$currentMessage

    if ((noCurrentMessage || currentMessageNotFound) && messages.length > 0) {
      const firstNotWatchedIndex = Math.max(messages.map(m => m.watched).lastIndexOf(false), 0)
      goToFeed(messages[firstNotWatchedIndex])
    }
  }

  !$feedMessages && loadMoreFeed()

  async function loadMoreFeed() {
    if ($noMoreFeedMessages || loading) return
    loading = true
    await loadFeed().catch(addError)
    loading = false
  }

  function openChannelSettings() {
    if (canEditChannel) {
      openModal({
        type: ModalType.CHANNEL_SETTINGS,
        arguments: {
          thread: get(currentThread),
        },
      })
    }
  }

  function editMessageRecipients(message?: Message) {
    if (message || $currentMessage) {
      openModal({
        type: ModalType.MESSAGE_RECIPIENTS,
        arguments: {
          message: message || $currentMessage,
        },
      })
    }
  }

  function showUser(user: User) {
    if (user.id !== session.get()?.profile.id) {
      openModal({
        type: ModalType.USER_CARD,
        arguments: {
          user,
          ...(canEditChannel ? { threadToBan: get(currentThread) } : {}),
        },
      })
    }
  }

  function updateSiblingMessages(message: Message | undefined) {
    if (!message) return
    const currentIndex = messages.findIndex(m => m.id === message.id)

    nextMessage = currentIndex < messages.length - 1 ? messages[currentIndex + 1] : undefined
    prevMessage = currentIndex > 0 ? messages[currentIndex - 1] : undefined
    error = undefined
  }

  function updateCurrentThread(threads: Thread[] | null, messageId: string | undefined) {
    const threadId = messages.find(m => m.id === messageId)?.threadId
    const thread = threads?.find(t => t.id === threadId)
    currentThreadId.set(thread?.id)
  }

  async function _goToMessage(messageId: string) {
    const message = messages.find(m => m.id === messageId)

    message && goToFeed(message)
  }

  function handleSwipe({ detail }: CustomEvent) {
    let message: Message | undefined
    if (detail.direction === 'left') {
      message = nextMessage
    } else if (detail.direction === 'right') {
      message = prevMessage
    }
    message && _goToMessage(message?.id || '')
  }

  async function resolveInvitation(action: 'accept' | 'decline' | 'report') {
    const thread = get(currentThread)

    if (thread) {
      pendingInvitationAction = action

      try {
        if (action === 'accept') {
          await joinThread(thread.id)
        } else if (action === 'decline') {
          await leaveThread(thread.id)
        } else if (action === 'report') {
          if (session.get()?.profile.isTrusted) {
            openModal({
              type: ModalType.CREATE_REPORT,
              arguments: { thread },
            })
          } else {
            openModal({
              type: ModalType.TRUSTED_USER_ONLY,
              arguments: { action: 'report' },
            })
          }
        }
      } catch (e) {
        addError(e)
      }

      pendingInvitationAction = undefined
    }
  }

  onDestroy(() => {
    currentThreadId.set(undefined)
  })

  ; // prettier-ignore
</script>

<section
  class="thread"
  use:swipe={{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}
  on:swipe={handleSwipe}>
  {#if error}
    <div class="error">{error}</div>
    <!-- {:else if loadingMessages}
    <div class="spinner">
      <Spinner size="large" color="#000" />
    </div> -->
  {/if}
  <Router
    routes={{
      '/feed/:messageId/:replyId?': MessageComponent,
    }} />
  {#if $threads}
    <Navigation>
      <div class="message-info">
        {#if $currentMessage}
          <div class="sender" on:click={showUser.bind(null, $currentMessage.sender)}>
            <UserBadge
              user={$currentMessage.sender}
              currentUser={$session?.profile}
              color="white" />
            <div class="separator" />
            {#if $newMessages[$currentMessage.id]}
              <div class="uploading">
                Uploading
                <UploadIndicator progress={$newMessages[$currentMessage.id].progress || 0} />
              </div>
            {:else}
              <span class="date">
                <TimeAgo date={$currentMessage.createdAt} compact={false} withTime={true} />
              </span>
            {/if}
          </div>
          {#if $currentThread}
            <div class="to">To:</div>
            {#if $currentThread.channel && $currentThread.title}
              <div
                class="channel"
                class:with-emoji={hasEmoji($currentThread.title)}
                on:click={canEditChannel ? openChannelSettings : () => {}}>
                <ContactPointBadge
                  contactPoint={{ thread: $currentThread }}
                  color="white"
                  size="medium" />
              </div>
            {:else}
              <div class="recipients">
                {#each getThreadRecipients($currentThread, $currentMessage.sender) as recipient}
                  <div class="recipient" on:click={showUser.bind(null, recipient)}>
                    <UserBadge user={recipient} currentUser={$session?.profile} color="white" />
                  </div>
                {/each}
              </div>
            {/if}
            {#if $currentMessage.sender.id === $session?.profile.id}
              <div class="add-recipients edit" on:click={() => editMessageRecipients()}>Edit</div>
            {/if}
          {:else if $currentMessage.sender.id === $session?.profile.id}
            <div class="add-recipients" on:click={() => editMessageRecipients()}>
              Add recipients
            </div>
          {/if}
        {/if}
      </div>
    </Navigation>
    <MessageSearch />
    {#if prevMessage}
      <div class="message-arrow-screen" />
      <div class="message-arrow prev" on:click={_goToMessage.bind(null, prevMessage.id)}>
        <PrevBtnIcon />
      </div>
    {/if}
    {#if nextMessage}
      <div
        class="message-arrow next"
        class:with-shift={$currentMessage?.replies}
        on:click={_goToMessage.bind(null, nextMessage.id)}>
        <NextBtnIcon />
      </div>
    {/if}
    {#if $threads}
      <Thumbs
        bind:this={thumbs}
        threads={$threads || []}
        messages={messages || []}
        activeThumbId={$currentMessageId}
        loadingPrevious={loading}
        on:activeChanged={({ detail }) => _goToMessage(detail)}
        on:activeThreadChanged={({ detail }) => goToThread(detail)}
        on:addRecipientsFor={({ detail }) => editMessageRecipients(detail)}
        on:reachedStart={loadMoreFeed} />
    {/if}
    {#if noMessages}
      <Recorder isFeed={true} />
    {/if}
    {#if $currentThread?.needToAcceptInvite}
      <div class="accept-invite">
        <div class="title">
          You have been invited to join a {$currentThread.channel ? 'channel' : 'thread'}
        </div>
        <div class="btns">
          <Button
            color="green"
            on:click={() => resolveInvitation('accept')}
            loading={pendingInvitationAction === 'accept'}>Accept</Button>
          <Button
            color="red"
            on:click={() => resolveInvitation('decline')}
            loading={pendingInvitationAction === 'decline'}>Decline</Button>
          <Button
            textColor="white"
            on:click={() => resolveInvitation('report')}
            loading={pendingInvitationAction === 'report'}>Report</Button>
        </div>
      </div>
    {/if}
  {/if}
</section>

<style lang="scss">
  .thread {
    width: 100%;
    height: 100%;
  }

  .message-arrow {
    position: absolute;
    top: 50%;
    width: 37px;
    height: 63px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;

    &.prev {
      left: 16px;
    }

    :global(.active-thumbs) &.next {
      z-index: 1;
    }

    &.next {
      right: 16px;
      z-index: 3;

      &.with-shift {
        right: 64px;
      }
    }
  }

  .message-arrow-screen {
    position: absolute;
    top: 50%;
    width: 0;
    height: 80px;
    z-index: 1;
    transform: translateY(-50%);
    transition: step-end width 0.3s;

    :global(.inactive-sidebar) & {
      width: 220px;
    }
  }

  .message-info {
    display: flex;
    color: #fff;
    align-items: center;

    .channel {
      display: flex;
      align-items: center;
      height: 32px;
      cursor: pointer;
      border-radius: 32px;
      padding: 4px 8px 4px 4px;
      box-sizing: border-box;
      margin-right: 2px;
      background-color: transparent;
      transition: background-color 0.2s ease;

      &.with-emoji :global(.icon) {
        display: none;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .separator {
      color: rgba(255, 255, 255, 0.5);
      margin-right: 8px;

      &::before {
        content: '/';
      }
    }

    .sender {
      display: flex;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 3px;
      border-radius: 32px;
      box-sizing: border-box;
      padding-right: 12px;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .separator {
        margin: 0 6px;
        font-size: 10px;

        &::before {
          content: '\2022';
        }
      }
    }
  }

  .to {
    font-size: 13px;
    opacity: 0.75;
    margin: 0 4px 0 16px;
  }

  .recipients {
    display: flex;
    align-items: center;
  }

  .add-recipients {
    margin-left: 8px;
    padding: 8px 16px;
    border-radius: 32px;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;

    &.edit {
      padding: 4px 12px;
      font-size: 14px;
    }
  }

  .recipient {
    margin-left: 8px;
    overflow: hidden;
    height: 24px;
    cursor: pointer;
  }

  .accept-invite {
    position: fixed;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    padding: 16px;
    border-radius: 16px;
    color: #fff;

    .title {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .btns {
      display: flex;
      margin: 0 -8px;

      > :global(*) {
        margin: 0 8px;
      }
    }
  }

  .date {
    opacity: 0.75;
    font-size: 12px;
  }

  .uploading {
    stroke: white;
    margin: -2px -11px -2px 0;
    white-space: nowrap;
    font-size: 12px;
    line-height: 28px;
    vertical-align: top;

    > :global(div) {
      display: inline-block;
      vertical-align: top;
    }
  }

  .error {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 348px;
    transform: translate(-50%, -50%);
    color: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
  }
</style>
