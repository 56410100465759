export function validateUserId(id: string): boolean {
  return (
    /^([a-zA-Z\d]{20})$/i.test(id) ||
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id)
  )
}

export function validateEmail(email: string): boolean {
  const re =
    // eslint-disable-next-line max-len
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function validatePhoneNumber(number: string, strict = false): boolean {
  if (strict) {
    return /\+\d \d{3} \d{3}-\d{2}-\d{2}/.test(number)
  } else {
    return /^\+[0-9]{10,15}$/.test(normalizePhoneNumber(number))
  }
}

export function normalizePhoneNumber(number: string): string {
  number = number.replace(/[^\d+]+/g, '')
  number = number.replace(/^00/, '+')
  if (number.match(/^1/)) number = '+' + number
  if (!number.match(/^\+/)) number = '+1' + number

  return number
}
