import ReconnectingWebSocket from 'reconnecting-websocket'
import config from 'config'
import { wsConnected } from 'stores'

export type WSNotification = {
  title: string
  type: string
  data: { [key: string]: any }
}
export type WSSubscriber = (notification: WSNotification) => void

let client: ReconnectingWebSocket | null = null
const subscribers: Set<WSSubscriber> = new Set()

export function connect(token: string): ReconnectingWebSocket {
  disconnect()

  client = new ReconnectingWebSocket(`${config.wsUrl}?token=${token}`, undefined, {})
  client.onmessage = function (message) {
    let { data: messageData } = message

    // Parsing data object from the string
    while (typeof messageData === 'string') {
      try {
        messageData = JSON.parse(messageData) as WSNotification
        subscribers.forEach(subscriber => subscriber(messageData))
      } catch (e) {
        break
      }
    }
  }
  client.onopen = () => wsConnected.set(true)
  client.onclose = () => wsConnected.set(false)

  return client
}

export function disconnect(): void {
  client?.close()
}

export function subscribe(subscriber: WSSubscriber): () => void {
  subscribers.add(subscriber)
  return () => subscribers.delete(subscriber)
}
