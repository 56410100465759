<script lang="ts">
  import type { Notification } from 'types'
  import { createEventDispatcher } from 'svelte'
  import { fly } from 'svelte/transition'
  import { removeNotification } from 'actions'

  export let notifications: Notification[]

  const dispatch = createEventDispatcher<{
    remove: string
    removeAll: void
  }>()
  let isStacked: boolean | undefined
  let isHidden = false
  let withTransition = false

  $: internalNotifications = notifications.filter(n => n.type === 'internal')
  $: shouldBeStacked = typeof isStacked === 'boolean' ? isStacked : true
  $: toggleTransition(shouldBeStacked)

  function toggleStacked(value?: boolean) {
    isStacked = value === undefined ? !isStacked : value
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function toggleTransition(_: any) {
    withTransition = true
    setTimeout(() => (withTransition = false), 300)
  }

  function clearAll() {
    isHidden = true
    setTimeout(() => {
      dispatch('removeAll')
      setTimeout(() => (isHidden = false), 300)
    }, 300)
  }
</script>

<div
  class="notifications"
  class:stacked={shouldBeStacked}
  class:with-transition={withTransition}
  class:hidden={isHidden}>
  {#if internalNotifications.length > 1}
    <div class="controls">
      <button class="toggle" on:click={() => toggleStacked(true)}>Show less</button>
      <button class="clear" on:click={clearAll}>Clear all</button>
    </div>
  {/if}
  <div class="list">
    {#each internalNotifications as notification, index (notification.id)}
      <div
        class="notification"
        transition:fly={{ duration: 300, y: -10 }}
        style={shouldBeStacked
          ? `transform: translateY(-${index * 100}%) scale(${Math.max(
              1 - index * 0.1,
              0.8,
            )});z-index: ${internalNotifications.length - index}${index > 2 ? ';opacity: 0' : ''}`
          : `z-index: ${internalNotifications.length - index}`}
        on:click={() =>
          shouldBeStacked ? toggleStacked(false) : removeNotification(notification.id, true)}>
        <div class="close" on:click|stopPropagation={() => dispatch('remove', notification.id)} />
        {#if notification.imageUrl}
          <img src={notification.imageUrl} alt={notification.id} />
        {/if}
        <span>{notification.title}</span>
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  .notifications {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;
    max-height: 100%;
    overflow-y: auto;
    padding: 8px 12px 12px;
    box-sizing: border-box;
    transition: max-height 0.3s step-start, opacity 0.3s ease;

    &.stacked {
      max-height: 104px;
      transition: max-height 0.3s step-end, opacity 0.3s ease;

      &:hover .controls .clear {
        opacity: 1;
      }
    }

    &.hidden {
      opacity: 0;
    }

    :global(.windows) & {
      top: 24px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .notification {
    position: relative;
    display: flex;
    background: rgba(0, 0, 0, 0.55);
    border-radius: 12px;
    padding: 12px 16px;
    font-size: 14px;
    margin-top: 8px;
    width: 240px;
    min-height: 72px;
    color: #fff;
    cursor: pointer;
    box-sizing: border-box;
    backdrop-filter: blur(16px);
    box-shadow: inset 0 0 1px 0 rgba(255, 255, 255, 0.75), 0 0 4px 0 rgba(0, 0, 0, 0.25);

    .with-transition & {
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    .stacked & {
      .close {
        opacity: 0 !important;
      }

      // &:nth-child(2) {
      //   transform: translateY(-100%) scale(0.9) !important;
      // }

      // &:nth-child(3) {
      //   transform: translateY(-200%) scale(0.8) !important;
      // }
    }

    img {
      max-height: 48px;
      margin: 0 16px 0 -4px;
      border-radius: 4px;
    }

    span {
      display: flex;
      align-items: center;
      min-height: 100%;
    }

    &:hover .close {
      opacity: 1;
    }

    .close {
      position: absolute;
      top: -8px;
      left: -8px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background: rgba(0, 0, 0, 0.55);
      box-shadow: inset 0 0 1px 0 rgba(255, 255, 255, 0.75), 0 0 4px 0 rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(16px);
      opacity: 0;
      transition: opacity 0.2s ease;
      cursor: pointer;

      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50%;
        margin-left: -5px;
        border-top: 1px solid #fff;
        content: '';
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }
    }
  }

  .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: -16px 0 0;
    transition: margin 0.3s ease;

    button {
      padding: 4px 8px;
      border: none;
      color: #fff;
      background: rgba(0, 0, 0, 0.55);
      box-shadow: inset 0 0 1px 0 rgba(255, 255, 255, 0.75), 0 0 4px 0 rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(16px);
      border-radius: 20px;
      margin-left: 8px;
      cursor: pointer;
      transition: opacity 0.2s ease;
    }

    .stacked & {
      margin: -16px -16px -22px 0;
      z-index: 100;

      button {
        opacity: 0;
      }
    }
  }
</style>
