const config = {
  apiUrl: `${envVar('__API_HOST__', 'https://api.boom.me')}/v1`,
  imgUrl: `${envVar('__IMG_HOST__', 'https://img.boom.me')}`,
  wsUrl: `${envVar('__WS_HOST__', 'wss://api.boom.me')}/v1/notifications`,
  googleClientId: '213992789804-1mu6v40ee2dffsur9qi2eo10rmi40kg0.apps.googleusercontent.com',
  googleClientSecret: '3owtfKQcuD8N34OYBfPu-KFc',
  fcmSenderId: '213992789804',
  sentryDsn: 'https://7432b630bd154949a1b3ae7cab672741@o1089529.ingest.sentry.io/6104723',
  recaptchaKeyId: '6LcDnpkgAAAAAPS8sVoHCX3-mp13bKzt0nXjASNR',
}

function envVar(variable: string, defaultValue: string): string {
  return /^([A-Z_]*)$/.test(variable) ? defaultValue : variable
}

export default config

export * from './constants'
