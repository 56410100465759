<script lang="ts">
  import type { User, Thread, Message, Community } from 'types'
  import { ReportType, ReportReason } from 'types'
  import { createEventDispatcher } from 'svelte'
  import { REPORT_REASON_TITLES } from 'config/constants'
  import { createReport } from 'actions'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'
  import Select from 'components/Select.svelte'

  export let thread: Thread | undefined = undefined
  export let community: Community | undefined = undefined
  export let user: User | undefined = undefined
  export let message: Message | undefined = undefined

  const dispatch = createEventDispatcher<{ done: undefined }>()

  const reasons = Object.values(ReportReason).map(reason => ({
    value: reason,
    label: REPORT_REASON_TITLES[reason],
  }))

  let reason = reasons[0]
  let comment: string | undefined = ''
  let sending = false
  let error: string | undefined = undefined
  let type: ReportType | undefined

  $: type = message
    ? ReportType.message
    : thread
    ? ReportType.thread
    : community
    ? ReportType.community
    : user
    ? ReportType.user
    : undefined

  async function submit() {
    if (sending) return
    if (!type) {
      error = 'Type is required'
      return
    }
    sending = true

    try {
      const data: any = {}

      switch (type) {
        case ReportType.message:
          data.messageId = message!.id
          break
        case ReportType.thread:
          data.threadId = thread!.id
          break
        case ReportType.community:
          data.communityId = community!.id
          break
        case ReportType.user:
          data.userId = user!.id
          break
      }

      await createReport({
        ...data,
        type,
        reason: reason.value,
        comment,
      })

      dispatch('done')
    } catch (e: any) {
      error = e.message || e
    }

    sending = false
  }
</script>

<form class="report" on:submit|preventDefault={submit}>
  <h1>Report</h1>
  <div class="inputs">
    <h3>Why are you reporting this {type || 'content'}</h3>
    <Select items={reasons} bind:selected={reason} />
    <Input bind:value={comment} textarea name="title" label=" " placeholder="Optional comment" />
    <Button type="submit" color="red" loading={sending}>Send</Button>
    {#if error}
      <div class="error">{error}</div>
    {/if}
  </div>
</form>

<style lang="scss">
  .report {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.85);
    max-height: calc(100vh - 40px);
    font-size: 15px;
    line-height: 1.2;
    padding: 16px 36px;
    color: #000;
  }

  h1 {
    width: 100%;
    padding: 0;
    height: 28px;
    font-size: 18px;
    margin: 0 0 24px 0;
    text-align: center;
  }

  .inputs {
    width: 100%;

    > :global(.input) {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 12px;
      background: rgba(255, 255, 255, 0.25);
    }

    > :global(*) {
      margin-bottom: 12px;
    }
  }

  .error {
    color: red;
  }

  h3 {
    font-size: 15px;
    margin: 0;
  }
</style>
