<script lang="ts">
  import type { User, ContactPoint, Thread } from 'types'
  import { contactToUser, getUserName, getThreadRecipients, userImageUrl } from 'utils'
  import DefaultAvatar from 'assets/icons/default-avatar.svg'
  import FeedIcon from 'assets/icons/feed.svg'
  import UserBadge from 'components/UserBadge.svelte'
  import TimeAgo from 'components/TimeAgo.svelte'

  export let contactPoint: ContactPoint
  export let currentUser: User | undefined = undefined
  export let size: 'small' | 'medium' | 'large' = 'medium'
  export let color: 'black' | 'white' = 'black'
  export let showFullInfo = false
  export let showDate = false

  let userBadgeSize: 'small' | 'xsmall' = 'small'
  $: userBadgeSize = size === 'large' ? 'small' : 'xsmall'

  function threadImage(thread: Thread): string | undefined {
    return getThreadRecipients(thread, currentUser).reduce<string | undefined>(
      (result, user) => result || userImageUrl(user),
      undefined,
    )
  }
</script>

<div class={`badge ${size} ${color}`}>
  {#if contactPoint.thread}
    {#if (contactPoint.thread.channel || contactPoint.thread.id === 'feed') && contactPoint.message}
      <div class="thread-user">
        <UserBadge
          {color}
          size={userBadgeSize}
          user={contactPoint.message.sender}
          {currentUser}
          showFullInfo={false} />
      </div>
    {/if}
    {#if contactPoint.community}
      <div class="icon">
        {#if contactPoint.community.imageURL}
          <img src={contactPoint.community.imageURL} alt={contactPoint.community.title} />
        {:else}
          <div class="circle" />
        {/if}
      </div>
      <div class="title inline">
        {contactPoint.community.title}
      </div>
      <div class="divider">></div>
    {/if}
    <div class="icon" class:thread={contactPoint.thread.id !== 'feed'}>
      {#if contactPoint.thread.channel}
        #
      {:else if threadImage(contactPoint.thread)}
        <img
          src={threadImage(contactPoint.thread)}
          alt={contactPoint.thread.title}
          referrerpolicy="no-referrer" />
      {:else if contactPoint.thread.id === 'feed'}
        <FeedIcon />
      {:else}
        <DefaultAvatar {color} />
      {/if}
    </div>
    <div class="title">
      {#if contactPoint.thread.title}
        {contactPoint.thread.title}
      {:else}
        {getThreadRecipients(contactPoint.thread, currentUser)
          .map(u => getUserName(u, currentUser))
          .join(', ')}
      {/if}
      {#if showDate}
        <span class="date"><TimeAgo date={contactPoint.thread.modifiedAt} /></span>
      {/if}
    </div>
  {:else if contactPoint.community}
    <div class="icon">
      {#if contactPoint.community.imageURL}
        <img src={contactPoint.community.imageURL} alt={contactPoint.community.title} />
      {:else}
        <div class="circle" />
      {/if}
    </div>
    <div class="title">
      {contactPoint.community.title}
    </div>
  {:else if contactPoint.contact}
    <span>
      <UserBadge
        {color}
        size={userBadgeSize}
        user={contactToUser(contactPoint.contact)}
        {currentUser}
        showFullInfo={true} />
    </span>
  {:else if contactPoint.user}
    <span>
      <UserBadge
        {color}
        size={userBadgeSize}
        user={contactPoint.user}
        {currentUser}
        {showFullInfo} />
    </span>
  {:else}
    <span>{contactPoint.contactPoint}</span>
  {/if}
</div>

<style lang="scss">
  .badge {
    display: flex;
    position: relative;
    align-items: center;
    line-height: 24px;
    color: #000;
    box-sizing: border-box;

    &.white {
      color: #fff;

      .icon > :global(svg path) {
        fill: #fff;
      }
    }
  }

  .icon {
    width: 21px;
    height: 21px;
    text-align: center;
    line-height: 21px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 8px;
    text-align: center;
    flex: 0 0 auto;
    background: rgba(0, 0, 0, 0.1);

    > :global(svg path) {
      fill: #000;
    }

    .medium & {
      width: 24px;
      height: 24px;
      line-height: 24px;
    }

    img,
    :global(svg) {
      display: block;
      width: 100%;
      height: 100%;
    }

    .circle {
      width: 10px;
      height: 10px;
      border: 2px solid;
      box-sizing: border-box;
      border-radius: 100%;
      margin: 7px 0 0 8px;
      left: 28px;
    }
  }

  .title {
    flex: 2 1 100%;

    &.inline {
      flex: 1 1 auto;
    }
  }

  .divider {
    margin: 0 8px;
  }

  .counter {
    flex: 0 0 auto;
    min-width: 8px;
    margin-left: 4px;
  }

  .date {
    font-weight: 300;
    margin-left: 4px;
    opacity: 0.5;
  }

  .thread-user {
    margin: 0 6px 0 2px;
    display: flex;

    &::after {
      content: 'in';
      margin-left: 6px;
    }

    + .icon.thread {
      margin-right: 0;
      width: 12px;
      background: none;
    }
  }

  .message {
    display: flex;
    margin-left: 8px;
    height: 100%;

    &::before {
      content: '>';
    }

    img {
      margin: 2px 8px;
      border-radius: 2px;
      height: calc(100% - 4px);
    }
  }

  span {
    display: inline-flex;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }
</style>
