<script lang="ts">
  import RangeSlider from 'svelte-range-slider-pips'
  import SoundOnIcon from 'assets/icons/sound-on.svg'
  import SubtitlesIcon from 'assets/icons/subtitles.svg'
  import PlayIcon from 'assets/icons/player-play.svg'
  import PauseIcon from 'assets/icons/player-pause.svg'
  import RestartIcon from 'assets/icons/player-restart.svg'
  import Tooltip from 'components/Tooltip.svelte'
  import { formatTime } from 'utils'

  export let shouldPlay = false
  export let currentTime = 0
  export let duration = 0
  export let playbackRate: number | undefined = undefined
  export let volume: number | undefined = undefined
  export let muted = false
  export let hasSubtitles = true
  export let showSubtitles = false
  export let disabled = false

  const playbackRates = [
    0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.6, 1.7, 1.8, 1.9, 2, 2.5, 3, 4, 5, 6, 7, 8,
  ]
  let isRateSliderActive = false

  function onTimeChange({ detail }: { detail: { value: number } }) {
    currentTime = detail.value / 100
  }

  function onVolumeChange({ detail }: { detail: { value: number } }) {
    volume = (100 - detail.value) / 100
  }

  function onSpeedChange({ detail }: { detail: { value: number } }) {
    const newPlaybackRate = playbackRates[playbackRates.length - 1 - detail.value]
    playbackRate = Number.isFinite(newPlaybackRate) ? newPlaybackRate : playbackRates[0]
  }

  function restart() {
    currentTime = 0
    shouldPlay = true
  }
</script>

<div class="player-controls" class:disabled>
  <div class="left">
    <div class="playback" on:click={() => (shouldPlay = !shouldPlay)}>
      {#if shouldPlay}
        <PauseIcon />
      {:else}
        <PlayIcon />
      {/if}
    </div>
    <div class="restart" on:click={restart}>
      <RestartIcon />
    </div>
    <div class="time">{formatTime(currentTime)} / {formatTime(disabled ? 0 : duration)}</div>
    {#if playbackRate !== undefined}
      <div class="rate" class:active={isRateSliderActive}>
        <div class="rate-btn">
          {playbackRate}x
        </div>
        <div class="rate-slider">
          <RangeSlider
            range="max"
            springValues={{ stiffness: 1, damping: 1 }}
            values={[playbackRates.length - 1 - playbackRates.indexOf(playbackRate)]}
            max={playbackRates.length - 1}
            min={0}
            step={1}
            reversed={true}
            vertical={true}
            on:start={() => (isRateSliderActive = true)}
            on:stop={() => (isRateSliderActive = false)}
            on:change={onSpeedChange} />
        </div>
      </div>
    {/if}
    <div class="progress">
      <RangeSlider
        range="min"
        springValues={{ stiffness: 1, damping: 1 }}
        values={[currentTime * 100]}
        max={duration * 100}
        on:change={onTimeChange} />
    </div>
  </div>
  {#if volume !== undefined}
    <div class="volume" class:muted>
      <div
        class="volume-btn"
        on:click={() => {
          muted = !muted
        }}>
        <SoundOnIcon />
      </div>
      <div class="volume-slider">
        <RangeSlider
          range="max"
          springValues={{ stiffness: 1, damping: 1 }}
          values={[100 - 100 * volume]}
          max={100}
          reversed={true}
          vertical={true}
          on:change={onVolumeChange} />
      </div>
    </div>
  {/if}
  {#if hasSubtitles}
    <div
      class="subtitles"
      class:active={showSubtitles}
      on:click={() => {
        showSubtitles = !showSubtitles
      }}>
      <SubtitlesIcon />
      <Tooltip title={`${showSubtitles ? 'Hide' : 'Show'} subtitles`} />
    </div>
  {/if}
</div>

<style lang="scss">
  .player-controls {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    transition: opacity 0.2s ease;

    &.disabled {
      opacity: 0.5;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    }
  }

  .left {
    position: relative;
  }

  .playback,
  .restart {
    float: left;
    width: 20px;
    height: 26px;
    margin-right: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .restart {
    margin-right: 8px;
  }

  .time {
    float: left;
    padding: 4px 0;
  }

  .rate-btn {
    position: relative;
    float: right;
    font-weight: 600;
    padding: 6px;
    margin: -2px -4px -2px 0;
    min-width: 18px;
    cursor: pointer;
    border-radius: 18px;
    background: transparent;
    text-align: center;
    transition: background 0.3s ease;
  }

  .progress,
  .volume-slider,
  .rate-slider {
    &:hover :global(.rangeSlider .rangeHandle) {
      transform: scale(1) translateY(-50%) translateX(-50%);
    }

    :global(.rangeSlider) {
      --slider: rgba(255, 255, 255, 0.2);
      --handle-inactive: #fff;
      --handle: var(--range-handle, #fff);
      --handle-focus: var(--range-handle-focus, #fff);
      --handle-border: var(--range-handle-border, var(--handle));
      --range-inactive: var(--range-range-inactive, var(--handle-inactive));
      --range: var(--range-range, var(--handle-focus));
      margin: 0;
      background: none;
    }

    :global(.rangeSlider::before) {
      content: '';
      position: absolute;
      background: var(--slider);
    }

    :global(.rangeSlider .rangeHandle) {
      width: 14px;
      height: 14px;
      transform: scale(0) translateY(-50%) translateX(-50%);
      transform-origin: 0 0;
      transition: transform 0.3s ease;
    }

    :global(.rangeSlider.range .rangeNub) {
      border-radius: 100%;
    }
  }

  .progress {
    width: 246px;
    clear: both;
    cursor: pointer;

    &:hover :global(.rangeSlider .rangeHandle) {
      transform: scale(1) translateY(-50%) translateX(-50%);
    }

    :global(.rangeSlider) {
      height: 16px;
    }

    :global(.rangeSlider::before) {
      top: 7px;
      left: 0;
      right: 0;
      height: 2px;
    }

    :global(.rangeSlider .rangeBar) {
      top: 7px;
      height: 2px;
    }

    :global(.rangeSlider .rangeHandle) {
      top: 8px;
    }
  }

  .volume {
    position: relative;
    cursor: pointer;
    margin-left: 16px;

    &:not(.muted):hover .volume-slider {
      transform: translateY(0);
      opacity: 1;
      z-index: 1;
      height: 84px;
      transition: z-index 0.2s step-start, height 0.2s step-start, opacity 0.2s, transform 0.2s;
    }
  }

  .rate {
    position: relative;
    cursor: pointer;

    &.active,
    &:hover {
      .rate-slider {
        transform: translateY(0);
        opacity: 1;
        z-index: 1;
        height: 84px;
        transition: z-index 0.2s step-start, height 0.2s step-start, opacity 0.2s, transform 0.2s;
      }
    }
  }

  .volume-btn {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      transform: rotate(45deg);
      transform-origin: top left;
      transition: width 0.2s;
      border-radius: 2px;
      background: #fff;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
      width: 0;
      height: 2px;

      .muted & {
        width: 26px;
      }
    }
  }

  .volume-slider,
  .rate-slider {
    position: absolute;
    bottom: calc(100% + 10px);
    right: 4px;
    width: 16px;
    height: 0;
    transform: translateY(10px);
    opacity: 0;
    z-index: -1;
    transition: z-index 0.2s step-end, height 0.2s step-end, opacity 0.2s, transform 0.2s;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 10px;
    }

    :global(.rangeSlider) {
      width: 16px;
      height: 100%;
      min-height: 0;
    }

    :global(.rangeSlider::before) {
      top: 0;
      bottom: 0;
      left: 5px;
      width: 6px;
      border-radius: 6px;
      box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);
    }

    :global(.rangeSlider .rangeBar) {
      left: 5px;
      width: 6px;
      border-radius: 6px;
    }

    :global(.rangeSlider .rangeHandle) {
      left: 8px;
    }

    :global(.rangeSlider .rangeHandle .rangeNub) {
      box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .subtitles {
    position: relative;
    cursor: pointer;
    margin-left: 16px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 1px;
      width: 0;
      height: 2px;
      background: red;
      border-radius: 2px;
      transition: width 0.3s ease;
    }

    &.active::after {
      width: calc(100% - 2px);
    }
  }
</style>
