<script lang="ts">
  import { ContactPoint, AIImage, AIImageResult, Message, ModalType } from 'types'
  import { urltoImage } from 'image-conversion'
  import { SidebarState } from 'types'
  import { onMount, onDestroy, tick } from 'svelte'
  import { scale, slide } from 'svelte/transition'
  import { UploadManager } from 'vendors/videokit'
  import { EImageType } from 'image-conversion'
  import QRCode from 'svelte-qrcode'
  import hotkeys from 'hotkeys-js'
  import { querystring } from 'svelte-spa-router'
  import CheckIcon from 'assets/icons/check.svg'
  import CloseFullscreenIcon from 'assets/icons/close-fullscreen.svg'
  import TrashIcon from 'assets/icons/trash.svg'
  import ConfigIcon from 'assets/icons/config.svg'
  import PrevSmallBtnIcon from 'assets/icons/prev-btn-small.svg'
  import NextSmallBtnIcon from 'assets/icons/next-btn-small.svg'
  import CrossIcon from 'assets/icons/cross.svg'
  import Navigation from 'components/Navigation.svelte'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'
  import Spinner from 'components/Spinner.svelte'
  import Tooltip from 'components/Tooltip.svelte'
  import LocalPlayer from 'components/LocalPlayer.svelte'
  import RecipientsField from '../components/RecipientsField.svelte'
  import { settings, threads, aiImages, allImagesLoaded, aiInitImageUrl, session } from 'stores'
  import { uploadsAPI } from 'api'
  import {
    addError,
    addConfirm,
    setSidebarState,
    createMessage,
    goToMessage,
    loadImages,
    generateImage,
    deleteImage,
    upscaleImageResult,
    generateCameraLink,
    setInitImage,
    openModal,
    clearQuery,
  } from 'actions'
  import { convertImage, getVideoThumb } from 'utils'

  type Request = {
    id: string
    prompt?: string
    initImageUrl?: string
    initImageBlob?: Blob
    deleting?: boolean
    activeOptions?: boolean
    width: string | number
    height: string | number
    numberOfImages: string | number
    scale: string | number
    steps: string | number
    seed: string | number
  }

  const defaultConfig = {
    width: 512,
    height: 512,
    numberOfImages: 8,
    scale: 10,
    steps: 50,
    seed: '',
  }

  let root: HTMLElement
  let fileInput: HTMLInputElement
  let loaderEl: HTMLDivElement
  let recipients: ContactPoint[] = []
  let upscalingRequests: { [key: string]: boolean } = {}
  let activeRequest: AIImage | null = null
  let selectedResultId: string | null = null
  let activeResultIndex = 0
  let activeResultFullscreen = false
  let generationRequestId: string | null = null
  let isSending = false
  let isSaving = false
  let isLoading = false
  let imagesMap: { [key: string]: AIImage } = {}
  let requests: Request[] = [{ id: 'new', prompt: '', ...defaultConfig }]
  let loaderObserver: IntersectionObserver
  let dragging = false
  let mediaFile: File | null = null
  let mediaFileUrl: string | null = null
  let loadingIndex = 0
  let loadingImagesCount = 0
  let qrCodeUrl: string | null = null
  const sidebarStateBefore = settings.get()?.sidebarState
  const unsubscribes: (() => void)[] = []

  loadMoreImages()
  updatePrefilledRecipient()
  handlePrefilledRequest()
  setSidebarState(SidebarState.HIDE)

  unsubscribes.push(threads.subscribe(() => updatePrefilledRecipient()))
  unsubscribes.push(querystring.subscribe(query => updatePrefilledRecipient(query)))
  unsubscribes.push(
    aiImages.subscribe(images => {
      const newImagesMap: typeof imagesMap = {}
      let newRequests: Request[] = []

      for (const image of images) {
        newImagesMap[image.id] = image
      }

      imagesMap = newImagesMap

      if (images.length === 0) {
        newRequests = [{ id: 'new', prompt: '', ...defaultConfig }]
      } else {
        newRequests = images.map(image => {
          const request = requests.find(r => r.id === image.id)
          const upscalingResult = (image?.results || []).find(
            r => upscalingRequests[r.id] && !r.upscaling,
          )

          if (upscalingResult) {
            upscalingRequests[upscalingResult.id] = false
            activeRequest?.id === image.id && tick().then(() => (activeResultFullscreen = true))
          }

          return {
            id: image.id,
            activeOptions: request?.activeOptions || false,
            prompt: request?.prompt || image.prompt,
            initImageUrl: request?.initImageUrl || image.config?.initImageUrl,
            scale: request?.scale || image.config?.scale || defaultConfig.scale,
            steps: request?.steps || image.config?.steps || defaultConfig.steps,
            seed: request?.seed || image.config?.seed || defaultConfig.seed,
            width: request?.width || image.config?.width || defaultConfig.width,
            height: request?.height || image.config?.height || defaultConfig.height,
            numberOfImages:
              request?.numberOfImages ||
              image.config?.numberOfImages ||
              defaultConfig.numberOfImages,
          }
        })
      }

      requests = [...requests.filter(r => r.id.startsWith('new_')), ...newRequests]

      while (requests[loadingIndex] && !imagesMap[requests[loadingIndex].id]?.results) {
        loadingIndex++
      }

      if (activeRequest) {
        activeRequest = imagesMap[activeRequest.id] || null
      }
    }),
  )
  unsubscribes.push(
    aiInitImageUrl.subscribe(imageUrl => {
      if ((qrCodeUrl || mediaFileUrl) && imageUrl) {
        mediaFileUrl = imageUrl
        qrCodeUrl = null
        setInitImage('')
      }
    }),
  )

  function updatePrefilledRecipient(query?: string) {
    const params = new URLSearchParams(query || $querystring)
    const threadId = params.get('threadId')
    const userId = params.get('userId')
    const messageId = params.get('messageId')

    if (threadId) {
      const thread = threads.get()?.find(thread => thread.id === threadId)

      if (thread && !recipients.find(r => r.thread?.id === thread.id)) {
        const recipient: ContactPoint = { thread }

        if (messageId) {
          const message = thread.messages?.find(m => m.id === messageId)

          if (message) {
            recipient.message = message
          }
        }

        recipients = [recipient, ...recipients]
      }
    } else if (userId) {
      const user = threads
        .get()
        ?.flatMap(thread => thread.members)
        .find(user => user.id === userId)

      if (user && !recipients.find(r => r.user?.id === user.id)) {
        recipients = [{ user }, ...recipients]
      }
    }
  }

  async function handlePrefilledRequest() {
    const params = new URLSearchParams($querystring)
    const prompt = params.get('prompt')
    const initImageUrl = params.get('initImageUrl')
    const scale = params.get('scale')
    const steps = params.get('steps')
    const seed = params.get('seed')

    if (prompt || initImageUrl) {
      clearQuery(['prompt', 'initImageUrl', 'scale', 'steps'], true)
      const request = await generateNewImageVariations(initImageUrl, prompt || '', {
        ...(scale && { scale: parseFloat(scale) }),
        ...(steps && { steps: parseFloat(steps) }),
        ...(seed && { seed: parseInt(seed) }),
      })
      generate(request)
    }
  }

  async function loadMoreImages() {
    if ($allImagesLoaded || isLoading) return

    isLoading = true
    try {
      await loadImages()
    } catch (error) {
      addError(error)
    }
    isLoading = false
  }

  async function generate(request: Request, initImageUrl?: string) {
    if (!request.prompt) return

    if (!session.get()?.profile.isTrusted) {
      return openModal({
        type: ModalType.TRUSTED_USER_ONLY,
        arguments: { action: 'create AI-generated images' },
      })
    }

    generationRequestId = request.id
    try {
      const scale = request.scale ? parseFloat(request.scale.toString()) : defaultConfig.scale
      const steps = request.steps ? parseInt(request.steps.toString()) : defaultConfig.steps
      let seed = request.seed ? parseInt(request.seed.toString()) : defaultConfig.seed
      const width = request.width ? parseInt(request.width.toString()) : defaultConfig.width
      const height = request.height ? parseInt(request.height.toString()) : defaultConfig.height
      const numberOfImages = request.numberOfImages
        ? parseInt(request.numberOfImages.toString())
        : defaultConfig.numberOfImages
      const isNew = request.id.includes('new')
      const image = imagesMap[request.id]
      const isEqual =
        request.scale.toString() === (image?.config?.scale || defaultConfig.scale).toString() &&
        request.steps.toString() === (image?.config?.steps || defaultConfig.steps).toString() &&
        request.seed.toString() === (image?.config?.seed || defaultConfig.seed).toString() &&
        request.width.toString() === (image?.config?.width || defaultConfig.width).toString() &&
        request.height.toString() === (image?.config?.height || defaultConfig.height).toString() &&
        request.numberOfImages.toString() ===
          (image?.config?.numberOfImages || defaultConfig.numberOfImages).toString()

      // Randomize seed if it's not specified or if user improve existing request without settings
      // changes
      if (!seed || (!isNew && isEqual)) {
        seed = Math.floor(Math.random() * 1000000)
      }

      if (request.initImageUrl && !initImageUrl) {
        if (request.initImageBlob) {
          try {
            const jpgBlob = await convertImage(request.initImageBlob, EImageType.JPEG, 512, 64)
            const { url } = await uploadsAPI.upload(jpgBlob)
            initImageUrl = url
          } catch (error) {
            addError(error)
            return
          }
        } else {
          initImageUrl = request.initImageUrl
        }
      }

      const config = {
        width,
        height,
        numberOfImages,
        scale,
        steps,
        seed: seed as number,
        ...(initImageUrl ? { initImageUrl } : {}),
      }

      try {
        const { id } = await generateImage(request.prompt, config)
        if (request.activeOptions) {
          requests = requests.map(r => (r.id === id ? { ...r, activeOptions: true } : r))
        }
      } catch (error) {
        addError(error)
      }

      if (request.id !== 'new') {
        requests = requests
          .filter(r => !request.id.startsWith('new_') || r.id !== request.id)
          .map(r => {
            if (r.id === request.id) {
              const image = imagesMap[request.id]

              return {
                ...r,
                prompt: image?.prompt,
                initImageUrl: image?.config?.initImageUrl,
                scale: image?.config?.scale || defaultConfig.scale,
                steps: image?.config?.steps || defaultConfig.steps,
                seed: image?.config?.seed || defaultConfig.seed,
                width: image?.config?.width || defaultConfig.width,
                height: image?.config?.height || defaultConfig.height,
                numberOfImages: image?.config?.numberOfImages || defaultConfig.numberOfImages,
              }
            } else {
              return r
            }
          })
      }
    } catch (e) {
      addError(e)
    }
    generationRequestId = null
    focusFirstRequest()
  }

  async function send() {
    const result = getResult()
    if (!result && !mediaFile && !mediaFileUrl) return

    if (result && !result.upscaledUrl) {
      upscaleResult()
      return addConfirm('Image need to be upscaled before sending')
    }
    if (recipients.length === 0) return addConfirm('Please select a recipient')

    isSending = true
    try {
      let message: Message | undefined

      if (result || mediaFile?.type.startsWith('image')) {
        const thumb = result
          ? await fetch(result.upscaledUrl || result.url).then(r => r.blob())
          : mediaFile!
        const jpgThumb = await convertImage(thumb)

        message = await createMessage(
          recipients,
          jpgThumb,
          undefined,
          activeRequest?.prompt,
          activeRequest?.id,
        )
      } else if (mediaFile && mediaFile.type.startsWith('video')) {
        const thumbsInfo = await getVideoThumb(mediaFile)
        const upload = UploadManager.uploadFile(mediaFile, {
          width: thumbsInfo.width,
          height: thumbsInfo.height,
        })

        message = await createMessage(recipients, thumbsInfo.thumb, upload)
      }

      if (message) {
        const thread = $threads?.find(t => t.id === message!.threadId)
        thread && goToMessage(thread, message)
      } else {
        addError('Something went wrong')
      }
    } catch (e) {
      addError(e)
    }
    isSending = false
  }

  function focusFirstRequest() {
    root.scrollTop = 0
    tick().then(() => (root.querySelector('input[name=prompt]') as HTMLInputElement)?.focus())
    back()
  }

  function back(closeFullscreen = false) {
    if (closeFullscreen && activeResultFullscreen) {
      activeResultFullscreen = false
      return
    }

    activeRequest = null
    selectedResultId = null
    qrCodeUrl = null
    mediaFile = null
    mediaFileUrl = null
    activeResultFullscreen = false
  }

  function selectRequest(image: AIImage, resultId: string) {
    if (image.state === 'processed') {
      activeRequest = image
      selectResult(resultId)
    }
  }

  function selectResult(id: string) {
    if (!activeRequest || !activeRequest.results) return

    activeResultIndex = activeRequest.results.findIndex(result => result.id === id)
    selectedResultId = id
  }

  function getResult(): AIImageResult | null {
    if (!activeRequest || !activeRequest.results || !selectedResultId) return null
    return activeRequest.results.find(result => result.id === selectedResultId) || null
  }

  function prevResult() {
    if (!activeRequest || !activeRequest.results) return

    selectResult(activeRequest.results[Math.max(0, activeResultIndex - 1)].id)
  }

  function nextResult() {
    if (!activeRequest || !activeRequest.results) return

    selectResult(
      activeRequest.results[Math.min(activeRequest.results.length - 1, activeResultIndex + 1)].id,
    )
  }

  async function upscaleResult() {
    const result = getResult()
    if (!activeRequest || !result || result.upscaledUrl) return

    upscalingRequests[result.id] = true
    try {
      await upscaleImageResult(activeRequest, result.id)
    } catch (e) {
      addError(e)
    }
  }

  async function save() {
    const result = getResult()
    if (!result || !activeRequest) return
    if (!result.upscaledUrl) {
      upscaleResult()
      return addConfirm('Image need to be upscaled before saving')
    }

    isSaving = true

    const imageBlob = await fetch(result.upscaledUrl).then(r => r.blob())
    const imageUrl = URL.createObjectURL(imageBlob)
    const tag = document.createElement('a')
    const ext = result.upscaledUrl.split('.').pop() || 'png'

    tag.href = imageUrl
    tag.download = `${activeRequest.prompt
      .replace(/\s/g, '_')
      .replace(/[^a-zA-Z0-9_]/g, '')
      .toLowerCase()}_${activeResultIndex}.${ext}`
    document.body.appendChild(tag)
    tag.click()
    document.body.removeChild(tag)

    isSaving = false
  }

  function onDrop(event: DragEvent) {
    dragging = false

    const { dataTransfer } = event
    if (!dataTransfer) return

    let file: File | null = dataTransfer.items
      ? [...dataTransfer.items].map(i => i.getAsFile())[0]
      : [...dataTransfer.files][0]

    onFileSelect(file)
  }

  async function onFileSelect(file?: File | null) {
    if (!file) return

    if (!/image\/(.*)|video\/(mp4|webm|x-matroska)/.test(file.type)) {
      return addError('File type is not supported.')
    }
    if (file.type.startsWith('video') && file.size > 500 * 1024 * 1024) {
      return addError('File size is too large. It can not exceed 500 MB.')
    }

    if (file.type.startsWith('image')) {
      mediaFile = (await convertImage(file, EImageType.JPEG, 512, 64)) as File
    } else {
      mediaFile = file
    }

    mediaFileUrl = URL.createObjectURL(mediaFile)
  }

  function deleteRequestImage(request: Request) {
    requests = requests.map(r =>
      r.id === request.id ? { ...r, initImageUrl: undefined, initImageBlob: undefined } : r,
    )
  }

  async function deleteRequest(id: string) {
    if (!imagesMap[id]) {
      requests = requests.filter(r => r.id !== id)
      return
    }

    requests = requests.map(r => (r.id === id ? { ...r, deleting: true } : r))
    try {
      await deleteImage(id)
    } catch (error) {
      addError(error)
    }
    requests = requests.map(r => (r.id === id ? { ...r, deleting: false } : r))
  }

  function toggleRequestOptions(id: string) {
    requests = requests.map(r => (r.id === id ? { ...r, activeOptions: !r.activeOptions } : r))
  }

  function resetRequestOptions(id: string) {
    let config: any = { ...defaultConfig }
    requests = requests.map(r => {
      if (r.id === id) {
        if (r.initImageUrl) {
          delete config.width
          delete config.height
        }

        return { ...r, ...config }
      }
      return r
    })
  }

  function isDefaultOptions(request: Request): boolean {
    return (
      request.scale.toString() === defaultConfig.scale.toString() &&
      request.steps.toString() === defaultConfig.steps.toString() &&
      // request.seed.toString() === defaultConfig.seed.toString() &&
      request.width.toString() === defaultConfig.width.toString() &&
      request.height.toString() === defaultConfig.height.toString() &&
      request.numberOfImages.toString() === defaultConfig.numberOfImages.toString()
    )
  }

  function generateVariations() {
    const currentRequest = requests.find(r => r.id === activeRequest?.id)
    const result = getResult()
    if (!currentRequest || !result) return

    requests = [
      {
        ...currentRequest,
        id: `new_${Date.now()}`,
        initImageBlob: undefined,
        initImageUrl: result.url,
      },
      ...requests,
    ]
    focusFirstRequest()
  }

  async function generateNewImageVariations(
    initImageUrl?: string | null,
    prompt = '',
    config?: {
      scale?: number
      steps?: number
      seed?: number
    },
  ): Promise<Request> {
    const imageData: any = {}

    if (initImageUrl) {
      let image = await urltoImage(initImageUrl)

      if (image.width > 512 || image.height > 512) {
        const blob = await fetch(initImageUrl).then(r => r.blob())
        const resizedBlob = await convertImage(blob, EImageType.JPEG, 512, 64)

        initImageUrl = URL.createObjectURL(resizedBlob)
        mediaFile = resizedBlob as File
        image = await urltoImage(initImageUrl)
      }

      imageData.width = image.width
      imageData.height = image.height
      imageData.initImageUrl = initImageUrl
      imageData.initImageBlob = mediaFile || undefined
    }

    const request = {
      ...defaultConfig,
      ...config,
      ...imageData,
      id: `new_${Date.now()}`,
      prompt,
    }

    if (requests.length === 1 && requests[0].id === 'new') {
      requests = [request]
    } else {
      requests = [request, ...requests]
    }

    focusFirstRequest()

    return request
  }

  function addNewRequest() {
    const request = {
      ...defaultConfig,
      prompt: '',
      id: `new_${Date.now()}`,
    }

    if (requests.length === 1 && requests[0].id === 'new') {
      requests = [request]
    } else {
      requests = [request, ...requests]
    }
    focusFirstRequest()
  }

  function openInitImage(request: Request) {
    if (request.initImageUrl) {
      mediaFileUrl = request.initImageUrl
    }
  }

  function markLoaded(index: number) {
    if (loadingIndex === index) {
      const totalCount = parseInt(requests[loadingIndex]?.numberOfImages.toString()) || 8
      loadingImagesCount++

      if (loadingImagesCount === totalCount) {
        loadingIndex++
        loadingImagesCount = 0

        while (requests[loadingIndex] && !imagesMap[requests[loadingIndex].id]?.results) {
          loadingIndex++
        }
      }
    }
  }

  function getHeightCoefficient(id: string) {
    return (imagesMap[id].config?.height || 512) / (imagesMap[id].config?.width || 512)
  }

  async function openQRCode() {
    qrCodeUrl = await generateCameraLink()
  }

  onMount(() => {
    hotkeys('esc', back.bind(null, true))
    hotkeys('left', prevResult)
    hotkeys('right', nextResult)

    loaderObserver = new IntersectionObserver(
      ([target]) => target.isIntersecting && loadMoreImages(),
      { root, threshold: 0.5 },
    )
    loaderObserver.observe(loaderEl)
  })

  onDestroy(() => {
    hotkeys.unbind('esc', back.bind(null, true))
    hotkeys.unbind('left', prevResult)
    hotkeys.unbind('right', nextResult)
    unsubscribes.forEach(un => un())
    sidebarStateBefore && setSidebarState(sidebarStateBefore)
    loaderObserver.disconnect()
  })

  ; // prettier-ignore
</script>

<section
  bind:this={root}
  class="ai"
  class:dragging
  on:dragover|preventDefault={() => (dragging = true)}>
  {#if dragging}
    <div
      class="drag-overlay"
      on:dragleave|preventDefault={() => (dragging = false)}
      on:drop|preventDefault={onDrop}>
      Drag and drop assets here
    </div>
  {/if}
  <Navigation>
    <RecipientsField bind:recipients />
  </Navigation>
  <div class="requests">
    {#each requests as request, index (request.id)}
      <div class="request" class:new={request.id === 'new'}>
        {#if index === 0}
          <div class="label">
            Enter a detailed prompt, <span class="link" on:click={() => fileInput.click()}
              >upload an image or video</span>
            or <span class="link" on:click={openQRCode}>take a photo</span>
            {#if request.id !== 'new'}
              <div class="add-new" on:click={addNewRequest}>New Generation</div>
            {/if}
          </div>
          <input
            type="file"
            accept="image/*,video/mp4,video/webm,video/x-matroska"
            style="display: none;"
            bind:this={fileInput}
            on:change={() => onFileSelect(fileInput.files?.item(0))} />
        {/if}
        <form class="prompt" on:submit|preventDefault={() => generate(request)}>
          <div class="field">
            {#if request.initImageUrl}
              <div class="image">
                <img
                  src={request.initImageUrl}
                  alt={request.prompt}
                  on:click={() => openInitImage(request)} />
                <div class="image-delete" on:click={() => deleteRequestImage(request)}>
                  <CrossIcon />
                </div>
              </div>
            {/if}
            <Input
              bind:value={request.prompt}
              name="prompt"
              label=" "
              placeholder="A greek philosopher wearing a VR headset..." />
            <div class="buttons">
              <div
                class="button"
                class:active={!isDefaultOptions(request)}
                on:click={() => toggleRequestOptions(request.id)}>
                <ConfigIcon />
                <Tooltip title="Advanced configuration" />
              </div>
              {#if request.id !== 'new'}
                <div class="button" on:click={() => deleteRequest(request.id)}>
                  {#if request.deleting}
                    <Spinner color="black" />
                  {:else}
                    <TrashIcon />
                  {/if}
                  <Tooltip
                    title={`${
                      imagesMap[request.id]?.state === 'processing' ? 'Cancel' : 'Delete'
                    } request`} />
                </div>
              {/if}
            </div>
            {#if request.activeOptions}
              <div class="options" transition:slide={{ duration: 300 }}>
                <div class="option">
                  <span>width:</span>
                  <Input
                    bind:value={request.width}
                    type="number"
                    step="64"
                    min="64"
                    max="704"
                    disabled={!!request.initImageUrl}
                    label=" " />
                  <Tooltip title="Width (64 - 704)" position="bottom" />
                </div>
                <div class="option">
                  <span>height:</span>
                  <Input
                    bind:value={request.height}
                    type="number"
                    step="64"
                    min="64"
                    max="704"
                    disabled={!!request.initImageUrl}
                    label=" " />
                  <Tooltip title="Height (64 - 704)" position="bottom" />
                </div>
                <div class="option">
                  <span>number of images:</span>
                  <Input
                    bind:value={request.numberOfImages}
                    type="number"
                    step="1"
                    min="1"
                    max="8"
                    label=" " />
                  <Tooltip title="Number of images (1 - 8)" position="bottom" />
                </div>
                <div class="option">
                  <span>CFG:</span>
                  <Input
                    bind:value={request.scale}
                    type="number"
                    step="0.5"
                    min="0"
                    max="20"
                    label=" " />
                  <Tooltip title="Unconditional guidance scale (0 - 20)" position="bottom" />
                </div>
                <div class="option">
                  <span>steps:</span>
                  <Input
                    bind:value={request.steps}
                    type="number"
                    step="1"
                    min="10"
                    max="150"
                    label=" " />
                  <Tooltip title="Number of ddim sampling steps (10 - 150)" position="bottom" />
                </div>
                <div class="option">
                  <span>seed:</span>
                  <Input bind:value={request.seed} placeholder="random" label=" " />
                  <Tooltip
                    title="Seed, for reproducible sampling (0 - 999999)."
                    position="bottom" />
                </div>
                <div class="option reset">
                  <span on:click={() => resetRequestOptions(request.id)}>Reset</span>
                </div>
              </div>
            {/if}
          </div>
          <Button
            type="submit"
            disabled={!request.prompt}
            textColor="white"
            loading={imagesMap[request.id]?.state === 'processing' ||
              generationRequestId === request.id}>
            Generate
          </Button>
        </form>
        {#if imagesMap[request.id]?.error}
          <div class="request-error">{imagesMap[request.id].error}</div>
        {/if}
        {#if imagesMap[request.id]?.results || imagesMap[request.id]?.state === 'processing'}
          <div
            class="request-results"
            class:loading={imagesMap[request.id]?.state === 'processing'}>
            {#each imagesMap[request.id]?.results || Array(parseInt(request.numberOfImages.toString()) || 8) as result}
              <div
                class="request-result"
                style={`padding-bottom: calc(${getHeightCoefficient(request.id) * 25}% - ${
                  getHeightCoefficient(request.id) * 16
                }px);`}
                on:click={result
                  ? selectRequest.bind(null, imagesMap[request.id], result.id)
                  : () => {}}>
                {#if result && index <= loadingIndex}
                  <img
                    src={result.url}
                    alt={`Result #${result.id}`}
                    on:load={markLoaded.bind(null, index)}
                    on:error={markLoaded.bind(null, index)} />
                {/if}
              </div>
            {/each}
          </div>
        {/if}
      </div>
    {/each}
  </div>
  <div class="loader" class:hidden={!isLoading} bind:this={loaderEl}>
    <Spinner color="black" />
  </div>
  {#if activeRequest || mediaFileUrl || qrCodeUrl}
    <div
      class="active-request"
      class:fullscreen={activeResultFullscreen}
      transition:scale={{ duration: 300 }}>
      <div class="inner">
        <div class="request-top">
          <div class="request-btn back" on:click={back.bind(null, false)}>
            <PrevSmallBtnIcon /> Back
          </div>
          <div class="request-prompt" />
          {#if activeRequest}
            <div class="request-btn">
              <Button
                on:click={upscaleResult}
                disabled={upscalingRequests[selectedResultId || '']}
                loading={(activeRequest.results || [])[activeResultIndex].upscaling ||
                  upscalingRequests[selectedResultId || '']}>
                {#if (activeRequest.results || [])[activeResultIndex].upscaledUrl}
                  <CheckIcon /> Upscaled
                {:else}
                  Upscale
                {/if}
              </Button>
            </div>
            <div class="request-btn">
              <Button
                on:click={generateVariations}
                loading={generationRequestId === activeRequest.id}>Variations</Button>
            </div>
            <div class="request-btn">
              <Button on:click={save} loading={isSaving} disabled={isSaving}>Save</Button>
            </div>
          {:else if !qrCodeUrl && (!mediaFile || mediaFile?.type.startsWith('image'))}
            <div class="request-btn">
              <Button on:click={() => generateNewImageVariations(mediaFileUrl)}>Variations</Button>
            </div>
          {/if}
          {#if !qrCodeUrl}
            <div class="request-btn">
              <Button color="red" on:click={send} loading={isSending} disabled={isSending}>
                Send
              </Button>
            </div>
          {/if}
        </div>
        {#if activeRequest}
          <div class="results">
            {#if selectedResultId && activeRequest.results}
              {#if activeResultIndex !== 0}
                <div class="arrow prev" on:click={prevResult}>
                  <PrevSmallBtnIcon />
                </div>
              {/if}
              {#if activeResultIndex !== activeRequest.results.length - 1}
                <div class="arrow next" on:click={nextResult}>
                  <NextSmallBtnIcon />
                </div>
              {/if}
            {/if}
            <div class="results-inner" style={`margin-left: ${activeResultIndex * -100}%`}>
              {#each activeRequest.results || [] as result, index}
                <div
                  class="result"
                  class:fullscreen={activeResultFullscreen && activeResultIndex === index}
                  on:click={() => (activeResultFullscreen = !activeResultFullscreen)}>
                  <div
                    class="close"
                    on:click|stopPropagation={() => (activeResultFullscreen = false)}>
                    <CloseFullscreenIcon />
                  </div>
                  <img src={result.upscaledUrl || result.url} alt={`Result #${result.id}`} />
                </div>
              {/each}
            </div>
          </div>
        {:else if mediaFileUrl}
          <div class="body">
            {#if !mediaFile || mediaFile.type.startsWith('image/')}
              <img src={mediaFileUrl} alt="New asset" />
            {:else}
              <LocalPlayer urls={[mediaFileUrl]} isHorizontal={false} shouldPlay={true} />
            {/if}
          </div>
        {:else if qrCodeUrl}
          <div class="qr-code">
            <ol>
              <li>Scan this QR code using your phone camera.</li>
              <li>Follow the link and open the web app.</li>
              <li>
                Take a photo of your drawing or anything else you want to use as a reference for
                your AI image.
              </li>
              <li>The photo would appear here.</li>
            </ol>
            <QRCode value={qrCodeUrl} size="320" />
          </div>
        {/if}
      </div>
    </div>
  {/if}
</section>

<style lang="scss">
  .ai {
    width: 100%;
    height: 100%;
    padding: 24px;
    box-sizing: border-box;
    overflow-y: auto;
  }

  .drag-overlay {
    content: 'Drag and drop assets here';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    color: #fff;
    font-size: 24px;
    z-index: 100;
  }

  .label {
    margin-bottom: 16px;
    color: rgba(0, 0, 0, 0.6);

    .link {
      color: var(--primary-color);
      cursor: pointer;
    }
  }

  .add-new {
    background: rgba(0, 0, 0, 0.05);
    padding: 6px 12px;
    float: right;
    font-size: 14px;
    color: #000;
    border-radius: 6px;
    margin-top: -6px;
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
  }

  .prompt {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.15);
    background-color: #fff;

    .field {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      align-items: center;
    }

    .image {
      height: 32px;
      display: flex;
      flex-direction: row;
      flex: 0 0 auto;
      background: rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      margin-left: 4px;
      border: 1px solid rgba(0, 0, 0, 0.05);
      overflow: hidden;
      box-sizing: border-box;

      img {
        height: 100%;
        display: block;
        cursor: pointer;
      }
    }

    .image-delete {
      cursor: pointer;
      height: 100%;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      :global(svg) {
        width: 12px;
        height: 12px;
      }

      :global(svg path) {
        fill: #000;
      }
    }

    :global(.input) {
      flex: 1 0 auto;
      border-radius: 5px 0 0 5px;
      padding: 8px 12px;
      border-color: transparent;
      background-color: #fff;
    }

    :global(button) {
      flex: 0 0 100px;
      border-radius: 0 5px 5px 0;
      margin: 0;
      color: #fff;
      background-color: var(--primary-color) !important;
      border-left: 1px solid rgba(0, 0, 0, 0.05);
      height: auto;

      &:disabled {
        color: rgba(0, 0, 0, 0.3);
        background-color: #fff !important;
      }
    }

    .buttons {
      display: flex;
    }

    .button {
      position: relative;
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &.active::before {
        content: '';
        position: absolute;
        top: 6px;
        right: 6px;
        left: 6px;
        bottom: 6px;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 6px;
        z-index: 0;
      }

      & :global(svg) {
        height: 18px;
      }

      & :global(svg path:not(.stroke)) {
        fill: rgba(0, 0, 0, 0.85);
      }

      & :global(svg path.stroke) {
        stroke: rgba(0, 0, 0, 0.85);
      }
    }

    .options {
      display: flex;
      width: 100%;
      padding: 0 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      height: 40px;
    }

    .option {
      position: relative;
      display: flex;
      align-items: center;
      margin: 0 8px;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      z-index: 1;

      span {
        color: rgba(0, 0, 0, 0.6);
        margin-right: 8px;
      }

      :global(.input) {
        max-width: 60px;
        padding: 8px;
      }

      &.reset {
        flex: 1 1 auto;
        border: none;
        justify-content: right;
        margin: 0;

        span {
          cursor: pointer;
        }
      }
    }
  }

  .requests {
    display: flex;
    flex-direction: row;
    width: calc(100% + 32px);
    margin: 64px -16px 0;
    flex-wrap: wrap;
    justify-content: center;
  }

  .request {
    width: calc(100% - 32px);
    max-width: 1000px;
    flex: 0 0 auto;
    margin: 16px;
    border-radius: 5px;
    box-sizing: border-box;
    transition: margin-top 0.8s ease;

    &.new {
      margin-top: 25vh;
    }
  }

  .request-prompt {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    font-size: 18px;
    word-break: break-all;
    margin-bottom: 8px;
  }

  .loader {
    display: flex;
    flex-direction: row;
    margin: 16px 0 0;
    flex-wrap: wrap;
    justify-content: center;

    &.hidden {
      opacity: 0;
    }
  }

  .request-error {
    font-size: 16px;
    margin: 16px 0 12px;
    color: var(--primary-color);
  }

  .request-results {
    display: flex;
    flex-direction: row;
    width: calc(100% + 16px);
    margin: 8px -8px;
    flex-wrap: wrap;
    align-items: flex-start;
    user-select: none;
  }

  .request-result {
    position: relative;
    width: calc(25% - 16px);
    height: 0;
    padding-bottom: calc(25% - 16px);
    background-color: rgba(0, 0, 0, 0.05);
    flex: 0 0 auto;
    margin: 8px;
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;

    &:hover img {
      transform: scale(1.03);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: transform 0.2s ease;
    }

    .loading > & {
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        width: 500%;
        margin-left: -250%;
        pointer-events: none;
        content: ' ';
        background: linear-gradient(
            to right,
            rgba(#fff, 0) 46%,
            rgba(#fff, 0.35) 50%,
            rgba(#fff, 0) 54%
          )
          50% 50%;
        animation: ph-animation 0.8s linear infinite;
      }
    }
  }

  .active-request {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.95);
    padding: 64px 24px 24px;
    z-index: 1;

    &.fullscreen {
      z-index: 100;

      .results-inner {
        transition: none;
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 24px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      overflow-y: auto;
      overflow-x: hidden;
    }

    .body {
      position: relative;
      flex: 1 1 auto;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      :global(.vk-player-video) {
        background-color: #fff;
      }

      :global(.preview-controls) {
        position: absolute;
        right: 20px;
        bottom: 20px;
      }

      :global(.player::after) {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 84px;
        background: linear-gradient(
          transparent 0,
          rgba(0, 0, 0, 0.1) 10%,
          rgba(0, 0, 0, 0.25) 25%,
          rgba(0, 0, 0, 0.5) 50%,
          rgba(0, 0, 0, 0.75) 100%
        );
      }
    }

    .qr-code {
      position: relative;
      flex: 1 1 auto;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: 60px;

      ol {
        width: 50%;
        flex: 1 1 50%;
        font-size: 21px;
      }

      :global(img) {
        flex: 1 1 50%;
        object-fit: none;
        display: block;
      }
    }

    .results {
      display: flex;
      flex-direction: row;
      width: calc(100% + 16px);
      margin: 8px -8px;
      padding-left: 8px;
      align-items: flex-start;
      user-select: none;
      flex: 1 1 auto;
      align-content: normal;
      flex-wrap: nowrap;
      overflow: hidden;
    }

    .results-inner {
      display: flex;
      width: 100%;
      height: 100%;
      align-items: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      transition: margin-left 0.3s ease;
    }

    .result {
      position: relative;
      width: calc(100% - 16px);
      height: calc(100% - 16px);
      cursor: pointer;
      flex: 0 0 auto;
      margin: 8px;
      box-sizing: border-box;
      overflow: hidden;

      &.fullscreen {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        z-index: 3;
        background: rgba(0, 0, 0, 0.9);
        margin: 0 !important;
        width: 100%;
        height: 100%;

        .close {
          display: block;
        }

        img {
          width: auto;
          height: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .close {
        position: absolute;
        top: 16px;
        right: 16px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        display: none;

        :global(svg) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .request-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 24px;

    > :global(*:not(:first-child)) {
      margin-left: 8px;
    }

    > :global(*:not(:last-child)) {
      margin-right: 8px;
    }

    .request-prompt {
      flex: 1 1 auto;
      padding: 6px 0;
      font-size: 21px;
      margin-left: 16px;
    }
  }

  .request-btn {
    display: flex;
    flex: 0 0 auto;
    color: #000;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    height: 40px;

    :global(svg) {
      height: 24px;
      margin-right: 8px;
    }

    & :global(svg path:not(.stroke)) {
      fill: #000;
    }

    & :global(svg path.stroke) {
      stroke: #000;
    }

    :global(button),
    :global(a) {
      margin-top: 0;
      min-width: 100px;
    }
  }

  .arrow {
    position: absolute;
    display: flex;
    top: 50%;
    width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;

    > :global(svg) {
      width: 100%;
      height: 100%;
    }

    > :global(svg path) {
      fill: rgba(0, 0, 0, 0.75);
    }

    &.prev {
      left: 56px;
    }

    &.next {
      right: 56px;
    }
  }

  @keyframes ph-animation {
    0% {
      transform: translate3d(-30%, 0, 0);
    }

    100% {
      transform: translate3d(30%, 0, 0);
    }
  }
</style>
