import type { User, FullUserProfile } from 'types'
import * as api from './api'

export type ProfileData = {
  email: string
  fullName: string
  username: string
  imageId: string
}

export async function getUser(userId: string): Promise<FullUserProfile> {
  return api.get<FullUserProfile>(`/users/${userId}`)
}

export async function getProfile(): Promise<User> {
  return (await api.get<{ profile: User }>('/users/me')).profile
}

export async function updateProfile(data: Partial<ProfileData>): Promise<User> {
  return (await api.put<{ profile: User }>('/users/me', data)).profile
}

export async function addAppleAccount(authorizationCode: string, idToken: string): Promise<User> {
  return (await api.post<{ profile: User }>('/auth/apple', { authorizationCode, idToken })).profile
}

export async function addGoogleAccount(idToken: string): Promise<User> {
  return (await api.post<{ profile: User }>('/auth/google', { idToken })).profile
}

export async function deleteProfileImage(): Promise<User> {
  return (await api.del<{ profile: User }>('/users/me/profileimage')).profile
}

export async function deleteAccount(): Promise<void> {
  return await api.del<void>('/users/me')
}
