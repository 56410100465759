import type {
  Thread,
  ChannelSettings,
  Community,
  User,
  Member,
  UnwatchedMessagesInfo,
  InviteLink,
} from 'types'
import * as api from './api'
import { getQueryString } from 'utils'

export type ThreadData = {
  communityId?: string
  title?: string
  imageURL?: string
  imageId?: string
  members?: {
    userIds?: string[]
    emails?: string[]
    phoneNumbers?: string[]
  }
  channel?: ChannelSettings
}

export async function getThreads(withExtraInfo = true): Promise<Thread[]> {
  return (
    await api.get<{ threads: Thread[] }>(
      `/threads${withExtraInfo ? '?withLastMessage=true&withUnwatchedMessagesCount=true' : ''}`,
    )
  ).threads.map(thread => {
    if (thread.lastMessage) {
      thread.messages = [thread.lastMessage]
      delete thread.lastMessage
    }
    return thread
  })
}

export async function getUnwatchedMessagesInfo(feed = false): Promise<UnwatchedMessagesInfo[]> {
  return api.get<UnwatchedMessagesInfo[]>(
    `/threads/unwatched-messages-info${feed ? '?feed=true' : ''}`,
  )
}

export async function getThread(id: string): Promise<Thread> {
  return (await api.get<{ thread: Thread }>(`/threads/${id}`)).thread
}

export async function createThread(data: ThreadData): Promise<Thread> {
  return (await api.post<{ thread: Thread }>('/threads', data)).thread
}

export async function updateThread(id: string, data: ThreadData): Promise<Thread> {
  return (await api.put<{ thread: Thread }>(`/threads/${id}`, data)).thread
}

export async function getMembers(
  id: string,
  filters: {
    offset?: number
    limit?: number
    query?: string
  } = {},
): Promise<User[]> {
  return (await api.get<{ members: User[] }>(`/threads/${id}/members${getQueryString(filters)}`))
    .members
}

export async function addMembers(id: string, members: Member[]): Promise<User[]> {
  return (await api.post<{ members: User[] }>(`/threads/${id}/members/add`, { members })).members
}

export async function removeMembers(id: string, memberIds: string[]): Promise<void> {
  return api.post<void>(`/threads/${id}/members/remove`, { memberIds })
}

export async function banMember(
  id: string,
  data: {
    memberId: string
    duration?: number
    comment?: string
  },
): Promise<void> {
  return api.post<void>(`/threads/${id}/members/ban`, data)
}

export async function createInviteLink(id: string): Promise<InviteLink> {
  return api.post<InviteLink>(`/threads/${id}/invite`)
}

export async function joinThread(id: string, token?: string): Promise<Thread> {
  return (await api.post<{ thread: Thread }>(`/threads/${id}/join`, token ? { token } : undefined))
    .thread
}

export async function join(token: string): Promise<{ thread?: Thread; community?: Community }> {
  return api.post<{ thread?: Thread; community?: Community }>(`/join/${token}`)
}

export async function leaveThread(id: string): Promise<void> {
  return api.post<void>(`/threads/${id}/leave`)
}

export async function mute(id: string): Promise<void> {
  return api.post<void>(`/threads/${id}/mute`)
}

export async function unmute(id: string): Promise<void> {
  return api.post<void>(`/threads/${id}/unmute`)
}

export async function markAsWatched(id: string): Promise<void> {
  return api.post<void>(`/threads/${id}/mark-as-watched`)
}
