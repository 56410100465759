<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { authAPI } from 'api'
  import { validatePhoneNumber } from 'utils'
  import { savePhoneNumber } from 'actions/profile'
  import Input from 'components/Input.svelte'
  import CodeInput from 'components/CodeInput.svelte'
  import Button from 'components/Button.svelte'

  const dispatch = createEventDispatcher<{ close: undefined }>()

  export let phoneNumber = '+1'
  export let isActive = false

  let phoneInput: Input
  let codeInput: CodeInput
  let code = ''
  let showCodeInput = false
  let isLoadingSMS = false
  let isLoadingLogin = false
  let error = ''

  $: isValidPhone = validatePhoneNumber(phoneNumber, true)
  $: canSubmit = isValidPhone && code.length === 6
  $: isActive && (isValidPhone && !showCodeInput ? sendSMS() : phoneInput?.focus())

  async function sendSMS() {
    if (!isValidPhone || isLoadingSMS) return

    isLoadingSMS = true
    showCodeInput = false
    try {
      error = ''
      await authAPI.sendSMS(phoneNumber)
      showCodeInput = true
      codeInput.focus()
    } catch (e: any) {
      error = e.message || e.error || e
    }
    isLoadingSMS = false
  }

  async function save() {
    if (!canSubmit || isLoadingLogin) return

    isLoadingLogin = true
    try {
      error = ''
      await savePhoneNumber(phoneNumber, code)
      close()
    } catch (e: any) {
      error = e.message || e.error || e
    }
    isLoadingLogin = false
  }

  function close() {
    dispatch('close')
  }
</script>

<div class="root">
  <div>
    <form on:submit|preventDefault={sendSMS}>
      <Input
        bind:this={phoneInput}
        bind:value={phoneNumber}
        name="phone"
        label="Phone"
        mask="+0 000 000-00-00"
        type="tel" />
      <Button color="red" loading={isLoadingSMS} disabled={!isValidPhone} type="submit"
        >Send Verification Code</Button>
    </form>
    <form on:submit|preventDefault={save} class:invisible={!showCodeInput}>
      <CodeInput bind:this={codeInput} bind:value={code} />
      <button type="submit" style="display: none" />
    </form>
    <div class="error">{error}</div>
  </div>
  <div class="buttons">
    <Button on:click={close}>Cancel</Button>
    <Button color="red" loading={isLoadingLogin} disabled={!canSubmit} on:click={save}>Save</Button>
  </div>
</div>

<style lang="scss">
  .root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  form {
    width: 100%;
    transition: opacity 0.3s;

    > :global(*:not(:first-child)) {
      margin-top: 10px;
    }

    > :global(.code) {
      margin: 24px 0 12px;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    > :global(button) {
      width: auto;
      padding-left: 16px;
      padding-right: 16px;
      min-width: 100px;
    }
  }

  .error {
    color: red;
  }

  .invisible {
    opacity: 0;
  }
</style>
