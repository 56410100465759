<script lang="ts">
  import { Community, SidebarState, Thread } from 'types'
  import hotkeys from 'hotkeys-js'
  import { onMount, onDestroy, createEventDispatcher } from 'svelte'
  import { fly } from 'svelte/transition'
  import { location } from 'svelte-spa-router'
  import LogoIcon from 'assets/icons/logo.svg'
  import SearchIcon from 'assets/icons/search.svg'
  import CloseIcon from 'assets/icons/close.svg'
  import CrossIcon from 'assets/icons/cross.svg'
  import DraftsIcon from 'assets/icons/drafts.svg'
  import PlusIcon from 'assets/icons/new-plus.svg'
  import MoreIcon from 'assets/icons/more.svg'
  import WarningIcon from 'assets/icons/warning.svg'
  import HideMenu from 'assets/icons/hide-menu.svg'
  import DownArrow from 'assets/icons/down-arrow.svg'
  import FeedArrow from 'assets/icons/feed.svg'
  import Spinner from 'components/Spinner.svelte'
  import {
    goToThread,
    goToNewMessage,
    isNewMessagePage,
    goToDraft,
    goToFeed,
    goToReports,
    goToAI,
  } from 'actions/router'
  import { setSidebarHovered, setSidebarState } from 'actions'
  import { isThreadMatch, animateProperty } from 'utils'
  import { clickOutseide } from 'utils/clickOutsideAction'
  import {
    session,
    threads as threadsStore,
    communities as communitiesStore,
    currentThread,
    currentThreadId,
    newMessages,
    unwatchedMessagesCount,
    reportsTotalCount,
  } from 'stores'
  import Avatar from 'components/Avatar.svelte'
  import ThreadsList from './ThreadsList.svelte'
  import Menu from './Menu.svelte'
  import Profile from './Profile/Profile.svelte'
  import Tooltip from 'components/Tooltip.svelte'

  export let isLoading = false
  export let isLeavingCommunity = false
  export let error: string | undefined

  const dispatch = createEventDispatcher<{
    toggleSidebar: boolean
  }>()

  let root: HTMLElement
  let scrollBox: HTMLDivElement
  let scrollBoxTop = 0
  let threadsFilter: string | undefined
  let allThreads: Thread[]
  let threads: Thread[]
  let channels: Thread[]
  let communities: Community[]
  let activeCommunityId: string | null | undefined
  let isSearchFocused = false
  let activeTabInAllMessages = false
  let isDrafts = false
  let isReports = false
  let isFeed = false
  let isAI = false
  let menuEl: Menu
  let activeMenu: 'root' | 'community' | 'channel' | undefined
  let activeMenuVisible = false
  let activeMenuEntityId: string | undefined
  let hideMenuTimeout: any
  let activeMenuTop = 0
  let showProfile = false
  let sidebarThreadItems: HTMLDivElement[] = []
  let notWatchedMessagesCommunitiesMap: { [key: string]: boolean } = {}
  const communityExpandedState: { [key: string]: boolean } = {}
  const activeSections: boolean[] = [false, true, true]
  const unsubscribes: (() => void)[] = []

  $: unwatchedTotal = $unwatchedMessagesCount
  $: updateThreads(undefined, threadsFilter)
  $: root && updateSidebarThreadItems()
  $: !isLeavingCommunity && toggleMenu()
  $: updateActiveCommunityId($currentThread, isFeed)

  unsubscribes.push(
    location.subscribe(location => {
      isDrafts = /\/drafts/.test(location)
      isReports = /\/reports/.test(location)
      isFeed = /\/feed/.test(location)
      isAI = /\/ai/.test(location)
    }),
  )
  unsubscribes.push(
    threadsStore.subscribe(allThreads => {
      updateThreads(allThreads || [], threadsFilter)
      expandCommunities(allThreads || [])
    }),
  )
  unsubscribes.push(
    communitiesStore.subscribe(communities => {
      updateThreads(undefined, threadsFilter, communities)
    }),
  )
  unsubscribes.push(currentThreadId.subscribe(() => setTimeout(scrollToActiveThread)))

  function updateThreads(_allThreads?: Thread[], filter?: string, allCommunities?: Community[]) {
    const { profile } = session.get() || {}
    const communityThreads: { [key: string]: Thread[] } = {}
    const filterValue = filter?.toLowerCase()
    allThreads = _allThreads || threadsStore.get() || []
    communities = allCommunities || communitiesStore.get() || []
    filter = filter || threadsFilter
    threads = []
    channels = []

    allThreads = allThreads.filter(thread => {
      if (!filterValue || isThreadMatch(thread, filterValue, profile)) {
        if (thread.channel) {
          if (thread.communityId) {
            communityThreads[thread.communityId] = [
              ...(communityThreads[thread.communityId] || []),
              thread,
            ]
          } else {
            channels.push(thread)
          }
        } else {
          threads.push(thread)
        }
        return true
      }
      return false
    })

    communities = communities
      .map(community => ({
        ...community,
        threads: communityThreads[community.id] || [],
      }))
      .filter(
        community =>
          !(
            filterValue &&
            community.threads.length === 0 &&
            community.title.toLowerCase().indexOf(filterValue) === -1
          ),
      )

    expandCommunities()
    setTimeout(updateSidebarThreadItems)
  }

  function updateActiveCommunityId(thread: Thread | undefined, isFeed: boolean) {
    activeCommunityId = (!isFeed && !activeTabInAllMessages && thread?.communityId) || null
    if (activeCommunityId) {
      communityExpandedState[activeCommunityId] = true
    }
  }

  function updateSidebarThreadItems() {
    sidebarThreadItems = Array.from(root?.querySelectorAll('.thread:not(.menu-anchor)') || [])
  }

  function expandCommunities(threads?: Thread[]) {
    const map: typeof notWatchedMessagesCommunitiesMap = {}
    const dayAgo = new Date(Date.now() - 24 * 60 * 60 * 1000)

    ;(threads || threadsStore.get() || []).forEach(thread => {
      if (thread && thread.communityId) {
        const hasNewMessage = (thread.unwatchedMessagesCount || 0) > 0
        const wasUpdatedRecently = new Date(thread.modifiedAt) > dayAgo

        if (hasNewMessage || wasUpdatedRecently) {
          if (communityExpandedState[thread.communityId] !== false) {
            communityExpandedState[thread.communityId] = true
          }

          if (hasNewMessage) {
            map[thread.communityId] = true
          }
        }
      }
    })

    notWatchedMessagesCommunitiesMap = map
  }

  function scrollToActiveThread() {
    if (!scrollBox) return
    const activeThread = scrollBox.querySelector('.active.thread') as HTMLElement
    if (!activeThread) return
    const offsetTop = activeThread.offsetTop - scrollBox.offsetTop
    const threadHeight = activeThread.offsetHeight
    const { scrollTop, offsetHeight } = scrollBox

    if (scrollTop > offsetTop || scrollTop + offsetHeight < offsetTop + threadHeight) {
      animateProperty(
        scrollBox,
        'scrollTop',
        activeThread.offsetTop - (scrollBox.offsetHeight - activeThread.offsetHeight) / 2,
      )
    }
  }

  function onThreadClick(thread: Thread, fromAllMessages = false) {
    activeTabInAllMessages = fromAllMessages
    goToThread(thread)
  }

  function moveToThread(step: number, event: KeyboardEvent) {
    if (isNewMessagePage()) return
    event.preventDefault()

    const active = root.querySelector('.thread.active:not(.menu-anchor)') as HTMLElement
    const index = sidebarThreadItems.findIndex(el => el === active)
    if (index === -1) return
    const nextIndex = Math.max(0, Math.min(index + step, sidebarThreadItems.length - 1))

    if (nextIndex !== index) {
      const nextThread = sidebarThreadItems[nextIndex]
      let sectionEl: HTMLElement | null = nextThread

      while (
        sectionEl &&
        (!sectionEl.classList.contains('threads-section') || sectionEl === root)
      ) {
        sectionEl = sectionEl?.parentElement
      }

      if (sectionEl && !sectionEl.classList.contains('active')) {
        ;(sectionEl.firstElementChild as HTMLElement)?.click()
      }
      nextThread.click()
    }
  }

  function toggleMenu(event?: Event, menu?: string, id?: string) {
    if (!menu || (activeMenu === menu && activeMenuEntityId === id)) {
      activeMenuVisible = false
      hideMenuTimeout = setTimeout(() => {
        activeMenu = undefined
        activeMenuEntityId = undefined
        activeMenuTop = 0
      }, 200)
    } else {
      let anchor: HTMLElement | null = event?.target as HTMLElement
      while (anchor && !anchor.classList.contains('menu-anchor')) {
        anchor = anchor.parentElement
      }
      const btn = anchor?.querySelector('.thread-btn') as HTMLElement

      activeMenuEntityId = id
      activeMenu = menu as typeof activeMenu
      activeMenuTop = anchor
        ? anchor.offsetTop + (btn?.offsetTop || 0) + (btn || anchor).offsetHeight / 2
        : 0
      hideMenuTimeout && clearTimeout(hideMenuTimeout)
      setTimeout(() => {
        activeMenuVisible = true
        menuEl?.focus()
      }, 10)
    }
  }

  function toggleProfile(state?: boolean) {
    state = typeof state === 'boolean' ? state : !showProfile
    setTimeout(
      () => {
        showProfile = state!
        setSidebarState(showProfile ? SidebarState.SHOW : SidebarState.AUTO)
      },
      state ? 10 : 0,
    )
  }

  onMount(() => {
    hotkeys('up', moveToThread.bind(null, -1))
    hotkeys('down', moveToThread.bind(null, 1))
  })

  onDestroy(() => {
    unsubscribes.forEach(un => un && un())
    hotkeys.unbind('up')
    hotkeys.unbind('down')
  })

  ; // prettier-ignore
</script>

<aside
  class="sidebar"
  bind:this={root}
  on:mouseenter={() => setTimeout(() => setSidebarHovered(true))}
  on:mouseleave={() => setTimeout(() => setSidebarHovered(false))}>
  <div class="inner">
    <div class="logo">
      <LogoIcon width="64" height="17" />
    </div>
    <div class="toggler" on:click={() => dispatch('toggleSidebar', false)}>
      <HideMenu />
    </div>
    {#if isLoading}
      <div class="spinner">
        <Spinner size="small" color="black" />
      </div>
    {/if}
    {#if error}
      <div
        class="error"
        on:click={() => {
          error = undefined
        }}>
        {error}
      </div>
    {/if}
    <div class="search" class:focused={isSearchFocused || !!threadsFilter}>
      <SearchIcon />
      <input
        placeholder="Search"
        bind:value={threadsFilter}
        on:focus={() => {
          isSearchFocused = true
        }}
        on:blur={() => {
          isSearchFocused = false
        }} />
      {#if threadsFilter}
        <div
          class="clear"
          on:click={() => {
            threadsFilter = ''
          }}>
          <CloseIcon />
        </div>
      {/if}
    </div>
    <div class="top">
      {#if $reportsTotalCount > 0}
        <div class="reports" class:active={isReports} on:click={() => goToReports()}>
          <WarningIcon />
          <span class="counter">{$reportsTotalCount}</span>
          <Tooltip title="Reports to review" align="left" position="bottom" />
        </div>
      {/if}
      <div class="drafts" class:active={isDrafts} on:click={() => goToDraft()}>
        <DraftsIcon />
        <Tooltip title="Drafts" position="bottom" />
      </div>
      <div class="feed" class:active={isFeed} on:click={() => goToFeed()}>
        <FeedArrow />
        {#if unwatchedTotal}
          <span class="counter">{unwatchedTotal}</span>
        {/if}
        <Tooltip title="Feed" position="bottom" />
      </div>
      <div class="ai" class:active={isAI} on:click={() => goToAI()}>
        AI
        <Tooltip title="AI Generations" position="bottom" />
      </div>
      {#if $session}
        <div class="profile" class:active={showProfile} on:click={() => toggleProfile()}>
          <Avatar size="xsmall" user={$session.profile} color="black" />
          <Tooltip title="Profile" position="bottom" />
        </div>
      {/if}
      <div class="new-video" on:click={() => goToNewMessage()}>
        <PlusIcon />
        <Tooltip title="New message" align="right" position="bottom" />
      </div>
    </div>
    {#if showProfile}
      <div
        class="profile-container"
        transition:fly={{ duration: 200, x: -10 }}
        use:clickOutseide={() => showProfile && toggleProfile(false)}
        on:click|preventDefault={() => toggleProfile(true)}>
        <div class="close" on:click|stopPropagation={() => toggleProfile(false)}>
          <CrossIcon />
        </div>
        <Profile />
      </div>
    {/if}
    <Menu
      bind:this={menuEl}
      type={activeMenu}
      community={activeMenu === 'community'
        ? communities.find(c => c.id === activeMenuEntityId)
        : undefined}
      thread={activeMenu === 'channel'
        ? ($threadsStore || []).find(t => t.id === activeMenuEntityId)
        : undefined}
      visible={activeMenuVisible}
      topPosition={activeMenuTop - scrollBoxTop}
      on:toggleMenu={toggleMenu} />
    <div
      class="scroll-box"
      bind:this={scrollBox}
      on:scroll={() => {
        scrollBoxTop = scrollBox?.scrollTop || 0
      }}>
      {#if $threadsStore}
        {#each [allThreads, channels, threads] as threadsGroup, index (index)}
          <div class="threads-section" class:active={activeSections[index]}>
            <h2
              class:not-watched={index === 0 && unwatchedTotal > 0}
              on:click={() => {
                activeSections[index] = !activeSections[index]
              }}>
              <DownArrow />
              {['All Messages', 'Communities & Channels', 'Direct Messages'][index]}
              {#if index === 0 && unwatchedTotal > 0}
                <div class="counter">
                  {unwatchedTotal}
                </div>
              {:else if index === 1}
                <div
                  class="plus-btn menu-anchor"
                  class:active={activeMenu === 'root' && activeMenuVisible}
                  on:click|stopPropagation={e => toggleMenu(e, 'root')}>
                  <CrossIcon />
                </div>
              {/if}
            </h2>
            <div class="threads-body">
              {#if index === 1 && communities.length > 0}
                {#each communities as community (community.id)}
                  <div
                    class="community"
                    class:active={community.id === activeCommunityId}
                    class:expanded={threadsFilter || communityExpandedState[community.id] === true}
                    on:click={() => {
                      communityExpandedState[community.id] = !communityExpandedState[community.id]
                    }}>
                    <div
                      class="thread menu-anchor"
                      class:active={community.id === activeCommunityId}
                      class:not-watched={notWatchedMessagesCommunitiesMap[community.id] === true}>
                      <div class="thread-icon" />
                      <div class="thread-title">{community.title}</div>
                      <div
                        class="thread-btn"
                        class:active={activeMenuEntityId === community.id && activeMenuVisible}
                        on:click|stopPropagation={e => toggleMenu(e, 'community', community.id)}>
                        <MoreIcon />
                        <CrossIcon />
                      </div>
                    </div>
                    <div class="community-threads" on:click|stopPropagation>
                      <ThreadsList
                        threads={community.threads}
                        canShowActive={activeTabInAllMessages === false && !isFeed}
                        currentThreadId={$currentThreadId}
                        session={$session}
                        newMessages={$newMessages}
                        {activeMenuVisible}
                        {activeMenuEntityId}
                        on:toggleMenu={({ detail }) =>
                          toggleMenu(detail.event, 'channel', detail.id)}
                        on:click={({ detail }) => onThreadClick(detail, false)} />
                    </div>
                  </div>
                {/each}
              {/if}
              <ThreadsList
                threads={threadsGroup}
                canShowActive={(index === 0) === activeTabInAllMessages && !isFeed}
                currentThreadId={$currentThreadId}
                session={$session}
                newMessages={$newMessages}
                {activeMenuVisible}
                {activeMenuEntityId}
                on:toggleMenu={({ detail }) => toggleMenu(detail.event, 'channel', detail.id)}
                on:click={({ detail }) => onThreadClick(detail, index === 0)} />
            </div>
          </div>
        {/each}
      {/if}
    </div>
  </div>
</aside>

<style lang="scss">
  :global(.screen-recording) {
    .sidebar {
      display: none;
    }
  }

  .sidebar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 240px;
    background: rgba(255, 255, 255, 0.95);
    padding: 12px 12px 0;
    transform: translateX(-100%);
    transition: transform var(--sidebar-transition-duration) ease;
    box-sizing: border-box;
    z-index: 4;

    .inner {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      transition: opacity calc(var(--sidebar-transition-duration) - var(--sidebar-transition-delay))
        var(--sidebar-transition-delay) ease;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 7px;
      width: 4px;
      height: 120px;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
      background: #fff;
      transform: translateY(-50%);
      border-radius: 4px;
      opacity: 0;
      transition: opacity calc(var(--sidebar-transition-duration) - var(--sidebar-transition-delay))
        var(--sidebar-transition-delay) ease;
    }

    :global(.active-sidebar) & {
      transform: translateX(0);
    }

    :global(.inactive-sidebar) & {
      transform: translateX(calc(18px - 100%));

      &::after {
        opacity: 1;
      }

      .inner {
        opacity: 0;
      }
    }

    .toggler {
      position: absolute;
      top: 9px;
      right: 10px;
      cursor: pointer;
    }
  }

  .scroll-box {
    overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 -12px;
    padding: 0 12px 12px;
    flex: 1 1 auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .logo {
    margin: 2px 0 12px;
    text-align: center;
    user-select: none;
    -webkit-app-region: drag;
  }

  .top {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 2px 20px;
    z-index: 1;

    :global(.tooltip) {
      :global(.body) {
        background: none;
        color: #000;
        padding: 0;
        font-weight: bold;
        margin-top: -12px;
      }

      :global(.arrow) {
        display: none;
      }
    }

    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 30px;
      min-width: 30px;
      box-sizing: border-box;
    }

    .counter {
      margin-left: 4px;
    }

    .reports,
    .ai,
    .feed,
    .drafts,
    .profile {
      padding: 0 4px;
      border-radius: 9px;
      color: var(--primary-color);
      font-weight: bold;

      > :global(svg path) {
        fill: var(--primary-color);
      }

      &:hover {
        background-color: rgba(233, 224, 211, 0.5);
      }

      &.active {
        background-color: var(--active-bg-color);
      }
    }
  }

  .ai {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .new-video {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    border-radius: 15px;
    width: 30px;
    height: 30px;
  }

  .profile {
    display: flex;
    align-items: center;
    cursor: pointer;

    :global(.avatar) {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 100%;
    }
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    line-height: 1.4;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 0 10px 10px 0;
    z-index: 1;
  }

  h2 {
    font-size: 13px;
    font-weight: 300;
    margin: 0 0 12px;
    padding-right: 2px;
    cursor: pointer;

    :global(svg) {
      position: relative;
      transition: transform 0.3s ease;
      top: 2px;
    }

    .counter {
      float: right;
    }

    .plus-btn {
      position: relative;
      float: right;

      :global(svg) {
        width: 13px;
        height: 13px;
        transform: scale(0.7) rotate(-45deg);
        transition: transform 0.3s ease;
      }

      &.active :global(svg) {
        transform: none;
      }

      :global(svg path) {
        fill: #000;
      }
    }
  }

  .threads-section {
    &:not(:first-child) {
      margin-top: 16px;
    }

    &:not(.active) {
      > h2 > :global(svg) {
        transform: rotate(-90deg);
      }

      .threads-body {
        display: none;
      }
    }
  }

  .search {
    display: flex;
    position: relative;
    width: 100%;
    margin: 0;
    background: rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 17px;
    padding: 10px;
    transition: box-shadow 0.3s ease;

    &.focused {
      box-shadow: 0 0 0 2px var(--blue-color);
    }

    input {
      background: none;
      border: none;
      outline: none;
      margin: -5px 18px -5px 8px;
      padding: 0;
      flex-grow: 2;
      color: #000;

      &::placeholder {
        color: rgba(121, 121, 121, 1);
      }
    }

    .clear {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }

  .thread {
    display: flex;
    position: relative;
    margin: 0 -8px;
    padding: 7px 10px 7px 24px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 300;
    cursor: pointer;
    color: #000;
    transition: background-color 0.2s ease, color 0.2s ease;
    border-radius: 8px;

    &:hover {
      background-color: rgba(233, 224, 211, 0.5);
    }

    &.active {
      background-color: #e2d7c7;
    }
  }

  .community {
    display: block;
    margin-left: -4px;
    margin-right: -8px;
    padding-left: 28px;
    border-radius: 8px;

    > .thread {
      margin-left: -32px;
      padding-left: 24px;
      margin-right: 0;

      &::after {
        content: '';
        position: absolute;
        top: 12px;
        left: 10px;
        width: 6px;
        height: 6px;
        border-right: 1px solid;
        border-bottom: 1px solid;
        box-sizing: border-box;
        transform: rotate(-45deg);
        transition: transform 0.3s ease;
      }

      .thread-icon::before {
        content: '';
        width: 10px;
        height: 10px;
        border: 2px solid;
        box-sizing: border-box;
        border-radius: 100%;
        display: block;
        margin: 4px;
      }
    }

    &.active {
      background: #f5f0e9;
    }

    &.expanded {
      > .thread::after {
        transform: rotate(45deg) translate(0, -2px);
      }
    }
  }

  .community-threads {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    display: none;

    .community.expanded & {
      display: block;
    }

    :global(.thread) {
      margin-left: 0;
      padding-left: 16px;
    }

    :global(.thread) {
      margin-right: 0;
    }

    :global(.thread.active) {
      background: var(--active-bg-color);
      color: #000;
      stroke: #000;
    }
  }

  .not-watched {
    font-weight: 600;
  }

  .thread-icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: -1px 0;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 8px;
    text-align: center;
    flex: 0 0 auto;
    background-color: rgba(175, 175, 175, 0.2);

    :global(svg) {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .thread-title {
    flex: 2 1 100%;
  }

  .thread-btn {
    position: relative;
    flex: 0 0 auto;
    min-width: 8px;
    margin-left: 4px;
    width: 16px;
    height: 16px;

    :global(svg) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    :global(svg:last-child path) {
      fill: #000;
    }

    &:not(.active) :global(svg:last-child) {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(-45deg) scale(0);
    }

    &.active :global(svg:first-child) {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }

    &.active :global(svg:last-child) {
      transform: translate(-50%, -50%) rotate(0) scale(0.95);
    }
  }

  .profile-container {
    position: absolute;
    top: 0;
    left: 100%;
    margin: 12px;
    outline: none;
    border: none;

    &::before {
      content: '';
      position: absolute;
      top: 90px;
      right: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 6px 0;
      border-color: transparent rgba(255, 255, 255, 0.95) transparent transparent;
    }
  }

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;
    cursor: pointer;

    > :global(svg path) {
      fill: black;
    }
  }
</style>
