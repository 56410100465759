<script lang="ts">
  import ScreenPermissionsImage from 'assets/screen-permissions.png'

  export let active: boolean
  export let onClose: () => void

  import Modal from 'components/Modal.svelte'
</script>

<Modal {active} {onClose}>
  <img src={ScreenPermissionsImage} alt="System preferences for screen recording" />
  <h1>Allow access to Screen Recording.</h1>
  <p>
    You need to grant access to screen recording in system preferences <nobr
      >(Security & Privacy > Privacy > Screen Recording)</nobr> and then restart the app.
  </p>
</Modal>

<style lang="scss">
  img {
    display: block;
    margin: 16px auto;
    max-width: 420px;
  }

  h1 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  p {
    text-align: center;
    font-size: 14px;
    margin-bottom: 16px;
  }
</style>
