import type {
  User,
  Draft,
  Thread,
  Session,
  Contact,
  ContactPoint,
  Member,
  Video,
  Message,
} from 'types'
import { Video as VKVideo, VKCue } from 'vendors/videokit'
import { validateEmail, validatePhoneNumber, validateUserId } from './validation'
import { supportsHEVC } from 'env'

export function getUserName(
  user: User,
  sessionOrUser: Session | User | null | undefined = undefined,
): string {
  const currentUser = (sessionOrUser as Session)?.profile || (sessionOrUser as User)
  if (user.id === currentUser?.id) return 'Me'
  return user.fullName || user.email || user.phoneNumber || user.username || ''
}

export function userImageUrl(user: User): string | undefined {
  return user.imageURL ? `${user.imageURL}` : undefined
}

export function getThreadRecipients(
  thread: Thread,
  sessionOrUser: Session | User | null | undefined,
): User[] {
  const currentUser = (sessionOrUser as Session)?.profile || (sessionOrUser as User)

  if (thread.members.length === 1 && thread.members[0].id === currentUser.id) {
    return [currentUser]
  }

  return thread.members.filter(user => user.id !== currentUser?.id)
}

export function isContactPointsEqual(left: ContactPoint, right: ContactPoint): boolean {
  return (
    (left.thread && right.thread && left.thread.id === right.thread.id) ||
    (left.user && right.user && left.user.id === right.user.id) ||
    (left.contact && right.contact && left.contact.identifier === right.contact.identifier) ||
    (!!left.contactPoint && !!right.contactPoint && left.contactPoint === right.contactPoint)
  )
}

export function convertVideoToVKVideo(video: Video): VKVideo {
  const assets: Video['assets'] = {}

  for (const profile of ['source', '1080p', '720p', '540p', '360p', '240p', '144p']) {
    let value = video.assets[profile as keyof typeof assets]

    if (supportsHEVC) {
      value = video.assets[`${profile}.hevc` as keyof typeof assets] || value
    }

    if (value) {
      assets[profile as keyof typeof assets] = value
    }
  }

  return new VKVideo({
    id: video.id,
    width: video.width,
    height: video.height,
    input: {
      duration: video.duration,
    },
    thumbUrl: video.thumbnailURL,
    mp4Url: assets,
    transcription: {
      state: video.subtitlesJsonURL || video.subtitlesURL ? 'processed' : 'processing',
      vtt: video.subtitlesURL,
      json: video.subtitlesJsonURL,
    },
    createdAt: new Date(video.createdAt || 0),
    updatedAt: new Date(video.updatedAt || 0),
  })
}

export function convertMessageToVKVideo(message: Message): VKVideo {
  const { video, type } = message
  if (video && type === 'video') {
    return convertVideoToVKVideo(video)
  } else {
    return new VKVideo({
      id: message.id,
      width: 512,
      height: 512,
      input: { duration: 0 },
      thumbUrl: message.thumbnailURL,
      mp4Url: {},
      createdAt: new Date(0),
      updatedAt: new Date(0),
    })
  }
}

export function contactToUser(contact: Contact): User {
  let imageURL: string | undefined

  try {
    if (contact.contactImage && contact.contactImage.length) {
      imageURL = URL.createObjectURL(new Blob([contact.contactImage.buffer]))
    }
  } catch (e) {
    console.log(e)
  }

  return {
    id: contact.identifier,
    fullName: [contact.firstName, contact.lastName].join(' '),
    imageURL,
    email: contact.emailAddresses[0],
    phoneNumber: contact.phoneNumbers[0],
    isTrusted: false,
  }
}

export function mapContactPoints(contactPoints: ContactPoint[], usePrivateInfo = true): any {
  const userIds: string[] = []
  const usernames: string[] = []
  const emails: string[] = []
  const phoneNumbers: string[] = []
  const threadIds: string[] = []

  contactPoints.forEach(contactPoint => {
    if (contactPoint.contact) {
      const [phoneNumber] = contactPoint.contact.phoneNumbers
      const [email] = contactPoint.contact.emailAddresses

      if (phoneNumber && validatePhoneNumber(phoneNumber)) {
        phoneNumbers.push(phoneNumber)
      } else if (email && validateEmail(email)) {
        emails.push(email)
      }
    } else if (contactPoint.user) {
      if (usePrivateInfo || !contactPoint.user.fullName) {
        if (contactPoint.user.email) {
          emails.push(contactPoint.user.email)
        } else if (contactPoint.user.phoneNumber) {
          phoneNumbers.push(contactPoint.user.phoneNumber)
        } else {
          userIds.push(contactPoint.user.id)
        }
      } else {
        userIds.push(contactPoint.user.id)
      }
    } else if (contactPoint.contactPoint) {
      if (validateEmail(contactPoint.contactPoint)) {
        emails.push(contactPoint.contactPoint)
      } else if (validatePhoneNumber(contactPoint.contactPoint)) {
        phoneNumbers.push(contactPoint.contactPoint)
      } else if (validateUserId(contactPoint.contactPoint)) {
        userIds.push(contactPoint.contactPoint)
      } else {
        usernames.push(contactPoint.contactPoint)
      }
    } else if (contactPoint.thread) {
      threadIds.push(contactPoint.thread.id)
    }
  })

  return {
    ...(threadIds.length > 0 && { threadIds }),
    ...(userIds.length > 0 && { userIds }),
    ...(usernames.length > 0 && { usernames }),
    ...(emails.length > 0 && { emails }),
    ...(phoneNumbers.length > 0 && { phoneNumbers }),
  }
}

export function contactPointToMember(contactPoint: ContactPoint): Member | null {
  const { userIds, usernames, emails, phoneNumbers } = mapContactPoints([contactPoint])
  const member = {
    ...(userIds && { id: userIds[0] }),
    ...(usernames && { username: usernames[0] }),
    ...(emails && { email: emails[0] }),
    ...(phoneNumbers && { phoneNumber: phoneNumbers[0] }),
  }

  return Object.keys(member).length > 0 ? member : null
}

export function getContactPointsKey(contactPoints: ContactPoint[]): string {
  return contactPoints
    .map(contactPoint => {
      if (contactPoint.thread) {
        return contactPoint.thread.id
      } else if (contactPoint.user) {
        return contactPoint.user.email || contactPoint.user.phoneNumber
      } else if (contactPoint.contact) {
        return (
          contactPoint.contact.emailAddresses[0] ||
          contactPoint.contact.phoneNumbers[0] ||
          contactPoint.contact.identifier
        )
      } else {
        return contactPoint.contactPoint
      }
    })
    .sort()
    .join('_')
}

export function extractRecipientsFromDraft(draft: Draft, threads: Thread[]): ContactPoint[] {
  const contactPoints: ContactPoint[] = []

  if (draft && draft.recipients) {
    if (!threads) return []
    const users = threads.flatMap(t => t.members)
    const { threadIds, userIds, emails, phoneNumbers } = draft.recipients

    if (threadIds) {
      threadIds.forEach(threadId => {
        const thread = threads.find(thread => thread.id === threadId)
        thread && contactPoints.push({ thread })
      })
    }

    if (userIds) {
      userIds.forEach(userId => {
        const user = users.find(user => user.id === userId)
        user && contactPoints.push({ user })
      })
    }

    if (emails) {
      emails.forEach(email => {
        const user = users.find(user => user.email === email)
        contactPoints.push(user ? { user } : { contactPoint: email })
      })
    }

    if (phoneNumbers) {
      phoneNumbers.forEach(phoneNumber => {
        const user = users.find(user => user.phoneNumber === phoneNumber)
        contactPoints.push(user ? { user } : { contactPoint: phoneNumber })
      })
    }
  }

  return contactPoints
}

export function isThreadMatch(thread: Thread, query: string, currentUser?: User): boolean {
  let searchStrings: string[] = []

  thread.title && searchStrings.push(thread.title)

  if (!thread.channel) {
    thread.members
      .filter(user => user.id !== currentUser?.id)
      .forEach(user => {
        searchStrings = [...searchStrings, ...extractUserStrings(user)]
      })
  }

  return searchStrings.reduce(
    (result: boolean, str: string) => result || str.toLowerCase().indexOf(query) !== -1,
    false,
  )
}

export function extractUserStrings(user: User): string[] {
  return [user.username, user.fullName, user.email, user.phoneNumber].filter(s => !!s) as string[]
}

export function makeParagraphsFromCues(cues: VKCue[]): VKCue[][] {
  const maxParagraphLength = 240
  const extraParagraphLength = 90
  const paragraphs: VKCue[][] = []
  let paragraph: VKCue[] = []
  let paragraphLength = 0
  let startIndex = -1
  const addCueToParagraph = (cue: VKCue) => {
    paragraph.push({
      text: cue.text,
      start: cue.start,
      end: cue.end,
    })
  }

  for (let index = 0; index < cues.length; index++) {
    if (index <= startIndex) continue
    let cue = cues[index]

    paragraphLength += cue.text.length

    if (paragraphLength < maxParagraphLength) {
      addCueToParagraph(cue)
    } else {
      let endOfSentence = cue.text.search(/\.|\?|!/)

      if (endOfSentence === -1) {
        const extraCues = []
        let extraEndOfSentence = -1
        let extraIndex = 1

        while (
          paragraphLength < maxParagraphLength + extraParagraphLength &&
          index + extraIndex < cues.length - 1 &&
          extraEndOfSentence === -1
        ) {
          const extraCue = cues[index + extraIndex]
          extraCues.push(extraCue)
          extraEndOfSentence = extraCue.text.search(/\.|\?|!/)
          paragraphLength += extraCue.text.length
          extraIndex++
        }

        if (extraEndOfSentence !== -1) {
          addCueToParagraph(cue)
          cue = extraCues.pop()!
          paragraph = paragraph.concat(extraCues)
          endOfSentence = extraEndOfSentence
          startIndex = index + extraIndex - 1
        }
      }

      const sentence = endOfSentence > 0 ? cue.text.substring(0, endOfSentence + 1) : cue.text
      let sentenceEnd = cue.end

      // If we split a cue into two sentences, calculate the time of split point using segments
      // if available or by calculating the time based on the ratio of the sentence
      if (endOfSentence > 0) {
        if (cue.segments) {
          let sentenceLengthLeft = sentence.length
          for (let index = 0; index < cue.segments.length; index++) {
            const { text, start } = cue.segments[index]

            if (sentenceLengthLeft <= 0) {
              sentenceEnd = start
              break
            } else {
              sentenceLengthLeft -= text.length + (index > 0 ? 1 : 0)
            }
          }
        } else {
          sentenceEnd = cue.start + (sentence.length / cue.text.length) * (cue.end - cue.start)
        }
      }

      const sentenceCue = {
        ...cue,
        text: sentence.trim(),
        end: sentenceEnd,
      }
      addCueToParagraph(sentenceCue)
      paragraphs.push(paragraph)

      if (endOfSentence > 0 && cue.text.length > sentence.length) {
        const secondSentence = cue.text.substring(endOfSentence + 1)
        const secondSentenceCue = {
          ...cue,
          text: secondSentence.trim(),
          start: sentenceCue.end,
        }

        paragraph = []
        addCueToParagraph(secondSentenceCue)
        paragraphLength = secondSentenceCue.text.length
      } else {
        paragraph = []
        paragraphLength = 0
      }
    }
  }

  if (paragraph.length) {
    // Merge last paragraph with the previous one if it's too short
    if (paragraphs.length > 0 && paragraphLength < 50) {
      const lastParagraph = paragraphs.pop()!
      paragraphs.push(lastParagraph.concat(paragraph))
    } else {
      paragraphs.push(paragraph)
    }
  }

  return paragraphs
}
