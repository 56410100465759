<script lang="ts">
  import { onDestroy, onMount, createEventDispatcher } from 'svelte'
  import hotkeys from 'hotkeys-js'
  import { Player, Video, Playlist, AspectMode, VideoQuality } from 'vendors/videokit'
  import { isVKReady, videos as videosStore } from 'stores'

  const dispatch = createEventDispatcher<{
    ended: undefined
  }>()

  export let videoId: string | undefined = undefined
  export let video: Video | undefined = undefined
  export let videos: Video[] | undefined = undefined
  export let currentTime = 0
  export let duration = 0
  export let playbackRate = 1
  export let volume = 1
  export let loop = false
  export let muted = false
  export let shouldPlay = true
  export let showSubtitles = true
  export let isHorizontal = true
  export let autoDetectHorizontal = false
  export let disabled = false
  export let onErrorHandler: ((error: Error) => Promise<boolean>) | undefined = undefined

  let playerContainer: HTMLElement
  let player: Player
  let timeUpdated = false

  const unsubscribe = isVKReady.subscribe(isReady => isReady && createPlayer(true))

  $: updateVideos(videoId, video, videos)
  $: updateCurrentTime(currentTime)
  $: updatePlayerValue('rate', playbackRate)
  $: updatePlayerValue('volume', volume)
  $: updatePlayerValue('muted', muted)
  $: updatePlayerValue('loop', loop)
  $: updateAspectRatio(isHorizontal)
  $: toggleSubtitles(showSubtitles)
  $: togglePlayback(shouldPlay)

  function createPlayer(isReady = isVKReady.get()) {
    if (!isReady || !playerContainer) return

    player = new Player(playerContainer, {
      size: 'parent',
      autoplay: shouldPlay,
      showControls: false,
      showSubtitles,
      rate: playbackRate,
      playbackQuality: VideoQuality.RES_1080p,
      volume,
      muted,
      loop,
      aspectMode: AspectMode[isHorizontal ? 'RESIZE_ASPECT_FILL' : 'RESIZE_ASPECT'],
      hideControlsTimeout: -1,
    })

    player.subscribe('canplay', () => {
      if (autoDetectHorizontal) {
        const { videoWidth, videoHeight } = player.element.querySelector('video') || {}

        if (videoWidth && videoHeight) {
          isHorizontal = videoWidth > videoHeight
        }
      }
      updateDuration()
    })
    player.subscribe('timeupdate', (_, data) => {
      if (currentTime !== data.currentTime) {
        timeUpdated = true
        currentTime = data.currentTime
      }

      duration === 0 && updateDuration()
    })
    player.subscribe('muted', (_, data) => {
      if (muted !== data.muted) {
        muted = data.muted
      }
    })
    player.subscribe('play', () => {
      shouldPlay = true
    })
    player.subscribe('pause', () => {
      shouldPlay = false
    })
    player.subscribe('ended', () => dispatch('ended'))
    player.subscribe('error', async (_, { error }) => {
      if (onErrorHandler && (await onErrorHandler(error))) return

      const { mp4Url } = player.video || {}

      if (mp4Url) {
        console.error(
          `Failed to play video: ${mp4Url}`,
          JSON.stringify({
            error: {
              code: (error as any).code,
              message: (error as any).message,
            },
            currentTime: player.currentTime,
            duration: player.duration,
            buffered: player.buffered,
          }),
        )
      }
    })
    player.rate = playbackRate

    updateVideos(videoId, video, videos)
  }

  async function updateVideos(videoId?: string, video?: Video, videos?: Video[]) {
    if (!player) return

    player.autoplay = shouldPlay

    if (videos) {
      const localVideos = videosStore.get() || {}
      videos = videos.map(video => localVideos[video.id] || video)
    }

    if (videos) {
      if (player.playlist?.videos.map(v => v.id).join() === videos.map(v => v.id).join()) {
        const index = player.playlist!.videos.findIndex(video => video.id === videoId) || 0

        if (player.playlistIndex !== index) {
          player.setPlaylistIndex(index)
        } else {
          player.playlist.updateVideos(videos)
          return
        }
      } else {
        const playlist = new Playlist({ videos })
        const currentVideoIndex = playlist.videos.findIndex(video => video.id === videoId)
        player.setPlaylist(playlist, currentVideoIndex || 0)

        if (currentVideoIndex >= 0) return
      }
    } else if (video) {
      player.setVideo(video)
    } else if (videoId) {
      player.setVideoId(videoId)
    }

    currentTime = 0
    updateDuration()
  }

  function updateCurrentTime(time: number) {
    if (!player) return

    if (!timeUpdated && player.currentTime !== time) {
      player.currentTime = time
    } else {
      timeUpdated = false
    }
  }

  function updateDuration() {
    if (Number.isFinite(player.duration) && player.duration) {
      duration = player.duration
    }
  }

  function togglePlayback(shouldPlay: boolean) {
    if (!player) return
    player.paused === shouldPlay && player[shouldPlay ? 'play' : 'pause']()
  }

  function toggleSubtitles(showSubtitles: boolean) {
    if (!player) return
    player.showSubtitles = showSubtitles
  }

  function updatePlayerValue(field: 'volume' | 'rate' | 'muted' | 'loop', value: any) {
    if (!player) return
    if ((player[field] as any) !== value) {
      ;(player[field] as any) = value
    }
  }

  function updateAspectRatio(isHorizontal: boolean) {
    if (!player) return
    player.aspectMode = AspectMode[isHorizontal ? 'RESIZE_ASPECT_FILL' : 'RESIZE_ASPECT']
  }

  function onSpacePressed() {
    if (disabled) return
    togglePlayback(!shouldPlay)
  }

  onMount(() => {
    createPlayer()
    hotkeys('space', onSpacePressed)
  })

  onDestroy(() => {
    unsubscribe()
    hotkeys.unbind('space', onSpacePressed)
    player?.destroy()
  })

  ; // prettier-ignore
</script>

<div class="player" bind:this={playerContainer} />

<style lang="scss">
  .player {
    width: 100%;
    height: 100%;

    :global(.vk-player-controls) {
      display: flex;
      width: 300px;
      left: auto;
      right: 200px;
      flex-direction: row-reverse;
      align-items: center;
      padding-top: 20px;
      z-index: 2;

      &:before {
        background: none;
      }
    }

    :global(.vk-player-progress) {
      flex-grow: 2;
      margin: -2px 0 0 0;
    }

    :global(.vk-player-progress .vk-slider-fill),
    :global(.vk-player-progress .vk-slider-handle:after) {
      background: #fff;
    }

    :global(.vk-player-progress .vk-slider) {
      background: rgba(255, 255, 255, 0.2);
    }

    :global(.vk-player-buttons-right),
    :global(.vk-player-prev),
    :global(.vk-player-next) {
      display: none;
    }

    :global(.vk-player-muted-autoplay) {
      top: 48px;
      left: auto;
      right: 8px;
    }

    :global(.vk-subtitles) {
      transition: transform var(--thumbs-transition-duration) ease, font-size 0.3s ease;
      bottom: 112px;
      width: 100%;
      max-width: 80%;
      padding: 0;
      background: none;
      text-align: center;
      border-radius: 0;
      font-size: 27px;
      line-height: 30px;
      z-index: 2;

      @media (max-width: 1024px) {
        font-size: 24px;
        line-height: 27px;
      }

      @media (max-width: 780px) {
        font-size: 21px;
        line-height: 23px;
      }

      @media (max-width: 640px) {
        font-size: 18px;
        line-height: 20px;
      }
    }

    :global(.vk-subtitles-line::before),
    :global(.vk-subtitles span) {
      padding: 2px 6px;
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone;
    }

    :global(.vk-subtitles span) {
      background: rgba(8, 8, 8, 0.75);
    }

    :global(.active-thumbs) & :global(.vk-subtitles) {
      transform: translate(-50%, -120px);
    }

    :global(.windows) & :global(.vk-subtitles) {
      margin-bottom: 24px;
    }
  }
</style>
