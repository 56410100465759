<script lang="ts">
  import type { Message, Thread, User } from 'types'
  import { ModalType } from 'types'
  import Router from 'svelte-spa-router'
  import { swipe } from 'svelte-gestures'
  import { onDestroy } from 'svelte'
  import PrevBtnIcon from 'assets/icons/prev-btn.svg'
  import NextBtnIcon from 'assets/icons/next-btn.svg'
  import Spinner from 'components/Spinner.svelte'
  import Navigation from 'components/Navigation.svelte'
  import UserBadge from 'components/UserBadge.svelte'
  import UploadIndicator from 'components/UploadIndicator.svelte'
  import Button from 'components/Button.svelte'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'
  import MessageSearch from './components/MessageSearch.svelte'
  import {
    loadMessages,
    loadMessagesFromId,
    loadMoreMessages,
    loadReplies,
    loadMoreReplies,
    leaveThread,
    loadThreadMembersIfNeeded,
    addError,
    goToMessage,
    openModal,
    joinThread,
  } from 'actions'
  import { get } from 'svelte/store'
  import {
    currentThread,
    currentThreadId,
    currentMessage,
    currentMessageId,
    currentReply,
    currentReplyId,
    newMessages,
    session,
  } from 'stores'
  import { timeout } from 'utils'
  import TimeAgo from 'components/TimeAgo.svelte'
  import Thumbs from '../../components/Thumbs.svelte'
  import Recorder from '../../components/Recorder/Recorder.svelte'
  import MessageComponent from './scenes/Message.svelte'

  export let params: { threadId?: string } = {}

  let thumbs: Thumbs
  let initialLoading = false
  let loadingMembers = false
  let loadingPreviousMessages = false
  let loadingNextMessages = false
  let initialRepliesLoading = false
  let loadingPreviousReplies = false
  let loadingNextReplies = false
  let noMessages = false
  let error: string | undefined
  let nextMessage: Message | undefined
  let prevMessage: Message | undefined
  let canEditChannel = false
  let activeThumbId: string | undefined
  let pendingInvitationAction: string | undefined
  const unsubscribes: (() => void)[] = []

  $: activeThumbId = $currentMessageId
  $: message = $currentReply || $currentMessage
  $: loadingMessages = initialLoading || loadingPreviousMessages || loadingNextMessages
  $: loadingReplies = initialRepliesLoading || loadingPreviousReplies || loadingNextReplies

  $: {
    const threadId = params.threadId

    if (threadId !== currentThreadId.get()) {
      canEditChannel = false
      noMessages = false
      initialLoading = false
      error = undefined
      currentThreadId.set(threadId)
    }
  }

  currentThreadId.set(params.threadId)

  unsubscribes.push(
    currentMessageId.subscribe(messageId => {
      const thread = get(currentThread)

      if (
        messageId &&
        thread &&
        thread.messages &&
        !thread.messages.find(m => m.id === messageId)
      ) {
        loadInitialMessages(thread, messageId)
      }
    }),
  )
  unsubscribes.push(
    currentThread.subscribe(async currentThread => {
      if (!currentThread) return

      // Wait one tick to make sure all parts of url was set to the state
      await timeout(0)

      const { profile } = session.get() || {}
      const roles = (currentThread.channel || {}).roles
      const userRole = roles && profile && roles[profile.id]
      const messageId = currentMessageId.get()

      canEditChannel = userRole === 'owner' || userRole === 'admin'
      if (!loadingMembers && currentThread.id !== 'feed') {
        loadingMembers = true
        loadThreadMembersIfNeeded(currentThread)
          .catch(addError)
          .finally(() => {
            loadingMembers = false
          })
      }

      // Load thread messages if non are loaded, or loaded only the last one + new message
      if (
        !currentThread.messages ||
        (!currentThread.isMessagesPreloaded && !currentThread.noPreviousMessages && !initialLoading)
      ) {
        loadInitialMessages(currentThread, messageId)
      } else if (!initialLoading) {
        if (currentThread.messages.length === 0) {
          noMessages = true
        } else if (
          !messageId ||
          (!currentThread.messages.find(m => m.id === messageId) && !error)
        ) {
          const firstNew = currentThread.messages.filter(m => !m.watched).reverse()[0]

          if (firstNew) {
            goToMessage(currentThread, firstNew)
          } else {
            _goToMessage(currentThread.messages[0].id, true)
          }
        }
      }
    }),
  )

  unsubscribes.push(
    currentMessage.subscribe(message => {
      const thread = get(currentThread)

      if (message && thread && thread.messages) {
        const currentIndex = thread.messages.findIndex(item => item.id === message.id)

        if (message.repliesCount && !message.replies && !initialRepliesLoading) {
          initialRepliesLoading = true
          loadReplies(thread.id, message.id)
            .catch(addError)
            .finally(() => {
              initialRepliesLoading = false
            })
        }

        nextMessage =
          currentIndex < thread.messages.length - 1 ? thread.messages[currentIndex + 1] : undefined
        prevMessage = currentIndex > 0 ? thread.messages[currentIndex - 1] : undefined
        error = undefined
      }
    }),
  )

  function openChannelSettings() {
    if (canEditChannel) {
      openModal({
        type: ModalType.CHANNEL_SETTINGS,
        arguments: {
          thread: get(currentThread),
        },
      })
    }
  }

  function showUser(user: User) {
    if (user.id !== session.get()?.profile.id) {
      openModal({
        type: ModalType.USER_CARD,
        arguments: {
          user,
          ...(canEditChannel ? { threadToBan: get(currentThread) } : {}),
        },
      })
    }
  }

  async function _goToMessage(messageId: string, toReply = false) {
    const thread = get(currentThread)
    const message = thread?.messages?.find(m => m.id === messageId)

    if (message) {
      if (toReply && (message.repliesCount || 0) > 0) {
        if (!message.replies) {
          activeThumbId = message.id
          await loadReplies(currentThreadId.get(), message.id).catch(addError)
          _goToMessage(messageId, toReply)
        } else {
          const reply =
            message.replies
              ?.slice()
              .reverse()
              .find(r => !r.watched) || (message.replies || [])[0]
          reply && goToMessage(thread!, reply)
        }
      } else {
        goToMessage(thread!, message)
      }
    } else {
      const message = get(currentMessage)
      const reply = message?.replies?.find(r => r.id === messageId)

      reply && goToMessage(thread!, reply)
    }
  }

  function loadInitialMessages(thread: Thread, messageId?: string) {
    initialLoading = true

    const noCurrentMessage = messageId && !thread.messages?.find(m => m.id === messageId)
    const request = noCurrentMessage
      ? loadMessagesFromId(thread.id, messageId!)
      : loadMessages(thread.id)

    request
      .then(() => thumbs?.scrollToActiveThumbIfNeeded(false))
      .catch(e => {
        error = e.message || e.toString()
        if (noCurrentMessage) {
          const thread = get(currentThread)
          return thread && loadMessages(thread.id)
        }
      })
      .finally(() => {
        initialLoading = false
      })
  }

  function loadPreviousMessages() {
    const thread = get(currentThread)
    if (!thread || thread.noPreviousMessages || loadingMessages) return

    loadingPreviousMessages = true
    loadMoreMessages(thread.id, 'prev')
      .catch(addError)
      .finally(() => {
        loadingPreviousMessages = false
      })
  }

  function loadNextMessages() {
    const thread = get(currentThread)
    if (!thread || thread.noNextMessages || loadingMessages) return

    loadingNextMessages = true
    loadMoreMessages(thread.id, 'next')
      .catch(addError)
      .finally(() => {
        loadingNextMessages = false
      })
  }

  function loadPreviousReplies() {
    const thread = get(currentThread)
    const message = get(currentMessage)
    if (!thread || !message || message.noPreviousReplies || loadingReplies) return

    loadingPreviousReplies = true
    loadMoreReplies(thread.id, message.id, 'prev')
      .catch(addError)
      .finally(() => {
        loadingPreviousReplies = false
      })
  }

  function loadNextReplies() {
    const thread = get(currentThread)
    const message = get(currentMessage)
    if (!thread || !message || message.noNextReplies || loadingReplies) return

    loadingNextReplies = true
    loadMoreReplies(thread.id, message.id, 'next')
      .catch(addError)
      .finally(() => {
        loadingNextReplies = false
      })
  }

  function handleSwipe({ detail }: CustomEvent) {
    const thread = get(currentThread)
    if (!thread) return
    let message: Message | undefined

    if (detail.direction === 'left') {
      message = nextMessage
    } else if (detail.direction === 'right') {
      message = prevMessage
    }

    message && goToMessage(thread, message)
  }

  async function resolveInvitation(action: 'accept' | 'decline' | 'report') {
    const thread = get(currentThread)

    if (thread) {
      pendingInvitationAction = action

      try {
        if (action === 'accept') {
          await joinThread(thread.id)
        } else if (action === 'decline') {
          await leaveThread(thread.id)
        } else if (action === 'report') {
          if (session.get()?.profile.isTrusted) {
            openModal({
              type: ModalType.CREATE_REPORT,
              arguments: { thread },
            })
          } else {
            openModal({
              type: ModalType.TRUSTED_USER_ONLY,
              arguments: { action: 'report' },
            })
          }
        }
      } catch (e) {
        addError(e)
      }

      pendingInvitationAction = undefined
    }
  }

  onDestroy(() => {
    unsubscribes.forEach(un => un())
    currentThreadId.set(undefined)
  })

  ; // prettier-ignore
</script>

<section
  class="thread"
  use:swipe={{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}
  on:swipe={handleSwipe}>
  {#if error}
    <div class="error">{error}</div>
  {:else if loadingMessages}
    <div class="spinner">
      <Spinner size="large" color="#000" />
    </div>
  {/if}
  <Router
    routes={{
      '/threads/:threadId/messages/:messageId/:replyId?': MessageComponent,
    }} />
  {#if $currentThread}
    <Navigation>
      {#if message || $currentThread.channel}
        <div class="message-info">
          {#if $currentThread.channel && $currentThread.title}
            <div class="channel" on:click={canEditChannel ? openChannelSettings : () => {}}>
              <ContactPointBadge
                contactPoint={{ thread: $currentThread }}
                color="white"
                size="medium" />
            </div>
            {#if message}
              <div class="separator" />
            {/if}
          {/if}
          {#if message}
            <div class="sender" on:click={showUser.bind(null, message.sender)}>
              <UserBadge user={message.sender} currentUser={$session?.profile} color="white" />
              <div class="separator" />
              {#if $newMessages[message.id]}
                <div class="uploading">
                  Uploading
                  <UploadIndicator progress={$newMessages[message.id].progress || 0} />
                </div>
              {:else}
                <span class="date"
                  ><TimeAgo date={message.createdAt} compact={false} withTime={true} /></span>
              {/if}
            </div>
          {/if}
        </div>
      {/if}
    </Navigation>
    <MessageSearch />
    {#if prevMessage}
      <div class="message-arrow-screen" />
      <div
        class="message-arrow prev"
        on:click={goToMessage.bind(null, $currentThread, prevMessage, undefined, false)}>
        <PrevBtnIcon />
      </div>
    {/if}
    {#if nextMessage}
      <div
        class="message-arrow next"
        class:with-shift={$currentMessage?.replies}
        on:click={goToMessage.bind(null, $currentThread, nextMessage, undefined, false)}>
        <NextBtnIcon />
      </div>
    {/if}
    {#if $currentMessage && $currentMessage.replies}
      <Thumbs
        position="right"
        thumbs={[...$currentMessage.replies, $currentMessage]}
        activeThumbId={$currentReplyId || $currentMessage.id}
        loadingPrevious={loadingPreviousReplies}
        loadingNext={loadingNextReplies}
        on:activeChanged={({ detail }) => _goToMessage(detail)}
        on:reachedStart={loadPreviousReplies}
        on:reachedEnd={loadNextReplies} />
    {/if}
    {#if $currentThread.messages && $currentThread.messages.length > 0}
      <Thumbs
        bind:this={thumbs}
        thumbs={$currentMessage || error ? $currentThread.messages : []}
        {activeThumbId}
        loadingPrevious={loadingPreviousMessages}
        loadingNext={loadingNextMessages}
        on:activeChanged={({ detail }) => _goToMessage(detail, true)}
        on:reachedStart={loadPreviousMessages}
        on:reachedEnd={loadNextMessages} />
    {/if}
    {#if noMessages}
      <Recorder recipients={[{ thread: $currentThread }]} withAIButton={true} />
    {/if}
    {#if $currentThread.needToAcceptInvite}
      <div class="accept-invite">
        <div class="title">
          You have been invited to join a {$currentThread.channel ? 'channel' : 'thread'}
        </div>
        <div class="btns">
          <Button
            color="green"
            on:click={() => resolveInvitation('accept')}
            loading={pendingInvitationAction === 'accept'}>Accept</Button>
          <Button
            color="red"
            on:click={() => resolveInvitation('decline')}
            loading={pendingInvitationAction === 'decline'}>Decline</Button>
          <Button
            textColor="white"
            on:click={() => resolveInvitation('report')}
            loading={pendingInvitationAction === 'report'}>Report</Button>
        </div>
      </div>
    {/if}
  {/if}
</section>

<style lang="scss">
  .thread {
    width: 100%;
    height: 100%;
  }

  .message-arrow {
    position: absolute;
    top: 50%;
    width: 37px;
    height: 63px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;

    &.prev {
      left: 16px;
    }

    :global(.active-thumbs) &.next {
      z-index: 1;
    }

    &.next {
      right: 16px;
      z-index: 3;

      &.with-shift {
        right: 64px;
      }
    }
  }

  .message-arrow-screen {
    position: absolute;
    top: 50%;
    width: 0;
    height: 80px;
    z-index: 1;
    transform: translateY(-50%);
    transition: step-end width 0.3s;

    :global(.inactive-sidebar) & {
      width: 220px;
    }
  }

  .message-info {
    display: flex;
    color: #fff;
    align-items: center;

    .channel {
      display: flex;
      align-items: center;
      height: 32px;
      cursor: pointer;
      border-radius: 32px;
      padding: 4px 8px 4px 4px;
      box-sizing: border-box;
      margin-right: 2px;
      background-color: transparent;
      transition: background-color 0.2s ease;

      // &::after {
      //   content: '';
      //   width: 0;
      //   height: 0;
      //   border-style: solid;
      //   border-width: 4px 3px 0;
      //   border-color: #fff transparent transparent;
      //   margin-left: 5px;

      //   .info.full & {
      //     transform: rotate(180deg);
      //   }
      // }

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    .separator {
      color: rgba(255, 255, 255, 0.5);
      margin-right: 8px;

      &::before {
        content: '/';
      }
    }

    .sender {
      display: flex;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.5);
      padding: 3px;
      border-radius: 32px;
      box-sizing: border-box;
      padding-right: 12px;
      cursor: pointer;
      background-color: transparent;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }

      .separator {
        margin: 0 6px;
        font-size: 10px;

        &::before {
          content: '\2022';
        }
      }
    }
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .accept-invite {
    position: fixed;
    top: 54px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    padding: 16px;
    border-radius: 16px;
    color: #fff;

    .title {
      font-size: 18px;
      margin-bottom: 16px;
    }

    .btns {
      display: flex;
      margin: 0 -8px;

      > :global(*) {
        margin: 0 8px;
      }
    }
  }

  .date {
    opacity: 0.75;
    font-size: 12px;
  }

  .uploading {
    stroke: white;
    margin: -2px -11px -2px 0;
    white-space: nowrap;
    font-size: 12px;
    line-height: 28px;
    vertical-align: top;

    > :global(div) {
      display: inline-block;
      vertical-align: top;
    }
  }

  .error {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 348px;
    transform: translate(-50%, -50%);
    color: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
  }
</style>
