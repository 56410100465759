<script lang="ts">
  import {
    openAuthWindow,
    loginUsingEmail,
    loginUsingGoogle,
    loginUsingApple,
    getRecaptchaToken,
  } from 'actions/auth'
  import GoogleIcon from 'assets/icons/google.svg'
  import AppleIcon from 'assets/icons/apple.svg'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'
  import CodeInput from 'components/CodeInput.svelte'
  import Home from '../Home.svelte'
  import Register from './components/Register.svelte'
  import PhoneAuth from './components/PhoneAuth.svelte'

  const transitionDuration = 300

  let email = ''
  let password = ''
  let error = ''
  let activeScreen = 'auth'
  let nextActiveScreen = 'auth'
  let isLoading = false
  let isLoadingGoogle = false
  let isLoadingApple = false
  let twoFactorAuthRequired = false
  let code = ''

  $: canSubmit = !!email && !!password && (!twoFactorAuthRequired || code.length === 6)

  async function login() {
    if (!canSubmit || isLoading) return

    setError()
    isLoading = true

    try {
      await loginUsingEmail({
        email,
        password,
        recaptchaToken: await getRecaptchaToken(),
        ...(twoFactorAuthRequired && { code }),
      })
    } catch (e: any) {
      if (e?.code === 'A012') {
        twoFactorAuthRequired = true
      } else {
        setError(e.message)
      }
    }

    isLoading = false
  }

  function setActiveScreen(screen: string) {
    nextActiveScreen = screen
    setTimeout(() => {
      activeScreen = nextActiveScreen
    }, transitionDuration)
    setError()
  }

  function setError(e = '') {
    error = e
  }

  async function googleAuth() {
    try {
      const { idToken } = window.ipc
        ? await window.ipc.request('GOOGLE_AUTH')
        : await openAuthWindow('google')

      isLoadingGoogle = true
      await loginUsingGoogle(idToken)
    } catch (error: any) {
      setError(error.message || error.toString())
    }
    isLoadingGoogle = false
  }

  async function appleAuth() {
    try {
      const { code, idToken } = await openAuthWindow('apple')
      isLoadingApple = true
      await loginUsingApple(code, idToken)
    } catch (error: any) {
      setError(error.message || error.toString())
    }
    isLoadingApple = false
  } // eslint-disable-line
</script>

<div class="root">
  <div class="left">
    <Home />
  </div>
  <div class="right">
    <div class="screens" class:next={nextActiveScreen !== 'auth'}>
      <div class="screen">
        <form class="block" on:submit|preventDefault={login}>
          <Input bind:value={email} name="email" placeholder="Email address" />
          <Input bind:value={password} name="password" placeholder="Password" type="password" />
          {#if twoFactorAuthRequired}
            <p>
              We noticed unusual activity on your account, please confirm your identity using the
              code sent to your email.
            </p>
            <CodeInput bind:value={code} />
          {/if}
          <Button color="red" type="submit" loading={isLoading} disabled={!canSubmit}>Login</Button>
        </form>
        <div class="block">
          <Button on:click={() => setActiveScreen('phoneAuth')}>Sign In using Phone</Button>
          <Button color="white" loading={isLoadingGoogle} on:click={googleAuth}>
            <GoogleIcon />
            Sign In with Google
          </Button>
          <Button color="black" loading={isLoadingApple} on:click={appleAuth}>
            <AppleIcon />
            Sign In with Apple
          </Button>
        </div>
        <div class="block">
          <Button color="red" on:click={() => setActiveScreen('register')}
            >Create an Account</Button>
        </div>
      </div>
      <div class="screen">
        <div
          class="screen-item"
          class:active={activeScreen === 'register' || nextActiveScreen === 'register'}>
          <Register
            isActive={activeScreen === 'register'}
            on:error={({ detail }) => setError(detail)}
            on:close={() => setActiveScreen('auth')} />
        </div>
        <div
          class="screen-item"
          class:active={activeScreen === 'phoneAuth' || nextActiveScreen === 'phoneAuth'}>
          <PhoneAuth
            isActive={activeScreen === 'phoneAuth'}
            on:error={({ detail }) => setError(detail)}
            on:close={() => setActiveScreen('auth')} />
        </div>
      </div>
    </div>
    <a class="policy" href="https://boom.me/privacy-policy" target="_blank">Privacy Policy</a>
    <div class="error" class:active={error} on:click={() => setError()}>{error}</div>
  </div>
</div>

<style lang="scss">
  .root {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    overflow-y: auto;
    z-index: 2;
  }

  .left {
    width: 60%;
    flex-grow: 2;
    -webkit-app-region: drag;
    min-height: 580px;
  }

  .right {
    position: relative;
    width: 40%;
    max-width: 400px;
    border-left: 1px solid #dcdcdc;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    min-height: 580px;
  }

  .screens {
    height: 100%;
    width: 200%;
    display: flex;
    transition: transform 0.3s ease;

    &.next {
      transform: translateX(-50%);
    }
  }

  .screen {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    width: 50%;

    @media (max-height: 580px) {
      justify-content: start;
      padding-top: 12px;
    }
  }

  .screen-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: none;

    &.active {
      display: flex;
    }
  }

  .block {
    position: relative;
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;

    > :global(*:not(:first-child)) {
      margin-top: 20px;
    }

    &:not(:first-child) {
      border-top: 1px solid #dcdcdc;
    }
  }

  .policy {
    position: absolute;
    bottom: 20px;
    max-width: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    color: rgba(0, 0, 0, 0.5);
  }

  .error {
    position: absolute;
    bottom: 20px;
    max-width: 80%;
    left: 50%;
    transform: translate(-50%, 600%);
    color: var(--primary-color);
    background: rgb(251, 220, 229);
    border-radius: 24px;
    padding: 5px 20px;
    text-align: center;

    &.active {
      transform: translate(-50%, 0);
      transition: transform 0.3s ease;
    }
  }
</style>
