<script lang="ts">
  import type { User, ContactPoint } from 'types'
  import { onDestroy } from 'svelte'
  import { contacts, threads, session } from 'stores'
  import { isContactPointsEqual } from 'utils'
  import { fetchContacts } from 'actions/contacts'
  import CloseIcon from 'assets/icons/cross.svg'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'
  import Spinner from 'components/Spinner.svelte'

  export let contactPoints: ContactPoint[]
  export let addContactPoint: (user: ContactPoint) => void
  export let canRemoveContactPoint: (user: ContactPoint) => boolean
  export let removeContactPoint: (user: ContactPoint) => void
  export let loadingContacts: { [id: number]: boolean } = {}
  export let showFullInfo = true

  let field: HTMLInputElement
  let fieldValue = ''
  let users: User[] = []
  let suggestedUsers: ContactPoint[] = []
  let suggestedContacts: ContactPoint[] = []
  let activeSuggestionIndex = -1
  const unsubscribes: (() => void)[] = []

  $: (users || contactPoints) && filterSuggestions(fieldValue)

  unsubscribes.push(contacts.subscribe(() => filterSuggestions(fieldValue)))
  unsubscribes.push(
    threads.subscribe(threads => {
      const map: { [key: string]: boolean } = {}
      users = (threads || [])
        .flatMap(thread => thread.members.filter(p => p.id !== session.get()?.profile.id))
        .filter(user => {
          if (map[user.id]) {
            return false
          } else {
            map[user.id] = true
            return true
          }
        })
    }),
  )

  fetchContacts()

  function handleKeyUp(event: KeyboardEvent) {
    const key = event?.key?.toLowerCase()
    const suggestionsCount = suggestedUsers.length + suggestedContacts.length

    if (key === 'enter') {
      if (activeSuggestionIndex >= 0 && getSuggestion(activeSuggestionIndex)) {
        addContactPoint(getSuggestion(activeSuggestionIndex))
      } else if (fieldValue) {
        addContactPoint({ contactPoint: fieldValue })
      } else {
        return
      }
      field.value = ''
      fieldValue = ''
      filterSuggestions('')
    }
    if (key === 'arrowup' && suggestionsCount > 0) {
      activeSuggestionIndex = Math.max(0, activeSuggestionIndex - 1)
    } else if (key === 'arrowdown' && suggestionsCount > 0) {
      activeSuggestionIndex = Math.min(suggestionsCount - 1, activeSuggestionIndex + 1)
    }
  }

  function filterSuggestions(query: string) {
    const activeSuggestion = getSuggestion(activeSuggestionIndex)

    suggestedUsers = []
    suggestedContacts = []

    for (const user of users) {
      if (suggestedUsers.length > 8) {
        break
      }
      if (contactPoints.find(contactPoint => isContactPointsEqual(contactPoint, { user }))) {
        continue
      }

      if (
        [
          ...(user.email ? [user.email] : []),
          ...(user.phoneNumber ? [user.phoneNumber] : []),
          ...(user.fullName ? [user.fullName] : []),
          ...(user.username ? [user.username] : []),
        ].reduce(
          (result: boolean, str: string) => result || str.toLowerCase().indexOf(query) !== -1,
          false,
        )
      ) {
        suggestedUsers.push({ user })
      }
    }

    if (query) {
      for (const contact of contacts.get() || []) {
        if (suggestedContacts.length > 8) {
          break
        }
        if (
          contactPoints.find(
            contactPoint => contactPoint.contact?.identifier === contact.identifier,
          )
        ) {
          continue
        }

        if (
          [
            ...contact.emailAddresses,
            ...contact.phoneNumbers,
            ...(contact.firstName && [contact.firstName]),
            ...(contact.lastName && [contact.lastName]),
            ...(contact.nickname && [contact.nickname]),
          ].reduce(
            (result: boolean, str: string) => result || str.toLowerCase().indexOf(query) !== -1,
            false,
          )
        ) {
          suggestedContacts.push({ contact })
        }
      }
    }

    const newActiveSuggestion = getSuggestion(activeSuggestionIndex)

    if (!newActiveSuggestion || !isContactPointsEqual(activeSuggestion, newActiveSuggestion)) {
      activeSuggestionIndex = -1
    }
  }

  function getSuggestion(index: number): ContactPoint {
    return index < suggestedUsers.length
      ? suggestedUsers[index]
      : suggestedContacts[index - suggestedUsers.length]
  }

  onDestroy(() => unsubscribes.forEach(un => un()))

  ; // prettier-ignore
</script>

<div class="list">
  <input
    bind:this={field}
    bind:value={fieldValue}
    on:keyup={handleKeyUp}
    class="search"
    placeholder="Type any email, phone number, or name" />
  <div class="members">
    {#each contactPoints as contactPoint, index (index)}
      <div class="member">
        <ContactPointBadge color="white" {contactPoint} {showFullInfo} />
        {#if loadingContacts[index]}
          <Spinner color="white" />
        {:else if contactPoint && canRemoveContactPoint(contactPoint)}
          <div class="remove-member" on:click={() => removeContactPoint(contactPoint)}>
            <CloseIcon />
          </div>
        {/if}
      </div>
    {/each}
  </div>
  <div class="suggestions">
    {#each [...suggestedUsers, ...suggestedContacts] as suggestion, index (index)}
      <div
        class="suggestion"
        class:active={activeSuggestionIndex === index}
        on:mousedown|preventDefault={() => addContactPoint(suggestion)}>
        <ContactPointBadge
          contactPoint={suggestion}
          color={activeSuggestionIndex === index ? 'white' : 'black'}
          {showFullInfo} />
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  .list {
    width: 100%;
    margin-top: -8px;
  }

  .search {
    display: block;
    position: relative;
    height: 32px;
    width: 100%;
    margin: 0;
    background: #f5f5f5;
    box-sizing: border-box;
    border-radius: 17px;
    padding: 4px 10px;
    border: none;
    font: 14px/24px var(--main-font);
    transition: box-shadow 0.3s ease;
    outline: none;

    &:focus {
      box-shadow: 0 0 0 2px var(--blue-color);
    }
  }

  .members {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  .member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 8px 8px 0 0;
    padding: 3px 12px 3px 12px;
    background: var(--blue-color);
    border-radius: 16px;
    color: #fff;

    :global(.spinner) {
      transform: scale(0.8);
      margin-right: -5px;
      margin-left: 6px;
    }

    :global(.user-badge) {
      margin-left: -7px;
    }
  }

  .remove-member {
    transform: scale(0.75);
    cursor: pointer;
    margin-left: 8px;
    margin-bottom: -2px;
  }

  .suggestions {
    display: flex;
    flex-direction: column;
    margin: 0 -32px;
  }

  .suggestion {
    position: relative;
    padding: 5px 56px 5px 32px;
    cursor: pointer;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &.active {
      background: var(--blue-color);
    }
  }
</style>
