<script lang="ts">
  import type { Thumb } from 'types'
  import { onMount, onDestroy, createEventDispatcher, tick } from 'svelte'
  import hotkeys from 'hotkeys-js'
  import { newMessages, session } from 'stores'
  import { setThumbsActive } from 'actions'
  import { animateProperty } from 'utils'
  import UserBadge from 'components/UserBadge.svelte'
  import Spinner from 'components/Spinner.svelte'
  import TimeAgo from 'components/TimeAgo.svelte'

  export let thumbs: Thumb[] = []
  export let position: 'bottom' | 'right' = 'bottom'
  export let activeThumbId: string | undefined = undefined
  export let loadingPrevious = false
  export let loadingNext = false
  export let disableNotWatched = false

  let thumbsScrollBox: HTMLDivElement
  let verticalMessagesMap: { [key: string]: boolean } = {}
  let dontScrollToActiveThumb = false
  let previousLoader: HTMLDivElement
  let nextLoader: HTMLDivElement
  let firstThumb: HTMLDivElement | undefined
  let firstThumbPosition = 0

  const observers: IntersectionObserver[] = []

  const dispatch = createEventDispatcher<{
    activeChanged: string
    reachedStart: string
    reachedEnd: string
  }>()

  $: activeThumbId && scrollToActiveThumbIfNeeded()
  $: handleLoadingNext(loadingNext)

  export function scrollToActiveThumbIfNeeded(withAnimation = true): void {
    !dontScrollToActiveThumb && setTimeout(() => scrollToActiveThumb(withAnimation))
    dontScrollToActiveThumb = false
  }

  async function handleLoadingNext(loadingNext: boolean) {
    await tick()

    if (loadingNext) {
      firstThumb = thumbsScrollBox.querySelector('.thumb') as HTMLDivElement
      firstThumbPosition = firstThumb ? firstThumb.offsetLeft : 0
    } else if (firstThumb) {
      thumbsScrollBox.scrollLeft = firstThumb.offsetLeft - firstThumbPosition
      firstThumb = undefined
    }
  }

  function scrollToActiveThumb(withAnimation = true) {
    if (!thumbsScrollBox) return
    const activeThumb = thumbsScrollBox.querySelector('.active') as HTMLElement
    if (!activeThumb) return

    const scrollLeft =
      activeThumb.offsetLeft - (thumbsScrollBox.offsetWidth - activeThumb.offsetWidth) / 2

    if (withAnimation) {
      animateProperty(thumbsScrollBox, 'scrollLeft', scrollLeft)
    } else {
      thumbsScrollBox.scrollLeft = scrollLeft
    }
  }

  function updateImageOrientation(event: Event, id: string) {
    const image = event.target as HTMLImageElement

    if (image.naturalWidth && image.naturalHeight) {
      verticalMessagesMap = {
        ...verticalMessagesMap,
        [id]: image.naturalHeight > image.naturalWidth,
      }
    }
  }

  function moveToThumb(step: number) {
    const activeImdex = thumbs.findIndex(m => m.id === activeThumbId)
    if (activeImdex === -1) return
    const nextIndex = Math.max(0, Math.min(activeImdex + step, thumbs.length - 1))

    if (nextIndex !== activeImdex) {
      dispatch('activeChanged', thumbs[nextIndex].id)
    }
  }

  function goToThumb(thumb: Thumb) {
    dontScrollToActiveThumb = true
    dispatch('activeChanged', thumb.id)
  }

  function observeLoader(loader: HTMLDivElement, event: 'reachedStart' | 'reachedEnd') {
    const observer = new IntersectionObserver(
      ([target]) => target.isIntersecting && dispatch(event),
      { root: thumbsScrollBox, threshold: 0.5 },
    )
    observer.observe(loader)
    observers.push(observer)
  }

  function getRatio(thumb: Thumb) {
    return (thumb.width || thumb.video?.width || 16) / (thumb.height || thumb.video?.height || 9)
  }

  onMount(() => {
    if (position === 'bottom') {
      hotkeys('left', () => moveToThumb(-1))
      hotkeys('right', () => moveToThumb(1))
    }

    observeLoader(previousLoader, 'reachedStart')
    observeLoader(nextLoader, 'reachedEnd')
  })

  onDestroy(() => {
    if (position === 'bottom') {
      hotkeys.unbind('left')
      hotkeys.unbind('right')
    }

    observers.forEach(observer => observer.disconnect())
  })

  ; // prettier-ignore
</script>

<div
  class={`thumbs ${position}`}
  on:mouseenter={() => setThumbsActive(true)}
  on:mouseleave={() => setTimeout(() => setThumbsActive(false))}>
  <div class="scrollbox" bind:this={thumbsScrollBox}>
    <div class="loader" class:active={loadingNext} bind:this={nextLoader}>
      <Spinner color="white" />
    </div>
    {#each thumbs as thumb (thumb.id)}
      <div
        class="thumb"
        class:not-watched={thumb.watched === false && !disableNotWatched}
        class:not-watched-replies={(thumb.unwatchedRepliesCount || 0) > 0 && !disableNotWatched}
        class:with-replies={(thumb.repliesCount || 0) > 0 && !disableNotWatched}
        class:active={thumb.id === activeThumbId}
        class:square={getRatio(thumb) === 1}
        class:vertical={verticalMessagesMap[thumb.id] || getRatio(thumb) < 1}
        on:click={() => goToThumb(thumb)}>
        {#if thumb.sender}
          <div class="thumb-info">
            <UserBadge
              user={thumb.sender}
              currentUser={$session?.profile}
              showExtraInfo={true}
              color="white">
              <span slot="extraInfo">
                {#if $newMessages[thumb.id]}
                  Uploading...
                {:else}
                  <TimeAgo date={thumb.createdAt} withTime={true} />
                {/if}
              </span>
            </UserBadge>
          </div>
        {/if}
        <div class="thumb-image">
          {#if thumb.thumbnailURL}
            <img
              src={`${thumb.thumbnailURL}${/^blob/.test(thumb.thumbnailURL) ? '' : '?size=240'}`}
              alt={`Message ${thumb.id}`}
              on:load={event => updateImageOrientation(event, thumb.id)} />
          {/if}
        </div>
      </div>
    {/each}
    <div class="loader" class:active={loadingPrevious} bind:this={previousLoader}>
      <Spinner color="white" />
    </div>
  </div>
</div>

<style lang="scss">
  .thumbs {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 104px;
    opacity: 0.75;
    transform: translateY(80px);
    transition: transform var(--thumbs-transition-duration) ease,
      opacity var(--thumbs-transition-duration) ease;
    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      height: 196px;
      left: -300px;
      right: 0;
      bottom: 0;
      transition: height var(--thumbs-transition-duration) ease;
      background: linear-gradient(
        transparent 0,
        rgba(0, 0, 0, 0.1) 10%,
        rgba(0, 0, 0, 0.2) 15%,
        rgba(0, 0, 0, 0.3) 20%,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.5) 40%,
        rgba(0, 0, 0, 0.6) 100%
      );
      z-index: 2;
    }

    .scrollbox {
      position: relative;
      display: flex;
      height: 100%;
      align-items: flex-end;
      overflow-x: auto;
      white-space: nowrap;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overscroll-behavior: contain;
      -webkit-overflow-scrolling: touch;
      z-index: 2;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    :global(.active-thumbs) & {
      transform: translateY(0);
      height: 216px;
      opacity: 1;

      &::before {
        height: 236px;
      }
    }
  }

  .thumbs.right {
    left: auto;
    top: 136px;
    bottom: 96px;
    height: auto;
    width: 124px;
    transform: translateX(98px);
    transition: transform var(--thumbs-transition-duration) ease,
      opacity var(--thumbs-transition-duration) ease, bottom var(--thumbs-transition-duration) ease;

    &::before {
      width: 196px;
      height: auto;
      left: auto;
      top: -136px;
      bottom: -300px;
      transition: width var(--thumbs-transition-duration) ease;
      background: linear-gradient(
        90deg,
        transparent 0,
        rgba(0, 0, 0, 0.1) 10%,
        rgba(0, 0, 0, 0.2) 15%,
        rgba(0, 0, 0, 0.3) 20%,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.5) 40%,
        rgba(0, 0, 0, 0.6) 100%
      );
    }

    :global(.active-thumbs) & {
      transform: translateX(0);
      width: 360px;
      bottom: 216px;
      height: auto;

      &::before {
        width: 320px;
        height: auto;
      }
    }

    .scrollbox {
      flex-direction: column-reverse;
      width: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .thumb {
      margin: 0 12px 0 3px;
      padding: 4px 0;
      width: 100px;
      height: 60px;

      &:hover {
        width: 140px;
        height: 80px;

        &.vertical {
          width: 48px;
        }

        &.square {
          width: 80px;
        }
      }

      &.vertical {
        width: 36px;
        left: -64px;
      }

      &.square {
        width: 60px;
        left: -40px;
      }

      :global(.active-thumbs) & {
        left: 0;
      }

      &.not-watched::after,
      &.not-watched-replies::after {
        margin-top: 6px;
      }

      &.with-replies::after {
        display: none;
      }
    }

    .thumb-info {
      top: 4px;
      left: auto;
      padding: 8px 14px 0 0;
      right: 100%;
      height: 100%;
    }

    .loader {
      width: 140px;
      height: 12px;
      margin: 0 12px 0 0;
    }
  }

  .thumb {
    position: relative;
    width: 140px;
    height: 80px;
    margin: 0 0 16px;
    padding: 0 3px;
    box-sizing: content-box;
    flex: 0 0 auto;
    cursor: pointer;
    user-select: none;
    transition: all 0.2s ease;

    &:hover {
      width: 178px;
      height: 100px;
      z-index: 2;

      &.vertical {
        width: 56px;
      }

      &.square {
        width: 100px;
      }
    }

    &.vertical {
      width: 45px;
    }

    &.square {
      width: 80px;
    }

    &.active {
      z-index: 1;
    }

    &.not-watched::after,
    &.not-watched-replies::after,
    &.with-replies::after {
      content: 'new';
      position: absolute;
      top: 4px;
      right: 8px;
      padding: 0 6px;
      height: 16px;
      border-radius: 4px;
      background: var(--primary-color);
      z-index: 2;
      color: #fff;
      font-size: 8px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
    }

    &.with-replies::after {
      content: '';
      width: 16px;
      padding: 0 4px;
      background: url('assets/icons/reply.svg') var(--blue-color) 6px 4px no-repeat;
      background-size: 12px;
    }

    &.with-replies.not-watched-replies::after {
      content: 'new';
    }

    &.not-watched-replies::after {
      background: url('assets/icons/reply.svg') red 28px 4px no-repeat;
      background-size: 12px;
      padding-right: 24px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .thumb-image {
    position: relative;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.7);

    .active > & {
      box-shadow: 0 0 0 3px #fff;
    }
  }

  .thumb-info {
    position: absolute;
    top: -36px;
    left: 10px;
    padding-bottom: 8px;
    opacity: 0;
    transition: opacity 0.2s ease;

    .thumb:hover & {
      opacity: 1;
    }
  }

  .loader {
    width: 12px;
    height: 80px;
    margin: 0 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &:not(.active) > :global(*) {
      display: none;
    }

    &.active {
      width: 80px;
    }
  }
</style>
