import { contacts } from 'stores'

export async function fetchContacts(): Promise<void> {
  if (!contacts.get()) {
    if (window.ipc) {
      try {
        contacts.set(await window.ipc.request('CONTACT_LIST'))
      } catch (e) {
        contacts.set([])
      }
    } else {
      contacts.set([])
    }
  }
}
