import type { Thread, Member } from 'types'
import type { ThreadData } from 'api/threads'
import { threads, currentThreadId } from 'stores'
import { threadsAPI, uploadsAPI } from 'api'
import { updateCommunities } from './communities'
import { goToCommunity, goToThread } from './router'
import { addAlert } from './alerts'

export async function loadThreads(): Promise<void> {
  threads.set(await threadsAPI.getThreads())
}

export async function loadThreadMembersIfNeeded(thread: Thread): Promise<void> {
  if (!thread.members || thread.members.length === 0) {
    const members = await threadsAPI.getMembers(thread.id)

    threads.update(threads => {
      if (!threads) return null
      return threads.map(t => ({
        ...t,
        ...(t.id === thread.id ? { members } : {}),
      }))
    })
  }
}

export async function createChannel(data: ThreadData, imageFile?: Blob): Promise<Thread> {
  if (imageFile) {
    const { id } = await uploadsAPI.upload(imageFile)
    data.imageId = id
  }

  return updateThreads(await threadsAPI.createThread(data))
}

export async function updateChannel(id: string, data: ThreadData): Promise<Thread> {
  return updateThreads(await threadsAPI.updateThread(id, data))
}

export async function uploadChannelPhoto(id: string, blob: Blob): Promise<Thread> {
  const { id: imageId } = await uploadsAPI.upload(blob)
  return updateThreads(await threadsAPI.updateThread(id, { imageId }))
}

export async function handleNewThread(
  threadOrId: string | Thread,
  shouldGoTo = false,
): Promise<void> {
  const thread =
    typeof threadOrId === 'string'
      ? threads.get()?.find(c => c.id === threadOrId) || (await threadsAPI.getThread(threadOrId))
      : threadOrId

  if (!threads.get()?.find(c => c.id === thread?.id)) {
    if (thread) {
      threads.update(threads =>
        [thread!, ...(threads || [])].sort((l, r) => (l.modifiedAt > r.modifiedAt ? -1 : 1)),
      )
    } else {
      throw new Error('Thread is not found')
    }
  }

  shouldGoTo && goToThread(thread)
}

export async function toggleMute(thread: Thread): Promise<Thread> {
  await threadsAPI[thread.muted ? 'unmute' : 'mute'](thread.id)
  return updateThreads({ ...thread, muted: !thread.muted })
}

export async function getChannelInviteLink(id: string): Promise<string> {
  const inviteLink = await threadsAPI.createInviteLink(id)
  threads.update(threads => {
    if (!threads) return threads

    return threads.map(thread => ({
      ...thread,
      inviteLink: thread.id === id ? inviteLink.link : thread.inviteLink,
    }))
  })
  return inviteLink.link
}

export async function joinInviteLink(token: string): Promise<void> {
  const { thread, community } = await threadsAPI.join(token)

  if (thread) {
    updateThreads(thread)
    goToThread(thread)
    addAlert(`You're successfully joined #${thread.title || thread.id} channel`)
  } else if (community) {
    updateCommunities(community)
    goToCommunity(community)
    addAlert(`You're successfully joined ${community.title} community`)
  }
}

export async function addChannelMembers(threadId: string, members: Member[]): Promise<void> {
  const users = await threadsAPI.addMembers(threadId, members)

  threads.update(threads => {
    if (!threads) return null

    return threads.map(thread => {
      if (thread.id === threadId) {
        return {
          ...thread,
          members: [
            ...thread.members,
            ...users.filter(u => !thread.members.find(p => p.id === u.id)),
          ],
        }
      } else {
        return thread
      }
    })
  })
}

export async function removeChannelMembers(threadId: string, memberIds: string[]): Promise<void> {
  await threadsAPI.removeMembers(threadId, memberIds)
  threads.update(threads => {
    if (!threads) return null

    return threads.map(thread => {
      if (thread.id === threadId) {
        return {
          ...thread,
          members: thread.members.filter(p => memberIds.indexOf(p.id) === -1),
        }
      } else {
        return thread
      }
    })
  })
}

export async function banChannelMember(
  threadId: string,
  memberId: string,
  duration?: number,
  comment?: string,
): Promise<void> {
  await threadsAPI.banMember(threadId, { memberId, duration, comment })

  if (!duration) {
    threads.update(threads => {
      if (!threads) return null

      return threads.map(thread => {
        if (thread.id === threadId) {
          return {
            ...thread,
            members: thread.members.filter(m => memberId !== m.id),
          }
        } else {
          return thread
        }
      })
    })
  }
}

export async function joinThread(threadId: string): Promise<Thread> {
  const thread = await threadsAPI.joinThread(threadId)
  updateThreads({ ...thread, needToAcceptInvite: false })
  return thread
}

export async function leaveThread(threadId: string): Promise<void> {
  await threadsAPI.leaveThread(threadId)
  handleDeletedThread(threadId)
}

export function handleDeletedThread(threadId: string): void {
  const threadIndex = threads.get()?.findIndex(thread => thread.id === threadId)
  threads.update(threads => (threads ? threads.filter(thread => thread.id !== threadId) : null))

  if (currentThreadId.get() === threadId) {
    const threadsValue = threads.get() || []
    const newThreadIndex = Math.min(threadIndex || 0, threadsValue.length - 1)
    const newThread = threadsValue[newThreadIndex]

    newThread && goToThread(newThread)
  }
}

export function updateThreads(updatedThread: Thread): Thread {
  threads.update(threads => {
    if (!threads) return null
    let wasFound = false
    let updatedThreads = threads.map(thread => {
      if (thread.id === updatedThread.id) {
        wasFound = true
        return {
          ...thread,
          ...updatedThread,
        }
      } else {
        return thread
      }
    })

    if (!wasFound) {
      updatedThreads = [updatedThread, ...updatedThreads]
    }

    return updatedThreads.sort((l, r) => (l.modifiedAt > r.modifiedAt ? -1 : 1))
  })

  return updatedThread
}

export async function markThreadAsWatched(thread: Thread) {
  await threadsAPI.markAsWatched(thread.id)
  return updateThreads({
    ...thread,
    unwatchedMessagesCount: 0,
    messages:
      thread.messages?.map(message => ({
        ...message,
        watched: true,
        unwatchedRepliesCount: undefined,
        replies: message.replies?.map(reply => ({ ...reply, watched: true })) || message.replies,
      })) || thread.messages,
  })
}
