<script lang="ts">
  import Router, { location } from 'svelte-spa-router'
  import { get } from 'svelte/store'
  import { fly } from 'svelte/transition'
  import { WindowSize } from 'types'
  import {
    session,
    settings,
    alerts,
    confirm,
    notifications,
    online,
    wsConnected,
    isAppFocused,
  } from 'stores'
  import { connect, disconnect } from 'api/ws'
  import { goToAuth, goToFeed, goToNewMessage, goToShare } from 'actions/router'
  import {
    joinInviteLink,
    addError,
    removeAlert,
    removeNotification,
    removeAllNotifications,
    resolveConfirm,
    validateSession,
    loadThreads,
    loadCommunities,
    loadDrafts,
    loadImages,
    loadReports,
    checkReportsToReview,
    loadUnatchedMessages,
    handleWebSocketsNotifications,
  } from 'actions'
  import { isMac, isWindows, production, isElectron } from 'env'
  import { timeout } from 'utils'
  import Home from './scenes/Home.svelte'
  import Auth from './scenes/Auth/Auth.svelte'
  import AICamera from './scenes/AICamera.svelte'
  import Root from './scenes/Root/Root.svelte'
  import TitleBar from './components/TitleBar.svelte'
  import Confirm from './components/Confirm.svelte'
  import Spinner from './components/Spinner.svelte'
  import NewUpdate from './components/NewUpdate.svelte'
  import Notifications from './components/Notifications.svelte'

  const routes = new Map<string | RegExp, any>([
    ['/', Home],
    [/^\/threads|drafts|feed|reports|share|ai(\/(.*))?/, Root],
    ['/auth', Auth],
    [/^\/camera(\/(.*))?/, AICamera],
  ])

  let isInit = true
  let linkToProcess: string[] = []
  let newUpdateVersion: string | undefined
  let wentOfflineAt: Date | undefined
  let wasOfflineOnLaunch = false
  let wasDisconnectedAt: Date | undefined
  let refreshingApp = false
  let validateSessionTimeout: any
  let cameraState: 'preparing' | 'prepared' | null = 'prepared'

  session.subscribe(currentSession => {
    const currentLocation = get(location)
    const isHomePage = currentLocation === '/'
    const isAuthPage = currentLocation === '/auth'
    const allowedPages = ['/auth', '/camera']
    const isAllowedPage = allowedPages.reduce(
      (acc, page) => acc || currentLocation.startsWith(page),
      false,
    )
    const isNewMessage = currentLocation.search(/^\/threads\/new/) !== -1
    const isAuthorized = !!currentSession

    if (production && !isElectron) {
      document.cookie = `state=${isAuthorized ? 'loggedIn' : 'loggedOut'};domain=.boom.me`
    }

    currentSession && processLinks()

    if (isAuthorized) {
      runSessionValidation()
    } else {
      clearTimeout(validateSessionTimeout)
      wasDisconnectedAt = undefined
    }

    currentSession ? connect(currentSession.authToken) : disconnect()

    if (isAuthorized && (isAuthPage || isHomePage)) {
      goToFeed(undefined, undefined, isHomePage)
      window.ipc?.send('RESIZE', { size: WindowSize.FULLSCREEN })
      !isNewMessage && prepareCamera()
    } else if (!isAuthorized && !isAllowedPage) {
      goToAuth()
      window.ipc?.send('RESIZE', { size: WindowSize.AUTH })
    } else if (isAuthorized) {
      !isNewMessage && prepareCamera()
    }

    if (isInit) {
      // Rendering takes a bit time, so wait for 500ms to make sure page is rendered
      setTimeout(() => window.ipc?.send('READY_TO_SHOW'), 500)
      isInit = false
    }
  })

  location.subscribe(async location => {
    const joinToken = (/^\/join\/([^/]*)$/.exec(location) || [])[1]

    if (joinToken) {
      try {
        await joinInviteLink(joinToken)
      } catch (e) {
        goToFeed()
        addError(e)
      }
    }
  })

  isAppFocused.subscribe(isAppFocused => isAppFocused && runSessionValidation())

  wsConnected.subscribe(async wsConnected => {
    if (wsConnected) {
      const now = new Date().getTime()
      const wasDisconnectedFor = (now - (wasDisconnectedAt?.getTime() || now)) / 1000

      if (wasDisconnectedFor > 5) {
        await refreshApp(wasDisconnectedFor)
      }

      wasDisconnectedAt = undefined
    } else {
      wasDisconnectedAt = $session ? new Date() : undefined
    }
  })

  window.ipc?.on('OPEN_RECORDER', () => session.get() && goToNewMessage())
  window.ipc?.on('OPEN_APP_LINK', (_, link) => {
    linkToProcess.push(link)
    processLinks()
  })
  window.ipc?.on('UPDATE_DOWNLOADED', (_, version) => {
    newUpdateVersion = version
  })
  window.ipc?.on('APP_IS_FOCUSED', (_, isFocused) => isAppFocused.set(isFocused))
  window.addEventListener('visibilitychange', () => isAppFocused.set(!document.hidden))

  // Subscribe on server notifications
  handleWebSocketsNotifications()

  // Track online status
  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
  updateOnlineStatus()

  async function runSessionValidation() {
    const secondsToExpire = await validateSession()
    if (secondsToExpire === 0) return
    clearTimeout(validateSessionTimeout)
    // Run revalidation in a minute before expiration
    validateSessionTimeout = setTimeout(
      runSessionValidation,
      Math.max(0, secondsToExpire - 60) * 1000,
    )
  }

  async function processLinks() {
    if (!session.get()) return

    for (const link of linkToProcess) {
      const [action, token] = link.split('://')[1]?.split('/') || []

      if (action === 'join' && token) {
        try {
          await joinInviteLink(token)
        } catch (e) {
          addError(e)
        }
      } else if (action === 'share' && token) {
        goToShare(token)
      }
    }

    linkToProcess = []
  }

  // Prepare camera for faster initialization for later
  async function prepareCamera() {
    if (cameraState !== 'prepared') {
      const devices = await navigator.mediaDevices?.enumerateDevices()
      const videoDevices = devices
        .filter(d => d.kind === 'videoinput')
        .map(d => ({ label: d.label || d.deviceId, value: d }))
      const audioDevices = devices
        .filter(d => d.kind === 'audioinput')
        .map(d => ({ label: d.label || d.deviceId, value: d }))

      if (videoDevices.length > 0 && audioDevices.length > 0) {
        cameraState = 'preparing'
        let stream: MediaStream | null = null

        try {
          const lastUsedCameraId = localStorage.getItem('lastUsedCameraId') || ''
          const cameraIndex = Math.max(
            0,
            videoDevices.findIndex(({ value, label }) =>
              lastUsedCameraId
                ? value.deviceId === lastUsedCameraId
                : label.toLowerCase().indexOf('facetime') !== -1,
            ),
          )
          const currentVideoDevice = videoDevices[cameraIndex]
          const lastUsedMicId = localStorage.getItem('lastUsedMicId') || ''
          const micIndex = Math.max(
            0,
            audioDevices.findIndex(({ value }) => value.deviceId === lastUsedMicId),
          )
          const currentAudioDevice = audioDevices[micIndex]

          stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: currentVideoDevice.value.deviceId } },
            audio: { deviceId: { exact: currentAudioDevice.value.deviceId } },
          })
          const video = document.createElement('video')
          video.srcObject = stream
          video.volume = 0
          await video.play()
          await timeout(1000)
          video.srcObject = null
        } catch (e) {}

        if (stream) {
          stream.getTracks().forEach(t => t.stop())
          await timeout(500)
        }
      }

      cameraState = 'prepared'
    }
  }

  async function updateOnlineStatus(event?: Event) {
    const isInitialUpdate = event === undefined
    online.set(navigator.onLine)

    if (navigator.onLine) {
      const now = new Date().getTime()
      const wasOfflineFor = (now - (wentOfflineAt?.getTime() || now)) / 1000

      await refreshApp(wasOfflineFor)
      wentOfflineAt = undefined
      wasOfflineOnLaunch = false
    } else {
      wentOfflineAt = new Date()
      wasOfflineOnLaunch = isInitialUpdate
    }
  }

  async function refreshApp(wasOfflineFor: number) {
    refreshingApp = true

    try {
      // If app was offline more than a minute, refresh everything
      if (wasOfflineFor > 60 || wasOfflineOnLaunch) {
        const currentLocation = get(location)

        await Promise.all([loadCommunities(), loadThreads(), checkReportsToReview()])

        if (currentLocation.startsWith('/drafts/')) {
          await loadDrafts()
        } else if (currentLocation.startsWith('/ai/')) {
          await loadImages(true)
        } else if (currentLocation.startsWith('/reports/')) {
          await loadReports()
        }
      } else if (wasOfflineFor > 0) {
        // If app was offline less than a minute, refresh only new messages
        await loadUnatchedMessages()
      }
    } catch (e) {
      addError(e)
    }

    refreshingApp = false
  }
</script>

<div
  class="app"
  class:mac={isMac}
  class:windows={isWindows}
  class:screen-recording={$settings.screenRecordingMode}>
  {#if newUpdateVersion}
    <NewUpdate version={newUpdateVersion} close={() => (newUpdateVersion = undefined)} />
  {/if}
  <div class="alerts">
    {#each $alerts as alert}
      <div
        class={`alert ${alert.type}`}
        transition:fly={{ duration: 200 }}
        on:click={removeAlert.bind(null, alert)}>
        {alert.message}
      </div>
    {/each}
  </div>
  <Notifications
    notifications={$notifications}
    on:remove={({ detail }) => removeNotification(detail)}
    on:removeAll={() => removeAllNotifications()} />
  {#if cameraState === 'preparing' && $location.search(/^\/threads\/new/) === -1}
    <div class="camera-init" transition:fly={{ duration: 200 }}>
      <div class="indicator" />
      <b>Preparing camera</b>
      Application not recording.
    </div>
  {/if}
  {#if !$online || refreshingApp}
    <div class="online-indicator" transition:fly={{ duration: 200, y: -20 }}>
      {#if $online}
        <Spinner color="white" /> You are back online. Refreshing app.
      {:else}
        You are offline. App might not work properly.
      {/if}
    </div>
  {/if}
  <Confirm
    active={!!$confirm && !$confirm.resolved}
    onClose={resolveConfirm}
    title={$confirm?.title}
    actionButtonLabel={$confirm?.actionButtonLabel} />
  {#if isWindows && !$settings.screenRecordingMode}
    <TitleBar />
  {/if}
  <div class="body">
    <Router {routes} />
  </div>
</div>

<style lang="scss">
  .app {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .body {
    position: relative;
    flex-grow: 2;
  }

  .alerts {
    position: absolute;
    top: 0;
    left: 50%;
    max-width: 320px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 101;

    .windows & {
      top: 24px;
    }
  }

  .alert {
    background: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    margin-top: 16px;
    color: #fff;
    cursor: pointer;
  }

  .online-indicator {
    position: absolute;
    top: 16px;
    left: 50%;
    height: 24px;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 101;

    .windows & {
      margin-top: 24px;
    }

    :global(.spinner) {
      margin-right: 12px;
    }
  }

  .camera-init {
    position: absolute;
    top: 32px;
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    background: rgba(0, 0, 0, 0.85);
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 14px;
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 101;

    .windows & {
      margin-top: 24px;
    }

    > * {
      margin-right: 12px;
    }

    .indicator {
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      border-right: 1px solid rgba(255, 255, 255, 0.2);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      border-left: 1px solid #86bcfc;
      animation: spin 1.1s infinite linear;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: #70ff19;
        margin: -3px 0 0 -3px;
      }
    }

    @keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }
</style>
