<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import Spinner from './Spinner.svelte'

  type Colors = 'primary' | 'red' | 'green' | 'black' | 'white'
  type TextColors = 'black' | 'white' | 'red'
  const textColors: { [key in Colors]: TextColors } = {
    primary: 'black',
    red: 'white',
    green: 'white',
    black: 'white',
    white: 'black',
  }
  const dispatch = createEventDispatcher<{ click: undefined }>()

  export let disabled = false
  export let loading = false
  export let type = 'button'
  export let color: Colors = 'primary'
  export let textColor: TextColors = textColors[color]
  export let link: string | undefined = undefined
  export let download: string | undefined = undefined

  ; // prettier-ignore
</script>

{#if link}
  <a
    href={link}
    {download}
    class={color + (textColor === 'black' ? '' : ` text-${textColor}`)}
    {type}
    {disabled}
    on:click|stopPropagation={() => !loading && dispatch('click')}>
    {#if loading}
      <Spinner size="small" color={textColor} />
    {:else}
      <slot />
    {/if}
  </a>
{:else}
  <button
    class={color + (textColor === 'black' ? '' : ` text-${textColor}`)}
    {type}
    {disabled}
    on:click|stopPropagation={() => !loading && dispatch('click')}>
    {#if loading}
      <Spinner size="small" color={textColor} />
    {:else}
      <slot />
    {/if}
  </button>
{/if}

<style lang="scss">
  button,
  a {
    height: 40px;
    border: none;
    border-radius: 6px;
    display: flex;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer;
    padding: 9px 10px;
    font: 15px/20px var(--main-font);
    align-items: center;
    justify-content: center;
    text-decoration: none;
    box-sizing: border-box;
    transition: background-color 0.3s ease, color 0.3s ease;
    color: #000;

    & > :global(svg) {
      margin-right: 6px;
    }

    &:active {
      background: rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .red {
    font-weight: 500;
    background: var(--primary-color);

    &:active {
      background: #d8594e;
    }
  }

  .green {
    font-weight: 500;
    background: green;

    &:active {
      background: green;
    }
  }

  .black {
    background: #000;

    &:active {
      background: #000;
    }
  }

  .white {
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.25);

    &:active {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  .primary.text-white {
    background: rgba(255, 255, 255, 0.1);

    &:active {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .text-white {
    color: #fff;

    & > :global(svg path:not(.stroke)) {
      fill: #fff;
    }

    & > :global(svg path.stroke) {
      stroke: #fff;
    }
  }

  .text-red {
    color: var(--primary-color);

    & > :global(svg path:not(.stroke)) {
      fill: var(--primary-color);
    }

    & > :global(svg path.stroke) {
      stroke: var(--primary-color);
    }
  }
</style>
