<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import Modal from 'components/Modal.svelte'
  import Icon from 'assets/icons/mic-mute.svg'

  const dispatch = createEventDispatcher<{ confirm: boolean; close: void }>()

  export let active: boolean
</script>

<Modal size="small" title="" {active} onClose={() => dispatch('close')} noClose={true}>
  <div class="root">
    <div class="icon"><Icon /></div>
    <h1>No Audio Detected</h1>
    <p>
      Looks like you’re not connected to the microphone<br />
      Switch to another device
    </p>
    <div class="btns">
      <div class="btn" on:click={() => dispatch('confirm', true)}>Continue with Mute</div>
      <div class="btn red" on:click={() => dispatch('confirm', false)}>Switch</div>
    </div>
  </div>
</Modal>

<style lang="scss">
  .root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .icon {
    margin-top: 8px;
  }

  h1 {
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin: 16px 0;
  }

  p {
    text-align: center;
    font-size: 14px;
    margin: 0 0 24px;
  }

  .btns {
    width: calc(100% + 32px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 -16px -16px;
  }

  .btn {
    width: 50%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &.red {
      background: var(--primary-color);
      color: #fff;
    }
  }
</style>
