<script lang="ts">
  import { Video } from 'vendors/videokit'
  import Player from './Player.svelte'
  import PlayerControls from './PlayerControls.svelte'

  export let urls: string[] | undefined = undefined
  export let isHorizontal: boolean | undefined = undefined
  export let shouldPlay = false
  export let duration = 0
  export let currentTime = 0
  export let playbackRate = 1
  export let volume = 1

  let video: Video | undefined = undefined

  $: video = urls
    ? {
        id: 'local_video_id',
        mp4Urls: {},
        localVideoUrls: urls,
        toJSON: () => ({ id: 'local_video_id' }),
      }
    : undefined

  ; // prettier-ignore
</script>

{#if video}
  <Player
    {video}
    {playbackRate}
    loop={true}
    isHorizontal={isHorizontal ?? true}
    autoDetectHorizontal={isHorizontal === undefined}
    bind:duration
    bind:shouldPlay
    bind:volume
    bind:currentTime />
  <div class="preview-controls">
    <PlayerControls
      bind:currentTime
      bind:playbackRate
      bind:shouldPlay
      bind:volume
      {duration}
      hasSubtitles={false} />
  </div>
{/if}
