import App from './App.svelte'
import { IPC } from 'types'
import * as Sentry from '@sentry/svelte'
import { CaptureConsole } from '@sentry/integrations'
import { production, version } from './env'
import config from 'config'
import './app.scss'
import './vendors/videokit.css'

if (production) {
  Sentry.init({
    dsn: config.sentryDsn,
    release: `v${version}`,
    integrations: [new CaptureConsole({ levels: ['error'] }) as any],
    beforeSend(event, hint) {
      // Filter ReCaptrcha error (https://github.com/getsentry/sentry-javascript/issues/2514)
      if (hint?.originalException === 'Timeout') return null
      // Filter VideoKit API error logs
      if (event.message?.includes('API call')) return null
      return event
    },
  })
}

const app = new App({
  target: document.getElementById('app')!,
})

declare global {
  interface Window {
    ipc: IPC
    grecaptcha: any
  }
}

export default app
