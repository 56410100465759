<script lang="ts">
  import type { Thread, Community } from 'types'
  import { ModalType } from 'types'
  import { deleteAccount, addError, openModal } from 'actions'
  import { session, communities, threads } from 'stores'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'

  let confirmText = ''
  let deleting = false
  let userId = $session?.profile.id || ''

  $: canSubmit = confirmText.toUpperCase() === 'DELETE'
  $: userCommunities = $communities?.filter(c => c.roles[userId] === 'owner') || []
  $: userChannels = $threads?.filter(t => (t.channel?.roles || {})[userId] === 'owner') || []

  async function _deleteAccount() {
    if (!canSubmit) return addError('Please type "DELETE" to confirm')

    deleting = true
    try {
      await deleteAccount()
    } catch (e: any) {
      addError(e.message)
    }
    deleting = false
  }

  function openCommunity(community: Community) {
    openModal({
      type: ModalType.COMMUNITY_SETTINGS,
      arguments: { community },
    })
  }

  function openChannel(channel: Thread) {
    openModal({
      type: ModalType.CHANNEL_SETTINGS,
      arguments: { thread: channel },
    })
  }
</script>

<div class="root">
  <div class="info">
    <p>
      <b>This action cannot be undone</b>. This will permanently delete your account, all your
      messages, drafts, channels and communities where you are an owner, share links, AI-generated
      images and reports.
    </p>
    {#if userChannels.length > 0 || userCommunities.length > 0}
      <p>
        To prevent channels and communities where you are an owner from being deleted, you can
        assign a new owner to them before deleting your account.
      </p>
    {/if}
    {#if userCommunities.length > 0}
      <p><b>Communities:</b></p>
      <div class="list">
        {#each userCommunities as community}
          <div class="item" on:click={() => openCommunity(community)}>{community.title}</div>
        {/each}
      </div>
    {/if}
    {#if userChannels.length > 0}
      <p><b>Channels:</b></p>
      <div class="list">
        {#each userChannels as channel}
          <div class="item" on:click={() => openChannel(channel)}>{channel.title}</div>
        {/each}
      </div>
    {/if}
  </div>
  <div class="field">
    <p>Please type <b>DELETE</b> to confirm.</p>
    <Input bind:value={confirmText} label=" " />
  </div>
  <Button color="red" disabled={!canSubmit} loading={deleting} on:click={_deleteAccount}>
    I understand the consequences, delete my account
  </Button>
</div>

<style lang="scss">
  .root {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    :global(button) {
      height: auto;
    }
  }

  .info {
    flex: 1 1 auto;
    margin-bottom: 8px;

    :global(*:last-child) {
      margin-bottom: 8px;
    }
  }

  .field {
    flex: 30 30 auto;
  }

  p {
    margin: 0 0 16px;
  }

  .list {
    margin: -4px 0 16px;
  }

  .item {
    text-decoration: underline;
    cursor: pointer;
  }
</style>
