<script lang="ts">
  import { imask } from '@imask/svelte'
  import Spinner from './Spinner.svelte'

  type Action = {
    name: string
    fn: () => void
  }

  export let value: string | number | undefined
  export let error = false
  export let disabled = false
  export let loading = false
  export let name = ''
  export let type = 'text'
  export let step: string | undefined = undefined
  export let min: string | undefined = undefined
  export let max: string | undefined = undefined
  export let mask = ''
  export let placeholder = ''
  export let label = ''
  export let textarea = false
  export let action: Action | undefined = undefined
  export const focus = (): void => element?.focus()
  export let element: HTMLInputElement | HTMLTextAreaElement | undefined = undefined

  function handleInput(e: Event) {
    updateValue(e.target as HTMLInputElement)
    mask && setTimeout(() => updateValue(e.target as HTMLInputElement))
  }

  function updateValue(el: HTMLInputElement) {
    const { value: newValue } = el
    value = newValue ? (type.match(/^(number|range)$/) ? +newValue : newValue) : ''
  }

  function handleKeyPress(e: KeyboardEvent) {
    if (e.code.toLowerCase() === 'enter' && action) {
      action.fn()
    }
  }
</script>

<div class="input" class:with-label={!!label} class:disabled>
  {#if label}
    <label for={name}>{label}</label>
  {/if}
  {#if textarea}
    <textarea
      bind:this={element}
      class:error
      use:imask={mask ? { mask } : undefined}
      {type}
      {name}
      {placeholder}
      {value}
      {step}
      {max}
      {min}
      {disabled}
      on:input={handleInput}
      on:keypress={handleKeyPress} />
  {:else}
    <input
      bind:this={element}
      class:error
      use:imask={mask ? { mask } : undefined}
      {type}
      {name}
      {placeholder}
      {value}
      {step}
      {max}
      {min}
      {disabled}
      on:input={handleInput}
      on:keypress={handleKeyPress} />
  {/if}
  {#if action}
    <button class="action" on:click={action.fn}>{action.name}</button>
  {/if}
  {#if loading}
    <div class="spinner"><Spinner /></div>
  {/if}
</div>

<style lang="scss">
  .input {
    position: relative;
  }

  input,
  textarea {
    height: 32px;
    border: none;
    border-bottom: 1px solid #cacaca;
    display: block;
    width: 100%;
    outline: none;
    box-sizing: border-box;
    transition: border-bottom-color 0.2s ease;
    padding: 6px 0;
    color: #000;
    font: 15px/20px var(--main-font);

    &:-webkit-autofill::first-line {
      font: 15px/20px var(--main-font);
    }

    &::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    &:focus {
      border-bottom-color: var(--primary-color);
      border-bottom-width: 2px;
      padding-bottom: 5px;
    }

    &:disabled {
      background: none;
    }

    &:not([type='number']) {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  textarea {
    height: 84px;
  }

  .with-label {
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 6px 12px;

    &.disabled {
      background: transparent;
      border-color: transparent;
    }

    > label {
      font-weight: 600;
    }

    > input,
    > textarea {
      border: none;
      background: none;
      padding-top: 1px;
      padding-bottom: 1px;
      height: 22px;
    }

    > textarea {
      height: 72px;
    }
  }

  .action {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.1);
    color: #000;
    padding: 5px 8px;
    border: none;
    border-radius: 6px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    cursor: pointer;
  }

  .spinner {
    position: absolute;
    bottom: 4px;
    right: 8px;
  }
</style>
