<script lang="ts">
  import type { Community, Thread, User } from 'types'
  import { ModalType } from 'types'
  import { onDestroy, createEventDispatcher } from 'svelte'
  import {
    loadCommunityChannel,
    joinThread,
    leaveThread,
    joinCommunity,
    leaveCommunity,
    goToThread,
    addError,
    openModal,
  } from 'actions'
  import Spinner from 'components/Spinner.svelte'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'
  import Button from 'components/Button.svelte'
  import Tooltip from 'components/Tooltip.svelte'
  import { session, threads, communities } from 'stores'

  const dispatch = createEventDispatcher<{ close: undefined }>()

  export let community: Community | undefined = undefined

  let isLoadingChannels = false
  let channels: Thread[] | undefined
  let publicChannels: Thread[] | undefined
  let admins: User[] | undefined
  let pendingInvitationAction: string | undefined
  const threadsLoadingMap: { [key: string]: boolean } = {}
  const unsubscribes: (() => void)[] = []

  $: loadChannel(community)
  $: mergeChannels(publicChannels)
  $: _community = $communities.find(c => c.id === community?.id)

  unsubscribes.push(threads.subscribe(threads => mergeChannels(publicChannels, threads || [])))

  async function loadChannel(community: Community | undefined) {
    if (community && !publicChannels) {
      isLoadingChannels = true
      try {
        publicChannels = await loadCommunityChannel(community)
      } catch (e) {
        addError(e)
      }
      isLoadingChannels = false
    } else {
      publicChannels = undefined
    }

    admins = community?.members
      ? community.members.filter(p => ['admin', 'owner'].indexOf(community.roles[p.id]) !== -1)
      : []
  }

  function mergeChannels(publicChannels?: Thread[], allThreads?: Thread[]) {
    if (!publicChannels || !community) {
      channels = []
    } else {
      channels = [
        ...publicChannels,
        ...(allThreads || threads.get() || [])?.filter(
          t => t.communityId === community!.id && !publicChannels.find(c => c.id !== t.id),
        ),
      ].sort((l, r) => (l.modifiedAt > r.modifiedAt ? -1 : 1))
    }
  }

  function isMember(thread: Thread): boolean {
    return !!threads.get()?.find(t => t.id === thread.id)
  }

  async function joinChannel(thread: Thread) {
    threadsLoadingMap[thread.id] = true
    try {
      const updatedThread = await joinThread(thread.id)
      publicChannels = publicChannels?.map(c => (c.id === updatedThread.id ? updatedThread : c))
    } catch (e) {
      addError(e)
    }
    threadsLoadingMap[thread.id] = false
  }

  async function leaveChannel(thread: Thread) {
    const userId = session.get()?.profile.id

    threadsLoadingMap[thread.id] = true
    try {
      await leaveThread(thread.id)
      publicChannels = publicChannels?.map(c => ({
        ...c,
        members: c.members.filter(p => p.id !== userId),
      }))
    } catch (e) {
      addError(e)
    }
    threadsLoadingMap[thread.id] = false
  }

  function channelClicked(channel: Thread) {
    if (isMember(channel)) {
      goToThread(channel)
      dispatch('close')
    }
  }

  function createChannel() {
    if (session.get()?.profile.isTrusted) {
      openModal({
        type: ModalType.CHANNEL_SETTINGS,
        arguments: { community },
        onDone: (thread: Thread) => thread && goToThread(thread),
      })
    } else {
      openModal({
        type: ModalType.TRUSTED_USER_ONLY,
        arguments: { action: 'create channels' },
      })
    }
  }

  async function resolveInvitation(action: 'accept' | 'decline' | 'report') {
    if (!community) return

    pendingInvitationAction = action

    try {
      if (action === 'accept') {
        await joinCommunity(community.id)
      } else if (action === 'decline') {
        await leaveCommunity(community.id)
        dispatch('close')
      } else if (action === 'report') {
        if (session.get()?.profile.isTrusted) {
          openModal({
            type: ModalType.CREATE_REPORT,
            arguments: { community },
          })
        } else {
          openModal({
            type: ModalType.TRUSTED_USER_ONLY,
            arguments: { action: 'report' },
          })
        }
      }
    } catch (e) {
      addError(e)
    }

    pendingInvitationAction = undefined
  }

  onDestroy(() => unsubscribes.forEach(un => un()))

  ; // prettier-ignore
</script>

<div class="profile">
  <div class="section center">
    {#if _community?.imageURL}
      <img
        class="image"
        src={_community.imageURL}
        alt={`"${_community.title}" community image`}
        referrerpolicy="no-referrer" />
    {/if}
    <h1>{_community?.title}</h1>
    {#if _community?.membersCount}
      <div class="members-count">{_community?.membersCount} members</div>
    {/if}
  </div>
  {#if _community?.needToAcceptInvite}
    <div class="section">
      You have been invited to join a community
      <div class="accept-btns">
        <Button
          color="green"
          on:click={() => resolveInvitation('accept')}
          loading={pendingInvitationAction === 'accept'}>Accept</Button>
        <Button
          color="red"
          on:click={() => resolveInvitation('decline')}
          loading={pendingInvitationAction === 'decline'}>Decline</Button>
        <Button
          on:click={() => resolveInvitation('report')}
          loading={pendingInvitationAction === 'report'}>Report</Button>
      </div>
    </div>
  {/if}
  <div class="section">
    <h2>
      Channels
      <div class="plus-btn" on:click={createChannel}>
        <Tooltip title="Create Channel in Community" position="left" />
      </div>
    </h2>
    {#if isLoadingChannels}
      <Spinner color="#000" />
    {:else if channels}
      <div class="channels scroll-box">
        {#each channels as channel}
          <div
            class="channel"
            class:joined={isMember(channel)}
            on:click={() => channelClicked(channel)}>
            # {channel.title}
            {#if isMember(channel)}
              <Button
                color="red"
                loading={threadsLoadingMap[channel.id]}
                on:click={() => leaveChannel(channel)}>
                Leave
              </Button>
            {:else}
              <Button loading={threadsLoadingMap[channel.id]} on:click={() => joinChannel(channel)}>
                Join
              </Button>
            {/if}
            <div class="info">
              {#if isMember(channel)}
                <span class="status">Joined</span>
              {/if}
              {#if channel.channel?.isPublic === false}
                <span class="private">Private</span>
              {/if}
              <span>{channel.membersCount} members</span>
            </div>
          </div>
        {/each}
      </div>
    {/if}
  </div>
  <div class="section">
    <h2>Admins</h2>
    {#if admins}
      <div class="users scroll-box">
        {#each admins as user}
          <div class="user">
            <ContactPointBadge contactPoint={{ user }} />
          </div>
        {/each}
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .profile {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.85);
    max-height: calc(100vh - 40px);
    font-size: 15px;
    color: #000;
    line-height: 1.2;
  }

  .section {
    padding: 16px;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &.center {
      text-align: center;
    }

    > :global(.spinner) {
      display: block;
      margin: 0 auto;
    }
  }

  .scroll-box {
    max-height: 192px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .image {
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
    object-fit: fill;
  }

  h1 {
    font-size: 15px;
    margin: 5px 0;

    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 2px solid;
      margin: 0 4px;
      box-sizing: border-box;
    }
  }

  h2 {
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
  }

  .members-count {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }

  .accept-btns {
    display: flex;
    margin: 16px -8px 0;

    > :global(*) {
      margin: 0 8px;
    }
  }

  .plus-btn {
    position: relative;
    width: 19px;
    height: 19px;
    float: right;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background: #000;
    }

    &::before {
      top: 4px;
      left: 9px;
      bottom: 4px;
      width: 1px;
    }

    &::after {
      left: 4px;
      top: 9px;
      right: 4px;
      height: 1px;
    }
  }

  .channel {
    position: relative;
    padding: 8px 12px;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    border-radius: 6px;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);

      :global(button) {
        opacity: 1;
      }
    }

    &.joined {
      cursor: pointer;
    }

    :global(button) {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 80px;
      margin: 0;
      height: 32px;
      opacity: 0;
    }

    :global(button:not(.red)) {
      background: rgba(0, 0, 0, 0.1);
    }

    .info {
      width: 100%;
      overflow: hidden;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }

    .status {
      color: green;
    }

    .status,
    .private {
      &::after {
        content: '\02022';
        color: rgba(0, 0, 0, 0.5);
        margin: 0 4px;
      }
    }

    .private::before {
      content: '';
      width: 12px;
      height: 12px;
      display: inline-block;
      background: url('assets/icons/lock.svg') 0 0 no-repeat;
      background-size: 12px;
      margin: 1px 2px 0 -2px;
      vertical-align: top;
      opacity: 0.5;
    }
  }

  .user {
    padding: 8px 12px;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
  }
</style>
