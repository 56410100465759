import store from './store'

let storedDevices: string[] = []
try {
  storedDevices = JSON.parse(localStorage.getItem('devices') || '')
} catch (e) {}

export const devices = store<string[]>(storedDevices)

devices.subscribe(value => localStorage.setItem('devices', value ? JSON.stringify(value) : ''))
