<script lang="ts">
  import type { Draft, ContactPoint } from 'types'
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import PrevBtnIcon from 'assets/icons/prev-btn.svg'
  import NextBtnIcon from 'assets/icons/next-btn.svg'
  import SendIcon from 'assets/icons/record-send.svg'
  import RestartIcon from 'assets/icons/restart.svg'
  import TrashIcon from 'assets/icons/record-trash.svg'
  import Tooltip from 'components/Tooltip.svelte'
  import Spinner from 'components/Spinner.svelte'
  import Navigation from 'components/Navigation.svelte'
  import Player from 'components/Player.svelte'
  import PlayerControls from 'components/PlayerControls.svelte'
  import { loadDrafts, sendDraft, updateDraft, deleteDraft } from 'actions/drafts'
  import { goToDraft, goToMessage, goToNewMessage } from 'actions/router'
  import { addError, addConfirm } from 'actions/alerts'
  import {
    settings,
    playerSettings,
    threads,
    drafts,
    noMoreDrafts,
    currentDraft,
    currentDraftId,
    videos,
  } from 'stores'
  import {
    getContactPointsKey,
    convertVideoToVKVideo,
    extractRecipientsFromDraft,
    timeout,
  } from 'utils'
  import { setShouldPlay, setThumbsActive } from 'actions/settings'
  import Thumbs from '../components/Thumbs.svelte'
  import RecipientsField from '../components/RecipientsField.svelte'
  import Recorder from '../components/Recorder/Recorder.svelte'

  export let params: { draftId?: string } = {}

  let noDrafts = false
  let loadingDrafts = false
  let draftsRequest: Promise<void>
  let sendRequest: Promise<void>
  let autoSaveRequest: Promise<any>
  let error: string | undefined
  let nextDraft: Draft | undefined
  let prevDraft: Draft | undefined
  let currentTime = 0
  let duration = 0
  let currentRatio = 16 / 9
  let siblingWidth = 0
  let isRecipientInitialized = true
  let recipients: ContactPoint[] = []
  const unsubscribes: (() => void)[] = []

  $: params.draftId !== currentDraftId.get() && currentDraftId.set(params.draftId)
  $: autoSaveRecipients(recipients)

  if ((drafts.get() || []).length === 0) {
    loadMoreDrafts()
  }
  currentDraftId.set(params.draftId)
  window.addEventListener('resize', updateSiblingWidth)

  unsubscribes.push(
    currentDraftId.subscribe(() => {
      isRecipientInitialized = false
      setShouldPlay(true)
    }),
  )
  unsubscribes.push(threads.subscribe(() => initRecipients()))
  unsubscribes.push(
    drafts.subscribe(drafts => {
      const draftId = currentDraftId.get()

      if (drafts && drafts.length > 0 && (!draftId || !drafts.find(t => t.id === draftId))) {
        if (!draftId || noMoreDrafts.get()) {
          goToDraft(drafts[0], true)
        } else {
          ;(draftsRequest || Promise.resolve()).then(loadMoreDrafts)
        }
      }

      noDrafts = drafts.length === 0
    }),
  )

  unsubscribes.push(
    currentDraft.subscribe(currentDraft => {
      const _drafts = drafts.get()

      duration = currentDraft ? (videos.get() || {})[currentDraft.video.id]?.duration || 0 : 0

      if (currentDraft && _drafts) {
        const currentIndex = _drafts.findIndex(draft => draft.id === currentDraft.id)

        nextDraft = currentIndex < _drafts.length - 1 ? _drafts[currentIndex + 1] : undefined
        prevDraft = currentIndex > 0 ? _drafts[currentIndex - 1] : undefined
        currentRatio = (currentDraft?.video?.width || 16) / (currentDraft?.video?.height || 9)

        initRecipients(currentDraft)
        updateSiblings(currentDraft)
      }
    }),
  )

  function loadMoreDrafts() {
    if (loadingDrafts || noMoreDrafts.get()) return
    loadingDrafts = true
    draftsRequest = loadDrafts()
      .catch(addError)
      .finally(() => {
        loadingDrafts = false
      })
  }

  async function autoSaveRecipients(recipients: ContactPoint[]) {
    const draft = get(currentDraft)

    if (
      draft &&
      isRecipientInitialized &&
      getContactPointsKey(recipients) !== getContactPointsKey(getRecipients(draft))
    ) {
      autoSaveRequest = Promise.all([updateDraft(draft, recipients), timeout(500)]).catch(addError)
    }
  }

  function initRecipients(draft?: Draft) {
    draft = draft || get(currentDraft)

    if (isRecipientInitialized || !draft) return

    recipients = getRecipients(draft)
    isRecipientInitialized =
      recipients.length === Object.values(draft.recipients || {}).flatMap(v => v).length
  }

  function getRecipients(draft?: Draft): ContactPoint[] {
    return draft ? extractRecipientsFromDraft(draft, threads.get() || []) : []
  }

  function _goToDraft(draftId: string) {
    const draft = drafts.get()?.find(draft => draft.id === draftId)
    draft && goToDraft(draft)
  }

  function updateSiblings(draft?: Draft) {
    const _drafts = drafts.get()
    draft = draft || get(currentDraft)

    if (draft && _drafts) {
      const currentIndex = _drafts.findIndex(item => item.id === draft!.id)

      nextDraft = _drafts[Math.min(_drafts.length - 1, currentIndex + 1)]
      prevDraft = _drafts[Math.max(0, currentIndex - 1)]
    } else {
      prevDraft = undefined
      nextDraft = undefined
    }

    updateSiblingWidth()
  }

  function updateSiblingWidth() {
    if (currentRatio > 1) {
      siblingWidth = 0
    } else {
      siblingWidth = (window.innerWidth - window.innerHeight * currentRatio) / 2
    }
  }

  async function send() {
    const draft = get(currentDraft)
    if (!draft || sendRequest) return

    if (Object.keys(draft.recipients || {}).length === 0 && recipients.length === 0) {
      return addConfirm('Please select a recipient')
    }

    sendRequest = sendDraft(draft, recipients)
      .then(message => {
        const thread = threads.get()?.find(t => t.id === message.threadId)
        thread && goToMessage(thread, message)
      })
      .catch(addError)
  }

  function restart() {
    const draftId = currentDraftId.get()
    draftId && goToNewMessage(undefined, draftId)
  }

  async function deleteCurrentDraft() {
    const draft = get(currentDraft)
    if (!draft) return

    if (await addConfirm('Are you sure you want to delete this draft?', 'Delete')) {
      const index = drafts.get()?.findIndex(d => d.id === draft.id) || 0

      try {
        await deleteDraft(draft.id)
      } catch (e: any) {
        error = e.message || e.error
      }

      const _drafts = drafts.get()
      goToDraft(_drafts ? _drafts[Math.min(index, _drafts.length - 1)] : undefined)
    }
  }

  onDestroy(() => {
    unsubscribes.forEach(un => un())
    currentDraftId.set(undefined)
    window.removeEventListener('resize', updateSiblingWidth)
  })

  ; // prettier-ignore
</script>

<section class="drafts">
  {#if $currentDraft}
    <Navigation>
      <RecipientsField bind:recipients />
      <div class="saving">
        {#await autoSaveRequest}
          <Spinner color="#fff" />
        {/await}
      </div>
    </Navigation>
    <div class="draft">
      <div class="player">
        <Player
          bind:currentTime
          bind:duration
          bind:shouldPlay={$playerSettings.shouldPlay}
          bind:showSubtitles={$playerSettings.showSubtitles}
          bind:playbackRate={$playerSettings.playbackRate}
          bind:volume={$playerSettings.volume}
          bind:muted={$playerSettings.muted}
          on:ended={() => {
            currentTime = 0
          }}
          isHorizontal={currentRatio > 1}
          videoId={$currentDraft.video.id}
          videos={$drafts?.map(d => convertVideoToVKVideo(d.video))} />
        {#if currentRatio < 1}
          {#if prevDraft && prevDraft.thumbnailURL}
            <div
              class="sibling prev"
              class:blurred={prevDraft.id === $currentDraft.id}
              style={`width: ${siblingWidth}px;`}
              on:click={goToDraft.bind(null, prevDraft, false)}>
              <img src={prevDraft.thumbnailURL} alt="" />
            </div>
          {/if}
          {#if nextDraft && nextDraft.thumbnailURL}
            <div
              class="sibling next"
              class:blurred={nextDraft.id === $currentDraft.id}
              style={`width: ${siblingWidth}px;`}
              on:click={goToDraft.bind(null, nextDraft, false)}>
              <img src={nextDraft.thumbnailURL} alt="" />
            </div>
          {/if}
        {/if}
      </div>
      <div
        class="controls"
        on:mouseenter={() =>
          settings.get()?.isThumbsActive && setTimeout(() => setThumbsActive(true))}
        on:mouseleave={() => settings.get()?.isThumbsActive && setThumbsActive(false)}>
        <PlayerControls
          bind:currentTime
          bind:shouldPlay={$playerSettings.shouldPlay}
          bind:showSubtitles={$playerSettings.showSubtitles}
          bind:playbackRate={$playerSettings.playbackRate}
          bind:volume={$playerSettings.volume}
          bind:muted={$playerSettings.muted}
          {duration} />
        <button class="button red" on:click={send}>
          {#await sendRequest}
            <Spinner color="#fff" />
          {:then}
            <SendIcon />
          {/await}
          <Tooltip title="Send" />
        </button>
        <button class="button" on:click={restart}>
          <RestartIcon />
          <Tooltip title="Restart Recording" />
        </button>
        <div class="button" on:click={deleteCurrentDraft}>
          <TrashIcon />
          <Tooltip title="Delete Recording" align="right" />
        </div>
      </div>
    </div>
  {/if}
  {#if loadingDrafts && !$currentDraft}
    <div class="spinner">
      <Spinner size="large" color="#000" />
    </div>
  {:else if noDrafts}
    <Recorder />
  {/if}
  {#if prevDraft}
    <div class="draft-arrow-screen" />
    <div class="draft-arrow prev" on:click={() => goToDraft(prevDraft)}>
      <PrevBtnIcon />
    </div>
  {/if}
  {#if nextDraft}
    <div class="draft-arrow next" on:click={() => goToDraft(nextDraft)}>
      <NextBtnIcon />
    </div>
  {/if}
  {#if $drafts && $drafts.length > 0}
    <Thumbs
      thumbs={$drafts.map(d => ({ ...d, type: 'video' }))}
      activeThumbId={$currentDraftId}
      loadingPrevious={loadingDrafts}
      on:activeChanged={({ detail }) => _goToDraft(detail)}
      on:reachedStart={loadMoreDrafts} />
  {/if}
  {#if error}
    <div class="error">{error}</div>
  {/if}
</section>

<style lang="scss">
  .drafts {
    width: 100%;
    height: 100%;

    :global(.navigation .recipients) {
      float: left;
    }
  }

  .draft-arrow {
    position: absolute;
    top: 50%;
    width: 37px;
    height: 63px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;

    &.prev {
      left: 16px;
    }

    &.next {
      right: 16px;
    }
  }

  .draft-arrow-screen {
    position: absolute;
    top: 50%;
    width: 0;
    height: 80px;
    z-index: 1;
    transform: translateY(-50%);
    transition: step-end width 0.3s;

    :global(.inactive-sidebar) & {
      width: 220px;
    }
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .saving {
    float: left;
    margin: 4px 8px;
  }

  .draft {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
  }

  .player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    :global(.windows) & {
      top: 24px;
    }
  }

  .sibling {
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -20px;
      box-shadow: inset 0 0 20px 0 #000;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
    }

    &.blurred {
      filter: blur(10px);
      cursor: default;
    }

    &.prev {
      left: 0;

      &::before {
        left: -20px;
        right: 0;
      }

      img {
        object-position: right center;
      }
    }

    &.next {
      right: 0;

      &::before {
        left: 0;
        right: -20px;
      }

      img {
        object-position: left center;
      }
    }
  }

  .controls {
    display: flex;
    position: absolute;
    right: 12px;
    bottom: 38px;
    z-index: 3;
    transform: translateY(0);
    transition: transform var(--thumbs-transition-duration) ease,
      opacity var(--thumbs-transition-duration) ease;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      top: -28px;
      left: -20px;
      right: -20px;
      bottom: 0;
      z-index: -1;
    }

    > * {
      margin-left: 16px;
    }

    :global(.active-thumbs) & {
      transform: translateY(-90px);
    }
  }

  .button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 100%;
    margin: 0 0 0 12px;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    user-select: none;
    flex: 0 0 auto;
    transition: background 0.5s ease;

    :global(.tooltip > *) {
      margin-bottom: 5px;
    }

    & > :global(svg) {
      transition: background 0.5s ease;
    }

    &.red {
      background-color: #e30000;
      width: 42px;
      height: 42px;
      margin: 0 6px 0 18px;

      :global(.tooltip > *) {
        margin-bottom: 8px;
      }

      &::before {
        content: '';
        position: absolute;
        top: -7px;
        right: -7px;
        bottom: -7px;
        left: -7px;
        border: 4px solid #e30000;
        border-radius: 100%;
      }
    }
  }
</style>
