import type { Session } from 'types'
import { session as sessionStore, online } from 'stores'
import config from 'config'

class APIError extends Error {
  status: number
  code?: string
  details?: { [key: string]: any }

  constructor(message: string, status = 500, code?: string, details?: { [key: string]: any }) {
    super(message)
    this.code = code
    this.status = status
    this.details = details
  }
}

type Data = { [key: string]: any }

const ENPOINTS_WITH_401 = ['/auth/password']

let refreshTokenRequest: Promise<void> | null = null

export async function api<T>(
  url: string,
  method = 'GET',
  data?: Data,
  rawData?: Blob,
  authToken?: string,
): Promise<T> {
  if (!url.includes('http')) {
    url = `${config.apiUrl}${url}`
  }
  const token = authToken || sessionStore.get()?.authToken
  const response = await fetch(url, {
    method,
    headers: {
      ...(data && { 'Content-Type': 'application/json' }),
      ...(rawData && { 'Content-Type': 'application/octet-stream' }),
      ...(token && { Authorization: `Bearer ${token}` }),
    },
    body: data ? JSON.stringify(data) : rawData || undefined,
  })
  const isRefreshTokenRequest = /^\/auth\/refresh/.test(url)

  if (response.status >= 200 && response.status < 400) {
    return response.status === 204 ? undefined : await response.json()
  } else {
    if (
      !isRefreshTokenRequest &&
      response.status === 401 &&
      ENPOINTS_WITH_401.indexOf(url) === -1
    ) {
      const session = sessionStore.get()

      // Trying to refresh token is we credentials for that
      if (session?.refreshToken) {
        try {
          await refreshToken(session.refreshToken)
          // Re-request with fresh session
          return api<T>(url, method, data)
        } catch (e) {}
      } else {
        console.error(
          `No refresh token, session: ${JSON.stringify(
            sessionStore.get(),
          )}, localStorage: ${localStorage.getItem('session')}`,
        )
        sessionStore.set(null)
      }
    }

    let json: any = { message: 'Something went wrong' }

    try {
      json = await response.json()
    } catch (e) {}

    throw new APIError(json.message, response.status || 500, json.code, json.details)
  }
}

export async function get<T>(url: string, data?: Data): Promise<T> {
  return api(url, 'GET', data)
}

export async function post<T>(url: string, data?: Data): Promise<T> {
  return api(url, 'POST', data)
}

export async function put<T>(url: string, data?: Data): Promise<T> {
  return api(url, 'PUT', data)
}

export async function del<T>(url: string, data?: Data): Promise<T> {
  return api(url, 'DELETE', data)
}

export async function refreshToken(tokenInfo: Session['refreshToken']): Promise<void> {
  if (!refreshTokenRequest) {
    const onlinePromise = online.get()
      ? Promise.resolve()
      : new Promise<void>(resolve => online.subscribe(online => online && resolve()))

    refreshTokenRequest = onlinePromise
      .then(() =>
        post<Session>(`/auth/refresh/${tokenInfo.id}`, {
          refreshToken: tokenInfo.code,
        }),
      )
      .then(session => sessionStore.set(session))
      .catch(error => {
        const { status } = (error as APIError) || {}

        console.error(`Failed to refresh token: ${error}, online: ${online.get()}`, error)

        // Reset session only if we got 4xx error, assiming in all other cases we are offline or
        // something went wrong with the server
        if (status && status >= 400 && status < 500) {
          sessionStore.set(null)
        }
      })
      .finally(() => {
        refreshTokenRequest = null
      })
  }

  return refreshTokenRequest
}
