<script lang="ts">
  export let action = ''

  function openProfile() {
    // openModal({ type: ModalType.PROFILE })
  }
</script>

<div>
  <h1>Only trusted users can {action || 'perform this action'}.</h1>
  <p>
    Please, <span class="link" on:click={openProfile}>add</span> your phone number or
    <span class="link" on:click={openProfile}>connect</span> your apple account, to get trusted status
    and be able to:
  </p>
  <ul>
    <li>Send direct messages</li>
    <li>Create AI-generated images</li>
    <li>Create communities and channels</li>
    <li>Join public communities and channels</li>
    <li>Invite users to communities and channels</li>
    <li>Like messages</li>
  </ul>
</div>

<style lang="scss">
  div {
    padding: 8px;
    font-size: 16px;
  }

  h1 {
    text-align: center;
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
  }

  p {
    margin-bottom: 16px;
  }

  span {
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
  }
</style>
