<script lang="ts">
  import { onMount, onDestroy } from 'svelte'

  export let title: string | undefined = undefined
  export let disabled = false
  export let position: 'left' | 'right' | 'top' | 'bottom' = 'top'
  export let align: 'left' | 'right' | 'center' = 'center'

  let element: HTMLElement
  let active = false
  let hidden = true
  let hideTimeout: any
  const onMouseEnter = () => {
    hidden = false
    hideTimeout && clearTimeout(hideTimeout)
    setTimeout(() => {
      active = true
    }, 10)
  }
  const onMouseLeave = () => {
    active = false
    hideTimeout = setTimeout(() => {
      hidden = true
    }, 200)
  }

  onMount(() => {
    element?.parentElement?.addEventListener('mouseenter', onMouseEnter)
    element?.parentElement?.addEventListener('mouseleave', onMouseLeave)
  })

  onDestroy(() => {
    element?.parentElement?.removeEventListener('mouseenter', onMouseEnter)
    element?.parentElement?.removeEventListener('mouseleave', onMouseLeave)
  })

  ; // prettier-ignore
</script>

<div
  class={`tooltip ${position} align-${align}`}
  class:active={active && !disabled}
  class:hidden
  bind:this={element}>
  <div class="body">
    {#if title}
      {title}
    {:else}
      <slot />
    {/if}
  </div>
  <div class="arrow" />
</div>

<style lang="scss">
  $bg: rgba(0, 0, 0, 0.8);
  $bottomMargin: 4px;

  .tooltip {
    opacity: 0;
    transition: opacity 0.2s;
    --popupDistance: -8px;

    &.bottom,
    &.left {
      --popupDistance: 8px;
    }

    &.hidden {
      display: none;
    }

    &.active {
      opacity: 1;
    }
  }

  .body {
    position: absolute;
    left: 50%;
    bottom: calc(100% + #{$bottomMargin});
    background: $bg;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 13px;
    color: #fff;
    transform: translate(-50%, 0);
    transition: transform 0.2s;
    white-space: nowrap;
    z-index: 0;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      height: 0;
      width: 100%;
      transition: height 0.2s;
    }

    .bottom > & {
      bottom: auto;
      top: calc(100% + #{$bottomMargin});

      &:before {
        top: auto;
        bottom: 100%;
      }
    }

    .left > &,
    .right > & {
      top: 50%;
      bottom: auto;
      left: calc(100% + #{$bottomMargin});
      transform: translate(0, -50%);

      &:before {
        top: 0;
        left: -12px;
        width: 0;
        height: 100%;
        transition: width 0.2s;
      }
    }

    .active > & {
      transform: translate(-50%, var(--popupDistance));
      opacity: 1;
      z-index: 1;
      transition: transform 0.2s;

      &::before {
        height: 12px;
      }
    }

    .active.left > &,
    .active.right > & {
      transform: translate(var(--popupDistance), -50%);

      &:before {
        height: 100%;
        width: 12px;
      }
    }

    .right > & {
      left: auto;
      right: calc(100% + #{$bottomMargin});

      &:before {
        left: auto;
        right: 100%;
      }
    }

    .align-right > & {
      left: auto;
      right: -2px;
    }

    .align-left > & {
      left: 0;
    }

    .align-left > &,
    .align-right > & {
      transform: translate(0, 0);
    }

    .align-left.active > &,
    .align-right.active > & {
      transform: translate(0, var(--popupDistance));
    }
  }

  .arrow {
    position: absolute;
    bottom: calc(100% + #{$bottomMargin - 6px});
    left: 50%;
    transform: translate(-50%, 0);
    transition: transform 0.2s;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid $bg;

    .bottom > & {
      bottom: auto;
      border-top: none;
      border-bottom: 6px solid $bg;
      top: calc(100% + #{$bottomMargin - 6px});
    }

    .active > & {
      transform: translate(-50%, var(--popupDistance));
    }

    .left > &,
    .right > & {
      top: 50%;
      left: calc(100% + #{$bottomMargin - 6px});
      bottom: auto;
      border-style: solid;
      transform: translate(0, -50%);
    }

    .left > & {
      border-width: 6px 6px 6px 0;
      border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
    }

    .right > & {
      right: calc(100% + #{$bottomMargin - 6px});
      left: auto;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent rgba(0, 0, 0, 0.8);
    }

    .active.left > &,
    .active.right > & {
      transform: translate(var(--popupDistance), -50%);
    }
  }
</style>
