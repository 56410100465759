import { WindowSize, SidebarState, BoomAnimationInfo } from 'types'
import { settings, playerSettings } from 'stores'

let setSidebarHoveredTimeout: any

export function setSidebarState(sidebarState: SidebarState): void {
  settings.update(settings => ({ ...settings, sidebarState }))
}

export function setSidebarHovered(isSidebarHovered: boolean): void {
  settings.update(settings => ({ ...settings, isSidebarHovered }))
  isSidebarHovered && clearTimeout(setSidebarHoveredTimeout)
}

export function setThumbsActive(isThumbsActive: boolean): void {
  settings.update(settings => {
    // If the sidebar is hovered, it means that user moved the cursor from the sidebar to the
    // thumbs and we should sync the sidebar state with the thumbs state.
    if (settings.isSidebarHovered) {
      setSidebarHoveredTimeout = setTimeout(() => setSidebarHovered(isThumbsActive))
    }

    return { ...settings, isThumbsActive }
  })
}

export function toggleScreenRecordingMode(screenRecordingMode: boolean, displayId?: string): void {
  settings.update(settings => ({
    ...settings,
    screenRecordingMode,
  }))
  window.ipc?.send('RESIZE', {
    size: WindowSize[screenRecordingMode ? 'SCREEN_RECORDING' : 'FULLSCREEN'],
    displayId,
  })
}

export function setShouldPlay(shouldPlay: boolean): void {
  playerSettings.update(playerSettings => ({ ...playerSettings, shouldPlay }))
}

export function addBoomAnimation(info: BoomAnimationInfo): void {
  settings.update(settings => ({
    ...settings,
    boomAnimations: [...settings.boomAnimations, info],
  }))
}

export function removeBoomAnimation(info: BoomAnimationInfo): void {
  settings.update(settings => ({
    ...settings,
    boomAnimations: settings.boomAnimations.filter(i => i !== info),
  }))
}
