<script lang="ts">
  import type { Report, Message, User } from 'types'
  import { ModalType } from 'types'
  import { onDestroy } from 'svelte'
  import { get } from 'svelte/store'
  import { REPORT_REASON_TITLES } from 'config/constants'
  import PrevBtnIcon from 'assets/icons/prev-btn.svg'
  import NextBtnIcon from 'assets/icons/next-btn.svg'
  import Spinner from 'components/Spinner.svelte'
  import Navigation from 'components/Navigation.svelte'
  import Player from 'components/Player.svelte'
  import PlayerControls from 'components/PlayerControls.svelte'
  import { loadReports, approveReport, rejectReport } from 'actions/reports'
  import { goToReports, goToThread, goToCommunity } from 'actions/router'
  import { addError } from 'actions/alerts'
  import {
    settings,
    playerSettings,
    session,
    communities,
    threads,
    reportsToReview,
    reportsTotalCount,
    currentReport,
    currentReportId,
    noReplies,
    videos,
  } from 'stores'
  import { convertVideoToVKVideo } from 'utils'
  import { setShouldPlay, setThumbsActive, openModal } from 'actions'
  import BanIcon from 'assets/icons/ban.svg'
  import TimeIcon from 'assets/icons/time.svg'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'
  import Thumbs from '../components/Thumbs.svelte'
  import UserBadge from 'components/UserBadge.svelte'
  import TimeAgo from 'components/TimeAgo.svelte'

  export let params: { reportId?: string } = {}

  let loadingReports = false
  let reportsRequest: Promise<void>
  let approving = false
  let rejecting = false
  let error: string | undefined
  let nextReport: Report | undefined
  let prevReport: Report | undefined
  let currentTime = 0
  let duration = 0
  let currentRatio = 16 / 9
  let siblingWidth = 0
  let messages: Message[] = []
  const unsubscribes: (() => void)[] = []

  $: params.reportId !== currentReportId.get() && currentReportId.set(params.reportId)
  $: messages = $reportsToReview.map(report => report.message).filter(r => r) as Message[]
  $: thread = $threads?.find(thread => thread.id === $currentReport?.message?.threadId)
  $: community = $communities?.find(community => community.id === thread?.communityId)
  $: messageVideos = messages?.filter(m => m.video).map(m => convertVideoToVKVideo(m.video!))

  if ((reportsToReview.get() || []).length === 0) {
    loadMoreReports()
  }
  currentReportId.set(params.reportId)
  window.addEventListener('resize', updateSiblingWidth)

  unsubscribes.push(currentReportId.subscribe(() => setShouldPlay(true)))
  unsubscribes.push(
    reportsTotalCount.subscribe(count => {
      if (count > 0 && reportsToReview.get()?.length === 0) {
        loadMoreReports()
      }
    }),
  )
  unsubscribes.push(
    reportsToReview.subscribe(reports => {
      const reportId = currentReportId.get()

      if (reports && reports.length > 0 && (!reportId || !reports.find(t => t.id === reportId))) {
        if (!reportId || get(reportsTotalCount) === reports.length) {
          goToReports(reports[0], true)
        } else {
          ;(reportsRequest || Promise.resolve()).then(loadMoreReports)
        }
      }
    }),
  )

  unsubscribes.push(
    currentReport.subscribe(currentReport => {
      const _reports = reportsToReview.get()
      const { video } = currentReport?.message || {}

      duration = currentReport ? (videos.get() || {})[video?.id || '']?.duration || 0 : 0

      if (currentReport && _reports) {
        const currentIndex = _reports.findIndex(report => report.id === currentReport.id)

        nextReport = currentIndex < _reports.length - 1 ? _reports[currentIndex + 1] : undefined
        prevReport = currentIndex > 0 ? _reports[currentIndex - 1] : undefined
        currentRatio = (video?.width || 16) / (video?.height || 9)

        updateSiblingWidth()
      }
    }),
  )

  function loadMoreReports() {
    const count = get(reportsTotalCount) || -1
    if (loadingReports || count < 0 || count === reportsToReview.get()?.length) return
    loadingReports = true
    reportsRequest = loadReports()
      .catch(addError)
      .finally(() => {
        loadingReports = false
      })
  }

  function updateSiblingWidth() {
    if (currentRatio > 1) {
      siblingWidth = 0
    } else {
      siblingWidth = (window.innerWidth - window.innerHeight * currentRatio) / 2
    }
  }

  async function review(approved: boolean) {
    const report = get(currentReport)
    if (!report || approving || rejecting) return
    const index = reportsToReview.get()?.findIndex(r => r.id === report.id) || 0

    approving = approved
    rejecting = !approved

    try {
      approved ? await approveReport(report) : await rejectReport(report)
      const reports = reportsToReview.get() || []
      goToReports(reports[Math.max(0, Math.min(index, reports.length - 1))])
    } catch (e) {
      addError(e)
    }

    approving = false
    rejecting = false
  }

  function goToReport(messageId: string) {
    const report = reportsToReview.get()?.find(report => report.message?.id === messageId)
    report && goToReports(report)
  }

  function showUser(user: User) {
    if (user.id !== session.get()?.profile.id) {
      openModal({
        type: ModalType.USER_CARD,
        arguments: {
          user,
          threadToBan: thread,
        },
      })
    }
  }

  function banUser(mode: 'ban' | 'timeout') {
    const user = get(currentReport)?.message?.sender
    if (!thread || !user) return

    openModal({
      type: ModalType.BAN_USER,
      arguments: { mode, user, thread },
    })
  }

  onDestroy(() => {
    unsubscribes.forEach(un => un())
    currentReportId.set(undefined)
    window.removeEventListener('resize', updateSiblingWidth)
  })

  ; // prettier-ignore
</script>

<section class="reports">
  {#if $currentReport}
    <Navigation>
      <div class="path">
        {#if community}
          <div class="part" on:click={goToCommunity.bind(null, community, false)}>
            <ContactPointBadge contactPoint={{ community }} color="white" />
          </div>
          <div class="divider" />
        {/if}
        {#if thread}
          <div class="part" on:click={goToThread.bind(null, thread, false)}>
            <ContactPointBadge contactPoint={{ thread }} color="white" />
          </div>
          <div class="divider" />
        {/if}
        {#if $currentReport.message?.sender}
          <div class="part" on:click={showUser.bind(null, $currentReport.message.sender)}>
            <ContactPointBadge
              contactPoint={{ user: $currentReport.message?.sender }}
              color="white" />
          </div>
        {/if}
      </div>
    </Navigation>
    <div class="report">
      <div class="info">
        <div class="block">
          <h1>{($currentReport.totalCount || 0) > 1 ? 'Reports' : 'Report'}</h1>
          <div class="section">
            {#if $currentReport.creator}
              <div class="label">
                {($currentReport.totalCount || 0) > 1 ? 'Last reporter' : 'Reporter'}
              </div>
              <UserBadge
                user={$currentReport.creator}
                currentUser={$session?.profile}
                showExtraInfo={true}
                color="white">
                <span slot="extraInfo">
                  <TimeAgo date={$currentReport.createdAt} withTime={true} />
                </span>
              </UserBadge>
            {:else}
              <div class="label">Auto-generated report</div>
              <TimeAgo date={$currentReport.createdAt} withTime={true} />
            {/if}
          </div>
          {#if ($currentReport.totalCount || 0) > 1}
            <div class="section">
              <div class="label">Total number of reports</div>
              {$currentReport.totalCount || 1}
            </div>
          {/if}
          <div class="section">
            <div class="label">Reason</div>
            {REPORT_REASON_TITLES[$currentReport.reason]}
          </div>
          {#if $currentReport.comment}
            <div class="section">
              <div class="label">Comment</div>
              {$currentReport.comment}
            </div>
          {/if}
          <div class="btns">
            <button class="btn" on:click={() => review(false)}>
              {#if rejecting}
                <Spinner color="#fff" />
              {:else}
                Keep<br />message
              {/if}
            </button>
            <button class="btn red" on:click={() => review(true)}>
              {#if approving}
                <Spinner color="#fff" />
              {:else}
                Block message
              {/if}
            </button>
          </div>
        </div>
        {#if $currentReport.message && $currentReport.message.sender.id !== $session?.profile.id && thread}
          <div class="block">
            <h1>Message sender</h1>
            <div class="section">
              <UserBadge
                user={$currentReport.message?.sender}
                currentUser={$session?.profile}
                showExtraInfo={true}
                color="white" />
            </div>
            <div class="btns stack">
              <button class="btn red" on:click={() => banUser('timeout')}>
                <TimeIcon /> Timeout user in {thread.title}
              </button>
              <button class="btn red" on:click={() => banUser('ban')}>
                <BanIcon /> Ban user in {thread.title}
              </button>
            </div>
          </div>
        {/if}
      </div>
      <div class="player">
        <Player
          bind:currentTime
          bind:duration
          bind:shouldPlay={$playerSettings.shouldPlay}
          bind:showSubtitles={$playerSettings.showSubtitles}
          bind:playbackRate={$playerSettings.playbackRate}
          bind:volume={$playerSettings.volume}
          bind:muted={$playerSettings.muted}
          on:ended={() => (currentTime = 0)}
          isHorizontal={currentRatio > 1}
          videoId={$currentReport.message?.video?.id}
          videos={messageVideos} />
        {#if currentRatio < 1}
          {#if prevReport && prevReport.message?.thumbnailURL}
            <div
              class="sibling prev"
              class:blurred={prevReport.id === $currentReport.id}
              style={`width: ${siblingWidth}px;`}
              on:click={goToReports.bind(null, prevReport, false)}>
              <img src={prevReport.message?.thumbnailURL} alt="" />
            </div>
          {/if}
          {#if nextReport && nextReport.message?.thumbnailURL}
            <div
              class="sibling next"
              class:blurred={nextReport.id === $currentReport.id}
              style={`width: ${siblingWidth}px;`}
              on:click={goToReports.bind(null, nextReport, false)}>
              <img src={nextReport.message?.thumbnailURL} alt="" />
            </div>
          {/if}
        {/if}
      </div>
      {#if $currentReport.message?.type === 'image'}
        {#if $currentReport.message.thumbnailURL}
          <div class="image">
            <img
              src={$currentReport.message.thumbnailURL}
              alt={$currentReport.message.description} />
          </div>
        {/if}
        {#if $currentReport.message.description}
          <div class="description">{$currentReport.message.description}</div>
        {/if}
      {/if}
      <div
        class="controls"
        on:mouseenter={() =>
          settings.get()?.isThumbsActive && setTimeout(() => setThumbsActive(true))}
        on:mouseleave={() => settings.get()?.isThumbsActive && setThumbsActive(false)}>
        <PlayerControls
          bind:currentTime
          bind:shouldPlay={$playerSettings.shouldPlay}
          bind:showSubtitles={$playerSettings.showSubtitles}
          bind:playbackRate={$playerSettings.playbackRate}
          bind:volume={$playerSettings.volume}
          bind:muted={$playerSettings.muted}
          {duration} />
      </div>
    </div>
  {/if}
  {#if loadingReports && !$currentReport}
    <div class="spinner">
      <Spinner size="large" color="#000" />
    </div>
  {:else if $noReplies}
    <div class="error">No reports to review.</div>
  {/if}
  {#if prevReport}
    <div class="report-arrow-screen" />
    <div class="report-arrow prev" on:click={() => goToReports(prevReport)}>
      <PrevBtnIcon />
    </div>
  {/if}
  {#if nextReport}
    <div class="report-arrow next" on:click={() => goToReports(nextReport)}>
      <NextBtnIcon />
    </div>
  {/if}
  {#if $reportsToReview && $reportsToReview.length > 0}
    <Thumbs
      thumbs={messages}
      activeThumbId={$currentReport?.message?.id}
      loadingPrevious={loadingReports}
      disableNotWatched={true}
      on:activeChanged={({ detail }) => goToReport(detail)}
      on:reachedStart={loadMoreReports} />
  {/if}
  {#if error}
    <div class="error">{error}</div>
  {/if}
</section>

<style lang="scss">
  .reports {
    width: 100%;
    height: 100%;

    :global(.navigation .recipients) {
      float: left;
    }
  }

  .path {
    display: inline-flex;
    color: #fff;
    align-items: center;
    height: 28px;
    background: rgba(255, 255, 255, 0.1);
    padding: 0 12px 0 2px;
    border-radius: 28px;

    .part {
      cursor: pointer;
    }

    .divider {
      margin: 0 8px;
      color: #fff;

      &::before {
        content: '>';
      }
    }
  }

  .report-arrow {
    position: absolute;
    top: 50%;
    width: 37px;
    height: 63px;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;

    &.prev {
      left: 16px;
    }

    &.next {
      right: 16px;
    }
  }

  .report-arrow-screen {
    position: absolute;
    top: 50%;
    width: 0;
    height: 80px;
    z-index: 1;
    transform: translateY(-50%);
    transition: step-end width 0.3s;

    :global(.inactive-sidebar) & {
      width: 220px;
    }
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .report {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
  }

  .info {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 48px;
    bottom: 96px;
    right: 8px;
    z-index: 10;
    overflow-y: auto;
    min-width: 240px;
    max-width: 320px;
    transition: bottom var(--thumbs-transition-duration) ease;

    :global(.active-thumbs) & {
      bottom: 200px;
    }
  }

  .block {
    background: rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    color: #fff;
    margin-bottom: 16px;
    padding: 16px;

    h1 {
      font-size: 16px;
      margin: 0 0 12px;
    }

    .section {
      font-size: 16px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      .label {
        margin-bottom: 6px;
        font-size: 13px;
        opacity: 0.6;
      }
    }

    .btns {
      display: flex;
      margin: 0 -16px;
      padding: 16px 8px 0;
      border-top: 1px solid rgba(255, 255, 255, 0.2);

      &.stack {
        flex-direction: column;
        margin: 0 -16px -16px;
        padding: 8px 16px;

        .btn {
          width: 100%;
          margin: 8px 0;
        }
      }
    }

    .btn {
      display: flex;
      border: none;
      height: 40px;
      border-radius: 8px;
      width: 50%;
      margin: 0 8px;
      height: 40px;
      align-items: center;
      justify-content: center;
      background: green;
      color: #fff;
      cursor: pointer;
      font-size: 14px;

      & > :global(svg) {
        flex: 0 0 auto;
        margin-right: 8px;
      }

      &.red {
        background: var(--primary-color);
      }
    }
  }

  .player,
  .image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    :global(.windows) & {
      top: 24px;
    }
  }

  .image {
    background: #000;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .description {
    position: absolute;
    top: 60px;
    left: 24px;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 16px;
    color: #fff;
    font-size: 18px;
    line-height: 1.2;
    margin-top: 4px;
    border-radius: 10px;
  }

  .sibling {
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -20px;
      box-shadow: inset 0 0 20px 0 #000;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
    }

    &.blurred {
      filter: blur(10px);
      cursor: default;
    }

    &.prev {
      left: 0;

      &::before {
        left: -20px;
        right: 0;
      }

      img {
        object-position: right center;
      }
    }

    &.next {
      right: 0;

      &::before {
        left: 0;
        right: -20px;
      }

      img {
        object-position: left center;
      }
    }
  }

  .controls {
    display: flex;
    position: absolute;
    right: 12px;
    bottom: 38px;
    z-index: 3;
    transform: translateY(0);
    transition: transform var(--thumbs-transition-duration) ease,
      opacity var(--thumbs-transition-duration) ease;
    align-items: center;
    padding: 0 8px 0 0;

    &::before {
      content: '';
      position: absolute;
      top: -28px;
      left: -20px;
      right: -20px;
      bottom: 0;
      z-index: -1;
    }

    :global(.active-thumbs) & {
      transform: translateY(-90px);
    }
  }

  .error {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 348px;
    transform: translate(-50%, -50%);
    color: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
  }
</style>
