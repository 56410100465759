import { api } from './api'
import config from 'config'

type UploadInfo = {
  id: string
  url: string
}

export async function upload(
  data: Blob,
  assetId?: string,
  authToken?: string,
): Promise<UploadInfo> {
  const url = `${config.imgUrl}?mimeType=${data.type}${assetId ? `&id=${assetId}` : ''}`
  return api<UploadInfo>(url, 'POST', undefined, data, authToken)
}
