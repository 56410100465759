<script lang="ts">
  import type { Thread, ContactPoint, Community } from 'types'
  import { onDestroy, createEventDispatcher } from 'svelte'
  import Dropzone from 'svelte-file-dropzone'
  import {
    createChannel,
    updateChannel,
    addChannelMembers,
    removeChannelMembers,
    uploadChannelPhoto,
    addError,
  } from 'actions'
  import { isContactPointsEqual, mapContactPoints, contactPointToMember, convertImage } from 'utils'
  import { session, threads } from 'stores'
  import CameraIcon from 'assets/icons/photo-camera-blue.svg'
  import CloseIcon from 'assets/icons/cross.svg'
  import PlusIcon from 'assets/icons/plus.svg'
  import BackIcon from 'assets/icons/back.svg'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'
  import Select from 'components/Select.svelte'
  import Switch from 'components/Switch.svelte'
  import Spinner from 'components/Spinner.svelte'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'
  import ContactPointList from 'components/ContactPointList.svelte'

  export let thread: Thread | undefined = undefined
  export let community: Community | undefined = undefined
  export let reversedStepsOrder = false

  const dispatch = createEventDispatcher<{
    done: Thread
    close: undefined
  }>()

  $: currentUser = $session?.profile

  const isNew = thread === undefined
  let isPrivate = !(thread?.channel?.isPublic || false)
  const addVideoRightsItems = [
    {
      label: 'Anyone can add videos',
      value: 'anyone',
    },
    {
      label: 'Only members can add videos',
      value: 'members',
    },
    {
      label: 'Only admins and owner can add videos',
      value: 'admins',
    },
  ]
  let addVideoRights =
    addVideoRightsItems.find(i => i.value === thread?.channel?.addVideoRights) ||
    addVideoRightsItems[isPrivate ? 1 : 0]
  const addMembersRightsItems = [
    {
      label: 'All members can add members',
      value: 'members',
    },
    {
      label: 'Only admins and owner can add members',
      value: 'admins',
    },
  ]
  let addMembersRights =
    addMembersRightsItems.find(i => i.value === thread?.channel?.addMembersRights) ||
    addMembersRightsItems[0]
  let contactPoints: ContactPoint[] = thread
    ? thread.members.map(user => ({ user }))
    : [{ user: currentUser }]
  let dropzoneEl: HTMLDivElement
  let isMainScreen = !reversedStepsOrder
  let title = thread?.title || ''
  let isDragged = false
  let isPhotoUploading = false
  let isSaving = false
  let canCreate = false
  let canSave = false
  let error = ''
  let imageFile: Blob | undefined
  const unsubscribes: (() => void)[] = []

  $: imageURL = $threads?.find(t => thread?.id === t.id)?.imageURL || thread?.imageURL
  $: canCreate = isNew && !!title && contactPoints.length > 0
  $: {
    if (thread) {
      canSave =
        thread.title !== title ||
        thread.channel?.addVideoRights !== addVideoRights.value ||
        thread.channel?.addMembersRights !== addMembersRights.value ||
        thread.channel?.isPublic === isPrivate
    }
  }
  $: contactPoints = thread ? thread.members.map(user => ({ user })) : [{ user: currentUser }]
  $: isPrivate && addVideoRights.value === 'anyone' && (addVideoRights = addVideoRightsItems[1])

  async function handleFilesSelect(e: any) {
    const { acceptedFiles }: { acceptedFiles: File[] } = e.detail

    isDragged = false

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0]

      imageFile = await convertImage(file)

      if (thread) {
        isPhotoUploading = true
        try {
          await uploadChannelPhoto(thread.id, imageFile)
        } catch (e) {
          addError(e)
        }
        isPhotoUploading = false
      }
    }
  }

  function canRemoveContactPoint(member: ContactPoint) {
    return isNew
      ? member.user?.id !== currentUser?.id
      : (thread?.channel?.roles || {})[member.user?.id || ''] !== 'owner'
  }

  async function addContactPoint(contactPoint: ContactPoint) {
    if (!contactPoints.find(r => isContactPointsEqual(r, contactPoint))) {
      contactPoints = [...contactPoints, contactPoint]

      const member = contactPointToMember(contactPoint)
      if (thread && member) {
        try {
          await addChannelMembers(thread.id, [member])
        } catch (e) {
          addError(e)
          contactPoints = contactPoints.filter(r => !isContactPointsEqual(r, contactPoint))
        }
      }
    }
  }

  async function removeContactPoint(contactPoint: ContactPoint) {
    contactPoints = contactPoints.filter(r => !isContactPointsEqual(r, contactPoint))

    if (thread && contactPoint.user) {
      try {
        await removeChannelMembers(thread.id, [contactPoint.user.id])
      } catch (e) {
        addError(e)
        contactPoints = [...contactPoints, contactPoint]
      }
    }
  }

  function back() {
    if (reversedStepsOrder) {
      dispatch('close')
    } else {
      isMainScreen = true
    }
  }

  async function save() {
    isSaving = true
    error = ''
    try {
      if (isNew) {
        const thread = await createChannel(
          {
            title,
            communityId: community?.id,
            channel: {
              addVideoRights: addVideoRights.value as 'anyone' | 'members' | 'admins',
              addMembersRights: addMembersRights.value as 'members' | 'admins',
              isPublic: !isPrivate,
              roles: {
                ...(currentUser && { [currentUser?.id]: 'owner' }),
              },
            },
            members: mapContactPoints(contactPoints, true),
          },
          imageFile,
        )
        dispatch('done', thread)
      } else {
        isMainScreen = true

        if (thread) {
          await updateChannel(thread.id, {
            title,
            channel: {
              addVideoRights: addVideoRights.value as 'anyone' | 'members' | 'admins',
              addMembersRights: addMembersRights.value as 'members' | 'admins',
              isPublic: !isPrivate,
              roles: thread.channel!.roles,
            },
          })
        }
      }
    } catch (e: any) {
      error = e.message || e.error || e
    }
    isSaving = false
  }

  onDestroy(() => unsubscribes.forEach(un => un()))

  ; // prettier-ignore
</script>

<div class="settings" class:reversed={reversedStepsOrder} class:main={isMainScreen}>
  <div class="screens">
    <div class="screen">
      <div class="title">
        {#if reversedStepsOrder}
          <div
            class="back"
            on:click={() => {
              isMainScreen = false
            }}>
            <BackIcon />
          </div>
          <h1>Name this Channel</h1>
          <Button loading={isSaving} disabled={!canCreate} on:click={save}>Create</Button>
        {:else if isNew}
          <h1>
            Create {isPrivate ? 'Private' : 'Public'} Channel{#if community}&nbsp;in <span
                class="community">{community.title}</span
              >{/if}
          </h1>
        {:else}
          <h1>#{thread?.title}</h1>
        {/if}
      </div>
      <div class="photo" class:dragged={isDragged}>
        {#if imageURL}
          <div class="photo-image">
            <img src={imageURL} alt="" referrerpolicy="no-referrer" />
          </div>
        {/if}
        <div class="photo-dropzone" bind:this={dropzoneEl}>
          <Dropzone
            accept="image/png,image/jpg,image/jpeg,image/gif"
            disableDefaultStyles={true}
            multiple={false}
            on:dragenter={() => {
              isDragged = true
            }}
            on:dragleave={() => {
              isDragged = false
            }}
            on:drop={handleFilesSelect}>
            {#if !imageURL}
              <div class="photo-blank">
                <CameraIcon />
              </div>
            {/if}
          </Dropzone>
        </div>
        {#if isPhotoUploading}
          <div class="photo-spinner"><Spinner color="#fff" /></div>
        {/if}
      </div>
      <div class="photo-change" on:click={() => dropzoneEl.querySelector('input')?.click()}>
        {imageURL ? 'Change' : 'Add'} a channel photo
      </div>
      <div class="inputs">
        <Input bind:value={title} name="title" label=" " placeholder="Channel name (required)" />
        <Select
          items={addVideoRightsItems.filter(i => (isPrivate ? i.value !== 'anyone' : true))}
          bind:selected={addVideoRights} />
        {#if isPrivate}
          <Select items={addMembersRightsItems} bind:selected={addMembersRights} />
        {/if}
        <div class="public">
          <div>
            <h3>Make Private</h3>
            When a channel is set to private, it can<br />
            only be view or joined by invitation
          </div>
          <Switch bind:checked={isPrivate} />
        </div>
        <div class="members">
          <h3>Members</h3>
          {#each contactPoints as contactPoint}
            <div class="member">
              <ContactPointBadge size="large" showFullInfo={true} {contactPoint} {currentUser} />
              {#if canRemoveContactPoint(contactPoint)}
                <div class="remove-member" on:click={() => removeContactPoint(contactPoint)}>
                  <CloseIcon />
                </div>
              {/if}
            </div>
          {/each}
          <div class="member add" on:click={() => (isMainScreen = false)}>
            <PlusIcon />
            <div class="name">add members</div>
          </div>
        </div>
        <div class="error">{error}</div>
      </div>
    </div>
    <div class="screen">
      <div class="title">
        <div class="back" on:click={back}>
          <BackIcon />
        </div>
        <h1>Choose Members</h1>
        {#if reversedStepsOrder}
          <Button
            on:click={() => {
              isMainScreen = true
            }}>Next</Button>
        {/if}
      </div>
      <ContactPointList
        bind:contactPoints
        {addContactPoint}
        {canRemoveContactPoint}
        {removeContactPoint} />
    </div>
  </div>
  {#if !reversedStepsOrder}
    <div class="btns">
      {#if isNew}
        <Button loading={isSaving} disabled={!canCreate} on:click={save}>Create</Button>
      {:else}
        <Button loading={isSaving} disabled={!canSave} on:click={save}>Save</Button>
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .settings {
    border-radius: 12px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.85);
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    font-size: 15px;
    color: #000;
    line-height: 1.2;
  }

  .screens {
    height: 100%;
    width: 200%;
    display: flex;
    transition: transform 0.3s ease;

    .settings.reversed & {
      flex-direction: row-reverse;
    }

    .settings.main.reversed &,
    .settings:not(.main):not(.reversed) & {
      transform: translateX(-50%);
    }
  }

  .screen {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 32px 32px 16px;
    width: 50%;
  }

  .inputs {
    width: 100%;

    > :global(.input) {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 12px;
      background: rgba(255, 255, 255, 0.25);
    }

    > :global(*) {
      margin-bottom: 12px;
    }
  }

  .error {
    color: red;
  }

  .title {
    position: relative;
    display: flex;
    width: 100%;
    margin: -32px -32px 8px;
    padding: 10px 16px 16px;
    align-items: center;
    height: 28px;

    .settings.reversed & {
      padding-top: 4px;
      padding-bottom: 12px;

      :global(button) {
        top: 4px;
        right: -12px;
      }
    }

    .settings.main:not(.reversed) & {
      h1 {
        width: 100%;
        text-align: center;
      }
    }

    .settings:not(.reversed) & {
      :global(button) {
        right: 24px;
      }
    }

    h1 {
      font-size: 15px;
      margin: 0;

      .community {
        &::before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 100%;
          border: 2px solid;
          margin: 0 4px 0 0;
          box-sizing: border-box;
        }
      }
    }

    .back {
      position: relative;
      margin: 0 0 -4px -24px;
      cursor: pointer;
    }

    :global(button) {
      position: absolute;
      right: -4px;
      top: 10px;
      width: 80px;
      height: 28px;
      padding: 4px 8px;
      margin: 0;
      border-radius: 14px;
      box-sizing: border-box;
      background: var(--blue-color);
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      transition: background 0.2s;

      :global(.spinner div) {
        background-color: #fff;
      }

      &:disabled {
        background: #afafaf;
      }
    }
  }

  .photo {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .photo-dropzone,
  .photo-image,
  .photo-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .photo-dropzone {
    > :global(*) {
      width: 100%;
      height: 100%;
    }
  }

  .photo-spinner {
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .photo-change {
    padding: 6px 0 10px;
    color: var(--blue-color);
    text-align: center;
    font-size: 15px;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .photo-blank {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: 15px;
    font-size: 12px;
    font-weight: 600;
    color: #000;
    text-align: center;
    transition: background 0.3s ease;

    .photo.dragged & {
      background: rgba(0, 0, 0, 0.4);
    }
  }

  h3 {
    font-size: 15px;
    margin: 0;
  }

  .public {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding: 0 4px;
    margin: 16px 0;

    div {
      flex: 0 0 auto;
    }

    :global(.switch) {
      flex: 0 0 auto;
    }
  }

  .members {
    margin: 16px 0;
    padding: 0 4px;
  }

  .member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    &.add {
      cursor: pointer;

      :global(svg) {
        margin: 1px 7px 0px 2px;
      }

      .name {
        flex: 2 0 auto;
      }
    }
  }

  .btns {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin: -16px 32px 32px 32px;

    :global(button) {
      width: 128px;
      height: 40px;
      padding: 8px 12px;
      margin: 0;
      border-radius: 20px;
      box-sizing: border-box;
      background: var(--blue-color);
      font-size: 15px;
      font-weight: 600;
      line-height: 24px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      transition: background 0.2s;

      :global(.spinner div) {
        background-color: #fff;
      }

      &:disabled {
        background: #afafaf;
      }
    }
  }

  .remove-member {
    transform: scale(0.75);
    cursor: pointer;

    :global(svg path) {
      fill: black;
    }
  }
</style>
