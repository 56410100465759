import type { Community, Thread, Message, Draft, ContactPoint, Report } from 'types'
import * as router from 'svelte-spa-router'
import { get } from 'svelte/store'
import { lastWatchedMessages, threads as threadsStore } from 'stores'

export function goToRoot(): void {
  router.push('/feed')
}

export function goToAuth(): void {
  router.push('/auth')
}

export function clearQuery(paramsToClear?: string[], replace = false): void {
  const query = get(router.querystring)

  if (query) {
    let newQuery = ''

    if (paramsToClear) {
      const params = new URLSearchParams(query)

      paramsToClear.forEach(param => params.delete(param))
      newQuery = params.toString()
    }

    router[replace ? 'replace' : 'push'](`${get(router.location)}${newQuery ? '?' + newQuery : ''}`)
  }
}

export function goToCommunity(community: Community, replace = false): void {
  router[replace ? 'replace' : 'push'](
    `${isNewMessagePage() ? '/threads' : get(router.location)}?communityId=${community.id}`,
  )
}

export function goToThreads(replace = false): void {
  router[replace ? 'replace' : 'push']('/threads')
}

export function goToThread(thread: Thread, replace = false): void {
  let message: Message | undefined

  if (!thread.unwatchedMessagesCount && thread.messages) {
    const messageId = (lastWatchedMessages.get() || {})[thread.id]
    message = (messageId && thread.messages.find(m => m.id === messageId)) || thread.messages[0]

    if ((message?.repliesCount || 0) > 0) {
      message = undefined
    }
  }

  goToMessage(thread, message, undefined, replace)
}

export function goToMessage(
  thread: Thread,
  message?: Message,
  time?: number,
  replace = false,
): void {
  message && lastWatchedMessages.update(m => ({ ...m, [thread.id]: message.id }))
  router[replace ? 'replace' : 'push'](
    `/threads/${thread.id}${message ? `/messages/${message.repliedTo || message.id}` : ''}${
      message?.repliedTo ? `/${message.id}` : ''
    }${time ? `?t=${time}` : ''}`,
  )
}

export function goToNewMessage(
  contactPoint?: ContactPoint,
  draftId?: string,
  lastWatchedMessageId?: string,
  replace = false,
): void {
  let params = ''

  if (contactPoint?.thread) {
    const messageId = contactPoint!.message ? `&messageId=${contactPoint!.message.id}` : ''
    params = `threadId=${contactPoint!.thread.id}${messageId}`
  } else if (contactPoint?.user) {
    params = `userId=${contactPoint!.user.id}`
  } else if (draftId) {
    params = `draftId=${draftId}`
  } else if (lastWatchedMessageId) {
    params = `lastWatchedMessageId=${lastWatchedMessageId}`
  }

  router[replace ? 'replace' : 'push'](`/threads/new${params ? `?${params}` : ''}`)
}

export function goToAI(
  contactPoint?: ContactPoint,
  requestData?: {
    initImageUrl?: string
    prompt?: string
    steps?: number
    scale?: number
    seed?: number
  },
  replace = false,
): void {
  const params: string[] = []

  if (contactPoint?.thread) {
    params.push(`threadId=${contactPoint!.thread.id}`)
    contactPoint!.message && params.push(`messageId=${contactPoint!.message.id}`)
  } else if (contactPoint?.user) {
    params.push(`userId=${contactPoint!.user.id}`)
  }

  if (requestData) {
    if (requestData.initImageUrl) {
      params.push(`initImageUrl=${encodeURIComponent(requestData.initImageUrl)}`)
    }
    if (requestData.prompt) {
      params.push(`prompt=${encodeURIComponent(requestData.prompt)}`)
    }
    if (requestData.scale) {
      params.push(`scale=${encodeURIComponent(requestData.scale)}`)
    }
    if (requestData.steps) {
      params.push(`steps=${encodeURIComponent(requestData.steps)}`)
    }
    if (requestData.seed) {
      params.push(`seed=${encodeURIComponent(requestData.seed)}`)
    }
  }

  router[replace ? 'replace' : 'push'](`/ai${params.length ? `?${params.join('&')}` : ''}`)
}

export function goToShare(token: string, replace = false): void {
  router[replace ? 'replace' : 'push'](`/share/${token}`)
}

export function isNewMessagePage(): boolean {
  return /^\/threads\/new/.test(get(router.location))
}

export function goToDraft(draft?: Draft, replace = false): void {
  router[replace ? 'replace' : 'push'](`/drafts${draft ? `/${draft.id}` : ''}`)
}

export function goToFeed(message?: Message, time?: number, replace = false): void {
  router[replace ? 'replace' : 'push'](
    `/feed${message ? `/${message.repliedTo || message.id}` : ''}${
      message?.repliedTo ? `/${message.id}` : ''
    }${time ? `?t=${time}` : ''}`,
  )
}

export function goToReports(report?: Report, replace = false): void {
  router[replace ? 'replace' : 'push'](`/reports${report ? `/${report.id}` : ''}`)
}

export function handleNotExistingRoute() {
  const [, t, threadId, m, messageId, replyId] = get(router.location).split('/')

  if (t === 'threads' && threadId) {
    const threads = threadsStore.get() || []
    const thread = threads.find(t => t.id === threadId)

    if (!thread) {
      return threads.length > 0 ? goToThread(threads[0]) : goToThreads()
    }

    if (m === 'messages' && messageId) {
      const message = thread.messages?.find(m => m.id === messageId)

      if (!message) {
        return goToMessage(thread, (thread.messages || [])[0])
      }

      if (replyId) {
        const reply = message.replies?.find(r => r.id === replyId)

        if (!reply) {
          return goToMessage(
            thread,
            (message.replies || []).length > 0 ? message.replies![0] : message,
          )
        }
      }
    }
  }
}
