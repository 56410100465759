<script lang="ts">
  import { onDestroy } from 'svelte'
  import type { ContactPoint } from 'types'
  import { SidebarState } from 'types'
  import { querystring } from 'svelte-spa-router'
  import { get } from 'svelte/store'
  import { extractRecipientsFromDraft } from 'utils'
  import { loadDrafts, addError, setSidebarState } from 'actions'
  import { threads, drafts, settings } from 'stores'
  import Recorder from '../components/Recorder/Recorder.svelte'

  let selectedDraftId: string | undefined
  let recipients: ContactPoint[] = []
  const sidebarStateBefore = settings.get()?.sidebarState
  const unsubscribes: (() => void)[] = []

  $: isFeed = !/userId|draftId|threadId/.test($querystring || '')
  $: lastWatchedMessageId =
    new URLSearchParams($querystring).get('lastWatchedMessageId') || undefined

  setSidebarState(SidebarState.HIDE)
  updatePrefilledRecipient()
  unsubscribes.push(threads.subscribe(() => updatePrefilledRecipient()))
  unsubscribes.push(drafts.subscribe(() => updatePrefilledRecipient()))
  unsubscribes.push(querystring.subscribe(query => updatePrefilledRecipient(query)))

  function updatePrefilledRecipient(query?: string) {
    const params = new URLSearchParams(query || get(querystring))
    const threadId = params.get('threadId')
    const userId = params.get('userId')
    const draftId = params.get('draftId')
    const messageId = params.get('messageId')

    selectedDraftId = undefined

    if (threadId) {
      const thread = threads.get()?.find(thread => thread.id === threadId)

      if (thread && !recipients.find(r => r.thread?.id === thread.id)) {
        const recipient: ContactPoint = { thread }

        if (messageId) {
          const message = thread.messages?.find(m => m.id === messageId)

          if (message) {
            recipient.message = message
          }
        }

        recipients = [recipient, ...recipients]
      }
    } else if (userId) {
      const user = threads
        .get()
        ?.flatMap(thread => thread.members)
        .find(user => user.id === userId)

      if (user && !recipients.find(r => r.user?.id === user.id)) {
        recipients = [{ user }, ...recipients]
      }
    } else if (draftId) {
      const draft = (drafts.get() || []).find(draft => draft.id === draftId)

      if (draft) {
        selectedDraftId = draft.id
        recipients = extractRecipientsFromDraft(draft, threads.get() || [])
      } else if (drafts.get()?.length === 0) {
        loadDrafts().catch(addError)
      }
    }
  }

  onDestroy(() => {
    sidebarStateBefore && setSidebarState(sidebarStateBefore)
  })

  ; // prettier-ignore
</script>

<Recorder {selectedDraftId} {recipients} {isFeed} {lastWatchedMessageId} />
