<script lang="ts">
  import { createEventDispatcher } from 'svelte'
  import { register, getRecaptchaToken } from 'actions/auth'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'

  const dispatch = createEventDispatcher<{
    close: undefined
    error: string
  }>()

  export let isActive = false

  let fullNameInput: Input
  let email = ''
  let fullName = ''
  let password = ''
  let repeatPassword = ''
  let isLoading = false
  let isBot = false

  $: canSubmit = !!email && !!fullName && !!password && !!repeatPassword && !isBot
  $: isActive && fullNameInput?.focus()

  async function onSubmit() {
    if (!canSubmit || isLoading) return

    if (password !== repeatPassword) {
      dispatch('error', 'Passwords should match')
      return
    }

    isLoading = true
    try {
      dispatch('error', '')
      await register({
        email,
        password,
        fullName,
        recaptchaToken: await getRecaptchaToken(),
      })
    } catch (e: any) {
      if (e?.code === 'A026') {
        isBot = true
      } else {
        dispatch('error', e.message)
      }
    }
    isLoading = false
  }
</script>

<form on:submit|preventDefault={onSubmit}>
  <h1>Create your account</h1>
  <Input bind:this={fullNameInput} bind:value={fullName} name="name" placeholder="Name" />
  <Input bind:value={email} name="email" placeholder="Email address" />
  <h2>Pick a strong password</h2>
  <Input bind:value={password} name="password" placeholder="Password" type="password" />
  <Input
    bind:value={repeatPassword}
    name="repeat_password"
    placeholder="Repeat password"
    type="password" />
  <div class="separator" />
  {#if isBot}
    <div class="error">
      Our bot detection system indicates that this request was made by a bot. If you believe an
      error has occurred, please contact us at <a href="mailto:contact@boom.me">contact@boom.me</a>.
    </div>
  {/if}
  <Button color="red" loading={isLoading} disabled={!canSubmit} type="submit">Register</Button>
  <Button on:click={() => dispatch('close')}>Cancel</Button>
</form>

<style lang="scss">
  form {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;

    > :global(*:not(:first-child)) {
      margin-top: 10px;
    }
  }

  h1 {
    font-size: 16px;
    margin: 0 0 20px;
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    margin: 30px 0 20px !important;
  }

  .separator {
    padding-top: 10px;
  }

  .error {
    padding: 16px;
    border-radius: 6px;
    margin-bottom: 24px;
    color: var(--primary-color);
    background: rgba(235, 79, 125, 0.2);

    a {
      color: var(--primary-color);
    }
  }
</style>
