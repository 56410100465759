<script lang="ts">
  export let size: 'large' | 'small' = 'small'
  export let color = '#3F3F3F'

  ; // prettier-ignore
</script>

<div class={`spinner ${size}`} style={`--spinner-color: ${color}`}>
  {#each Array(8) as _, __}
    <div />
  {/each}
</div>

<style lang="scss">
  .spinner {
    position: relative;
    width: 20px;
    height: 20px;
    display: inline-block;

    div {
      position: absolute;
      top: 6.5px;
      left: 8.5px;
      width: 2px;
      height: 6px;
      background-color: var(--spinner-color);
      border-radius: 1.25px;
      animation: spin 1s linear infinite;
      will-change: opacity;

      @for $i from 1 through 8 {
        &:nth-child(#{$i}) {
          transform: rotate(#{360deg / 8 * $i}) translateY(-6.5px);
          animation-delay: 1s / 8 * ($i - 14);
        }
      }
    }

    &.large {
      width: 35px;
      height: 35px;

      div {
        top: 11.5px;
        left: 15px;
        width: 5px;
        height: 12px;
        border-radius: 2.5px;

        @for $i from 1 through 8 {
          &:nth-child(#{$i}) {
            transform: rotate(#{360deg / 8 * $i}) translateY(-11.5px);
          }
        }
      }
    }
  }

  @keyframes spin {
    0% {
      opacity: 0.85;
    }
    50% {
      opacity: 0.25;
    }
    100% {
      opacity: 0.25;
    }
  }
</style>
