<script lang="ts">
  import { onMount, onDestroy } from 'svelte'
  import type { SelectItem } from 'types'
  import CloseIcon from 'assets/icons/cross.svg'
  import { isSameMediaDevice } from 'utils'

  export let videoDevice: MediaDeviceInfo | undefined
  export let audioDevice: MediaDeviceInfo | undefined | null
  export let disabled = false
  export let bottom = false
  export let isActive = false
  export let error: Error | undefined

  let videoDevices: SelectItem[] = []
  let audioDevices: SelectItem[] = []
  let currentVideoDevice: SelectItem | undefined
  let currentAudioDevice: SelectItem | undefined

  let root: HTMLElement
  let hidden = true
  let hideTimeout: any
  let isInited = false

  $: updateSelectedDevices(currentVideoDevice, currentAudioDevice, isInited)

  export function toggleActive(state?: boolean) {
    if (disabled) return

    if (typeof state === 'boolean' ? state : !isActive) {
      hidden = false
      hideTimeout && clearTimeout(hideTimeout)
      setTimeout(() => {
        isActive = true
        root?.focus()
      }, 10)
    } else {
      isActive = false
      hideTimeout = setTimeout(() => {
        hidden = true
      }, 200)
    }
  }

  export function refreshDevices(retry?: any): void {
    retry = typeof retry === 'number' ? retry : 0

    navigator.mediaDevices
      ?.enumerateDevices()
      .then(devices => {
        if (devices.length === 0) return requestMedia(retry)
        if (!devices[0].deviceId && retry < 10) {
          retry === 0 ? requestMedia(retry) : setTimeout(() => refreshDevices(retry + 1), 1000)
          return
        }

        audioDevices = devices
          .filter(d => d.kind === 'audioinput')
          .map(d => ({ label: d.label || d.deviceId, value: d }))
        videoDevices = devices
          .filter(d => d.kind === 'videoinput')
          .map(d => ({ label: d.label || d.deviceId, value: d }))

        if (audioDevices.length > 0 && audioDevice !== null) {
          const lastUsedMicId = localStorage.getItem('lastUsedMicId') || ''
          const micIndex = Math.max(
            0,
            audioDevices.findIndex(({ value }) =>
              audioDevice
                ? value.deviceId === audioDevice.deviceId
                : value.deviceId === lastUsedMicId,
            ),
          )

          currentAudioDevice = audioDevices[micIndex]
        }

        if (videoDevices.length > 0) {
          const lastUsedCameraId = localStorage.getItem('lastUsedCameraId') || ''
          const cameraIndex = Math.max(
            0,
            videoDevices.findIndex(({ value, label }) =>
              videoDevice
                ? value.deviceId === videoDevice.deviceId
                : lastUsedCameraId
                ? value.deviceId === lastUsedCameraId
                : label.toLowerCase().indexOf('facetime') !== -1,
            ),
          )

          currentVideoDevice = videoDevices[cameraIndex]
        }

        isInited = true
      })
      .catch(e => {
        error = e
      })
  }

  function requestMedia(retry: number) {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(stream => {
        stream.getTracks().forEach(t => t.stop())
        refreshDevices(retry + 1)
      })
      .catch(e => {
        error = e
      })
  }

  function updateSelectedDevices(
    currentVideoDevice: SelectItem | undefined,
    currentAudioDevice: SelectItem | undefined | null,
    isInited: boolean,
  ) {
    if (!isInited) return

    if (!isSameMediaDevice(currentAudioDevice?.value, audioDevice)) {
      audioDevice = currentAudioDevice ? currentAudioDevice.value : null
    }

    if (!isSameMediaDevice(currentVideoDevice?.value, videoDevice)) {
      videoDevice = currentVideoDevice?.value
    }

    if (currentAudioDevice?.value) {
      localStorage.setItem('lastUsedMicId', currentAudioDevice.value.deviceId)
    }

    if (currentVideoDevice?.value) {
      localStorage.setItem('lastUsedCameraId', currentVideoDevice.value.deviceId)
    }
  }

  function toggleMute() {
    if (currentAudioDevice) {
      currentAudioDevice = undefined
    } else {
      currentAudioDevice = audioDevices[0]
    }
  }

  onMount(() => {
    refreshDevices()
    navigator.mediaDevices.addEventListener('devicechange', refreshDevices)
  })

  onDestroy(() => {
    navigator.mediaDevices.removeEventListener('devicechange', refreshDevices)
  })

  ; // prettier-ignore
</script>

<div
  bind:this={root}
  tabIndex="-1"
  class="source-settings"
  class:active={isActive}
  class:bottom
  class:hidden
  class:disabled
  on:click|preventDefault={e => e.target === root && toggleActive()}
  on:blur={() => toggleActive(false)}>
  {#if videoDevices.length > 0 || audioDevices.length > 0}
    <div class="select">
      <div class="close" on:click={() => toggleActive(false)}><CloseIcon /></div>
      <div class="select-group">
        <span class="select-title">Select Camera</span>
        {#each videoDevices as item}
          <div
            class="select-item"
            class:select-item--active={item.value ===
              (currentVideoDevice && currentVideoDevice.value)}
            on:click={() => {
              currentVideoDevice = item
            }}>
            {item.label}
          </div>
        {/each}
      </div>
      <div class="select-group">
        <span class="select-title">Select Microphone</span>
        {#each audioDevices as item}
          <div
            class="select-item"
            class:select-item--active={item.value ===
              (currentAudioDevice && currentAudioDevice.value)}
            on:click={() => {
              currentAudioDevice = item
            }}>
            {item.label}
          </div>
        {/each}
      </div>
      <div class="button" on:click={toggleMute}>{currentAudioDevice ? 'Mute' : 'Unmute'}</div>
    </div>
  {/if}
</div>

<style lang="scss">
  .source-settings {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 0;
    color: #fff;
  }

  .select {
    position: absolute;
    bottom: calc(100% + 8px);
    left: 50%;
    width: 240px;
    transform: translateX(-50%) translateY(0);
    background: rgba(0, 0, 0, 0.65);
    border-radius: 8px;
    text-align: left;
    opacity: 0;
    overflow: hidden;
    transition: opacity 0.2s, transform 0.2s;
    z-index: 2;

    .bottom > & {
      bottom: auto;
      top: calc(100% + 8px);
    }

    .hidden > & {
      display: none;
    }

    .active > & {
      opacity: 1;
      transform: translateX(-50%) translateY(-10px);
    }

    .active.bottom > & {
      transform: translateX(-50%) translateY(10px);
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(0.75);
    cursor: pointer;
    padding: 8px;
  }

  .select-group {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .select-title {
    display: block;
    font-size: 12px;
    font-weight: bold;
    margin: 12px 12px 8px;
  }

  .select-item {
    position: relative;
    padding: 8px 12px 8px 32px;
    font-size: 12px;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .select-item--active {
    &::before {
      content: '';
      position: absolute;
      top: 9px;
      left: 14px;
      width: 10px;
      height: 4px;
      transform: rotate(-45deg);
      border-bottom: 2px solid #fff;
      border-left: 2px solid #fff;
    }
  }

  .button {
    width: 100%;
    height: 36px;
    font-size: 12px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    transition: background 0.2s ease;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }
</style>
