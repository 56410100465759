<script lang="ts">
  import type { Thread, Session, NewMessage } from 'types'
  import { createEventDispatcher } from 'svelte'
  import { getThreadRecipients, getUserName, userImageUrl } from 'utils'
  import DefaultAvatar from 'assets/icons/default-avatar.svg'
  import CrossIcon from 'assets/icons/cross.svg'
  import MoreIcon from 'assets/icons/more.svg'
  import MutedIcon from 'assets/icons/muted.svg'
  import TimeAgo from 'components/TimeAgo.svelte'
  import UploadIndicator from 'components/UploadIndicator.svelte'

  const dispatch = createEventDispatcher<{
    click: Thread
    toggleMenu: { event: MouseEvent; id: string }
  }>()

  export let threads: Thread[] = []
  export let currentThreadId: string | undefined = undefined
  export let activeMenuEntityId: string | undefined = undefined
  export let activeMenuVisible = false
  export let canShowActive = false
  export let session: Session | null = null
  export let newMessages: { [key: string]: NewMessage } = {}

  let uploadProgress: { [key: string]: number } = {}

  $: updateUploadProgress(newMessages)

  function updateUploadProgress(newMessages: { [key: string]: NewMessage }) {
    uploadProgress = {}
    Object.values(newMessages).forEach(({ message, progress }) => {
      if (message.threadId) {
        uploadProgress[message.threadId] =
          typeof uploadProgress[message.threadId] === 'number'
            ? Math.min(progress, uploadProgress[message.threadId])
            : progress
      }
    })
  }

  function threadImage(thread: Thread): string | undefined {
    return getThreadRecipients(thread, session).reduce<string | undefined>(
      (result, user) => result || userImageUrl(user),
      undefined,
    )
  }
</script>

<div class="threads">
  {#each threads as thread (thread.id)}
    <div
      class="thread menu-anchor"
      class:muted={thread.muted}
      class:active-menu={activeMenuEntityId === thread.id && activeMenuVisible}
      class:active={thread.id === currentThreadId && canShowActive}
      class:not-watched={(thread.unwatchedMessagesCount || 0) > 0}
      on:click={() => dispatch('click', thread)}>
      <div class="thread-icon">
        {#if thread.channel}
          #
        {:else if threadImage(thread)}
          <img src={threadImage(thread)} alt={thread.title} referrerpolicy="no-referrer" />
        {:else}
          <DefaultAvatar />
        {/if}
      </div>
      <div class="thread-title">
        <span class="thread-name">
          {#if thread.title}
            {thread.title}
          {:else}
            {getThreadRecipients(thread, session)
              .map(u => getUserName(u, session))
              .join(', ')}
          {/if}
          {#if thread.muted}
            <span class="muted-icon"><MutedIcon width="12" height="12" /></span>
          {/if}
        </span>
        <span class="thread-date"><TimeAgo date={thread.modifiedAt} /></span>
      </div>
      <div
        class="thread-btn"
        on:click|stopPropagation={event => dispatch('toggleMenu', { event, id: thread.id })}>
        <MoreIcon />
        <CrossIcon />
      </div>
      {#if typeof uploadProgress[thread.id] === 'number' && canShowActive}
        <div class="thread-uploading">
          <UploadIndicator progress={uploadProgress[thread.id]} />
        </div>
      {:else if (thread.unwatchedMessagesCount || 0) > 0}
        <div class="thread-counter">
          {thread.unwatchedMessagesCount || ''}
        </div>
      {/if}
    </div>
  {/each}
</div>

<style lang="scss">
  .thread {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin: 0 -8px;
    padding: 7px 10px 7px 24px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 300;
    cursor: pointer;
    color: #000;
    stroke: #000;
    transition: background-color 0.2s ease, color 0.2s ease;
    border-radius: 8px;

    &:hover {
      background-color: rgba(233, 224, 211, 0.5);
    }

    &:hover,
    &.active-menu {
      .thread-counter,
      .thread-uploading {
        opacity: 0;
      }

      .thread-btn {
        opacity: 1;
        z-index: 2;
      }
    }

    &.active {
      background-color: var(--active-bg-color);
    }

    &.muted {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .not-watched {
    font-weight: 600;
  }

  .thread-icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin: -1px 0;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 8px;
    text-align: center;
    flex: 0 0 auto;
    background-color: rgba(175, 175, 175, 0.2);

    img,
    :global(svg) {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .thread-title {
    flex: 2 1 100%;
  }

  .thread-counter {
    position: absolute;
    top: 7px;
    right: 7px;
    background-color: var(--primary-color);
    flex: 0 0 auto;
    min-width: 8px;
    margin-left: 4px;
    padding: 0px 4px;
    border-radius: 20px;
    color: white;
    transition: opacity 0.2s ease;
  }

  .thread-uploading {
    position: absolute;
    top: 1px;
    right: 2px;
    flex: 0 0 auto;
    min-width: 30px;
    transition: opacity 0.2s ease;
  }

  .thread-name {
    margin-right: 4px;
  }

  .thread-date {
    font-weight: 300;
    opacity: 0.5;
  }

  .thread-btn {
    position: relative;
    flex: 0 0 auto;
    min-width: 8px;
    margin-left: 4px;
    width: 16px;
    height: 16px;
    opacity: 0;
    transition: opacity 0.2s ease;

    :global(svg) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: transform 0.3s ease, opacity 0.3s ease;
    }

    :global(svg path) {
      stroke: none;
    }

    :global(svg:last-child path) {
      fill: #000;
    }

    .thread:not(.active-menu) & :global(svg:last-child) {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(-45deg) scale(0);
    }

    .active-menu & :global(svg:first-child) {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }

    .active-menu & :global(svg:last-child) {
      transform: translate(-50%, -50%) rotate(0) scale(0.95);
    }
  }

  .muted-icon {
    opacity: 0.5;

    :global(svg) {
      margin: 0px 0px -2px 0px;
    }

    :global(svg path) {
      stroke: none;
    }
  }
</style>
