export function clickOutseide(element: HTMLElement, callbackFunction: Function) {
  function onClick(event: MouseEvent) {
    if (!element.contains(event.target as Node)) {
      callbackFunction()
    }
  }

  document.body.addEventListener('click', onClick, { capture: true })

  return {
    update(newCallbackFunction: Function) {
      callbackFunction = newCallbackFunction
    },
    destroy() {
      document.body.removeEventListener('click', onClick, { capture: true })
    },
  }
}
