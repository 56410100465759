<script lang="ts">
  import PrevSmallBtnIcon from 'assets/icons/prev-btn-small.svg'
  import NextSmallBtnIcon from 'assets/icons/next-btn-small.svg'
</script>

<div class="navigation">
  <div class="arrow prev" on:click={() => history.back()}><PrevSmallBtnIcon /></div>
  <div class="arrow next" on:click={() => history.forward()}><NextSmallBtnIcon /></div>
  <div class="body">
    <slot />
  </div>
</div>

<style lang="scss">
  .navigation {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    padding: 8px 16px;
    transition: padding-left var(--sidebar-transition-duration) ease;
    z-index: 2;

    :global(.app:not(.windows)) & {
      -webkit-app-region: drag;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -300px;
      right: 0;
      bottom: -40px;
      background: linear-gradient(
        rgba(0, 0, 0, 0.75) 0,
        rgba(0, 0, 0, 0.5) 25%,
        rgba(0, 0, 0, 0.3) 50%,
        rgba(0, 0, 0, 0.1) 75%,
        transparent 100%
      );
      z-index: 1;
    }

    & > * {
      margin-right: 8px;
    }

    :global(.root:not(.active-sidebar)) & {
      padding-left: 56px;

      .body {
        max-width: calc(100% - 176px);
      }
    }

    :global(.mac .root:not(.active-sidebar)) & {
      padding-left: 120px;

      .body {
        max-width: calc(100% - 240px);
      }
    }

    :global(.mac .root.inactive-sidebar) & {
      padding-left: 112px;
    }
  }

  .arrow {
    position: relative;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    cursor: pointer;
    flex: 0 0 auto;
    z-index: 1;

    &.prev > :global(svg) {
      margin-left: -1px;
    }
  }

  .body {
    position: relative;
    flex: 2 1 auto;
    max-width: calc(100% - 136px);
    transition: max-width 0.3s ease;
    z-index: 1;
  }
</style>
