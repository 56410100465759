<script lang="ts">
  import Modal from './Modal.svelte'
  import Button from './Button.svelte'

  export let title = ''
  export let actionButtonLabel = ''
  export let active = true
  export let onClose: (result: boolean) => Promise<void>

  let confirming = false

  async function confirm() {
    if (confirming) return
    confirming = true
    try {
      await onClose(true)
    } catch (e) {}
    confirming = false
  }
</script>

<Modal size="small" {active} onClose={() => onClose(false)}>
  <div class="confirm">
    {#if title}
      <h1>{title}</h1>
    {/if}
    <div class="btns">
      <Button on:click={() => onClose(false)}>{actionButtonLabel ? 'Cancel' : 'Close'}</Button>
      {#if actionButtonLabel}
        <Button color="red" loading={confirming} on:click={confirm}>{actionButtonLabel}</Button>
      {/if}
    </div>
  </div>
</Modal>

<style lang="scss">
  h1 {
    font-size: 18px;
    font-weight: normal;
    margin: 16px 0px;
  }

  .btns {
    display: flex;
    margin: 24px -8px 0;

    > :global(*) {
      margin: 0 8px;
    }
  }
</style>
