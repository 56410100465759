import type { Community, Thread, Member } from 'types'
import type { CommunityData } from 'api/communities'
import { communities, threads } from 'stores'
import { communitiesAPI, uploadsAPI } from 'api'
import { goToCommunity } from './router'

export async function loadCommunities(): Promise<void> {
  communities.set(await communitiesAPI.getCommunities())
}

export async function loadCommunityChannel(community: Community): Promise<Thread[]> {
  return await communitiesAPI.getCommunityChannels(community.id)
}

export async function createCommunity(data: CommunityData, imageFile?: Blob): Promise<Community> {
  if (imageFile) {
    const { id } = await uploadsAPI.upload(imageFile)
    data.imageId = id
  }

  return updateCommunities(await communitiesAPI.createCommunity(data))
}

export async function getInviteLink(id: string): Promise<string> {
  const inviteLink = await communitiesAPI.createCommunityInviteLink(id)
  communities.update(communities =>
    communities.map(community => ({
      ...community,
      inviteLink: community.id === id ? inviteLink.link : community.inviteLink,
    })),
  )

  return inviteLink.link
}

export async function updateCommunity(id: string, data: CommunityData): Promise<Community> {
  return updateCommunities(await communitiesAPI.updateCommunity(id, data))
}

export async function uploadCommunityPhoto(id: string, blob: Blob): Promise<Community> {
  const { id: imageId } = await uploadsAPI.upload(blob)
  return updateCommunities(await communitiesAPI.updateCommunity(id, { imageId }))
}

export async function handleNewCommunity(
  communityOrId: string | Community,
  shouldGoTo = false,
): Promise<void> {
  const community =
    typeof communityOrId === 'string'
      ? communities.get()?.find(c => c.id === communityOrId) ||
        (await communitiesAPI.getCommunity(communityOrId))
      : communityOrId

  if (!communities.get()?.find(c => c.id === community?.id)) {
    if (community) {
      communities.update(communities =>
        [community!, ...(communities || [])].sort((l, r) => (l.modifiedAt > r.modifiedAt ? -1 : 1)),
      )
    } else {
      throw new Error('Community is not found')
    }
  }

  community && shouldGoTo && goToCommunity(community)
}

export async function addMembers(communityId: string, members: Member[]): Promise<void> {
  const users = await communitiesAPI.addMembers(communityId, members)

  communities.update(communities => {
    return communities.map(community => {
      if (community.id === communityId) {
        return {
          ...community,
          members: [
            ...(community.members || []),
            ...users.filter(u => !(community.members || []).find(p => p.id === u.id)),
          ],
        }
      } else {
        return community
      }
    })
  })
}

export async function removeMembers(communityId: string, memberIds: string[]): Promise<void> {
  await communitiesAPI.removeMembers(communityId, memberIds)
  communities.update(communities => {
    return communities.map(community => {
      if (community.id === communityId) {
        return {
          ...community,
          members: community.members?.filter(p => memberIds.indexOf(p.id) === -1),
        }
      } else {
        return community
      }
    })
  })
}

export async function banCommunityMember(
  communityId: string,
  memberId: string,
  duration?: number,
  comment?: string,
): Promise<void> {
  await communitiesAPI.banMember(communityId, { memberId, duration, comment })

  if (!duration) {
    communities.update(communities => {
      return communities.map(community => {
        if (community.id === communityId) {
          return {
            ...community,
            members: community.members?.filter(m => memberId !== m.id),
          }
        } else {
          return community
        }
      })
    })
  }
}

export async function joinCommunity(communityId: string): Promise<void> {
  const community = await communitiesAPI.joinCommunity(communityId)
  updateCommunities({ ...community, needToAcceptInvite: false })
}

export async function leaveCommunity(communityId: string): Promise<void> {
  await communitiesAPI.leaveCommunity(communityId)
  handleDeletedCommunity(communityId)
}

export function handleDeletedCommunity(communityId: string): void {
  communities.update(communities => communities.filter(community => community.id !== communityId))
  threads.update(threads =>
    threads ? threads.filter(thread => thread.communityId !== communityId) : null,
  )
}

export function updateCommunities(updatedCommunity: Community): Community {
  communities.update(communities => {
    let wasFound = false
    let updatedCommunities = communities.map(community => {
      if (community.id === updatedCommunity.id) {
        wasFound = true
        return {
          ...community,
          ...updatedCommunity,
        }
      } else {
        return community
      }
    })

    if (!wasFound) {
      updatedCommunities = [updatedCommunity, ...updatedCommunities]
    }

    return updatedCommunities
  })

  return updatedCommunity
}
