import store from './store'
import { session } from './session'
import { SessionManager, Video, enableLogging } from 'vendors/videokit'

enableLogging()

export const isVKReady = store(false)

session.subscribe(async session => {
  if (session && session.videoKitSessionToken) {
    await SessionManager.startSession({ sessionToken: session.videoKitSessionToken })
    isVKReady.set(true)
  } else {
    SessionManager.stopSession()
    isVKReady.set(false)
  }
})

export const videos = store<{ [key: string]: Video }>({})
