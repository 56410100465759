<script lang="ts">
  export let startsFrom = 3

  let numbers: number[] = []
  let current = startsFrom
  let interval: any = undefined

  $: update(startsFrom)

  function update(startsFrom: number) {
    numbers = Array.from({ length: startsFrom }, (_, i) => i + 1)
    current = startsFrom
    interval && clearInterval(interval)
    interval = setInterval(() => {
      current -= 1
      if (current === 0) {
        clearInterval(interval)
      }
    }, 1000)
  }
</script>

<div class="countdown">
  {#each numbers as number}
    <div class="number" class:prev={number > current} class:next={number < current}>
      {number}
    </div>
  {/each}
</div>

<style lang="scss">
  .countdown {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .number {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, opacity 0.3s ease;

    &.prev {
      transform: scale(3);
      opacity: 0;
    }

    &.next {
      transform: scale(0.3);
      opacity: 0;
    }
  }
</style>
