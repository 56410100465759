<script lang="ts">
  import LogoIcon from 'assets/icons/logo-min.svg'
  import CloseIcon from 'assets/icons/cross.svg'
  import Button from './Button.svelte'

  export let version = ''
  export let close: () => void

  ; // prettier-ignore
</script>

<div class="update">
  <div class="close" on:click={close}><CloseIcon /></div>
  <LogoIcon />
  <h1>New update available</h1>
  {#if version}
    <span class="version">New version: {version}</span>
  {/if}
  <Button on:click={() => window.ipc?.send('QUIT_AND_INSTALL')}>Update and relaunch</Button>
</div>

<style lang="scss">
  .update {
    position: fixed;
    top: 16px;
    right: 16px;
    width: 240px;
    padding: 16px;
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 10;

    :global(.windows) & {
      margin-top: 24px;
    }

    :global(svg) {
      width: 36px;
      height: 36px;
    }
  }

  h1 {
    font-size: 16px;
    margin: 8px 0 4px;
    font-weight: normal;
    text-transform: uppercase;
    line-height: 24px;
  }

  .version {
    font-size: 12px;
    color: #666;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    :global(svg) {
      width: 12px;
      height: 12px;
    }

    :global(path) {
      fill: #000;
    }
  }
</style>
