import { Settings, PlayerSettings, SidebarState } from 'types'
import store from './store'

export const settings = store<Settings>({
  sidebarState: SidebarState.AUTO,
  isSidebarHovered: false,
  screenRecordingMode: false,
  isThumbsActive: false,
  boomAnimations: [],
})

export const playerSettings = store<PlayerSettings>({
  shouldPlay: true,
  playbackRate: 1,
  volume: 1,
  muted: false,
  showSubtitles: true,
})
