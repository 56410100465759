import type { Notification } from 'types'
import { notifications, isAppFocused } from 'stores'
import { subscribe } from 'api/ws'
import { isElectron } from 'env'
import { handleNewMessage, handleUpdatedMessage, handleDeletedMessage } from 'actions/messages'
import { handleNewThread } from 'actions/threads'
import { handleNewCommunity } from 'actions/communities'
import { handleImageUpdate, setInitImage } from 'actions/aiImages'

window.ipc?.on('OPEN_NOTIFICATION', (_, id) => removeNotification(id, true))

export function addNotification(data: {
  id?: string
  title: string
  action?: () => void
  imageUrl?: string
}): void {
  if (data.id && notifications.get()?.find(n => n.id === data.id)) return

  // If app is focused show notification inside app, if not show native notification
  const type = isAppFocused.get() || !isElectron ? 'internal' : 'external'
  const notification = {
    id: data.id || (Math.random() + 1).toString(36).substring(2),
    title: data.title,
    imageUrl: data.imageUrl,
    type,
  } as Notification

  notifications.update(notifications => [
    { ...notification, action: data.action },
    ...(notifications || []),
  ])

  if (type === 'external') {
    window.ipc?.send('SHOW_NOTIFICATION', notification)
  }
}

export function removeNotification(notificationId: string, triggerAction = false): void {
  const notification = notifications.get()?.find(n => n.id === notificationId)

  if (notification) {
    triggerAction && notification.action && notification.action()
    notifications.update(notifications => notifications.filter(n => n.id !== notification.id))
  }

  if (isElectron) {
    window.ipc?.send('HIDE_NOTIFICATION', notificationId)
  }
}

export function removeAllNotifications(): void {
  for (const notification of notifications.get() || []) {
    if (isElectron) {
      window.ipc?.send('HIDE_NOTIFICATION', notification.id)
    }
  }

  notifications.set([])
}

export function handleWebSocketsNotifications(): void {
  subscribe(async ({ title, type, data }) => {
    type = type.toLowerCase()

    console.log('WS_NOTIFICATION', type, data)

    try {
      if (type === 'new_message' && data.message) {
        if (await handleNewMessage(data.message.threadId, data.message, data.muted)) {
          addNotification({
            id: data.message.id,
            title,
            action: () => handleNewMessage(data.message.threadId, data.message, data.muted, true),
            imageUrl: data.message.thumbnailURL,
          })
        }
      } else if (type === 'updated_message' && data.message) {
        handleUpdatedMessage(data.message)
      } else if (type === 'deleted_message' && data.messageId) {
        await handleDeletedMessage(data.threadId, data.messageId)
        removeNotification(data.messageId)
      } else if (type === 'channel_invitation' && data.channel) {
        await handleNewThread(data.channel)
        addNotification({ title, action: () => handleNewThread(data.channel, true) })
      } else if (type === 'community_invitation' && data.community) {
        await handleNewCommunity(data.community)
        addNotification({ title, action: () => handleNewCommunity(data.community, true) })
      } else if (type === 'ai_image_update' && data.image) {
        await handleImageUpdate(data.image)
      } else if (type === 'ai_image_upload' && data.imageUrl) {
        await setInitImage(data.imageUrl)
      }
    } catch (e: any) {
      const message = e.toString()

      // Ignore if message is not found, assuming it was deleted
      if (message.indexOf('not found') !== -1) {
        console.log(`Failed to handle web socket notification: ${type}`, message)
      }
    }
  })
}
