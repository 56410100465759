<script lang="ts">
  import { onMount, onDestroy } from 'svelte'
  import {
    RecorderController,
    RecordingState,
    RecorderErrorCode,
    UploadManager,
    RecordingQuality,
    Upload,
  } from 'vendors/videokit'
  import type { ContactPoint } from 'types'
  import { pop } from 'svelte-spa-router'
  import { getVideoThumb, timeout, isSameMediaDevice } from 'utils'
  import { draggable } from 'utils/dragAction'
  import { createMessage } from 'actions/messages'
  import { createDraft, deleteDraft } from 'actions/drafts'
  import { goToRoot, goToMessage, goToDraft, goToAI, goToFeed } from 'actions/router'
  import { toggleScreenRecordingMode, addBoomAnimation } from 'actions/settings'
  import { addError, addConfirm } from 'actions/alerts'
  import { isVKReady, threads, settings } from 'stores'
  import { isMac, isElectron } from 'env'
  import Button from 'components/Button.svelte'
  import Spinner from 'components/Spinner.svelte'
  import Modal from 'components/Modal.svelte'
  import LocalPlayer from 'components/LocalPlayer.svelte'
  import Navigation from 'components/Navigation.svelte'
  import Countdown from 'components/Countdown.svelte'
  import RecipientsField from '../../components/RecipientsField.svelte'
  import ScreenPermissionsModal from './components/ScreenPermissionsModal.svelte'
  import NoAudioConfirm from './components/NoAudioConfirm.svelte'
  import RecorderControls from './components/RecorderControls.svelte'
  import SourceSettings from './components/SourceSettings.svelte'
  import CaptureModule, { CaptureMode } from './CaptureModule'
  import RecipientsRecognizer from './RecipientsRecognizer'

  export let selectedDraftId: string | undefined = undefined
  export let lastWatchedMessageId: string | undefined = undefined
  export let recipients: ContactPoint[] = []
  export let withAIButton = false
  export let isFeed = false

  const recorderController = new RecorderController()
  const captureModule = new CaptureModule()
  const recipientsRecognizer = isFeed ? new RecipientsRecognizer(lastWatchedMessageId) : null

  let recorderPreview: HTMLVideoElement
  let controlsEl: HTMLElement
  let sourceSettings: SourceSettings
  let videoDevice: MediaDeviceInfo | undefined
  let audioDevice: MediaDeviceInfo | undefined | null
  let capturePromise: Promise<void> | undefined
  let capturePromiseResolve: (() => void) | undefined
  let isPreparingRecorder = false
  let capturedScreenId = ''
  let recordingState = RecordingState.IDLE
  let error = ''
  let captureError: Error | undefined
  let recordingTime = 0
  let previewTime = 0
  let previewDuration = 0
  let isPreviewPlaying = false
  let fileToUpload: File | undefined
  let previewUrls: string[] | undefined
  let showCameraCapture = false
  let shouldShowCameraCapture = false
  let screens: { id: string; thumbnail?: string }[] = []
  let showScreenSelector = false
  let showScreenPermissionsModal = false
  let showNoAudioConfirm = false
  let confirmedNoAudio = false
  let ignoreMouseEvents = false
  let root: HTMLElement
  let isSourceInitialized = false
  let isSending = false
  let showCountdown = false
  let countdownTimeout: any
  const unsubscribes: (() => void)[] = []

  $: selectAudioDevice(audioDevice)
  $: selectVideoDevice(videoDevice)
  $: window.ipc?.send('TOGGLE_IGNORE_MOUSE_EVENTS', ignoreMouseEvents)
  $: {
    if (!isSourceInitialized && audioDevice && videoDevice) {
      isSourceInitialized = true

      if (settings.get()?.screenRecordingMode) {
        capturedScreenId && captureScreen(capturedScreenId, false, false)
      } else {
        capture()
      }
    }
  }

  recorderController.uploadWhileRecording = true
  recorderController.videoQuality = RecordingQuality.RES_1080p
  recorderController.subscribe('stateUpdated', (_, { state }) => {
    recordingState = state
  })
  recorderController.subscribe('durationUpdated', (_, { recordingDuration }) => {
    recordingTime = recordingDuration
    recipientsRecognizer?.setVideoId((recorderController as any)?._upload?._video?.id)
  })

  unsubscribes.push(
    settings.subscribe(settings => {
      if (!settings.screenRecordingMode) {
        recorderPreview?.parentElement?.removeAttribute('style')
        capturedScreenId = ''
        ignoreMouseEvents = false
      }
    }),
  )

  async function capture(mode?: CaptureMode, reset = false): Promise<boolean> {
    try {
      capturePromise = new Promise(resolve => (capturePromiseResolve = resolve))

      // Remove listener from the previous media stream
      captureModule.outputStream
        ?.getVideoTracks()[0]
        ?.removeEventListener('ended', handleEndedStream)
      captureModule.outputStream
        ?.getAudioTracks()[0]
        ?.removeEventListener('ended', handleEndedStream)

      const { preview, output } = await captureModule.capture(
        mode || captureModule.mode,
        videoDevice,
        audioDevice,
        capturedScreenId,
      )

      recipientsRecognizer?.capture(output.getAudioTracks()[0])
      capturePromise.then(() => {
        isPreparingRecorder = false
        reset = reset || recorderController.state === RecordingState.IDLE
        recorderController.captureStream(output, reset)
      })

      // Refresh devices after capturing, because some browsers return device's labels only when
      // user allowed to capture stream
      sourceSettings?.refreshDevices()

      // If video stream is ended finish recording
      output.getVideoTracks()[0]?.addEventListener('ended', handleEndedStream)
      output.getAudioTracks()[0]?.addEventListener('ended', handleEndedStream)

      if (preview) {
        const [currentVideoTrack] =
          (recorderPreview.srcObject as MediaStream)?.getVideoTracks() || []
        const [newVideoTrack] = preview.getVideoTracks() || []

        if (!currentVideoTrack || !newVideoTrack || currentVideoTrack.id !== newVideoTrack.id) {
          recorderPreview.srcObject = preview
          recorderPreview.volume = 0
        }
        recorderPreview.play().then(async () => {
          // Wait for extra 1000 ms to avoid fade in part from black screen to actual camera stream
          await timeout(1000)
          capturePromiseResolve && capturePromiseResolve()
        })
      } else {
        capturePromiseResolve && capturePromiseResolve()
      }

      return true
    } catch (e: any) {
      if (e.code === RecorderErrorCode.CAPTURE_MODE_IS_NOT_SUPPORTED) {
        error = 'Video recording is not supported'
      }

      captureError = e

      return false
    }
  }

  function selectAudioDevice(audioDevice?: MediaDeviceInfo | null) {
    if (
      recorderController &&
      isSourceInitialized &&
      !isSameMediaDevice(audioDevice, captureModule.audioDevice)
    ) {
      capture()
    }
  }

  function selectVideoDevice(videoDevice?: MediaDeviceInfo | null) {
    if (
      recorderController &&
      isSourceInitialized &&
      !isSameMediaDevice(videoDevice, captureModule.videoDevice)
    ) {
      capture()
    }
  }

  function handleEndedStream() {
    if (
      recorderController.state === RecordingState.IDLE ||
      recorderController.state === RecordingState.CAPTURING
    ) {
      sourceSettings?.refreshDevices()
    } else {
      finish()
    }
  }

  function reset() {
    if (!recorderController) return

    previewUrls = undefined
    isPreviewPlaying = false
    recorderController.reset()
    recipientsRecognizer?.reset()

    if (settings.get()?.screenRecordingMode) {
      capturedScreenId && captureScreen(capturedScreenId, true, false)
    } else {
      capture(CaptureMode.CAMERA, true)
    }
  }

  function restart() {
    previewUrls = undefined
    isPreviewPlaying = false
    recorderController.reset()
    recipientsRecognizer?.reset()
    toggleRecord()
  }

  function remove() {
    reset()
  }

  function close() {
    recorderController.reset()
    history?.length > 2 ? pop() : goToRoot()
  }

  async function uploadFile(file: File) {
    if (file.size > 500 * 1024 * 1024) {
      return addError('File size is too large. It can not exceed 500 MB.')
    }

    fileToUpload = file
    previewUrls = [URL.createObjectURL(file)]
    previewDuration = 0
    isPreviewPlaying = true
    recordingState = RecordingState.PREVIEW
  }

  async function toggleRecord() {
    if (!isVKReady.get() || isSending) return
    if (isSourceInitialized && !audioDevice && !confirmedNoAudio) {
      showNoAudioConfirm = true
      return false
    }

    if (recorderController.state === RecordingState.RECORDING) {
      await recorderController.pause()
      recipientsRecognizer?.pause()
    } else {
      record()
    }
  }

  async function record() {
    isPreparingRecorder = true
    // Add extra delay to make sure stream was captured
    capturePromise && (await capturePromise.then(async () => await timeout(1)))
    isPreparingRecorder = false
    recorderController.record()
    recipientsRecognizer?.start()
  }

  async function finish() {
    if (
      recorderController.state !== RecordingState.RECORDING &&
      recorderController.state !== RecordingState.PAUSED
    )
      return
    if (recorderController.state === RecordingState.RECORDING) {
      await recorderController.pause()
    }

    recipientsRecognizer?.stop()
    ;(previewUrls = await recorderController.preview()), (isPreviewPlaying = true)
    previewDuration = 0

    settings.get()?.screenRecordingMode && toggleScreenRecordingMode(false)
  }

  async function confirmRecording(): Promise<{ upload: Upload; thumb: Blob | null }> {
    let upload: Upload
    let thumb: Blob | null = null

    if (fileToUpload) {
      const thumbsInfo = await getVideoThumb(fileToUpload)

      upload = UploadManager.uploadFile(fileToUpload, {
        width: thumbsInfo.width,
        height: thumbsInfo.height,
      })
      thumb = thumbsInfo.thumb
    } else {
      upload = await recorderController.confirm()
      const [firstClip] = recorderController.clips || []
      thumb = firstClip?.blob ? (await getVideoThumb(firstClip?.blob)).thumb : null
    }

    upload.video.duration = recorderController.recordingDuration

    return { upload, thumb }
  }

  async function send({ detail }: CustomEvent) {
    if (!isFeed && recipients.length === 0) {
      await finish()
      return addConfirm('Please select a recipient')
    }

    isSending = true

    try {
      const { upload, thumb } = await confirmRecording()
      const message = await createMessage(recipients, thumb, upload)

      if (selectedDraftId) {
        try {
          deleteDraft(selectedDraftId)
        } catch (e) {
          addError(e)
        }
      }

      if (isFeed) {
        goToFeed(message)
      } else {
        const thread = threads.get()?.find(t => t.id === message.threadId)
        thread && goToMessage(thread, message)
      }

      addBoomAnimation({ ...detail, size: 120 })
    } catch (e: any) {
      isPreviewPlaying = false
      error = e.message || e.error
      settings.get()?.screenRecordingMode && toggleScreenRecordingMode(false)
    }

    isSending = false
  }

  async function saveToDrafts() {
    try {
      const { upload, thumb } = await confirmRecording()
      goToDraft(await createDraft(upload, thumb, recipients, selectedDraftId))
    } catch (e: any) {
      error = e.message || e.error
    }
  }

  async function startScreenRecordingMode(withCameraCapture = false) {
    shouldShowCameraCapture = withCameraCapture
    if (recorderController.state === RecordingState.RECORDING) {
      await recorderController.pause()
    }

    try {
      if (window.ipc) {
        screens = (await window.ipc?.request('SCREEN_CAPTURE')).map(({ id, thumbnail }) => ({
          id,
          thumbnail: thumbnail ? URL.createObjectURL(new Blob([thumbnail])) : undefined,
        }))

        if (screens.length === 0) {
          throw new Error('')
        } else if (screens.length === 1) {
          captureScreen(screens[0].id)
        } else {
          showScreenSelector = true
        }
      } else {
        captureScreen('browser')
      }
    } catch (e) {
      showScreenPermissionsModal = true
    }
  }

  async function captureScreen(screenId: string, reset = false, autoStart = true) {
    try {
      capturedScreenId = screenId
      toggleScreenRecordingMode(true, screenId)
      showScreenSelector = false
      if (
        !(await capture(
          shouldShowCameraCapture ? CaptureMode.SCREEN_AND_CAMERA : CaptureMode.SCREEN,
          reset,
        ))
      )
        return
      showCameraCapture = shouldShowCameraCapture
      // Workaround to avoid occasional red frame in the beggining of screen capture
      await timeout(100)

      if (
        autoStart &&
        (recorderController.state === RecordingState.PAUSED ||
          recorderController.state === RecordingState.CAPTURING)
      ) {
        showCountdown = true
        clearTimeout(countdownTimeout)
        countdownTimeout = setTimeout(async () => {
          showCountdown = false
          await timeout(100)
          !isPreparingRecorder && recorderController.state === RecordingState.CAPTURING && record()
        }, 3200)
      }
    } catch (e: any) {
      error = e.message || e.toString()
    }
  }

  async function stopScreenRecordingMode() {
    if (recorderController.state === RecordingState.RECORDING) {
      await recorderController.pause()
    }

    clearTimeout(countdownTimeout)
    showCountdown = false
    toggleScreenRecordingMode(false)
    recorderPreview.srcObject = null
    showCameraCapture = shouldShowCameraCapture = false
    if (!(await capture(CaptureMode.CAMERA))) return

    if (
      recorderController.state === RecordingState.PAUSED ||
      recorderController.state === RecordingState.CAPTURING
    ) {
      record()
    }
  }

  async function toggleCameraCapture(show?: boolean) {
    showCameraCapture = typeof show === 'boolean' ? show : !showCameraCapture
  }

  function openSourceSettings() {
    sourceSettings.toggleActive(true)
    error = ''
    captureError = undefined
  }

  function onNoAudioConfirm(confirm: boolean) {
    showNoAudioConfirm = false
    confirmedNoAudio = confirm

    if (confirm) {
      toggleRecord()
    } else {
      openSourceSettings()
    }
  }

  onMount(() => {
    if (window.ipc && isMac) {
      window.addEventListener('mousemove', event => {
        if (settings.get()?.screenRecordingMode) {
          const isRecorderHovered = event.target === root || event.target === root?.parentNode

          if (isRecorderHovered !== ignoreMouseEvents) {
            ignoreMouseEvents = isRecorderHovered
          }
        } else if (ignoreMouseEvents) {
          ignoreMouseEvents = false
        }
      })
    }
  })

  onDestroy(() => {
    if (settings.get()?.screenRecordingMode) {
      toggleScreenRecordingMode(false)
      window.ipc?.send('TOGGLE_IGNORE_MOUSE_EVENTS', false)
    }

    unsubscribes.forEach(un => un())
    recorderController?.destroy()
    captureModule.destroy()
  })

  ; // prettier-ignore
</script>

<div bind:this={root} class="recorder" class:electron={isElectron}>
  <ScreenPermissionsModal
    active={showScreenPermissionsModal}
    onClose={() => {
      showScreenPermissionsModal = false
    }} />
  <NoAudioConfirm
    active={showNoAudioConfirm}
    on:confirm={({ detail }) => onNoAudioConfirm(detail)}
    on:close={() => (showNoAudioConfirm = false)} />
  <Modal
    title="Select screen to capture"
    active={showScreenSelector}
    onClose={() => {
      showScreenSelector = false
    }}>
    <div class="screens">
      {#each screens as screen}
        <div class="screen" on:click={() => captureScreen(screen.id)}>
          <img src={screen.thumbnail} alt={`Display ${screen.id} preview`} />
        </div>
      {/each}
    </div>
  </Modal>
  <Navigation>
    <RecipientsField bind:recipients />
  </Navigation>
  {#if recordingState === RecordingState.IDLE}
    <div class="spinner">
      <Spinner color="#fff" size="large" />
    </div>
  {/if}
  <div
    class="recorder-preview"
    class:screen-mode={showCameraCapture}
    use:draggable={{ disabled: !$settings.screenRecordingMode }}>
    <video bind:this={recorderPreview} muted playsInline />
  </div>
  {#if previewUrls}
    <LocalPlayer
      urls={previewUrls}
      bind:duration={previewDuration}
      bind:shouldPlay={isPreviewPlaying}
      bind:currentTime={previewTime} />
  {/if}
  {#if error || captureError}
    <div class="recorder-bg" />
    <div class="recorder-error">
      {#if captureError}
        <b>Unable to capture video/audio stream</b><br /><br />
        {#if captureError.name === 'NotFoundError'}
          Please make sure you have a camera and microphone connected to your computer.
        {:else if captureError.name === 'NotAllowedError'}
          Please make sure you have granted permission to {$settings.screenRecordingMode
            ? 'capture screen'
            : 'access the camera and microphone'} and restart the app.
        {:else if captureError.name === 'NotReadableError'}
          Please make sure no other applications are using the selected camera and microphone. Or
          select another ones in the <span on:click={openSourceSettings}>settings</span>.
        {/if}
        <br /><br />
        {#if error || captureError.message}
          (Error: {error || captureError.message})<br /><br />
        {/if}
      {:else}
        {error}
      {/if}
      <Button
        on:click={() => {
          error = ''
          captureError = undefined
        }}>Close</Button>
    </div>
  {/if}
  {#if showCountdown}
    <div class="countdown">
      <Countdown />
    </div>
  {/if}
  <RecorderControls
    {recordingState}
    {recordingTime}
    {previewTime}
    {showCameraCapture}
    {isPreparingRecorder}
    {isSending}
    {withAIButton}
    {showCountdown}
    screenRecordingMode={$settings.screenRecordingMode}
    bind:root={controlsEl}
    bind:isPreviewPlaying
    bind:videoDevice
    bind:audioDevice
    bind:sourceSettings
    bind:captureError
    on:toggleCameraCapture={({ detail }) => toggleCameraCapture(detail)}
    on:toggleRecord={toggleRecord}
    on:startScreenRecordingMode={({ detail }) => startScreenRecordingMode(detail)}
    on:stopScreenRecordingMode={stopScreenRecordingMode}
    on:close={close}
    on:restart={restart}
    on:remove={remove}
    on:finish={finish}
    on:upload={({ detail }) => uploadFile(detail)}
    on:save={saveToDrafts}
    on:goToAI={() => goToAI(recipients[0])}
    on:send={send} />
</div>

<style lang="scss">
  :global(.screen-recording) {
    .recorder {
      background: none;
    }

    :global(.navigation) {
      display: none !important;
    }

    .recorder:not(.electron) .recorder-preview {
      background: rgba(0, 0, 0, 0.8);

      video {
        display: none;
      }
    }

    .recorder.electron .recorder-preview {
      top: auto !important;
      left: 10px;
      bottom: 10px;
      width: 240px;
      height: 240px;
      border-radius: 100%;
      overflow: hidden;
      opacity: 0;
      z-index: -1;
      background: #000;
      // transition: z-index .5s step-end, opacity 0.5s;
    }

    .recorder.electron .screen-mode.recorder-preview {
      opacity: 1;
      z-index: 2;

      video {
        // transform: translate3d(0px, 0px, 0px) scale(-1, 1)!important;
        filter: brightness(1.1) saturate(1.05) contrast(1.05);
      }
    }
  }

  .recorder {
    position: relative;
    width: 100%;
    height: 100%;
    background: #000;

    :global(.preview-controls) {
      position: absolute;
      bottom: 104px;
      right: 20px;
    }
  }

  .recorder-preview,
  .recorder-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    :global(.windows) & {
      top: 24px;
    }
  }

  .recorder-preview video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .recorder-bg {
    background: rgba(0, 0, 0, 0.8);
  }

  .recorder-error {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 348px;
    transform: translate(-50%, -50%);
    color: #fff;
    line-height: 1.4;
    text-align: center;

    span {
      text-decoration: underline;
      cursor: pointer;
    }

    :global(button) {
      width: 96px;
      margin: 24px auto 0;
      background: #fff;
    }
  }

  .screens {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
  }

  .screen {
    position: relative;
    margin: 8px;
    border-radius: 8px;
    cursor: pointer;
    z-index: 1;
    overflow: hidden;
    flex: 0 0 auto;
    width: calc(50% - 16px);

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .countdown {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 120px;
    color: #fff;
    z-index: 1;

    .recorder.electron & {
      background: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(12px);
    }
  }
</style>
