import type { Draft, Message } from 'types'
import * as api from './api'

type DraftRecipients = {
  threadIds: string[]
  userIds: string[]
  emails: string[]
  phoneNumbers: string[]
}

type CreateDraftData = {
  videoId: string
  thumbnailId?: string
  recipients?: DraftRecipients
}

type UpdateDraftData = {
  videoId?: string
  thumbnailId?: string
  recipients?: DraftRecipients
}

type DraftsFilters = {
  state?: 'available' | 'pending'
  limit?: number
  offset?: number
  page?: number
  [key: string]: any
}

export async function getDrafts(filters: DraftsFilters = {}): Promise<Draft[]> {
  const queryParams = Object.entries(filters).map(p => p.join('='))
  const query = queryParams.length ? `?${queryParams.join('&')}` : ''

  return (await api.get<{ drafts: Draft[] }>(`/drafts${query}`)).drafts
}

export async function getDraft(draftId: string): Promise<Draft> {
  return (await api.get<{ draft: Draft }>(`/drafts/${draftId}`)).draft
}

export async function createDraft(data: CreateDraftData): Promise<Draft> {
  return (await api.post<{ draft: Draft }>('/drafts', data)).draft
}

export async function updateDraft(draftId: string, data: UpdateDraftData): Promise<Draft> {
  return (await api.put<{ draft: Draft }>(`/drafts/${draftId}`, data)).draft
}

export async function sendDraft(
  draftId: string,
  recipients?: DraftRecipients[],
): Promise<Message[]> {
  return (
    await api.post<{ messages: Message[] }>(
      `/drafts/${draftId}/send`,
      recipients ? { recipients } : undefined,
    )
  ).messages
}

export async function deleteDraft(draftId: string): Promise<void> {
  return api.del<void>(`/drafts/${draftId}`)
}
