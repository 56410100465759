<script lang="ts">
  import { timeAgo } from 'utils'
  import { timeRefreshCounter } from 'stores'

  export let date: Date
  export let live = true
  export let compact = true
  export let withTime = false

  let timeAgoDate = ''

  $: date && updateTime()
  $: live && $timeRefreshCounter && updateTime()

  function updateTime() {
    timeAgoDate = timeAgo(date, compact, withTime)
  }
</script>

{timeAgoDate}
