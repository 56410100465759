<script lang="ts">
  import type { Message, ContactPoint, APIError } from 'types'
  import type { Video as VKVideo } from 'vendors/videokit'
  import { ModalType } from 'types'
  import { onDestroy } from 'svelte'
  import { querystring, location } from 'svelte-spa-router'
  import LikeIcon from 'assets/icons/like.svg'
  import LikeFilledIcon from 'assets/icons/like-filled.svg'
  import ReplyIcon from 'assets/icons/reply.svg'
  import RecordIcon from 'assets/icons/feed-record.svg'
  import ReplyImageIcon from 'assets/icons/image.svg'
  import LinkIcon from 'assets/icons/link.svg'
  import TrashIcon from 'assets/icons/trash.svg'
  import ShareIcon from 'assets/icons/share.svg'
  import FacebookIcon from 'assets/icons/facebook.svg'
  import TwitterIcon from 'assets/icons/twitter.svg'
  import LinkedInIcon from 'assets/icons/linkedin.svg'
  import RedditIcon from 'assets/icons/reddit.svg'
  import MoreIcon from 'assets/icons/more.svg'
  import BanIcon from 'assets/icons/ban.svg'
  import TimeIcon from 'assets/icons/time.svg'
  import WarningIcon from 'assets/icons/warning.svg'
  import HoverTranscriptIcon from 'assets/icons/hover-transcript.svg'
  import Player from 'components/Player.svelte'
  import PlayerSubtitles from 'components/PlayerSubtitles.svelte'
  import PlayerControls from 'components/PlayerControls.svelte'
  import Tooltip from 'components/Tooltip.svelte'
  import Button from 'components/Button.svelte'
  import Spinner from 'components/Spinner.svelte'
  import Modal from 'components/Modal.svelte'
  import RecipientsField from '../../../components/RecipientsField.svelte'
  import { get } from 'svelte/store'
  import {
    communities,
    currentThread,
    currentMessage,
    currentMessageId,
    currentReply,
    currentReplyId,
    feedMessages,
    session,
    settings,
    playerSettings,
    videos,
  } from 'stores'
  import { goToMessage, goToNewMessage, goToAI, goToFeed, clearQuery } from 'actions/router'
  import {
    deleteMessage,
    markMessageAsWatched,
    forwardMessage,
    toggleLike,
    addError,
    addConfirm,
    setShouldPlay,
    setThumbsActive,
    openModal,
  } from 'actions'
  import { messagesAPI } from 'api'
  import { convertMessageToVKVideo, selectText, pluralize, normilizeToValues } from 'utils'

  export let params: { messageId?: string; replyId?: string } = {}

  let subtitlesViewer: PlayerSubtitles
  let canReply = true
  let canBan = false
  let isOwnMessage = false
  let currentTime = 0
  let duration = 0
  let preparingLink = false
  let publicLink = ''
  let markedAsWatched = false
  let markAsWatchedTimeout: number | undefined
  let showMoreMenu = false
  let showShareModal = false
  let forwardRecipients: ContactPoint[] = []
  let isForwarding = false
  let playerMessageId: string | undefined
  let currentRatio = 16 / 9
  let siblingWidth = 0
  let isPendingMessage = false
  let deletedMessageIds: string[] = []
  let prevMessage: Message | undefined
  let nextMessage: Message | undefined
  let message: Message | undefined
  let aiImageConfig:
    | {
        prompt: string
        scale: number
        steps: number
      }
    | undefined
  let messages: Message[] | undefined
  let messageVideos: VKVideo[] = []
  let hasSubtitles = false
  let showSubtitlesViewer = false
  let isFeed = /\/feed/.test($location)
  const unsubscribes: (() => void)[] = []

  $: {
    if (params.messageId !== currentMessageId.get()) {
      currentMessageId.set(params.messageId)
      markedAsWatched = false
    }

    if (params.replyId !== currentReplyId.get()) {
      currentReplyId.set(params.replyId)

      const message = get(currentMessage)
      if (message && !params.replyId) {
        setMessage(message)
      }

      markedAsWatched = false
    }
  }
  $: {
    if (
      message &&
      !isOwnMessage &&
      !markedAsWatched &&
      (currentTime > 3 || (duration > 0 && currentTime === duration))
    ) {
      markAsWatched(message)
    }
  }
  $: isFeed = /\/feed/.test($location)

  window.addEventListener('resize', updateSiblingWidth)
  currentMessageId.set(params.messageId)

  unsubscribes.push(
    currentThread.subscribe(thread => {
      if (message && !currentReplyId.get()) {
        setMessage(undefined, thread?.messages)
        thread?.isMessagesPreloaded && updateSiblings()
      }

      if (thread) {
        if (thread.channel) {
          const userId = session.get()?.profile.id
          const role =
            userId &&
            ((thread.channel.roles || {})[userId] ||
              (communities.get()?.find(c => c.id === thread.communityId)?.roles || {})[userId])
          const addVideoRights = normilizeToValues(thread.channel.addVideoRights, [
            ...(thread.channel.isPublic ? ['anyone'] : []),
            'members',
            'admins',
          ])

          canReply =
            (thread.channel.isPublic && addVideoRights === 'anyone') ||
            addVideoRights === 'members' ||
            (addVideoRights === 'admins' && (role === 'admin' || role === 'owner'))
          canBan = role === 'admin' || role === 'owner'
        } else {
          canReply = true
        }
      }
    }),
  )
  unsubscribes.push(
    currentMessage.subscribe(message => {
      hasSubtitles = !!message?.video?.subtitlesURL

      if (message && !currentReplyId.get()) {
        const thread = get(currentThread)
        setMessage(message, thread?.messages)
        if (isFeed || thread?.isMessagesPreloaded) updateSiblings()
      }
    }),
  )
  unsubscribes.push(
    currentReply.subscribe(reply => {
      if (reply) {
        setMessage(
          reply,
          $currentMessage ? [$currentMessage, ...($currentMessage.replies || [])] : [],
        )
        updateSiblings()
      }
    }),
  )

  function setMessage(_message?: Message, _messages?: Message[]) {
    message = _message || get(currentMessage)
    messages = isFeed ? $feedMessages || [] : _messages || get(currentThread)?.messages
    messageVideos = messages?.map(m => convertMessageToVKVideo(m)) as VKVideo[]

    if (playerMessageId !== message?.id) {
      isPendingMessage = message?.state === 'pending'
      playerMessageId = message?.id
      isOwnMessage = session.get()?.profile.id === message?.sender.id
      publicLink = ''
      preparingLink = false
      currentRatio = (message?.video?.width || 16) / (message?.video?.height || 9)
      aiImageConfig =
        message?.type === 'image'
          ? {
              prompt: message?.description || '',
              scale: message?.aiImageConfig?.scale || 7.5,
              steps: message?.aiImageConfig?.steps || 50,
            }
          : undefined
      duration =
        message && message.video ? (videos.get() || {})[message.video.id]?.duration || 0 : 0
      setShouldPlay(message?.type === 'video')
      clearTimeout(markAsWatchedTimeout)

      if (message && message.type === 'image') {
        markAsWatchedTimeout = window.setTimeout(() => message && markAsWatched(message), 3000)
      }
    }

    const params = new URLSearchParams(get(querystring))
    const time = parseFloat(params.get('t') || '0')

    if (time) {
      setTimeout(() => (currentTime = time))
      clearQuery(['t'])
    }
  }

  function updateSiblings() {
    if (message && messages) {
      const currentIndex = messages.findIndex(item => item.id === message!.id)

      nextMessage = messages[Math.min(messages.length - 1, currentIndex + 1)]
      prevMessage = messages[Math.max(0, currentIndex - 1)]
    } else {
      prevMessage = undefined
      nextMessage = undefined
    }

    updateSiblingWidth()
  }

  function updateSiblingWidth() {
    if (currentRatio > 1) {
      siblingWidth = 0
    } else {
      siblingWidth = (window.innerWidth - window.innerHeight * currentRatio) / 2
    }
  }

  async function markAsWatched(message: Message) {
    markedAsWatched = true
    try {
      await markMessageAsWatched(message)
    } catch (e: any) {
      const notFound = (e as APIError).status === 404
      const errorMessage = (e as APIError).message || e.toString()
      // Ignore if message is not found, assuming it was deleted
      if (
        !notFound &&
        errorMessage.indexOf('not found') === -1 &&
        errorMessage.toLowerCase().indexOf('failed to fetch') === -1
      ) {
        console.error(`Failed to mark message ${message.id} as watched`, e)
      }
    }
  }

  async function deleteCurrentMessage() {
    if (message) {
      await addConfirm('Are you sure you want to delete this message?', 'Delete', async () => {
        if (message) {
          try {
            await deleteMessage(message)
          } catch (e) {
            addError(e)
          }
        }
      })
    }
  }

  async function copyLink() {
    if (preparingLink || publicLink) return

    if (message) {
      preparingLink = true
      try {
        publicLink = await messagesAPI.shareMessage(message.id)
        navigator.clipboard?.writeText(publicLink).catch(() => {})
      } catch (e) {
        addError(e)
      }
      preparingLink = false
    }
  }

  async function share(provider: 'twitter' | 'facebook' | 'reddit' | 'linkedin') {
    if (!publicLink) {
      await copyLink()
    }
    if (!publicLink) return

    const publicUrl = encodeURIComponent(publicLink)
    let url = ''

    switch (provider) {
      case 'twitter':
        url = `https://twitter.com/intent/tweet?text=${publicUrl}`
        break
      case 'facebook':
        url = `https://www.facebook.com/sharer/sharer.php?u=${publicUrl}`
        break
      case 'reddit':
        url = `http://www.reddit.com/submit?url=${publicUrl}`
        break
      case 'linkedin':
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${publicUrl}`
        break
    }

    if (url) {
      window.ipc
        ? window.ipc.send('OPEN_LINK', url)
        : window.open(url, 'share', 'left=100,top=100,width=640,height=360')
    }
  }

  function reply(replyToMessage = false, image = false) {
    if (isFeed) return goToNewMessage(undefined, undefined, $currentMessage?.id)

    const thread = get(currentThread)
    let message: Message | undefined
    if (!thread) return

    if (replyToMessage) {
      message = get(currentMessage)
    }

    if (image) {
      goToAI({ thread, message })
    } else {
      goToNewMessage({ thread, message })
    }
  }

  async function onLikeClick() {
    if (message) {
      if (session.get()?.profile.isTrusted) {
        await toggleLike(message, !message.liked).catch(addError)
      } else {
        openModal({
          type: ModalType.TRUSTED_USER_ONLY,
          arguments: { action: 'like messages' },
        })
      }
    }
  }

  function closeShareModal() {
    showShareModal = false
    forwardRecipients = []
  }

  async function forward() {
    if (forwardRecipients.length === 0) return addConfirm('Please select a recipient')
    if (!message) return

    isForwarding = true
    try {
      await forwardMessage(message, forwardRecipients)
    } catch (e) {
      addError(e)
    }
    isForwarding = false
    closeShareModal()
  }

  function handleMoreBtnBlur() {
    setTimeout(() => {
      const { activeElement } = document

      if (activeElement && activeElement.className !== 'btn') {
        showMoreMenu = false
      }
    }, 200)
  }

  function banSender(mode: 'ban' | 'timeout') {
    const user = message?.sender
    const thread = get(currentThread)
    if (!user || !thread) return

    openModal({
      type: ModalType.BAN_USER,
      arguments: { mode, user, thread },
    })
  }

  function reportMessage() {
    if (session.get()?.profile.isTrusted) {
      openModal({
        type: ModalType.CREATE_REPORT,
        arguments: {
          message,
        },
      })
    } else {
      openModal({
        type: ModalType.TRUSTED_USER_ONLY,
        arguments: { action: 'report on messages' },
      })
    }
  }

  function generateImageVariations() {
    const thread = get(currentThread)
    if (!thread || !message || message.type !== 'image' || !aiImageConfig) return

    goToAI(
      { thread },
      {
        initImageUrl: message.thumbnailURL,
        prompt: aiImageConfig.prompt,
        scale: aiImageConfig.scale,
        steps: aiImageConfig.steps,
      },
    )
  }

  function toggleSubtitlesViewer() {
    showSubtitlesViewer = !showSubtitlesViewer
    showSubtitlesViewer && subtitlesViewer.scrollToCurrent()
  }

  async function playerOnErrorHandler(): Promise<boolean> {
    if (!message) return false
    if (deletedMessageIds.includes(message.id)) return true

    try {
      await messagesAPI.getMessage(message.id)
    } catch (e) {
      if ((e as APIError).status === 404) {
        deletedMessageIds = [...deletedMessageIds, message.id]
        return true
      }
    }

    return false
  }

  function _goToMessage(message: Message) {
    if (isFeed) {
      goToFeed(message)
    } else if ($currentThread) {
      goToMessage($currentThread, message)
    }
  }

  onDestroy(() => {
    currentMessageId.set(undefined)
    currentReplyId.set(undefined)
    unsubscribes.forEach(un => un())
    window.removeEventListener('resize', updateSiblingWidth)
  })

  ; // prettier-ignore
</script>

<div
  class="message"
  class:can-delete={isOwnMessage}
  class:pending={isPendingMessage}
  class:deleted={message && deletedMessageIds.includes(message.id)}>
  <div class="info">
    {#if message?.topics && message.topics.length > 0}
      <div class="topics">
        {#each message.topics as topic}
          <div class="topic">{topic}</div>
        {/each}
      </div>
    {/if}
    {#if message?.description}
      <div class="description">
        <div class="prompt">{message?.description}</div>
      </div>
    {/if}
  </div>
  <Modal
    title="Share Video to"
    active={showShareModal}
    scrollable={false}
    onClose={closeShareModal}
    size="medium">
    <div class="share-form">
      <RecipientsField
        placeholder="Any name, email, or phone number"
        allowCreateChannel={false}
        bind:recipients={forwardRecipients} />
      <Button on:click={forward} disabled={isForwarding}>
        {#if isForwarding}
          <Spinner color="#000" />
        {:else}
          Send
        {/if}
      </Button>
    </div>
    <div class="social-btns">
      <div class="btn" on:click={() => share('facebook')}>
        <FacebookIcon />
      </div>
      <div class="btn" on:click={() => share('twitter')}>
        <TwitterIcon />
      </div>
      <div class="btn" on:click={() => share('linkedin')}>
        <LinkedInIcon />
      </div>
      <div class="btn" on:click={() => share('reddit')}>
        <RedditIcon />
      </div>
    </div>
    <div class="copy-link">
      {#if publicLink}
        Link copied! <div class="link-text" on:click={selectText}>{publicLink}</div>
      {:else if preparingLink}
        <div class="prepare-link">
          <Spinner color="#000" /> Preparing
        </div>
      {:else}
        <button on:click={copyLink}>
          <LinkIcon />
          <span>Copy Link</span>
        </button>
      {/if}
    </div>
  </Modal>
  {#if message && messages}
    <div class="player" class:image={message.type === 'image'}>
      <Player
        bind:currentTime
        bind:duration
        bind:shouldPlay={$playerSettings.shouldPlay}
        bind:playbackRate={$playerSettings.playbackRate}
        bind:volume={$playerSettings.volume}
        bind:muted={$playerSettings.muted}
        on:ended={() => (currentTime = 0)}
        onErrorHandler={playerOnErrorHandler}
        showSubtitles={$playerSettings.showSubtitles && !showSubtitlesViewer}
        disabled={!message.video}
        isHorizontal={currentRatio > 1 && message.type === 'video'}
        videoId={message.video?.id || message.id}
        videos={messageVideos} />
      {#if currentRatio < 1}
        {#if prevMessage && prevMessage.thumbnailURL}
          <div
            class="sibling prev"
            class:blurred={prevMessage.id === message.id}
            style={`width: ${siblingWidth}px;`}
            on:click={_goToMessage.bind(null, prevMessage)}>
            <img src={prevMessage.thumbnailURL} alt="" />
          </div>
        {/if}
        {#if nextMessage && nextMessage.thumbnailURL}
          <div
            class="sibling next"
            class:blurred={nextMessage.id === message.id}
            style={`width: ${siblingWidth}px;`}
            on:click={_goToMessage.bind(null, nextMessage)}>
            <img src={nextMessage.thumbnailURL} alt="" />
          </div>
        {/if}
      {/if}
    </div>
    <div
      class="subtitles-viewer"
      class:active={showSubtitlesViewer}
      on:click={toggleSubtitlesViewer}>
      <PlayerSubtitles
        bind:this={subtitlesViewer}
        bind:shouldPlay={$playerSettings.shouldPlay}
        bind:currentTime
        video={convertMessageToVKVideo(message)}
        summary={message.summary} />
    </div>
    {#if hasSubtitles}
      <!-- <div class="subtitle-toggle-screen" /> -->
      <div class="subtitle-toggle" on:mouseenter={toggleSubtitlesViewer}>
        <HoverTranscriptIcon width="22" height="24" />
      </div>
    {/if}
    {#if message.type === 'image'}
      {#if message.description}
        <div class="image-info">
          <div class="settings">
            {#if aiImageConfig}
              <div class="setting">
                <span>CGF:</span>
                <input bind:value={aiImageConfig.scale} type="number" step="0.5" min="0" max="20" />
              </div>
              <div class="setting">
                <span>Steps:</span>
                <input bind:value={aiImageConfig.steps} type="number" step="1" min="10" max="150" />
              </div>
            {/if}
          </div>
          <div class="prompt">
            {#if message.aiImageConfig?.initImageUrl}
              <img src={message.aiImageConfig.initImageUrl} alt="" />
            {/if}
            {#if aiImageConfig}
              <div class="input">
                <input bind:value={aiImageConfig.prompt} />
                {message.description}
              </div>
            {/if}
          </div>
          <Button on:click={generateImageVariations}>Improve</Button>
        </div>
      {/if}
    {/if}
    <div
      class="controls"
      on:mouseenter={() =>
        settings.get()?.isThumbsActive && setTimeout(() => setThumbsActive(true))}
      on:mouseleave={() => settings.get()?.isThumbsActive && setThumbsActive(false)}>
      <div class="left">
        <div class="views">
          {pluralize(message.viewsCount, 'view')}
        </div>
        <div class="likes" class:liked={message.liked} on:click={onLikeClick}>
          <div class="icon">
            <LikeFilledIcon />
            <LikeIcon />
          </div>
          {message.likesCount}
        </div>
        {#if !isOwnMessage}
          <div class="more">
            <div class="menu" class:active={showMoreMenu}>
              <ul>
                {#if canBan}
                  <li on:click={() => banSender('timeout')}><TimeIcon /> Timeout sender</li>
                  <li on:click={() => banSender('ban')}><BanIcon /> Ban sender</li>
                {/if}
                <li on:click={reportMessage}><WarningIcon /> Report message</li>
              </ul>
            </div>
            <div
              tabIndex="0"
              class="btn"
              on:click={() => {
                showMoreMenu = !showMoreMenu
              }}
              on:blur={handleMoreBtnBlur}>
              <MoreIcon />
            </div>
          </div>
        {/if}
      </div>
      <div class="right">
        <PlayerControls
          bind:currentTime
          bind:shouldPlay={$playerSettings.shouldPlay}
          bind:showSubtitles={$playerSettings.showSubtitles}
          bind:playbackRate={$playerSettings.playbackRate}
          bind:volume={$playerSettings.volume}
          bind:muted={$playerSettings.muted}
          disabled={message.type === 'image'}
          {duration} />
        <div
          class="share"
          on:click={() => {
            showShareModal = true
          }}>
          <ShareIcon />
          <Tooltip title="Share" />
        </div>
        <div class="link" on:click={copyLink}>
          <LinkIcon />
          <Tooltip align="right">
            {#if publicLink}
              <div class="link-text" on:click={selectText}>{publicLink}</div>
              Link copied!
            {:else if preparingLink}
              <div class="prepare-link">
                <Spinner color="#fff" /> Preparing
              </div>
            {:else}
              Copy link
            {/if}
          </Tooltip>
        </div>
        {#if isOwnMessage}
          <div class="delete" on:click={deleteCurrentMessage}>
            <TrashIcon />
            <Tooltip title="Delete" />
          </div>
        {/if}
        {#if canReply}
          {#if isFeed}
            <div class="reply">
              <div class="button" on:click={() => reply()}>
                <RecordIcon />
              </div>
              <Tooltip title="New message" />
            </div>
          {:else}
            <div class="reply">
              <div class="stack side">
                <div class="wrapper">
                  <div class="button sub-thread" on:click={() => reply(false, true)}>
                    <ReplyImageIcon />
                  </div>
                  <Tooltip title="Image reply" />
                </div>
                <div class="wrapper">
                  <div class="button sub-thread" on:click={() => reply(true, true)}>
                    <ReplyImageIcon />
                  </div>
                  <Tooltip title="Image reply in thread" />
                </div>
              </div>
              <div class="stack">
                <div class="wrapper">
                  <div class="button" on:click={() => reply()}>
                    <ReplyIcon />
                  </div>
                  <Tooltip title="Reply" />
                </div>
                <div class="wrapper">
                  <div class="button sub-thread" on:click={() => reply(true)}>
                    <ReplyIcon />
                  </div>
                  <Tooltip title="Reply in thread" />
                </div>
              </div>
            </div>
          {/if}
        {/if}
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .message {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;

    &.can-delete :global(.vk-player-controls) {
      padding-right: 44px;
    }

    &.pending {
      :global(.vk-player-error) {
        color: transparent;
      }

      :global(.vk-player-error::after) {
        content: 'The message is being processed';
        color: #fff;
        display: block;
        margin-top: -54px;
      }
    }

    &.deleted {
      :global(.vk-player-error) {
        color: transparent;
      }

      :global(.vk-player-error::after) {
        content: 'The message has been deleted';
        color: #fff;
        display: block;
        margin-top: -54px;
      }
    }
  }

  .player {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    :global(.windows) & {
      top: 24px;
    }

    &.image :global(.vk-player-error) {
      display: none;
    }
  }

  .info {
    position: absolute;
    top: 48px;
    left: 16px;
    width: calc(100% - 68px);
    color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 3;
  }

  .topics {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .topic {
    background: rgba(0, 0, 0, 0.5);
    padding: 4px 8px;
    border-radius: 16px;
    margin-right: 12px;
  }

  .image-info {
    position: absolute;
    bottom: 98px;
    left: 24px;
    display: flex;
    flex-wrap: wrap;
    max-width: 90%;
    transform: translateY(0);
    transition: transform var(--thumbs-transition-duration) ease,
      opacity var(--thumbs-transition-duration) ease;
    z-index: 2;

    .prompt {
      display: flex;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 6px;

      img {
        height: 32px;
        border-radius: 4px;
        margin: 5px -3px 5px 5px;
      }

      .input {
        position: relative;
        padding: 10px 16px;
        color: transparent;
        font-size: 18px;
        line-height: 1.2;
        white-space: nowrap;

        input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          background: none;
          border: none;
          color: #fff;
          padding: 0;
          margin: 0;
          height: 100%;
          padding: 10px 16px;
          font-size: 18px;
          box-sizing: border-box;
          outline: none;
          font-family: var(--main-font);
        }
      }
    }

    :global(button) {
      margin: 0 0 0 16px;
      width: 84px;
      background: #fff;
      flex: 0 0 84px;
    }

    :global(.active-thumbs) & {
      transform: translateY(-120px);
    }

    .settings {
      display: flex;
      align-items: center;
      width: 100%;
      flex: 1 0 100%;
      margin-bottom: 8px;
    }

    .setting {
      display: flex;
      background: rgba(0, 0, 0, 0.6);
      padding: 8px 12px;
      color: #fff;
      font-size: 14px;
      line-height: 1.2;
      border-radius: 6px;
      margin-right: 8px;

      :global(svg) {
        transform: scale(0.8);
        margin: -5px -6px;
      }

      input {
        width: 24px;
        padding: 2px 6px 2px 6px;
        height: 28px;
        color: #fff;
        background: none;
        border: none;
        outline: none;
        margin: -7px -12px -8px 0px;
        appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
      }
    }
  }

  .controls {
    display: flex;
    position: absolute;
    left: 12px;
    right: 12px;
    bottom: 38px;
    z-index: 3;
    color: #fff;
    transform: translateY(0);
    transition: transform var(--thumbs-transition-duration) ease,
      opacity var(--thumbs-transition-duration) ease;
    align-items: flex-end;
    justify-content: space-between;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: 0;
      z-index: -1;
    }

    .left {
      padding-bottom: 12px;
    }

    .right {
      margin-right: 8px;
    }

    .left,
    .right {
      display: flex;
      align-items: center;

      > * {
        margin-left: 16px;
      }
    }

    :global(.active-thumbs) & {
      transform: translateY(-120px);
    }
  }

  .share-form {
    position: relative;
    margin: 16px 0;
    height: 40px;

    :global(button) {
      float: right;
      width: 120px;
      margin: 0;
    }

    :global(.recipients) {
      position: absolute;
      top: 2px;
      left: 0;
      right: 128px;
      padding: 0 1px 0 12px;
      border-radius: 6px;
      max-width: none;
      background: rgba(255, 255, 255, 0.9);
    }

    :global(.recipients::before) {
      display: none;
    }

    :global(.recipients-inner) {
      height: 38px;
      line-height: 38px;
    }

    :global(.recipients-inner > .recipient) {
      margin-top: 3px;
      background: #efefef;
    }

    :global(.recipients-field) {
      height: 34px;
      line-height: 34px;
    }

    :global(.suggestion.active),
    :global(.suggestion:hover) {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .social-btns {
    display: flex;
    margin: 32px 0;
    justify-content: space-around;

    .btn {
      cursor: pointer;
    }
  }

  .likes {
    display: flex;
    align-items: center;
    color: #fff;
    cursor: pointer;

    .icon {
      position: relative;
      width: 21px;
      height: 21px;
      margin-right: 6px;

      :global(svg) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      :global(svg:first-child) {
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }

    &.liked .icon :global(svg:first-child) {
      opacity: 1;
    }
  }

  .more {
    position: relative;
    cursor: pointer;

    .btn {
      padding: 3px 0 0 3px;

      :global(svg) {
        transform: scale(1.1);
      }

      :global(svg path) {
        fill: #fff;
      }
    }

    .menu {
      position: absolute;
      bottom: 100%;
      left: 50%;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      text-align: right;
      border-radius: 10px;
      margin-bottom: 10px;
      transform: translate(-50%, 10px);
      opacity: 0;
      width: 0;
      overflow: hidden;
      transition: width 0.2s step-end, opacity 0.2s, transform 0.2s;

      &.active {
        transform: translate(-50%, 0);
        opacity: 1;
        width: 160px;
        transition: width 0.2s step-start, opacity 0.2s, transform 0.2s;
      }

      ul {
        display: block;
        margin: 0;
        padding: 0;
      }

      li {
        font-size: 15px;
        font-weight: 500;
        padding: 8px 12px;
        display: flex;
        justify-content: left;
        align-items: center;

        :global(svg) {
          margin-right: 8px;
        }
      }

      li {
        &:hover {
          cursor: pointer;
          background: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }

  .copy-link {
    border-top: 3px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin: 16px -16px -16px;
    padding: 8px 16px;
    height: 32px;
    line-height: 32px;

    button {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      width: 98px;
      justify-content: space-between;
      cursor: pointer;

      :global(svg path) {
        fill: #000;
      }

      span {
        color: #000;
        font-size: 15px;
      }
    }

    .link-text {
      color: #000;
      border-color: var(--blue-color);
      background: #efefef;
      height: 28px;
      line-height: 20px;
      margin: 0 8px;
    }
  }

  .reply {
    position: relative;
    width: 58px;
    height: 58px;

    .button {
      display: flex;
      width: 58px;
      height: 58px;
      box-sizing: border-box;
      background: radial-gradient(
        circle,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 40, 40, 1) 75%,
        rgba(250, 96, 96, 1) 85%,
        rgba(250, 96, 96, 1) 90%,
        rgba(255, 164, 164, 1) 95%,
        rgba(255, 164, 164, 1) 100%
      );
      border: 2px solid #fff;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transform: scale(0.85);
      transition: transform 0.5s ease, opacity 0.2s ease;

      &.sub-thread {
        transform: scale(0.75);

        &:hover {
          transform: scale(0.9);
        }
      }

      &:hover {
        transform: scale(1);
        animation: pulse 2s;
      }
    }

    &:hover {
      .stack {
        height: 124px;
        transition: height 0.2s step-start;

        .wrapper {
          opacity: 1;

          &:not(:first-child) {
            opacity: 1;
            height: 58px;
            transition: height 0.2s step-start, transform 0.5s ease, opacity 0.2s ease;
          }
        }

        .button {
          opacity: 0.8;

          &:hover {
            opacity: 1;
          }
        }

        &.side {
          left: -100%;
          opacity: 1;
          transition: opacity 0.2s, left 0.2s step-start, height 0.2s step-start;
        }
      }
    }

    .stack {
      position: absolute;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: 58px;
      transition: height 0.2s step-end;

      .wrapper {
        display: flex;
        width: 58px;
        height: 58px;
        box-sizing: border-box;

        &:not(:first-child) {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          height: 0;
          transition: height 0.2s step-end, transform 0.5s ease, opacity 0.2s ease;
        }
      }

      &.side {
        left: 0;
        opacity: 0;
        transition: opacity 0.2s, left 0.2s step-end, height 0.2s step-end;
      }
    }
  }

  .share,
  .link,
  .delete {
    position: relative;
    cursor: pointer;
    min-width: 24px;
    text-align: center;
  }

  .link {
    margin: 3px -4px 0 12px;

    :global(.tooltip .body) {
      right: -24px;
    }
  }

  .prepare-link {
    :global(.spinner) {
      margin: -6px 4px -6px 0;
    }
  }

  .link-text {
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    margin: -3px 4px -3px 0;
    height: 22px;
    line-height: 16px;
    position: relative;
    border-radius: 10px;
    padding: 2px 8px;
    box-sizing: border-box;
    font-size: 12px;
  }

  .sibling {
    position: absolute;
    top: 0;
    height: 100%;
    overflow: hidden;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: -20px;
      bottom: -20px;
      box-shadow: inset 0 0 20px 0 #000;
      z-index: 1;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
    }

    &.blurred {
      filter: blur(10px);
      cursor: default;
    }

    &.prev {
      left: 0;

      &::before {
        left: -20px;
        right: 0;
      }

      img {
        object-position: right center;
      }
    }

    &.next {
      right: 0;

      &::before {
        left: 0;
        right: -20px;
      }

      img {
        object-position: left center;
      }
    }
  }

  .subtitles-viewer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 84px 64px;
    box-sizing: border-box;
    z-index: -1;
    opacity: 0;
    background: rgba(0, 0, 0, 0.75);
    transition: z-index 0.2s step-end, opacity 0.2s, padding var(--thumbs-transition-duration) ease;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 80px;
      height: 120px;
      background: linear-gradient(
        transparent 0,
        rgba(0, 0, 0, 0.1) 10%,
        rgba(0, 0, 0, 0.2) 15%,
        rgba(0, 0, 0, 0.3) 20%,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.5) 40%,
        rgba(0, 0, 0, 1) 100%
      );
      z-index: 1;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 80px;
      background-color: #000;
      z-index: 1;
    }

    > :global(.subtitles .scroll-box) {
      padding-bottom: 120px;
    }

    &.active {
      opacity: 1;
      z-index: 2;
      transition: z-index 0.2s step-start, opacity 0.2s,
        padding var(--thumbs-transition-duration) ease;
    }

    :global(.active-thumbs) & {
      padding-bottom: 224px;
    }
  }

  .subtitle-toggle {
    position: absolute;
    top: 54px;
    right: 8px;
    z-index: 4;
    padding: 4px 5px 1px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    backdrop-filter: blur(12px);
    cursor: pointer;
  }

  // .subtitle-toggle-screen {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   width: 200px;
  //   height: 136px;
  //   z-index: 3;
  // }

  @keyframes pulse {
    0% {
      // transform: scale(0.85);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    60% {
      // transform: scale(1);
      box-shadow: 0 0 0 10px rgba(129, 0, 12, 0);
    }

    100% {
      // transform: scale(0.85);
      box-shadow: 0 0 0 0 rgba(129, 0, 12, 0);
    }
  }
</style>
