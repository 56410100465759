import type { FullUserProfile } from 'types'
import { users } from 'stores'
import { usersAPI } from 'api'

export async function getUserProfile(userId: string): Promise<FullUserProfile> {
  let user = users.get()![userId]

  if (user) {
    return Promise.resolve(user)
  } else {
    user = await usersAPI.getUser(userId)
    users.update(users => ({ ...users, [userId]: user }))
    return user
  }
}
