<script lang="ts">
  import { onMount } from 'svelte'
  import { location } from 'svelte-spa-router'
  import { EImageType } from 'image-conversion'
  import Button from 'components/Button.svelte'
  import { uploadsAPI, aiImagesAPI } from 'api'
  import { addError } from 'actions'
  import { parseJwt, mobileAndTabletCheck, convertImage } from 'utils'

  let videoEl: HTMLVideoElement
  let innerWidth = 0
  let innerHeight = 0
  let videoWidth = 0
  let videoHeight = 0
  let frameWidth = 0
  let frameHeight = 0
  let relativeFrameWidth = 0
  let relativeFrameHeight = 0
  const frameSpacing = 0
  let imageBlob: Blob | null
  let imageUrl: string | null
  let isMobile = mobileAndTabletCheck()
  let uploading = false
  let done = false
  let error = ''
  let errorTitle = 'Error'

  $: token = validateToken($location.replace('/camera/', ''))
  $: updateOrientation(isMobile, innerWidth, innerHeight, videoWidth, videoHeight)
  $: updateFrameSize(innerWidth, innerHeight, videoWidth, videoHeight)

  async function capture() {
    let mediaStream: MediaStream | null = null

    try {
      mediaStream = await navigator?.mediaDevices?.getUserMedia({
        video: { facingMode: 'environment' },
        audio: false,
      })
      const settings = (mediaStream?.getVideoTracks() || [])[0]?.getSettings()

      if (settings && settings.width && settings.height) {
        videoWidth = settings.width
        videoHeight = settings.height
      }
    } catch (e) {
      // do nothing
    }

    if (mediaStream && videoEl) {
      videoEl.srcObject = mediaStream
      videoEl.volume = 0
      videoEl.play()
    } else {
      error = 'Failed to get media stream, please grant camera access'
    }
  }

  function updateOrientation(
    isMobile: boolean,
    innerWidth: number,
    innerHeight: number,
    _videoWidth: number,
    _videoHeight: number,
  ) {
    if (isMobile && _videoWidth && _videoHeight) {
      if (innerWidth > innerHeight) {
        videoWidth = Math.max(_videoWidth, _videoHeight)
        videoHeight = Math.min(_videoWidth, _videoHeight)
      } else {
        videoWidth = Math.min(_videoWidth, _videoHeight)
        videoHeight = Math.max(_videoWidth, _videoHeight)
      }
    }
  }

  function updateFrameSize(
    innerWidth: number,
    innerHeight: number,
    videoWidth: number,
    videoHeight: number,
  ) {
    if (innerWidth && innerHeight && videoWidth && videoHeight) {
      const innerRatio = innerWidth / innerHeight
      const videoRatio = videoWidth / videoHeight

      if (innerRatio > videoRatio) {
        frameHeight = innerHeight - 2 * frameSpacing
        frameWidth = frameHeight * videoRatio
        relativeFrameWidth = videoHeight - 2 * frameSpacing * (videoHeight / innerHeight)
        relativeFrameHeight = relativeFrameWidth * videoRatio
      } else {
        frameWidth = innerWidth - 2 * frameSpacing
        frameHeight = frameWidth / videoRatio
        relativeFrameWidth = videoWidth - 2 * frameSpacing * (videoWidth / innerWidth)
        relativeFrameHeight = relativeFrameWidth / videoRatio
      }

      if (videoRatio > 1) {
        frameWidth = frameHeight
        relativeFrameWidth = relativeFrameHeight
      } else {
        frameHeight = frameWidth
        relativeFrameHeight = relativeFrameWidth
      }
    }
  }

  function validateToken(token: string): string {
    try {
      const { exp } = parseJwt(token)
      if (exp * 1000 > new Date().getTime()) {
        return token
      } else {
        errorTitle = 'Token expired'
      }
    } catch (e) {}

    error = 'Please try to refresh page and scan QR code again.'
    return ''
  }

  async function takePhoto() {
    const canvas = document.createElement('canvas')
    canvas.width = relativeFrameWidth
    canvas.height = relativeFrameHeight

    const dx = (relativeFrameWidth - videoWidth) / 2
    const dy = (relativeFrameHeight - videoHeight) / 2
    canvas.getContext('2d')?.drawImage(videoEl, dx, dy, videoWidth, videoHeight)

    imageBlob = await new Promise(res => canvas.toBlob(res))
    imageUrl = imageBlob ? URL.createObjectURL(imageBlob) : null

    imageUrl && videoEl.pause()
  }

  function retake() {
    imageBlob = null
    imageUrl = null
    videoEl.play()
  }

  async function upload() {
    if (!imageBlob || !token) return

    uploading = true
    try {
      const jpgBlob = await convertImage(imageBlob, EImageType.JPEG, 512, 64)
      const { id } = await uploadsAPI.upload(jpgBlob, undefined, token)
      await aiImagesAPI.uploadImage(id, token)

      done = true
      setTimeout(() => {
        done = false
        retake()
      }, 5000)
    } catch (e) {
      addError(e)
    }
    uploading = false
  }

  onMount(capture)
  ; // prettier-ignore
</script>

<svelte:window bind:innerWidth bind:innerHeight />
<div class="root">
  <!-- svelte-ignore a11y-media-has-caption -->
  <video playsinline bind:this={videoEl} />
  {#if token}
    {#if frameWidth && frameHeight}
      <div class="frame" style={`width: ${frameWidth}px; height: ${frameHeight}px`}>
        {#if imageUrl}
          <!-- svelte-ignore a11y-missing-attribute -->
          <img src={imageUrl} />
        {/if}
      </div>
    {/if}
    <div class="btns">
      {#if done}
        <h1>Done!</h1>
      {:else if imageBlob}
        <Button color="white" on:click={retake}>Retake</Button>
        <Button color="red" on:click={upload} loading={uploading}>Upload</Button>
      {:else}
        <Button color="red" on:click={takePhoto}>Take a photo</Button>
      {/if}
    </div>
  {/if}
  {#if error}
    <div class="error">
      <h2>{errorTitle || 'Error'}</h2>
      {error}
      <div class="btns">
        <Button color="white" on:click={() => window.location.reload()}>Reload</Button>
      </div>
    </div>
  {/if}
</div>

<style lang="scss">
  .root {
    position: relative;
    width: 100%;
    height: 100%;
    background: #000;
    overflow: hidden;
  }

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 4000px solid rgba(black, 0.75);
    box-shadow: inset 0 0 0px 2px white;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .error {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background-color: rgba(black, 0.85);
    font-size: 16px;

    h2 {
      font-size: 21px;
    }
  }

  .btns {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    h1 {
      margin: 0;
      color: #fff;
      font-size: 24px;
      height: 40px;
      line-height: 40px;
    }

    :global(button) {
      width: 120px;
      margin: 0 8px;
    }
  }
</style>
