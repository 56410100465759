import type { Modal } from 'types'
import { ModalType } from 'types'
import { showActiveModal, activeModal } from 'stores/modals'

const defaultSettings: { [key in ModalType]: Partial<Modal> } = {
  [ModalType.CHANNEL_SETTINGS]: {
    scrollable: true,
    transparent: true,
    size: 'small',
  },
  [ModalType.COMMUNITY_PROFILE]: {
    scrollable: false,
    transparent: true,
    size: 'small',
  },
  [ModalType.COMMUNITY_SETTINGS]: {
    scrollable: true,
    transparent: true,
    size: 'small',
  },
  [ModalType.INVITE_MEMBERS]: {
    scrollable: true,
    transparent: true,
    size: 'medium',
  },
  [ModalType.CREATE_REPORT]: {
    scrollable: false,
    transparent: true,
    size: 'small',
  },
  [ModalType.BAN_USER]: {
    scrollable: false,
    transparent: true,
    size: 'small',
  },
  [ModalType.USER_CARD]: {
    scrollable: true,
    transparent: false,
    size: 'xsmall',
  },
  [ModalType.TRUSTED_USER_ONLY]: {
    scrollable: false,
    transparent: false,
    size: 'small',
  },
  [ModalType.MESSAGE_RECIPIENTS]: {
    scrollable: false,
    transparent: true,
    size: 'small',
  },
}
let showActiveModalTimeout: any

export async function openModal(modal: Partial<Modal>): Promise<void> {
  const fullModal = {
    ...defaultSettings[modal.type!],
    ...modal,
    onClose: () => {
      modal.onClose && modal.onClose()
      closeModals()
    },
    onDone: (details: any) => {
      modal.onDone && modal.onDone(details)
      closeModals()
    },
  } as Modal
  const currentActiveModal = activeModal.get()

  if (currentActiveModal !== undefined) {
    await closeModals()
    showActiveModal.set(false)
    clearTimeout(showActiveModalTimeout)
    activeModal.set(fullModal)
    showActiveModalTimeout = setTimeout(() => showActiveModal.set(true))
  } else {
    activeModal.set(fullModal)
    showActiveModal.set(true)
  }
}

export async function closeModals(): Promise<void> {
  return new Promise(resolve => {
    showActiveModal.set(false)
    clearTimeout(showActiveModalTimeout)
    showActiveModalTimeout = setTimeout(() => {
      activeModal.set(undefined)
      resolve()
    }, 200)
  })
}
