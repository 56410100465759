import type { Community, Thread, RolesConfig, InviteLink, User, Member } from 'types'
import * as api from './api'
import { getQueryString } from 'utils'

export type CommunityData = {
  title?: string
  imageURL?: string
  imageId?: string
  isPublic?: boolean
  roles?: RolesConfig
  members?: {
    userIds?: string[]
    emails?: string[]
    phoneNumbers?: string[]
  }
}

export async function getCommunities(): Promise<Community[]> {
  return (await api.get<{ communities: Community[] }>('/communities')).communities
}

export async function getCommunity(id: string): Promise<Community> {
  return (await api.get<{ community: Community }>(`/communities/${id}`)).community
}

export async function getCommunityChannels(id: string): Promise<Thread[]> {
  return (await api.get<{ threads: Thread[] }>(`/threads?communityId=${id}`)).threads
}

export async function createCommunity(data: CommunityData): Promise<Community> {
  return (await api.post<{ community: Community }>('/communities', data)).community
}

export async function updateCommunity(id: string, data: CommunityData): Promise<Community> {
  return (await api.put<{ community: Community }>(`/communities/${id}`, data)).community
}

export async function getMembers(
  id: string,
  filters: {
    offset?: number
    limit?: number
    query?: string
  },
): Promise<User[]> {
  return (
    await api.get<{ members: User[] }>(`/communities/${id}/members${getQueryString(filters)}`)
  ).members
}

export async function addMembers(id: string, members: Member[]): Promise<User[]> {
  return (await api.post<{ members: User[] }>(`/communities/${id}/members/add`, { members }))
    .members
}

export async function removeMembers(id: string, memberIds: string[]): Promise<void> {
  return api.post<void>(`/communities/${id}/members/remove`, { memberIds })
}

export async function banMember(
  id: string,
  data: {
    memberId: string
    duration?: number
    comment?: string
  },
): Promise<void> {
  return api.post<void>(`/communities/${id}/members/ban`, data)
}

export async function joinCommunity(id: string): Promise<Community> {
  return (await api.post<{ community: Community }>(`/communities/${id}/join`)).community
}

export async function leaveCommunity(id: string): Promise<void> {
  return api.post<void>(`/communities/${id}/leave`)
}

export async function createCommunityInviteLink(id: string): Promise<InviteLink> {
  return api.post<InviteLink>(`/communities/${id}/invite`)
}
