import {
  filetoDataURL,
  dataURLtoImage,
  imagetoCanvas,
  canvastoFile,
  Image2CanvasConfig,
  EImageType,
} from 'image-conversion'
export * from './validation'
export * from './data'

const INTERVALS: { label: string; seconds: number }[] = [
  { label: 'year', seconds: 31536000 },
  { label: 'month', seconds: 2592000 },
  { label: 'day', seconds: 86400 },
  { label: 'hour', seconds: 3600 },
  { label: 'minute', seconds: 60 },
  { label: 'second', seconds: 1 },
]
const MONTH_NAMES = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const DAYS_OF_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

function getFormattedDate(
  date: Date,
  compact = true,
  prefomattedDate = '',
  hideYear = false,
  withTime = false,
) {
  const day = date.getDate()
  const month = MONTH_NAMES[date.getMonth()].substr(0, compact ? 3 : undefined)
  const year = date.getFullYear()
  const time = withTime
    ? `  ${date
        .toLocaleTimeString()
        .replace(/(\d{1,2}):(\d{1,2}):(\d{1,2})(\s(A|P)M)?/, '$1:$2$4')
        .toUpperCase()}`
    : ''

  if (prefomattedDate) {
    return `${prefomattedDate}${time}`
  } else if (hideYear) {
    return `${month} ${day}${time}`
  }

  return `${month} ${day}, ${year}${time}`
}

export function timeAgo(dateParam: Date, compact = true, withTime = false): string {
  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
  const DAY_IN_MS = 86400000 // 24 * 60 * 60 * 1000
  const today = new Date()
  const yesterday = new Date(today.getTime() - DAY_IN_MS)
  const seconds = Math.round((today.getTime() - date.getTime()) / 1000)
  const minutes = Math.round(seconds / 60)
  const hours = Math.round(minutes / 60)
  const isToday = today.toDateString() === date.toDateString()
  const isYesterday = yesterday.toDateString() === date.toDateString()
  const isWeekAgo = date.getTime() + 7 * DAY_IN_MS > today.getTime()
  const isThisYear = today.getFullYear() === date.getFullYear()

  if (seconds < 5) {
    return 'now'
  } else if (seconds < 60) {
    return `${seconds}s`
  } else if (seconds < 90) {
    return '1m'
  } else if (minutes < 60) {
    return `${minutes}m`
  } else if (isToday) {
    return `${hours}h`
  } else if (isYesterday) {
    return getFormattedDate(date, compact, 'Yesterday', false, withTime) // Yesterday at 10:20
  } else if (isWeekAgo) {
    const day = DAYS_OF_WEEK[date.getDay()].substr(0, compact ? 3 : undefined)
    return getFormattedDate(date, compact, day, false, withTime) // Wednesday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, compact, '', true, withTime) // 10. January at 10:20
  }

  return getFormattedDate(date, compact, '', false, withTime) // 10. January 2017. at 10:20
}

export function timeSince(date: Date, fullFormat = false): string {
  date = date instanceof Date ? date : new Date(date)
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000)
  const interval = INTERVALS.find(i => i.seconds < seconds)
  if (!interval) return fullFormat ? 'Just now' : '30s'
  const count = Math.floor(seconds / interval!.seconds)

  if (fullFormat) {
    return `${count} ${interval!.label}${count !== 1 ? 's' : ''} ago`
  } else {
    return `${count}${interval!.label[0]}`
  }
}

export function getQueryString(data: { [key: string]: any }): string {
  const query = Object.keys(data)
    .reduce((query, key) => [...query, `${key}=${data[key].toString()}`], [] as string[])
    .join('&')

  return `${query ? '?' : ''}${query}`
}

export function zeroFill(num: number, length: number): string {
  return Array.from({ length: length - num.toString().length }, () => '0').join('') + num
}

export function formatTime(seconds: number, zeroFillMinutes = false): string {
  if (seconds && Number.isFinite(seconds)) {
    seconds = Math.round(seconds)
    const minutes = Math.floor(seconds / 60)
    seconds = Math.round(seconds - minutes * 60)

    return zeroFill(minutes, zeroFillMinutes ? 2 : 1) + ':' + zeroFill(seconds, 2)
  } else {
    return zeroFillMinutes ? '00:00' : '0:00'
  }
}

export async function getVideoThumb(
  blob: Blob,
  time = 0.01,
): Promise<{
  thumb: Blob | null
  width?: number
  height?: number
}> {
  return new Promise(resolve => {
    const video = document.createElement('video')
    const done = (result: any) => {
      resolve(result)
      video.src = ''
      video.onseeked = video.onerror = null
      video.load()
    }
    let retriesCount = 0

    video.onloadedmetadata = video.ondurationchange = () => (video.currentTime = time)
    video.onseeked = () => {
      try {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = video.videoWidth || 1280
        canvas.height = video.videoHeight || 720
        ctx?.drawImage(video, 0, 0, canvas.width, canvas.height)
        canvas.toBlob(blob => {
          if (blob) {
            done({
              thumb: blob,
              width: canvas.width,
              height: canvas.height,
            })
          } else if (retriesCount < 3) {
            // If canvas failed to capture video on this time position, seek further and try again
            video.currentTime += time
            retriesCount += 1
          }
        }, 'image/jpeg')
      } catch (e) {
        console.error(e)
        done({ thumb: null })
      }
    }

    video.onerror = () => done({ thumb: null })
    video.src = URL.createObjectURL(blob)
    video.load()
  })
}

export function timeout(timeout = 0): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, timeout))
}

export function selectText(event: MouseEvent): void {
  const element = event.target as HTMLDivElement
  const selection = window.getSelection()
  const range = document.createRange()

  range.selectNodeContents(element)
  selection?.removeAllRanges()
  selection?.addRange(range)
}

export function animateProperty(
  element: HTMLElement,
  property: 'scrollLeft' | 'scrollTop',
  to: number,
  duration = 300,
): void {
  const start = element[property]
  const change = to - start
  let currentTime = 0
  const increment = 20
  const animateScroll = () => {
    currentTime += increment
    element[property] = easeInOutQuad(currentTime, start, change, duration)
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }

  animateScroll()
}

function easeInOutQuad(t: number, b: number, c: number, d: number): number {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export function parseJwt(token: string): any {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  )

  return JSON.parse(jsonPayload)
}

export function pluralize(number: number, singular: string, plural?: string): string {
  const end = number === 1 ? singular : plural || singular + 's'
  let numberStr = number.toString()

  if (number > 1000) {
    numberStr = (number / 1000).toFixed(1)
  }

  return `${numberStr} ${end}`
}

export function normilizeToValues<T>(value: T | any, validValues: T[], defaultValue?: T): T {
  if (validValues.includes(value)) {
    return value
  } else {
    return defaultValue || validValues[0]
  }
}

export async function convertImage(
  blob: Blob,
  format: EImageType = EImageType.JPEG,
  size?: number,
  roundStep: number = 1,
): Promise<Blob> {
  const image = await filetoDataURL(blob).then(dataURLtoImage)
  const canvasConfig: Image2CanvasConfig = {}

  if (size) {
    const { width, height } = image
    const ratio = width / height
    const round = (num: number) =>
      num % roundStep > roundStep / 2
        ? Math.ceil(num / roundStep) * roundStep
        : Math.floor(num / roundStep) * roundStep

    if (ratio > 1) {
      canvasConfig.width = size
      canvasConfig.height = round(size / ratio)
    } else {
      canvasConfig.width = round(size * ratio)
      canvasConfig.height = size
    }
  }

  return canvastoFile(await imagetoCanvas(image, canvasConfig), 100, format)
}

export function mobileAndTabletCheck(): boolean {
  let check = false
  ;(function (a) {
    if (
      // eslint-disable-next-line max-len
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) || // eslint-disable-next-line max-len
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4),
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || (window as any).opera)
  return check
}

export function throttle<T extends Function>(func: T, timeFrame: number) {
  let lastTime = 0
  return function (...args: any) {
    const now = Date.now()
    if (now - lastTime >= timeFrame) {
      func.call(null, ...args)
      lastTime = now
    }
  }
}

export function debounce<T extends Function>(cb: T, wait = 100) {
  let h: ReturnType<typeof setTimeout>
  const callable = (...args: any) => {
    clearTimeout(h)
    h = setTimeout(() => cb(...args), wait)
  }
  return <T>(<any>callable)
}

export function hasEmoji(str: string): boolean {
  return /\p{Emoji_Presentation}/gu.test(str)
}

export function isSameMediaDevice(a?: MediaDeviceInfo | null, b?: MediaDeviceInfo | null): boolean {
  return a?.deviceId === b?.deviceId && a?.groupId === b?.groupId && a?.label === b?.label
}
