<script lang="ts">
  import type { Message } from 'types'
  import { onDestroy } from 'svelte'
  import Player from 'components/Player.svelte'
  import PlayerControls from 'components/PlayerControls.svelte'
  import Spinner from 'components/Spinner.svelte'
  import Navigation from 'components/Navigation.svelte'
  import UserBadge from 'components/UserBadge.svelte'
  import { threads as threadsStore, playerSettings, session } from 'stores'
  import { goToMessage } from 'actions'
  import { messagesAPI } from 'api'
  import { convertVideoToVKVideo } from 'utils'

  export let params: { token?: string } = {}

  let token: string | undefined
  let message: Message | undefined
  let error = ''
  let currentTime = 0
  let duration = 0
  let isLoading = true
  const unsubscribes: (() => void)[] = []

  $: {
    if (params.token !== token && params.token) {
      token = params.token
      isLoading = true
      getMessage(token)
    }
  }

  unsubscribes.push(threadsStore.subscribe(() => checkMessageAccess()))

  async function getMessage(token: string) {
    try {
      message = await messagesAPI.getSharedMessage(token)
    } catch (e: any) {
      error = e.message || e.toString()
      isLoading = false
    }

    checkMessageAccess()
  }

  function checkMessageAccess() {
    const threads = threadsStore.get()

    // If user has access to the message, redirect directly to this thread with message
    if (message && threads && isLoading) {
      const thread = threads.find(thread => thread.id === message?.threadId)

      thread && goToMessage(thread, message)
      isLoading = false
    }
  }

  onDestroy(() => {
    unsubscribes.forEach(un => un())
  })

  ; // prettier-ignore
</script>

<div class="shared">
  {#if error}
    <div class="error">{error}</div>
  {/if}
  {#if isLoading}
    <div class="spinner">
      <Spinner size="large" color="#000" />
    </div>
  {:else if message}
    <Navigation>
      <div class="info">
        <div class="body">
          <UserBadge user={message.sender} currentUser={$session?.profile} color="white" />
        </div>
      </div>
    </Navigation>
    {#if message.type === 'video' && message.video}
      <div class="player">
        <Player
          bind:currentTime
          bind:duration
          bind:shouldPlay={$playerSettings.shouldPlay}
          bind:showSubtitles={$playerSettings.showSubtitles}
          bind:playbackRate={$playerSettings.playbackRate}
          bind:volume={$playerSettings.volume}
          bind:muted={$playerSettings.muted}
          on:ended={() => {
            currentTime = 0
          }}
          isHorizontal={(message.video.width || 16) / (message.video.height || 9) > 1}
          videoId={message.video.id}
          videos={[convertVideoToVKVideo(message.video)]} />
      </div>
    {:else if message.type === 'image'}
      {#if message.thumbnailURL}
        <div class="image">
          <img src={message.thumbnailURL} alt={message.description} />
        </div>
      {/if}
      {#if message.description}
        <div class="description">{message.description}</div>
      {/if}
    {/if}
    <div class="controls">
      <PlayerControls
        bind:currentTime
        bind:shouldPlay={$playerSettings.shouldPlay}
        bind:showSubtitles={$playerSettings.showSubtitles}
        bind:playbackRate={$playerSettings.playbackRate}
        bind:volume={$playerSettings.volume}
        bind:muted={$playerSettings.muted}
        {duration} />
    </div>
  {/if}
</div>

<style lang="scss">
  .shared {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px;
    box-sizing: border-box;
  }

  .player,
  .image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    :global(.windows) & {
      top: 24px;
    }
  }

  .image {
    background: #000;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .description {
    position: absolute;
    top: 60px;
    left: 24px;
    background: rgba(0, 0, 0, 0.6);
    padding: 10px 16px;
    color: #fff;
    font-size: 18px;
    line-height: 1.2;
    margin-top: 4px;
    border-radius: 10px;
  }

  .controls {
    display: flex;
    position: absolute;
    right: 12px;
    bottom: 16px;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      height: 100px;
      left: -3000px;
      right: -20px;
      bottom: -20px;
      background: linear-gradient(
        transparent 0,
        rgba(0, 0, 0, 0.1) 25%,
        rgba(0, 0, 0, 0.2) 50%,
        rgba(0, 0, 0, 0.3) 75%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 348px;
    transform: translate(-50%, -50%);
    color: rgba(0, 0, 0, 0.75);
    font-size: 16px;
    line-height: 1.4;
    text-align: center;
  }

  .info {
    position: relative;
    color: #fff;
    height: 28px;
    border-radius: 14px;
    font-size: 14px;
    display: inline-flex;
    transition: background-color 0.2s;
    cursor: pointer;

    .body {
      position: relative;
      padding: 4px 10px;
      height: 100%;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    :global(.user-badge) {
      position: relative;
      margin-left: -7px;
    }

    :global(.user-info) {
      font-weight: 600;
      margin-top: -2px;
    }
  }
</style>
