import type { Draft, Message, ContactPoint } from 'types'
import { Upload, UploadState } from 'vendors/videokit'
import { drafts, noMoreDrafts, videos, uploadingDrafts } from 'stores'
import { draftsAPI, uploadsAPI } from 'api'
import { mapContactPoints } from 'utils'
import { addNewMessage } from './messages'

const perPage = 30

export async function loadDrafts(): Promise<void> {
  const draftsData = await draftsAPI.getDrafts({
    limit: perPage,
    offset: drafts.get()?.length || 0,
  })
  noMoreDrafts.set(draftsData.length < perPage)
  drafts.update(drafts => [...drafts, ...draftsData])
}

export async function createDraft(
  upload: Upload,
  thumb: Blob | null,
  recipients: ContactPoint[],
  draftId?: string,
): Promise<Draft> {
  const { video } = upload

  if (!video) throw new Error('Upload is failed')

  const videoId = video.id
  const draftData = { videoId, recipients: mapContactPoints(recipients) }
  const draft = draftId
    ? await draftsAPI.updateDraft(draftId, draftData)
    : await draftsAPI.createDraft(draftData)

  if (thumb) {
    draft.thumbnailURL = URL.createObjectURL(thumb)
    draft.video.width = draft.video.width || video.width
    draft.video.height = draft.video.height || video.height
  }

  uploadingDrafts.update(uploadingDrafts => ({
    ...uploadingDrafts,
    [draft.id]: upload.progress,
  }))
  videos.update(value => ({ ...value, [video.id]: video }))

  if (drafts.get()?.length === 0) await loadDrafts()
  drafts.update(drafts =>
    draftId ? drafts.map(d => (d.id === draftId ? draft : d)) : [draft, ...(drafts || [])],
  )

  if (thumb) {
    const { id } = await uploadsAPI.upload(thumb)
    await draftsAPI.updateDraft(draft.id, { thumbnailId: id })
  }

  upload.subscribe('progress', (event, { progress }) =>
    uploadingDrafts.update(d => ({ ...d, [draft.id]: progress })),
  )
  upload.subscribe('error', (event, { error }) => {
    console.error(
      `Failed to upload video: ${error}`,
      JSON.stringify({
        videoId: upload.video.id,
        state: upload.state,
        progress: upload.progress,
        online: navigator.onLine,
      }),
    )
  })
  upload.subscribe('stateChanged', (event, { state }) => {
    state === UploadState.READY_TO_PLAY &&
      uploadingDrafts.update(uploadingDrafts => {
        delete uploadingDrafts[draft.id]
        return uploadingDrafts
      })
  })

  return draft
}

export async function updateDraft(draft: Draft, recipients: ContactPoint[]): Promise<void> {
  const updatedDraft = await draftsAPI.updateDraft(draft.id, {
    recipients: mapContactPoints(recipients),
  })

  drafts.update(drafts => drafts.map(d => (d.id === draft.id ? updatedDraft : d)))
}

export async function sendDraft(draft: Draft, recipients?: ContactPoint[]): Promise<Message> {
  const messages = await draftsAPI.sendDraft(draft.id, recipients && mapContactPoints(recipients))
  const message = messages[0]

  drafts.update(drafts => drafts.filter(d => d.id !== draft.id))
  await Promise.all(messages.map(message => addNewMessage(message)))

  return message
}

export async function deleteDraft(draftId: string): Promise<void> {
  await draftsAPI.deleteDraft(draftId)

  drafts.update(drafts => drafts.filter(d => d.id !== draftId))
  uploadingDrafts.update(uploadingDrafts => {
    delete uploadingDrafts[draftId]
    return uploadingDrafts
  })
}
