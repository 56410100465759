<script lang="ts">
  import highlightWords from 'highlight-words'

  export let text = ''
  export let query = ''
  export let matchExactly = false

  $: chunks = highlightWords({ text, query, matchExactly })
</script>

{#each chunks as chunk (chunk.key)}
  {#if chunk.match}
    <span class="highlight">{chunk.text}</span>
  {:else}
    {chunk.text}
  {/if}
{/each}
