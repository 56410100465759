import type { Session, User } from 'types'
import * as api from './api'

type UserData = {
  email: string
  password: string
  fullName?: string
  username?: string
  recaptchaToken?: string
}

export type PasswordsData = {
  currentPassword: string
  newPassword: string
  repeatNewPassword: string
}

const source = 'macOS'

export async function login(data: {
  email: string
  password: string
  recaptchaToken?: string
  code?: string
}): Promise<Session> {
  return api.post<Session>('/auth/login', data)
}

export async function sendSMS(phoneNumber: string): Promise<{ message: string }> {
  return api.post<{ message: string }>('/auth/sms/code', { phoneNumber })
}

export async function loginUsingGoogle(idToken: string): Promise<Session> {
  return api.post<Session>('/auth/login', { source, google: { idToken } })
}

export async function loginUsingApple(
  authorizationCode: string,
  idToken: string,
): Promise<Session> {
  return api.post<Session>('/auth/login', { source, apple: { authorizationCode, idToken } })
}

export async function loginUsingPhone(phoneNumber: string, code: string): Promise<Session> {
  return api.post<Session>('/auth/login', { source, sms: { phoneNumber, code } })
}

export async function register(userData: UserData): Promise<Session> {
  return api.post<Session>('/auth/signup', { source, ...userData })
}

export async function setPassword(
  passwords: Omit<PasswordsData, 'currentPassword'>,
): Promise<void> {
  return api.post<void>('/auth/password', passwords)
}

export async function changePassword(passwords: PasswordsData): Promise<void> {
  return api.put<void>('/auth/password', passwords)
}

export async function addPhone(phoneNumber: string, code: string): Promise<User> {
  return api.post<User>('/auth/phone', { phoneNumber, code })
}

export async function invalidateRefreshToken(id: string, refreshToken: string): Promise<void> {
  return api.del<void>(`/auth/refresh/${id}`, { refreshToken })
}
