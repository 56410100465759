<script lang="ts">
  import type { User, ContactPoint, Thread, Contact } from 'types'
  import { ModalType } from 'types'
  import { onDestroy } from 'svelte'
  import CloseIcon from 'assets/icons/close.svg'
  import PeopleIcon from 'assets/icons/people.svg'
  import ContactPointBadge from 'components/ContactPointBadge.svelte'
  import { fetchContacts, openModal } from 'actions'
  import { session, contacts as contactsStore, threads as threadsStore, communities } from 'stores'
  import {
    isThreadMatch,
    isContactPointsEqual,
    getThreadRecipients,
    validateEmail,
    validatePhoneNumber,
  } from 'utils'
  import ChannelSettings from '../modals/ChannelSettings.svelte'

  export let recipients: ContactPoint[] = []
  export let placeholder = ''
  export let allowCreateChannel = true

  let threads: Thread[] = []
  let currentUser: User | undefined
  let contacts: Contact[] = []
  let field: HTMLInputElement
  let fieldValue = ''
  let fieldFocused = false
  let activeRecipientIndex = -1
  let suggestedThreads: ContactPoint[] = []
  let suggestedContacts: ContactPoint[] = []
  let firstSuggestionIndex = allowCreateChannel ? 0 : 1
  let activeSuggestionIndex = firstSuggestionIndex - 1
  let isNewChannel = false
  const unsubscribes: (() => void)[] = []

  fetchContacts()

  $: (threads || contacts) && filterSuggestions(fieldValue)
  $: firstSuggestionIndex = allowCreateChannel ? 0 : 1
  $: !fieldFocused && handleBlur()

  unsubscribes.push(
    session.subscribe(session => {
      currentUser = session?.profile
    }),
  )
  unsubscribes.push(threadsStore.subscribe(_threads => (threads = _threads || [])))
  unsubscribes.push(
    contactsStore.subscribe(_contacts => {
      contacts = _contacts || []
    }),
  )

  function handleKeyUp(event: KeyboardEvent) {
    const key = event.key.toLowerCase()
    const suggestionsCount = suggestedThreads.length + suggestedContacts.length

    if (key === 'enter') {
      if (allowCreateChannel && activeSuggestionIndex === 0) {
        createChannel()
        return
      }

      if (activeSuggestionIndex >= 0 && getSuggestion(activeSuggestionIndex)) {
        addRecipient(getSuggestion(activeSuggestionIndex))
      } else if (fieldValue) {
        if (session.get()?.profile.isTrusted) {
          addRecipient({ contactPoint: fieldValue })
        } else {
          openModal({
            type: ModalType.TRUSTED_USER_ONLY,
            arguments: { action: 'create new threads' },
          })
        }
      } else {
        return
      }
      field.value = ''
      fieldValue = ''
      filterSuggestions('')
    } else if (key === 'backspace' && !field.value) {
      if (activeRecipientIndex < 0) {
        moveRecipientFocus(-1)
      } else {
        recipients = recipients.filter((_, index) => index !== activeRecipientIndex)
        activeRecipientIndex--
        filterSuggestions(fieldValue)
      }
    } else if (key === 'arrowleft' && !field.value) {
      moveRecipientFocus(-1)
    } else if (key === 'arrowright' && !field.value) {
      moveRecipientFocus(1)
    }
    if (key === 'arrowup' && suggestionsCount > 0) {
      activeSuggestionIndex =
        activeSuggestionIndex === firstSuggestionIndex
          ? suggestionsCount
          : activeSuggestionIndex - 1
    } else if (key === 'arrowdown' && suggestionsCount > 0) {
      activeSuggestionIndex =
        activeSuggestionIndex === suggestionsCount
          ? firstSuggestionIndex
          : activeSuggestionIndex + 1
    }
  }

  function handleBlur() {
    if (fieldValue && (validateEmail(fieldValue) || validatePhoneNumber(fieldValue))) {
      addRecipient({ contactPoint: fieldValue })
      field.value = ''
      fieldValue = ''
      filterSuggestions('')
    }
  }

  function createChannel() {
    if (session.get()?.profile.isTrusted) {
      isNewChannel = true
    } else {
      openModal({
        type: ModalType.TRUSTED_USER_ONLY,
        arguments: { action: 'create channels' },
      })
    }
  }

  function moveRecipientFocus(direction: number) {
    if (direction > 0) {
      activeRecipientIndex =
        activeRecipientIndex >= recipients.length - 1 ? -1 : activeRecipientIndex + 1
    } else {
      activeRecipientIndex =
        activeRecipientIndex < 0 ? recipients.length - 1 : activeRecipientIndex - 1
    }
  }

  function addRecipient(recipient: ContactPoint) {
    if (recipient.thread && !recipient.thread.channel) {
      const recipients = getThreadRecipients(recipient.thread, currentUser)

      // If this is a one-on-one thread use the user as recipient instead of the thread
      if (recipients.length === 1) {
        recipient = { user: recipients[0] }
      }
    }

    recipients = [...recipients, recipient]
    field.value = ''
    fieldValue = ''
    filterSuggestions(fieldValue)
  }

  function removeRecipient(recipient: ContactPoint) {
    recipients = recipients.filter(r => r !== recipient)
    filterSuggestions(fieldValue)
  }

  function getSuggestion(index: number): ContactPoint {
    index--
    return index < suggestedThreads.length
      ? suggestedThreads[index]
      : suggestedContacts[index - suggestedThreads.length]
  }

  function filterSuggestions(query: string) {
    const activeSuggestion = getSuggestion(activeSuggestionIndex)

    suggestedThreads = threads
      .filter(thread => {
        if (recipients.find(recipients => recipients.thread?.id === thread.id)) {
          return false
        }

        const threadRecipients = getThreadRecipients(thread, currentUser)

        if (
          threadRecipients.length === 1 &&
          recipients.find(({ user }) => user && user.id === threadRecipients[0].id)
        ) {
          return false
        }

        return isThreadMatch(thread, query, currentUser)
      })
      .sort(a => (a.members.length === 2 ? -1 : 1))
      .map(thread => {
        return {
          thread,
          community: thread.communityId
            ? $communities.find(c => c.id === thread.communityId)
            : undefined,
        }
      })
      .slice(0, 8)

    suggestedContacts = []

    for (const contact of contacts) {
      if (suggestedContacts.length > 8) {
        break
      }
      if (recipients.find(recipients => recipients.contact?.identifier === contact.identifier)) {
        continue
      }

      if (
        [
          ...contact.emailAddresses,
          ...contact.phoneNumbers,
          ...(contact.firstName && [contact.firstName]),
          ...(contact.lastName && [contact.lastName]),
          ...(contact.nickname && [contact.nickname]),
        ].reduce(
          (result: boolean, str: string) => result || str.toLowerCase().indexOf(query) !== -1,
          false,
        )
      ) {
        suggestedContacts.push({ contact })
      }
    }

    const newActiveSuggestion = getSuggestion(activeSuggestionIndex)

    if (!newActiveSuggestion || !isContactPointsEqual(activeSuggestion, newActiveSuggestion)) {
      activeSuggestionIndex = firstSuggestionIndex - 1
    }
  }

  function onChannelCreated(thread: Thread) {
    isNewChannel = false
    addRecipient({ thread })
  }

  onDestroy(() => unsubscribes.forEach(un => un()))

  ; // prettier-ignore
</script>

<div class="recipients" class:new-channel={isNewChannel}>
  {#if isNewChannel}
    <ChannelSettings
      reversedStepsOrder={true}
      on:close={() => {
        isNewChannel = false
      }}
      on:done={({ detail }) => onChannelCreated(detail)} />
  {/if}
  <div class="recipients-inner">
    {#each recipients as recipient, index}
      <div
        class="recipient"
        class:active={activeRecipientIndex === index}
        class:contact={!!recipient.contactPoint}>
        <ContactPointBadge contactPoint={recipient} {currentUser} />
        <div class="delete" on:click={() => removeRecipient(recipient)}>
          <CloseIcon />
        </div>
      </div>
    {/each}
    <input
      {placeholder}
      bind:this={field}
      bind:value={fieldValue}
      on:keyup={handleKeyUp}
      on:focus={() => {
        fieldFocused = true
      }}
      on:blur={() => {
        fieldFocused = false
      }}
      class="recipients-field" />
    <!-- placeholder="Type any email, phone number, name, or channel" /> -->
  </div>
  <div
    class="suggestions"
    class:active={fieldFocused && (suggestedContacts.length > 0 || suggestedThreads.length > 0)}>
    {#if allowCreateChannel}
      <div
        class="suggestion"
        class:active={activeSuggestionIndex === 0}
        on:mousedown|preventDefault={createChannel}>
        <div class="icon"><PeopleIcon /></div>
        New Channel
      </div>
    {/if}
    {#if suggestedThreads.length > 0}
      {#each suggestedThreads as suggestion, index}
        <div
          class="suggestion"
          class:active={activeSuggestionIndex === index + 1}
          on:mousedown|preventDefault={() => addRecipient(suggestion)}>
          <ContactPointBadge contactPoint={suggestion} {currentUser} size="small" showDate={true} />
        </div>
      {/each}
    {/if}
    {#if suggestedContacts.length > 0}
      <h3 class="suggestions-title">Contacts</h3>
      {#each suggestedContacts as suggestion, index}
        <div
          class="suggestion"
          class:active={activeSuggestionIndex === suggestedThreads.length + index + 1}
          on:mousedown|preventDefault={() => addRecipient(suggestion)}>
          <ContactPointBadge contactPoint={suggestion} {currentUser} size="small" showDate={true} />
        </div>
      {/each}
    {/if}
  </div>
</div>

<style lang="scss">
  :global(.screen-recording) {
    .recipients {
      width: 280px;
      transform: translateY(50%);
      margin: 0 4px 32px;
    }

    .recipients-field {
      min-width: 100px;
    }

    :global(.recorder-controls:not(.top)) {
      .recipients {
        flex-direction: column-reverse;
        transform: translateY(-50%);
        margin-bottom: -32px;

        &::before {
          top: auto;
          bottom: 2px;
        }
      }

      .suggestions {
        flex-direction: column-reverse;
        margin: 12px 0 0 -32px;
      }
    }
  }

  .recipients {
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 16px;
    // margin-top: -2px;
    max-width: 440px;
    background: rgba(255, 255, 255, 0.75);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    overflow: hidden;
    font-size: 13px;
    line-height: 28px;
    padding: 0 1px 0 32px;
    color: rgba(0, 0, 0, 0.5);
    z-index: 2;

    &.new-channel {
      padding: 0;

      &::before {
        display: none;
      }

      :global(.settings) {
        background: none;
      }

      .recipients-inner {
        display: none;
      }
    }

    &::before {
      content: 'To:';
      position: absolute;
      font-size: 15px;
      top: 2px;
      left: 10px;
    }
  }

  .recipients-inner {
    display: flex;
    width: 100%;
    height: 100%;
    overflow-x: auto;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    line-height: 28px;
    padding: 2px 10px 2px 0;
    border-radius: 0 32px 32px 0;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .recipient {
    display: flex;
    position: relative;
    height: 28px;
    padding: 2px 30px 2px 2px;
    line-height: 24px;
    color: #000;
    background: #ffffff;
    margin: 0 5px 0 2px;
    border-radius: 16px;
    box-sizing: border-box;
    white-space: nowrap;

    &.contact {
      padding-left: 10px;
    }

    &.active {
      box-shadow: 0 0 0 2px var(--blue-color);
    }
  }

  .suggestions {
    display: flex;
    flex-direction: column;
    margin: 0 0 12px -32px;
    max-height: 320px;
    overflow-y: auto;

    &:not(.active) {
      display: none;
    }
  }

  .suggestions-title {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    margin: 16px 12px 12px 32px;
    line-height: 1;
  }

  .suggestion {
    padding: 5px 12px 5px 32px;
    cursor: pointer;
    color: #000;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;

    &:hover,
    &.active {
      background: rgba(0, 0, 0, 0.05);
    }

    .icon {
      float: left;
      width: 21px;
      height: 21px;
      background: rgba(0, 0, 0, 0.1);
      margin-right: 8px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .recipients-field {
    background: none;
    flex-grow: 2;
    height: 28px;
    padding: 0;
    margin: 0;
    border: none;
    font-size: 15px;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.8);
    outline: none;
    min-width: 300px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  .delete {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    display: flex;
  }
</style>
