<script lang="ts">
  import { ModalType } from 'types'
  import Modal from 'components/Modal.svelte'
  import { closeModals } from 'actions'
  import { activeModal, showActiveModal } from 'stores'
  import UserCard from 'components/UserCard.svelte'
  import CommunitySettings from './CommunitySettings.svelte'
  import InviteMembers from './InviteMembers.svelte'
  import CommunityProfile from './CommunityProfile.svelte'
  import ChannelSettings from './ChannelSettings.svelte'
  import CreateReport from './CreateReport.svelte'
  import TrustedUserOnly from './TrustedUserOnly.svelte'
  import BanUser from './BanUser.svelte'
  import MessageRecipients from './MessageRecipients.svelte'

  function onDone(event: CustomEvent) {
    $activeModal?.onDone(event.detail)
  }

  function onClose() {
    $activeModal?.onClose()
  }
</script>

<Modal
  active={$showActiveModal}
  onClose={$activeModal?.onClose || closeModals}
  size={$activeModal?.size || 'small'}
  scrollable={$activeModal?.scrollable ?? true}
  transparent={$activeModal?.transparent ?? true}>
  {#if $activeModal}
    {#if $activeModal.type === ModalType.CHANNEL_SETTINGS}
      <ChannelSettings {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {:else if $activeModal.type === ModalType.INVITE_MEMBERS}
      <InviteMembers {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {:else if $activeModal.type === ModalType.COMMUNITY_PROFILE}
      <CommunityProfile {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {:else if $activeModal.type === ModalType.COMMUNITY_SETTINGS}
      <CommunitySettings {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {:else if $activeModal.type === ModalType.CREATE_REPORT}
      <CreateReport {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {:else if $activeModal.type === ModalType.BAN_USER}
      <BanUser {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {:else if $activeModal.type === ModalType.USER_CARD}
      <UserCard {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {:else if $activeModal.type === ModalType.TRUSTED_USER_ONLY}
      <TrustedUserOnly {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {:else if $activeModal.type === ModalType.MESSAGE_RECIPIENTS}
      <MessageRecipients {...$activeModal.arguments} on:done={onDone} on:close={onClose} />
    {/if}
  {/if}
</Modal>
