import type { Report } from 'types'
import { derived } from 'svelte/store'
import store from './store'
import { session } from './session'

export const reportsCounts = store<{ [key: string]: number } | undefined>()

export const reportsTotalCount = derived<[typeof reportsCounts], number>(
  [reportsCounts],
  ([counts], set) => set(Object.values(counts || {}).reduce((acc, count) => acc + count, 0)),
)

export const reportsToReview = store<Report[]>([])

export const currentReportId = store<string | undefined>()

export const currentReport = derived<
  [typeof reportsToReview, typeof currentReportId],
  Report | undefined
>([reportsToReview, currentReportId], ([reportsToReview, currentReportId], set) => {
  set(reportsToReview?.find(report => report.id === currentReportId))
})

export const noReplies = derived<[typeof reportsCounts, typeof reportsToReview], boolean>(
  [reportsCounts, reportsToReview],
  ([reportsCounts, reportsToReview], set) => set(!!reportsCounts && reportsToReview.length === 0),
)

session.subscribe(session => {
  if (session === null) {
    reportsToReview.set([])
    reportsCounts.set(undefined)
  }
})
