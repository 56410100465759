<script lang="ts">
  import type { SelectItem } from 'types'

  export let items: SelectItem[] = []
  export let selected: SelectItem | undefined
  export let disabled = false
  export let bottom = false
  export let active = false

  let root: HTMLElement
  let hidden = true
  let hideTimeout: any

  function toggleActive(state?: boolean) {
    if (disabled) return

    if (typeof state === 'boolean' ? state : !active) {
      hidden = false
      hideTimeout && clearTimeout(hideTimeout)
      setTimeout(() => {
        active = true
        root?.focus()
      }, 10)
    } else {
      active = false
      hideTimeout = setTimeout(() => {
        hidden = true
      }, 200)
    }
  }
</script>

<div
  bind:this={root}
  tabIndex="-1"
  class="select"
  class:active
  class:bottom
  class:hidden
  class:disabled
  on:click|preventDefault={toggleActive.bind(null, undefined)}
  on:blur={() => toggleActive(false)}>
  {#if items && items.length > 0}
    <div class="btn">{selected?.label}</div>
    <div class="list">
      {#each items as item}
        <div
          class={`item${item.value === (selected && selected.value) ? ' item--active' : ''}`}
          on:click={() => {
            selected = item
          }}>
          {item.label}
        </div>
      {/each}
    </div>
  {/if}
</div>

<style lang="scss">
  .select {
    position: relative;
    background: rgba(255, 255, 255, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 100%;
    height: 40px;
    font: 14px/20px var(--main-font);
  }

  .btn {
    padding: 10px 12px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    font-weight: bold;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 14px;
      right: 10px;
      background: url(assets/icons/down-arrow.svg);
      width: 12px;
      height: 12px;
      opacity: 0.5;
      transition: transform 0.2s;

      .select.active & {
        transform: rotate(180deg);
      }
    }
  }

  .list {
    position: absolute;
    top: calc(100%);
    left: 50%;
    width: 100%;
    padding: 8px 0;
    transform: translateX(-50%) translateY(0);
    background: rgba(239, 239, 239, 0.95);
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    box-sizing: border-box;
    text-align: left;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.2s, transform 0.2s;

    .bottom > & {
      top: auto;
      bottom: calc(100% + 8px);
    }

    .hidden > & {
      display: none;
    }

    .active > & {
      opacity: 1;
      transform: translateX(-50%) translateY(10px);
    }

    .active.bottom > & {
      transform: translateX(-50%) translateY(-10px);
    }
  }

  .item {
    position: relative;
    padding: 5px 8px 5px 28px;
    cursor: pointer;

    &:hover {
      background: var(--blue-color);
      color: #fff;
    }
  }

  .item--active {
    &::before {
      content: '';
      position: absolute;
      top: 12px;
      left: 10px;
      width: 10px;
      height: 4px;
      transform: rotate(-45deg);
      border-bottom: 1px solid #000;
      border-left: 1px solid #000;
    }

    &:hover::before {
      border-color: #fff;
    }
  }
</style>
