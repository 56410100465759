import store from './store'

export const timeRefreshCounter = store<number>(0)

export const online = store<boolean>(true)

export const wsConnected = store<boolean>(false)

export const isAppFocused = store<boolean>(true)

setInterval(() => timeRefreshCounter.update(t => t + 1), 60 * 1000)
