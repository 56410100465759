<script lang="ts">
  import type { VKCue, Video as VKVideo } from 'vendors/videokit'
  import { loadSubtitles } from 'actions'
  import { formatTime, animateProperty } from 'utils'
  import Spinner from 'components/Spinner.svelte'

  export let currentTime = 0
  export let shouldPlay: boolean
  export let video: VKVideo | undefined = undefined
  export let summary: string | undefined = undefined

  let root: HTMLDivElement
  let subtitlesCues: VKCue[][] = []
  let isLoading = false
  let error = ''

  $: updateSubtitles(video)
  $: summaryLines = summary?.replace(/-|•/g, '').split(/\n/)

  async function updateSubtitles(video?: VKVideo) {
    subtitlesCues = []
    if (video) {
      isLoading = true
      try {
        subtitlesCues = await loadSubtitles(video)
      } catch (e: any) {
        error = e.message ? e.message : e.toString()
      }
      isLoading = false
    }
  }

  function seek(time: number) {
    shouldPlay = true
    currentTime = time
  }

  export function scrollToCurrent(withAnimation = true) {
    if (!root) return
    const activeCue = root.querySelector('.active') as HTMLElement
    if (!activeCue) return

    const scrollTop = activeCue.offsetTop - (root.offsetHeight - activeCue.offsetHeight) / 2

    if (withAnimation) {
      animateProperty(root, 'scrollTop', scrollTop)
    } else {
      root.scrollTop = scrollTop
    }
  }
</script>

<div
  class="subtitles"
  class:with-summary={summaryLines && summaryLines.length > 0}
  bind:this={root}>
  {#if subtitlesCues && subtitlesCues.length > 0}
    {#if summaryLines && summaryLines.length > 0}
      <div class="summary">
        <div class="scroll-box">
          {#each summaryLines as line}
            <p>{line}</p>
          {/each}
        </div>
      </div>
    {/if}
    <div class="trascript">
      <div class="scroll-box">
        {#each subtitlesCues as paragraph}
          {#if paragraph && paragraph.length > 0}
            <p>
              {#each paragraph as cue}
                <span
                  data-time={`${formatTime(cue.start)} - ${formatTime(cue.end)}`}
                  class:active={currentTime > cue.start && currentTime < cue.end}
                  on:click|stopPropagation={() => seek(cue.start)}>
                  {cue.text}
                </span>
              {/each}
            </p>
          {/if}
        {/each}
      </div>
    </div>
  {:else if isLoading}
    <Spinner color="white" size="large" />
  {:else if error}
    <div class="error">
      Error:<br />
      {error}
    </div>
  {/if}
</div>

<style lang="scss">
  .subtitles {
    display: flex;
    align-items: stretch;
    position: relative;
    top: 0;
    width: 100%;
    max-width: 960px;
    max-height: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 32px;
    line-height: 1.5;
    color: #fff;
    padding: 0;

    &::-webkit-scrollbar {
      display: none;
    }

    &.with-summary {
      max-width: 1440px;
    }
  }

  p {
    margin: 0 0 48px;
  }

  span {
    position: relative;
    margin-right: 8px;
    cursor: pointer;

    &::before {
      content: attr(data-time);
      position: absolute;
      bottom: 100%;
      left: 0;
      opacity: 0;
      font-size: 14px;
      padding: 0 6px;
      margin-bottom: 2px;
      white-space: nowrap;
      backdrop-filter: blur(5px);
    }

    &:hover {
      background: rgba(255, 228, 85, 0.2);

      &::before {
        opacity: 1;
        background: rgba(255, 228, 85, 0.2);
      }
    }

    &.active {
      background: rgba(255, 228, 85, 0.4);

      &::before {
        opacity: 1;
        background: rgba(255, 228, 85, 0.4);
      }
    }
  }

  .summary {
    flex: 1 0 30%;
    margin-right: 48px;
    font-size: 21px;

    &::before {
      content: 'Summary';
    }

    p {
      margin: 0 0 16px;
    }
  }

  .trascript {
    flex: 1 1 auto;

    &::before {
      content: 'Transcript';
    }
  }

  .summary,
  .trascript {
    &::before {
      display: block;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 4px;
      opacity: 0.5;
    }
  }

  .scroll-box {
    padding-top: 16px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }
</style>
