/* eslint-disable no-undef */
declare global {
  let __production__: boolean
}

export const isElectron = navigator.userAgent.indexOf('Electron') >= 0
export const isMac = isElectron && navigator.userAgent.indexOf('Mac') >= 0
export const isWindows = isElectron && navigator.userAgent.indexOf('Windows') >= 0
export const supportsHEVC = MediaSource.isTypeSupported('video/mp4;codecs="hev1.1.6.L120"')

// App version and prod mode are set from rollup
export const version = '__version__'
export const production = __production__
