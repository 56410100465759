<script lang="ts">
  import type { Community, Thread, ContactPoint } from 'types'
  import { onDestroy } from 'svelte'
  import {
    getInviteLink,
    getChannelInviteLink,
    addError,
    addMembers,
    removeMembers,
    addChannelMembers,
    removeChannelMembers,
  } from 'actions'
  import { isContactPointsEqual, contactPointToMember, selectText } from 'utils'
  import { session, communities, threads } from 'stores'
  import Spinner from 'components/Spinner.svelte'
  import Button from 'components/Button.svelte'
  import ContactPointList from 'components/ContactPointList.svelte'

  export let community: Community | undefined = undefined
  export let thread: Thread | undefined = undefined

  let members: ContactPoint[] = (community || thread)?.members?.map(user => ({ user })) || []
  let error = ''
  let inviteLink = ''
  let inviteLinkRequest: Promise<void> | undefined
  let copied = false
  let loadingMembers: { [id: number]: boolean } = {}
  const unsubscribes: (() => void)[] = []

  $: {
    members = community
      ? ($communities.find(c => c.id === community?.id) || community)?.members?.map(user => ({
          user,
        })) || []
      : (($threads || []).find(t => t.id === thread?.id) || thread)?.members?.map(user => ({
          user,
        })) || []
  }
  $: (community || thread) && loadInviteLink()

  function loadInviteLink() {
    if (community) {
      if (community.inviteLink) {
        inviteLink = community.inviteLink
      } else {
        inviteLinkRequest = getInviteLink(community.id)
          .then(link => {
            inviteLink = link
          })
          .catch(addError)
      }
    } else if (thread) {
      if (thread.inviteLink) {
        inviteLink = thread.inviteLink
      } else {
        inviteLinkRequest = getChannelInviteLink(thread.id)
          .then(link => {
            inviteLink = link
          })
          .catch(addError)
      }
    }
  }

  function copyLink() {
    try {
      inviteLink && navigator.clipboard?.writeText(inviteLink)
      copied = true
      setTimeout(() => {
        copied = false
      }, 3000)
    } catch (e) {}
  }

  function canRemoveMember(member: ContactPoint) {
    const role = community?.roles[session.get()?.profile.id || '']
    const memberRole = community?.roles[member.user?.id || '']

    // Only onwer and admin can remove member
    if (role !== 'admin' && role !== 'owner') {
      return false
    }

    // Nobody can remove owner
    if (memberRole === 'owner') {
      return false
    } else if (memberRole === 'admin') {
      // Only owner can remove admins
      return role === 'owner'
    }

    return true
  }

  function addMember(member: ContactPoint) {
    if (!community && !thread) return

    if (!members.find(r => isContactPointsEqual(r, member))) {
      members = [...members, member]

      const index = members.length - 1
      updateLoadingMembers(index, true)
      ;(community
        ? addMembers(community!.id, [contactPointToMember(member)!])
        : addChannelMembers(thread!.id, [contactPointToMember(member)!])
      )
        .catch(e => {
          members = members.filter((_, i) => i !== index)
          setError(e)
        })
        .finally(() => updateLoadingMembers(index, false))
    }
  }

  function removeMember(member: ContactPoint) {
    if (!community && !thread) return

    const index = members.findIndex(r => isContactPointsEqual(r, member))

    if (member.user && index !== -1) {
      updateLoadingMembers(index, true)
      ;(community
        ? removeMembers(community!.id, [member.user.id])
        : removeChannelMembers(thread!.id, [member.user.id])
      )
        .catch(setError)
        .finally(() => updateLoadingMembers(index, false))
    }
  }

  function updateLoadingMembers(index: number, loading: boolean) {
    const newLoadingMembers = { ...loadingMembers }

    if (loading) {
      newLoadingMembers[index] = true
    } else {
      delete newLoadingMembers[index]
    }

    loadingMembers = newLoadingMembers
  }

  function setError(e: any) {
    error = e.message || e
  }

  onDestroy(() => unsubscribes.forEach(un => un()))

  ; // prettier-ignore
</script>

<div class="settings">
  <div class="title">
    {#if community}
      <h1>Invite Friends to <span class="community-label">{community.title}</span></h1>
    {:else if thread}
      <h1>Invite Friends to <span>#{thread.title}</span></h1>
    {/if}
  </div>
  <div class="invite">
    <div class="link" on:click={selectText}>
      {#await inviteLinkRequest}
        <Spinner color="#000" />
      {:then}
        {inviteLink}
      {/await}
    </div>
    <Button on:click={copyLink}>{copied ? 'Copied!' : 'Copy Link'}</Button>
  </div>
  <ContactPointList
    bind:contactPoints={members}
    bind:loadingContacts={loadingMembers}
    showFullInfo={false}
    addContactPoint={addMember}
    canRemoveContactPoint={canRemoveMember}
    removeContactPoint={removeMember} />
  <div class="error">{error}</div>
</div>

<style lang="scss">
  .settings {
    padding: 16px;
    border-radius: 12px;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.85);
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    font-size: 15px;
    color: #000;
    line-height: 1.2;
  }

  .error {
    color: red;
  }

  .title {
    position: relative;
    display: flex;
    width: 100%;
    margin: -16px -16px 16px;
    padding: 10px 16px;
    align-items: center;
    height: 28px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h1 {
      font-size: 15px;
      margin: 0;
    }
  }

  .invite {
    display: flex;
    height: 32px;
    position: absolute;
    top: 8px;
    right: 40px;
    width: 320px;
    overflow: hidden;

    .link {
      flex: 2 1 auto;
      color: #000;
      background: #f5f5f5;
      height: 20px;
      line-height: 20px;
      padding: 6px 104px 6px 12px;
      border-radius: 32px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    :global(button) {
      position: absolute;
      top: 4px;
      right: 4px;
      width: 84px;
      height: 24px;
      margin: 0;
      border-radius: 24px;
      background: var(--blue-color);
      color: #fff;
      font-size: 13px;
      padding: 2px 10px;
    }
  }

  .community-label {
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 2px solid;
      margin: 0 4px;
      box-sizing: border-box;
    }
  }
</style>
