<script lang="ts">
  import { createEventDispatcher, onMount } from 'svelte'
  import { authAPI } from 'api'
  import intlTelInput from 'intl-tel-input'
  import { loginUsingPhone } from 'actions/auth'
  import Input from 'components/Input.svelte'
  import CodeInput from 'components/CodeInput.svelte'
  import Button from 'components/Button.svelte'
  import 'intl-tel-input/build/css/intlTelInput.css'

  const dispatch = createEventDispatcher<{
    close: undefined
    error: string
  }>()

  export let isActive = false

  let phoneInput: Input
  let phoneInputEl: HTMLInputElement
  let mask = ''
  let codeInput: CodeInput
  let phoneNumber = ''
  let fullPhoneNumber = ''
  let code = ''
  let showCodeInput = false
  let isLoadingSMS = false
  let isLoadingLogin = false
  let countryCode = ''
  let isValidPhone = false

  $: isValidPhone = phoneNumber.length === mask.length
  $: fullPhoneNumber = `+${countryCode}${phoneNumber.replace(/\D/g, '')}`
  $: canSubmit = isValidPhone && code.length === 6
  $: isActive && phoneInput?.focus()

  async function sendSMS() {
    if (!isValidPhone || isLoadingSMS) return

    isLoadingSMS = true
    showCodeInput = false
    try {
      dispatch('error', '')
      await authAPI.sendSMS(fullPhoneNumber)
      showCodeInput = true
      codeInput.focus()
    } catch (e: any) {
      dispatch('error', e.message)
    }
    isLoadingSMS = false
  }

  async function login() {
    if (!canSubmit || isLoadingLogin) return

    isLoadingLogin = true
    try {
      dispatch('error', '')
      await loginUsingPhone(fullPhoneNumber, code)
    } catch (e: any) {
      dispatch('error', e.message)
    }
    isLoadingLogin = false
  }

  onMount(() => {
    intlTelInput(phoneInputEl, {
      initialCountry: 'auto',
      separateDialCode: true,
      nationalMode: true,
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
      autoPlaceholder: 'aggressive',
      customPlaceholder: (selectedCountryPlaceholder, { dialCode }) => {
        mask = selectedCountryPlaceholder.replace(/\d/g, '0')
        countryCode = dialCode
        return selectedCountryPlaceholder
      },
      geoIpLookup: async cb => {
        try {
          const { country_code: countryCode } =
            (await fetch('https://api.ipbase.com/v1/json/', {
              headers: { apikey: '8dacfde0-852c-11ec-8be5-6b6d656ca0cb' },
            }).then(r => r.json())) || {}
          cb(countryCode || 'us')
        } catch (e: any) {
          cb('us')
        }
      },
    })
  })

  ; // prettier-ignore
</script>

<div class="root">
  <form on:submit|preventDefault={sendSMS}>
    <h1>Sign In Using Phone</h1>
    <Input
      bind:this={phoneInput}
      bind:element={phoneInputEl}
      bind:value={phoneNumber}
      name="phone"
      {mask}
      type="tel" />
    <Button color="red" loading={isLoadingSMS} disabled={!isValidPhone} type="submit"
      >Send Verification Code</Button>
  </form>
  <form on:submit|preventDefault={login} class:invisible={!showCodeInput}>
    <CodeInput bind:this={codeInput} bind:value={code} />
    <Button color="red" loading={isLoadingLogin} disabled={!canSubmit} type="submit">Next</Button>
  </form>
  <Button on:click={() => dispatch('close')}>Cancel</Button>
</div>

<style lang="scss">
  :global(.iti__flag) {
    background-image: url(assets/flags.png);

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      & {
        background-image: url(assets/flags@2x.png);
      }
    }
  }

  .root {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;

    > :global(*:not(:first-child)) {
      margin-top: 10px;
    }

    :global(.iti) {
      width: 100%;
    }

    :global(.iti__country-list) {
      max-width: 280px;
      width: 1000%;
      white-space: normal;
    }

    :global(.iti__selected-flag) {
      background: none;
    }
  }

  form {
    width: 100%;
    transition: opacity 0.3s;

    > :global(*:not(:first-child)) {
      margin-top: 10px;
    }
  }

  h1 {
    font-size: 16px;
    margin: 0 0 20px;
  }

  .invisible {
    opacity: 0;
  }
</style>
