import type { Alert } from 'types'
import { alerts, confirm } from 'stores'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function addError(error: any): void {
  error && addAlert(error.message || error.error || error.toString(), 'error')
}

export function addAlert(message: string, type: Alert['type'] = 'info', hideAfter = 5000): void {
  const alert = {
    id: (Math.random() + 1).toString(36).substring(2),
    type,
    message,
  }

  alerts.update(alerts => [...alerts, alert])
  hideAfter > 0 && setTimeout(removeAlert.bind(null, alert), hideAfter)
}

export function removeAlert(alert: Alert): void {
  alerts.update(alerts => alerts.filter(a => a.id !== alert.id))
}

export function addConfirm(
  title: string,
  actionButtonLabel = '',
  confirmedCallback?: () => Promise<void>,
): Promise<boolean> {
  return new Promise(resolve =>
    confirm.set({
      title,
      actionButtonLabel,
      resolve: async result => {
        resolve(result)
        if (result && confirmedCallback) {
          await confirmedCallback()
        }
      },
      resolved: false,
    }),
  )
}

export async function resolveConfirm(result: boolean): Promise<void> {
  const confirmValue = confirm.get()

  if (confirmValue) {
    await confirmValue.resolve(result)
    confirm.set({ ...confirmValue, resolved: true })
    setTimeout(() => confirm.update(c => (c?.title === confirmValue.title ? null : c)), 300)
  }
}
