export const DEFAULT_CLASS = {
  MAIN: 'draggable',
  DRAGGING: 'draggable-dragging',
  DRAGGED: 'draggable-dragged',
}

export type DragOptions = {
  disabled?: boolean
}

export const draggable = (
  node: HTMLElement,
  options: DragOptions,
): {
  destroy: () => void
  update: (options: DragOptions) => void
} => {
  const defaultClass = DEFAULT_CLASS.MAIN
  const defaultClassDragging = DEFAULT_CLASS.DRAGGING
  const defaultClassDragged = DEFAULT_CLASS.DRAGGED

  let active = false
  let disabled = options.disabled || false

  let [translateX, translateY] = [0, 0]
  let [initialX, initialY] = [0, 0]

  // The offset of the client position relative to the node's top-left corner
  let [clientToNodeOffsetX, clientToNodeOffsetY] = [0, 0]

  let [xOffset, yOffset] = [0, 0]

  setTranslate(xOffset, yOffset, node, true)

  let canMoveInX: boolean
  let canMoveInY: boolean

  let bodyOriginalUserSelectVal = ''

  let computedBounds: DOMRect
  let nodeRect: DOMRect

  let dragEl: HTMLElement | undefined

  function fireSvelteDragStopEvent(node: HTMLElement) {
    node.dispatchEvent(
      new CustomEvent('svelte-drag:end', { detail: { offsetX: translateX, offsetY: translateY } }),
    )
  }

  function fireSvelteDragStartEvent(node: HTMLElement) {
    node.dispatchEvent(
      new CustomEvent('svelte-drag:start', {
        detail: { offsetX: translateX, offsetY: translateY },
      }),
    )
  }

  function fireSvelteDragEvent(node: HTMLElement, translateX: number, translateY: number) {
    node.dispatchEvent(
      new CustomEvent('svelte-drag', { detail: { offsetX: translateX, offsetY: translateY } }),
    )
  }

  const listen = addEventListener

  listen('touchstart', dragStart, false)
  listen('touchend', dragEnd, false)
  listen('touchmove', drag, false)

  listen('mousedown', dragStart, false)
  listen('mouseup', dragEnd, false)
  listen('mousemove', drag, false)

  document.body.addEventListener('mouseleave', onBodyLeave, false)

  // On mobile, touch can become extremely janky without it
  node.style.touchAction = 'none'

  function dragStart(e: TouchEvent | MouseEvent) {
    if (disabled) return

    node.classList.add(defaultClass)

    dragEl = node

    canMoveInX = true
    canMoveInY = true

    // Compute bounds
    computedBounds = (node.parentNode as HTMLElement).getBoundingClientRect()

    // Compute current node's bounding client Rectangle
    nodeRect = node.getBoundingClientRect()

    if (dragEl.contains(e.target as HTMLElement)) {
      active = true
    }

    if (!active) return

    // Apply user-select: none on body to prevent misbehavior
    bodyOriginalUserSelectVal = document.body.style.userSelect
    document.body.style.userSelect = 'none'

    // Dispatch custom event
    fireSvelteDragStartEvent(node)

    const { clientX, clientY } = isTouchEvent(e) ? e.touches[0] : e

    if (canMoveInX) initialX = clientX - xOffset
    if (canMoveInY) initialY = clientY - yOffset

    // Only the bounds uses these properties at the moment,
    // may open up in the future if others need it
    if (computedBounds) {
      clientToNodeOffsetX = clientX - nodeRect.left
      clientToNodeOffsetY = clientY - nodeRect.top
    }
  }

  function dragEnd(e: MouseEvent | TouchEvent, force = false) {
    if (disabled) return
    if (!active) return

    // required, or the event will be fired for every single draggable instance present
    if (!node.contains(e.target as HTMLElement) && !force) return

    // Apply class defaultClassDragged
    node.classList.remove(defaultClassDragging)
    node.classList.add(defaultClassDragged)

    document.body.style.userSelect = bodyOriginalUserSelectVal

    fireSvelteDragStopEvent(node)

    if (canMoveInX) initialX = translateX
    if (canMoveInX) initialY = translateY

    active = false
  }

  function drag(e: TouchEvent | MouseEvent) {
    if (disabled) return
    if (!active) return

    // Apply class defaultClassDragging
    node.classList.add(defaultClassDragging)

    e.preventDefault()

    nodeRect = node.getBoundingClientRect()

    const { clientX, clientY } = isTouchEvent(e) ? e.touches[0] : e

    // Get final values for clamping
    let [finalX, finalY] = [clientX, clientY]

    if (computedBounds) {
      // Client position is limited to this virtual boundary to prevent node going out of bounds
      const left = computedBounds.left + clientToNodeOffsetX
      const top = computedBounds.top + clientToNodeOffsetY
      const right = computedBounds.right + clientToNodeOffsetX - nodeRect.width
      const bottom = computedBounds.bottom + clientToNodeOffsetY - nodeRect.height

      finalX = Math.min(Math.max(finalX, left), right)
      finalY = Math.min(Math.max(finalY, top), bottom)
    }

    if (canMoveInX) translateX = finalX - initialX
    if (canMoveInY) translateY = finalY - initialY
    ;[xOffset, yOffset] = [translateX, translateY]

    fireSvelteDragEvent(node, translateX, translateY)

    Promise.resolve().then(() => setTranslate(translateX, translateY, node, true))
  }

  function onBodyLeave(e: MouseEvent) {
    if (active) dragEnd(e, true)
  }

  return {
    destroy: () => {
      const unlisten = removeEventListener

      unlisten('touchstart', dragStart, false)
      unlisten('touchend', dragEnd, false)
      unlisten('touchmove', drag, false)

      unlisten('mousedown', dragStart, false)
      unlisten('mouseup', dragEnd, false)
      unlisten('mousemove', drag, false)

      document.body.removeEventListener('mouseleave', onBodyLeave, false)
    },
    update: (options: DragOptions) => {
      // Update all the values that need to be changed
      disabled = options.disabled ?? false

      const dragged = node.classList.contains(defaultClassDragged)

      node.classList.remove(defaultClass, defaultClassDragged)
      node.classList.add(defaultClass)

      if (dragged) node.classList.add(defaultClassDragged)

      if (disabled) {
        setTranslate(0, 0, node, true)
        initialX = 0
        initialY = 0
      }
    },
  }
}

function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  return Boolean((event as TouchEvent).touches && (event as TouchEvent).touches.length)
}

function setTranslate(xPos: number, yPos: number, el: HTMLElement, gpuAcceleration: boolean) {
  el.style.transform = gpuAcceleration
    ? `translate3d(${+xPos}px, ${+yPos}px, 0)`
    : `translate(${+xPos}px, ${+yPos}px)`
}
