import store from './store'
import type { Session } from 'types'

let storedSession: Session | null = null
try {
  storedSession = JSON.parse(localStorage.getItem('session') || '')
} catch (e) {}

export const session = store<Session | null>(storedSession)

session.subscribe(value => localStorage.setItem('session', value ? JSON.stringify(value) : ''))
