<script lang="ts">
  import type { User, Thread, Community } from 'types'
  import { createEventDispatcher } from 'svelte'
  import { banCommunityMember, banChannelMember } from 'actions'
  import Input from 'components/Input.svelte'
  import Button from 'components/Button.svelte'
  import Select from 'components/Select.svelte'
  import { getUserName } from 'utils'

  export let user: User
  export let mode: 'ban' | 'timeout' = 'ban'
  export let thread: Thread | undefined = undefined
  export let community: Community | undefined = undefined

  const dispatch = createEventDispatcher<{ done: undefined }>()

  const type = thread ? 'thread' : 'community'
  const title = thread ? thread.title : community?.title
  const durations = [
    {
      label: '60 seconds',
      value: 60,
    },
    {
      label: '5 minute',
      value: 5 * 60,
    },
    {
      label: '10 minutes',
      value: 10 * 60,
    },
    {
      label: '1 hour',
      value: 60 * 60,
    },
    {
      label: '1 day',
      value: 24 * 60 * 60,
    },
    {
      label: '1 week',
      value: 7 * 24 * 60 * 60,
    },
  ]
  let duration = durations[0]
  let comment: string | undefined = ''
  let sending = false
  let error: string | undefined = undefined

  async function submit() {
    if (sending) return
    sending = true

    try {
      const durationValue = mode === 'timeout' ? duration.value : undefined

      if (thread) {
        await banChannelMember(thread.id, user.id, durationValue, comment)
      } else if (community) {
        await banCommunityMember(community.id, user.id, durationValue, comment)
      } else {
        throw new Error('No thread or community selected')
      }

      dispatch('done')
    } catch (e: any) {
      error = e.message || e
    }

    sending = false
  }
</script>

<form class="report" on:submit|preventDefault={submit}>
  <h1>{mode === 'ban' ? 'Ban' : 'Time Out'} {getUserName(user)}</h1>
  <p>
    {#if mode === 'ban'}
      Ban permanently removes the user from <span class={type}>{title}</span>.
    {:else}
      Timeout temporarily removes some of the user permissions in <span class={type}>{title}</span>:
      <ul>
        <li>Disables sending of messages</li>
        <li>Disables sharing of messages</li>
        <li>Disables ability to like messages</li>
      </ul>
    {/if}
  </p>
  <div class="inputs">
    {#if mode === 'timeout'}
      <h3>Duration</h3>
      <Select items={durations} bind:selected={duration} />
    {/if}
    <h3>Reason</h3>
    <Input bind:value={comment} textarea name="title" label=" " />
    <Button type="submit" color="red" loading={sending}>Confirm</Button>
    {#if error}
      <div class="error">{error}</div>
    {/if}
  </div>
</form>

<style lang="scss">
  .report {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.85);
    max-height: calc(100vh - 40px);
    font-size: 15px;
    line-height: 1.2;
    padding: 16px 36px;
    color: #000;
  }

  h1 {
    width: 100%;
    padding: 0;
    height: 28px;
    font-size: 18px;
    margin: 0 0 24px 0;
    text-align: center;
  }

  ul {
    padding-left: 24px;
  }

  .inputs {
    width: 100%;

    > :global(.input) {
      width: 100%;
      box-sizing: border-box;
      padding: 10px 12px;
      background: rgba(255, 255, 255, 0.25);
    }

    > :global(*) {
      margin-bottom: 12px;
    }
  }

  .error {
    color: red;
  }

  h3 {
    font-size: 15px;
    margin: 0;
  }

  .community::before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 2px solid;
    margin: 0 4px;
    box-sizing: border-box;
  }

  .thread::before {
    content: '#';
    display: inline-block;
    margin: 0 4px;
  }
</style>
