import type { AIImage } from 'types'
import { aiImagesAPI } from 'api'
import { aiImages, allImagesLoaded, aiInitImageUrl } from 'stores'

export async function loadImages(reset = false) {
  const limit = 20
  const images = await aiImagesAPI.getImages({
    offset: aiImages.get()?.length || 0,
    limit,
  })

  aiImages.update(i => (reset ? images : [...i, ...images]))
  allImagesLoaded.update(() => images.length < limit)
}

export async function generateImage(prompt: string, config?: AIImage['config']): Promise<AIImage> {
  const image = await aiImagesAPI.generateImage(prompt, config)
  aiImages.update(images => [image, ...images])
  return image
}

export async function upscaleImageResult(image: AIImage, resultId: string): Promise<void> {
  handleImageUpdate(await aiImagesAPI.upscaleResult(image.id, resultId))
}

export async function handleImageUpdate(image: AIImage): Promise<void> {
  aiImages.update(images => images.map(i => (i.id === image.id ? { ...i, ...image } : i)))
}

export async function setInitImage(imageUrl: string): Promise<void> {
  aiInitImageUrl.set(imageUrl)
}

export async function generateCameraLink(): Promise<string> {
  return `https://app.boom.me/#/camera/${await aiImagesAPI.createUploadToken()}`
}

export async function deleteImage(id: string): Promise<void> {
  await aiImagesAPI.deleteImage(id)
  aiImages.update(images => images.filter(image => image.id !== id))
}
