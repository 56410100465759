import store from './store'
import type { AIImage } from 'types'
import { session } from './session'

export const aiImages = store<AIImage[]>([])

export const allImagesLoaded = store(false)

export const aiInitImageUrl = store('')

session.subscribe(session => {
  if (session === null) {
    aiImages.set([])
    allImagesLoaded.set(false)
  }
})
