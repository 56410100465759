import { api } from 'api/api'
import { getQueryString } from 'utils'

export default class RecipientsRecognizer {
  videoId?: string
  lastWatchedMessageId?: string
  recognitionId?: string

  audioTrack?: MediaStreamTrack
  stream?: MediaStream
  recorder?: MediaRecorder

  lastTimestamp = 0
  duration = 0
  maxDuration = 10
  chunks: Blob[] = []
  state: 'idle' | 'recording' | 'confirmed' | 'readyToSend' | 'sending' | 'sent' = 'idle'

  constructor(lastWatchedMessageId?: string) {
    this.lastWatchedMessageId = lastWatchedMessageId
  }

  async capture(audioTrack?: MediaStreamTrack): Promise<void> {
    if (this.audioTrack === audioTrack) return

    this.audioTrack = audioTrack
    if (!this.audioTrack) return

    this.stream = new MediaStream([this.audioTrack])
    this.captureStream()
  }

  captureStream() {
    if (!this.stream) return

    if (this.recorder) {
      this.recorder.ondataavailable = null
      this.recorder.onstart = null
      this.recorder.onresume = null
    }

    const mimeType = ['audio/webm', 'audio/mp4'].find(type => MediaRecorder.isTypeSupported(type))
    this.recorder = new MediaRecorder(this.stream, {
      ...(mimeType ? { mimeType } : {}),
      audioBitsPerSecond: 128000,
    })
    this.chunks = []
    this.duration = 0

    this.recorder.ondataavailable = e => {
      const timestamp = new Date().getTime()
      const isLastChunk = this.recorder?.state === 'inactive'
      const chunk = e.data
      const chunkDuration = (timestamp - this.lastTimestamp) / 1000

      this.duration += chunkDuration
      this.chunks.push(chunk)
      this.lastTimestamp = new Date().getTime()

      if (isLastChunk) {
        this.state = 'readyToSend'
        this.sendIfCan()
      } else if (this.duration >= this.maxDuration) {
        this.stop()
      }
    }
    this.recorder.onstart = () => {
      this.lastTimestamp = new Date().getTime()
    }
    this.recorder.onresume = () => {
      this.lastTimestamp = new Date().getTime()
    }
  }

  setVideoId(videoId: string) {
    if (this.videoId === videoId) return
    this.videoId = videoId
    this.sendIfCan()
  }

  start() {
    if (this.recorder?.state === 'recording') return
    this.state = 'recording'
    this.recorder?.state === 'paused' ? this.recorder.resume() : this.recorder?.start(1000)
  }

  reset(recapture = true) {
    this.state = 'idle'
    this.videoId = undefined
    this.recorder?.state !== 'inactive' && this.recorder?.stop()
    recapture && this.captureStream()

    if (this.recognitionId) {
      api(`/recipient-recognitions/${this.recognitionId}`, 'DELETE').catch(() => {})
      this.recognitionId = undefined
    }
  }

  pause() {
    if (this.recorder?.state !== 'recording') return
    this.recorder?.pause()
  }

  stop() {
    if (this.recorder?.state === 'inactive') return
    this.state = 'confirmed'
    this.recorder?.stop()
  }

  async sendIfCan() {
    if (this.videoId && this.state === 'readyToSend') {
      const mimeType = this.chunks[0].type.split(';')[0]

      this.state = 'sending'
      try {
        const { id } = await api<{ id: string }>(
          `/recipient-recognitions${getQueryString({
            videoId: this.videoId,
            mimeType,
            lastWatchedMessageId: this.lastWatchedMessageId,
          })}`,
          'POST',
          undefined,
          new Blob(this.chunks, { type: mimeType }),
        )
        this.recognitionId = id
      } catch (e) {
        // ignore
      }
      this.state = 'sent'
    }
  }

  destroy(): void {
    this.reset(false)
  }
}
