<script lang="ts">
  import { Community, Thread, RolesConfig, ChannelSettings } from 'types'
  import { ModalType } from 'types'
  import { createEventDispatcher } from 'svelte'
  import Spinner from 'components/Spinner.svelte'
  import { goToThread, handleNotExistingRoute } from 'actions/router'
  import {
    openModal,
    leaveCommunity,
    leaveThread,
    addError,
    toggleMute,
    markThreadAsWatched,
  } from 'actions'
  import { normilizeToValues } from 'utils'
  import { session } from 'stores'

  export let type: 'root' | 'channel' | 'community' | undefined = undefined
  export let visible = false
  export let topPosition = 0
  export let thread: Thread | undefined = undefined
  export let community: Community | undefined = undefined

  const dispatch = createEventDispatcher<{
    toggleMenu: string
    toggleSidebar: boolean
  }>()

  let menuEl: HTMLDivElement
  let isLeaving = false
  let isMarkingAsWatched = false

  $: !isLeaving && dispatch('toggleMenu')
  $: !isMarkingAsWatched && dispatch('toggleMenu')

  export function focus() {
    menuEl?.focus()
  }

  function canEdit(roles?: RolesConfig): boolean {
    return ['admin', 'owner'].indexOf((roles || {})[session.get()?.profile.id || ''] || '') !== -1
  }

  function canAddMembers(
    isPublic?: boolean,
    roles?: RolesConfig,
    addMembersRights?: ChannelSettings['addMembersRights'],
  ): boolean {
    if (typeof isPublic !== 'boolean') return false

    const userId = session.get()?.profile.id
    const userRole = (roles || {})[userId || '']
    let allow = true

    if (!isPublic) {
      addMembersRights = normilizeToValues(addMembersRights, ['members', 'admins'])

      if (addMembersRights === 'members') {
        allow = true
      } else if (addMembersRights === 'admins') {
        allow = ['owner', 'admin'].includes(userRole)
      } else {
        allow = false
      }
    }

    return allow
  }

  function _openModal(modalType: ModalType, trustedOnly = false) {
    if (trustedOnly && !session.get()?.profile.isTrusted) {
      let action = ''
      if (modalType === ModalType.COMMUNITY_SETTINGS) {
        action = 'create communities'
      } else if (modalType === ModalType.CHANNEL_SETTINGS) {
        action = 'create channels'
      } else if (modalType === ModalType.INVITE_MEMBERS) {
        action = 'invite new members'
      }

      return openModal({
        type: ModalType.TRUSTED_USER_ONLY,
        arguments: { action },
      })
    }

    openModal({
      type: modalType,
      arguments: type === 'community' ? (community ? { community } : {}) : thread ? { thread } : {},
      ...(modalType === ModalType.CHANNEL_SETTINGS
        ? {
            onDone: (thread: Thread) => thread && goToThread(thread),
          }
        : {}),
    })
  }

  async function toggleChannelMute() {
    if (!thread) return

    await toggleMute(thread)
  }

  async function markAsWatched() {
    if (!thread) return
    isMarkingAsWatched = true
    try {
      await markThreadAsWatched(thread)
    } catch (e) {
      addError(e)
    }
    isMarkingAsWatched = false
  }

  async function leaveChannel() {
    if (!thread) return

    isLeaving = true
    try {
      await leaveThread(thread.id)
    } catch (e) {
      addError(e)
    }
    isLeaving = false

    handleNotExistingRoute()
  }

  async function _leaveCommunity() {
    if (!community) return

    isLeaving = true
    try {
      await leaveCommunity(community.id)
    } catch (e) {
      addError(e)
    }
    isLeaving = false

    handleNotExistingRoute()
  }
</script>

<div
  class="menu"
  bind:this={menuEl}
  style={`top: ${topPosition}px`}
  tabIndex="-1"
  class:active={visible}
  class:hidden={!type}
  on:click|preventDefault={() => dispatch('toggleMenu')}
  on:blur={() => dispatch('toggleMenu')}>
  {#if type === 'root'}
    <div class="item" on:click={() => _openModal(ModalType.CHANNEL_SETTINGS, true)}>
      Create New #Channel
    </div>
    <div class="item" on:click={() => _openModal(ModalType.COMMUNITY_SETTINGS, true)}>
      Create New <span class="community-label">Community</span>
    </div>
  {:else if type === 'community' && community}
    {#if canEdit(community.roles)}
      <div class="item" on:click={() => _openModal(ModalType.COMMUNITY_SETTINGS, false)}>
        Edit Community
      </div>
    {/if}
    {#if canAddMembers(community.isPublic, community.roles, community.settings.addMembersRights)}
      <div class="item" on:click={() => _openModal(ModalType.INVITE_MEMBERS, true)}>
        Invite People
      </div>
    {/if}
    <div class="item" on:click={() => _openModal(ModalType.CHANNEL_SETTINGS, true)}>
      Create Channel in Community
    </div>
    <div class="item" on:click={() => _openModal(ModalType.COMMUNITY_PROFILE, false)}>
      Community Profile
    </div>
    <div class="item red" class:loading={isLeaving} on:click|stopPropagation={_leaveCommunity}>
      Leave Community
      {#if isLeaving}
        <Spinner color="#000" />
      {/if}
    </div>
  {:else if type === 'channel' && thread}
    {#if canEdit(thread.channel?.roles)}
      <div class="item" on:click={() => _openModal(ModalType.CHANNEL_SETTINGS, false)}>
        Edit Channel
      </div>
    {/if}
    {#if canAddMembers(thread.channel?.isPublic, thread.channel?.roles, thread.channel?.addMembersRights)}
      <div class="item" on:click={() => _openModal(ModalType.INVITE_MEMBERS, true)}>
        Invite People
      </div>
    {/if}
    {#if (thread.unwatchedMessagesCount || 0) > 0}
      <div class="item" class:loading={isMarkingAsWatched} on:click|stopPropagation={markAsWatched}>
        Mark as watched
        {#if isMarkingAsWatched}
          <Spinner color="#000" />
        {/if}
      </div>
    {/if}
    <div class="item" on:click={toggleChannelMute}>{thread.muted ? 'Unmute' : 'Mute'} channel</div>
    <div class="item red" class:loading={isLeaving} on:click|stopPropagation={leaveChannel}>
      Leave Channel
      {#if isLeaving}
        <Spinner color="#000" />
      {/if}
    </div>
  {/if}
</div>

<style lang="scss">
  .menu {
    position: absolute;
    top: 0;
    left: 100%;
    background: rgba(255, 255, 255, 0.95);
    color: #fff;
    border-radius: 4px;
    margin: -14px 0 0 0;
    opacity: 0;
    z-index: 2;
    transition: opacity 0.2s, transform 0.2s;
    outline: none;

    &.hidden {
      display: none;
    }

    &.active {
      opacity: 1;
      transform: translateX(12px);
    }

    &::before {
      content: '';
      position: absolute;
      top: 8px;
      right: 100%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 6px 6px 0;
      border-color: transparent rgba(255, 255, 255, 0.95) transparent transparent;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 100%;
      width: 12px;
      height: 100%;
    }

    .item {
      position: relative;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
      padding: 10px 12px;
      cursor: pointer;
      color: #000;
      margin: 2px;
      border-radius: 8px;

      :global(.spinner) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.red {
        color: #c95c5f;
      }

      &.loading {
        color: transparent;
      }

      &:first-child {
        margin-top: 2px;
      }

      &:last-child {
        margin-bottom: 2px;
      }

      &:hover {
        background-color: var(--active-bg-color);
      }
    }
  }

  .community-label {
    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 2px solid;
      margin: 0 4px;
      box-sizing: border-box;
    }
  }
</style>
