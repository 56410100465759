<script lang="ts">
  import type { User, FullUserProfile, Thread } from 'types'
  import { ModalType } from 'types'
  import { createEventDispatcher } from 'svelte'
  import Avatar from './Avatar.svelte'
  import Button from './Button.svelte'
  import Spinner from './Spinner.svelte'
  import ContactPointBadge from './ContactPointBadge.svelte'
  import CameraIcon from 'assets/icons/camera-filled.svg'
  import BanIcon from 'assets/icons/ban.svg'
  import TimeIcon from 'assets/icons/time.svg'
  import { getUserName } from 'utils'
  import { session } from 'stores'
  import { getUserProfile, addError, goToNewMessage, goToThread, openModal } from 'actions'

  export let user: User
  export let threadToBan: Thread | undefined
  export let communityToBan: Thread | undefined

  const dispatch = createEventDispatcher<{ close: undefined }>()

  let fullProfile: FullUserProfile | undefined
  const profileRequest: Promise<any> = getUserProfile(user.id)
    .then(profile => {
      fullProfile = profile
    })
    .catch(addError)

  $: name = getUserName(user, session.get()?.profile)
  $: email = user.email || fullProfile?.user?.email
  $: phoneNumber = user.phoneNumber || fullProfile?.user?.phoneNumber

  function banUser(mode: 'ban' | 'timeout') {
    if (!threadToBan && !communityToBan) return

    openModal({
      type: ModalType.BAN_USER,
      arguments: {
        mode,
        user,
        thread: threadToBan,
        community: communityToBan,
      },
    })
  }

  function _goToThread(thread: Thread) {
    dispatch('close')
    goToThread(thread)
  }
</script>

<div class="user-card">
  <Avatar {user} size="xlarge" color="black" />
  <h1>{name}</h1>
  {#if email}
    <div class="info">{email}</div>
  {/if}
  {#if phoneNumber}
    <div class="info">{phoneNumber}</div>
  {/if}
  <Button color="red" on:click={() => goToNewMessage({ user })}>
    <CameraIcon /> Send Boom
  </Button>
  <div class="separator" />
  <div class="threads">
    {#await profileRequest}
      <div class="spinner">
        <Spinner size="small" />
      </div>
    {/await}
    {#if fullProfile && fullProfile.sharedThreads}
      <h2>Shared Threads & Channels</h2>
      {#each fullProfile.sharedThreads as thread}
        <div class="thread" on:click={() => _goToThread(thread)}>
          <ContactPointBadge contactPoint={{ thread }} currentUser={$session?.profile} />
        </div>
      {/each}
    {/if}
  </div>
  {#if communityToBan}
    <div class="separator" />
    <Button textColor="red" on:click={() => banUser('timeout')}>
      <TimeIcon /> Timeout user in {communityToBan.title}
    </Button>
    <Button textColor="red" on:click={() => banUser('ban')}>
      <BanIcon /> Ban user in {communityToBan.title}
    </Button>
  {:else if threadToBan}
    <div class="separator" />
    <Button textColor="red" on:click={() => banUser('timeout')}>
      <TimeIcon /> Timeout user in {threadToBan.title}
    </Button>
    <Button textColor="red" on:click={() => banUser('ban')}>
      <BanIcon /> Ban user in {threadToBan.title}
    </Button>
  {/if}
</div>

<style lang="scss">
  .user-card {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  h1 {
    font: 600 15px/24px var(--main-font);
    margin: 16px 0 0;
  }

  h2 {
    font: 500 14px/16px var(--main-font);
    margin: 0 0 8px;
  }

  .info {
    margin-top: 4px;
  }

  :global(button) {
    margin-top: 16px;
  }

  .threads {
    width: 100%;
    padding: 4px 16px;

    :global(.spinner) {
      margin: 4px auto;
      text-align: center;
    }
  }

  .separator {
    margin: 16px -16px;
    height: 1px;
    width: calc(100% + 32px);
    background: rgba(0, 0, 0, 0.1);

    & + :global(button) {
      margin-top: 0;
    }
  }

  .thread {
    cursor: pointer;
    margin: 0 -16px;
    padding: 2px 16px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
</style>
