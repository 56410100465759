import { AIImage } from 'types'
import * as api from './api'

export async function getImages(
  filters: {
    state?: 'available' | 'pending'
    limit?: number
    offset?: number
    page?: number
    [key: string]: any
  } = {},
): Promise<AIImage[]> {
  const queryParams = Object.entries(filters).map(p => p.join('='))
  const query = queryParams.length ? `?${queryParams.join('&')}` : ''

  return (await api.get<{ images: AIImage[] }>(`/ai-images${query}`)).images
}

export async function generateImage(prompt: string, config?: AIImage['config']): Promise<AIImage> {
  return (await api.post<{ image: AIImage }>('/ai-images', { prompt, config })).image
}

export async function getImage(imageId: string): Promise<AIImage> {
  return (await api.get<{ image: AIImage }>(`/ai-images/${imageId}`)).image
}

export async function upscaleResult(imageId: string, resultId: string): Promise<AIImage> {
  return (await api.post<{ image: AIImage }>(`/ai-images/${imageId}/upscale`, { resultId })).image
}

export async function createUploadToken(): Promise<string> {
  return (await api.post<{ token: string }>(`/ai-images/upload-token`)).token
}

export async function uploadImage(imageId: string, token: string): Promise<void> {
  return api.api<void>('/ai-images/upload', 'POST', { imageId }, undefined, token)
}

export async function updateImage(
  imageId: string,
  data: {
    prompt?: string
    config?: any
  },
): Promise<AIImage> {
  return (await api.put<{ image: AIImage }>(`/ai-images/${imageId}`, data)).image
}

export async function deleteImage(imageId: string): Promise<void> {
  return api.del<void>(`/ai-images/${imageId}`)
}
