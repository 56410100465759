export enum WindowSize {
  AUTH = 'AUTH',
  FULLSCREEN = 'FULLSCREEN',
  SCREEN_RECORDING = 'SCREEN_RECORDING',
}

export enum SidebarState {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  AUTO = 'AUTO',
}

export enum ModalType {
  CHANNEL_SETTINGS = 'CHANNEL_SETTINGS',
  INVITE_MEMBERS = 'INVITE_MEMBERS',
  COMMUNITY_SETTINGS = 'COMMUNITY_SETTINGS',
  COMMUNITY_PROFILE = 'COMMUNITY_PROFILE',
  CREATE_REPORT = 'CREATE_REPORT',
  BAN_USER = 'BAN_USER',
  USER_CARD = 'USER_CARD',
  TRUSTED_USER_ONLY = 'TRUSTED_USER_ONLY',
  MESSAGE_RECIPIENTS = 'MESSAGE_RECIPIENTS',
}

export enum ReportState {
  pending = 'pending',
  preApproved = 'preApproved',
  approved = 'approved',
  rejected = 'rejected',
}

export enum ReportReason {
  spam = 'spam',
  nudity = 'nudity',
  hateSpeech = 'hateSpeech',
  violence = 'violence',
  harassment = 'harassment',
  sellingIllegalGoods = 'sellingIllegalGoods',
  intellectualPropertyViolation = 'intellectualPropertyViolation',
  suicide = 'suicide',
  eatingDisorder = 'eatingDisorder',
  scam = 'scam',
  falseInformation = 'falseInformation',
}

export enum ReportType {
  user = 'user',
  message = 'message',
  thread = 'thread',
  community = 'community',
}
