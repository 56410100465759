<script lang="ts">
  import type { User } from 'types'
  import { userImageUrl } from 'utils'
  import DefaultAvatar from 'assets/icons/default-avatar.svg'

  export let size: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' = 'medium'
  export let color: 'white' | 'black' = 'black'
  export let user: User
</script>

<div class={`avatar ${size} ${color}`}>
  {#if user.imageURL}
    <img src={userImageUrl(user)} alt={user.username} referrerpolicy="no-referrer" />
  {:else}
    <DefaultAvatar />
  {/if}
</div>

<style lang="scss">
  .avatar {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;

    &.xsmall {
      width: 21px;
      height: 21px;
    }

    &.small {
      width: 24px;
      height: 24px;
    }

    &.large {
      width: 48px;
      height: 48px;
    }

    &.xlarge {
      width: 96px;
      height: 96px;
    }

    &.white :global(path) {
      fill: #fff;
    }

    > :global(*) {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    img {
      border-radius: 100%;
      overflow: hidden;
    }
  }
</style>
