import type { Message, NewMessage, ContactPoint } from 'types'
import type { VKCue } from 'vendors/videokit'
import { get } from 'svelte/store'
import type { MessagesFilters } from 'api/messages'
import { location } from 'svelte-spa-router'
import { Upload, UploadState, Video as VKVideo, SubtitlesManager } from 'vendors/videokit'
import {
  threads,
  newMessages,
  videos,
  session,
  currentThread,
  currentMessage,
  feedMessages,
  noMoreFeedMessages,
  currentReply,
  subtitles,
} from 'stores'
import { messagesAPI, threadsAPI, uploadsAPI } from 'api'
import { mapContactPoints, makeParagraphsFromCues } from 'utils'
import { isElectron, supportsHEVC } from 'env'
import { goToMessage, goToFeed, goToThreads } from './router'
import { updateThreads } from './threads'

const perPage = 30

export async function loadFeed(): Promise<void> {
  const { threads, messages } = await messagesAPI.getFeed({
    limit: perPage,
    offset: feedMessages.get()?.length || 0,
  })
  feedMessages.update(feedMessages => (feedMessages || []).concat(messages))
  threads.forEach(updateThreads)
  noMoreFeedMessages.set(messages.length < perPage)
}

export async function loadMessages(threadId: string): Promise<void> {
  const messages = await messagesAPI.getMessages({ threadId, limit: perPage })
  addMessagesToThread(threadId, messages, {
    noPreviousMessages: messages.length < perPage,
    noNextMessages: true,
    isMessagesPreloaded: true,
  })
}

export async function loadMessagesFromId(threadId: string, messageId: string): Promise<void> {
  const message = await messagesAPI.getMessage(messageId)
  addMessagesToThread(threadId, [], {
    messages: [message],
    noPreviousMessages: false,
    noNextMessages: false,
    isMessagesPreloaded: true,
  })
  const messages = await Promise.all([
    await messagesAPI.getMessages({ threadId, before: message.createdAt, limit: perPage / 2 }),
    await messagesAPI.getMessages({ threadId, after: message.createdAt, limit: perPage / 2 }),
  ])
  addMessagesToThread(threadId, messages.flat(), {
    noPreviousMessages: messages[0].length < perPage / 2,
    noNextMessages: messages[1].length < perPage / 2,
  })
}

export async function loadMoreMessages(
  threadId: string,
  direction: 'next' | 'prev',
): Promise<void> {
  const thread = threads.get()?.find(t => t.id === threadId)

  if (thread && thread.messages) {
    let filters: MessagesFilters | undefined

    if (direction === 'next' && !thread.noNextMessages) {
      filters = { after: thread.messages[0].createdAt }
    } else if (direction === 'prev' && !thread.noPreviousMessages) {
      filters = { before: thread.messages[thread.messages.length - 1].createdAt }
    }

    if (filters) {
      const messages = await messagesAPI.getMessages({ threadId, ...filters, limit: perPage })
      addMessagesToThread(threadId, messages.flat(), {
        [direction === 'next' ? 'noNextMessages' : 'noPreviousMessages']: messages.length < perPage,
      })
    }
  }
}

export async function loadReplies(threadId = '', messageId: string): Promise<void> {
  const messages = await messagesAPI.getMessageReplies(messageId, { limit: perPage })
  addRepliesToMessage(threadId, messageId, messages, {
    noPreviousReplies: messages.length < perPage,
    noNextReplies: true,
    isRepliesPreloaded: true,
  })
}

export async function loadMoreReplies(
  threadId: string,
  messageId: string,
  direction: 'next' | 'prev',
): Promise<void> {
  const thread = threads.get()?.find(t => t.id === threadId)
  const message = thread?.messages?.find(m => m.id === messageId)

  if (message && message.replies) {
    let filters: MessagesFilters | undefined

    if (direction === 'next' && !message.noNextReplies) {
      filters = { after: message.replies[0].createdAt }
    } else if (direction === 'prev' && !message.noPreviousReplies) {
      filters = { before: message.replies[message.replies.length - 1].createdAt }
    }

    if (filters) {
      const replies = await messagesAPI.getMessageReplies(messageId, { ...filters, limit: perPage })
      addRepliesToMessage(threadId, messageId, replies.flat(), {
        [direction === 'next' ? 'noNextReplies' : 'noPreviousReplies']: replies.length < perPage,
      })
    }
  }
}

export async function markMessageAsWatched(message: Message): Promise<void> {
  await messagesAPI.markMessageAsWatched(message.id)
  if (message.watched) return

  threads.update(threads => {
    if (!threads) return threads
    return threads.map(thread => {
      if (thread.id === message.threadId) {
        const messages = (thread.messages || []).map(m => {
          if (m.id === message.id) {
            return {
              ...m,
              watched: true,
            }
          } else if (m.id === message.repliedTo) {
            return {
              ...m,
              unwatchedRepliesCount: Math.max(0, (m.unwatchedRepliesCount || 0) - 1),
              replies: (m.replies || []).map(r => ({
                ...r,
                watched: r.id === message.id ? true : r.watched,
              })),
            }
          } else {
            return m
          }
        })

        return {
          ...thread,
          messages,
          unwatchedMessagesCount: Math.max(0, (thread.unwatchedMessagesCount || 0) - 1),
        }
      } else {
        return thread
      }
    })
  })
  updateFeedMessages({ id: message.id, watched: true })
}

export async function updateMessageRecipients(
  message: Message,
  recipients: {
    userIds?: string[]
    threadId?: string
    newTopicName?: string
  },
): Promise<Message> {
  const updatedMessage = await messagesAPI.updateMessageRecipients(message.id, recipients)
  await updateMessage(message.id, updatedMessage)
  return updatedMessage
}

export async function toggleLike(message: Message, liked: boolean): Promise<void> {
  const { likesCount } = await messagesAPI[liked ? 'like' : 'unlike'](message.id)
  updateMessage(message.id, { likesCount, liked })
}

export async function createMessage(
  recipients: ContactPoint[],
  thumb: Blob | null,
  upload?: Upload,
  description?: string,
  aiImageId?: string,
): Promise<Message> {
  let messages: Message[] = []
  const recipientWithMessage = recipients.find(r => r.message)

  // If there is not upload assume this is a image message
  if (!upload) {
    if (!thumb) throw new Error('Thumb is required')

    const { id } = await uploadsAPI.upload(thumb)
    const data = {
      type: 'image',
      thumbnailId: id,
      description,
      aiImageId,
    } as const

    if (recipientWithMessage && recipientWithMessage.thread && recipientWithMessage.message) {
      messages = [await messagesAPI.createReplyMessage(recipientWithMessage.message.id, data)]
    } else {
      messages = await messagesAPI.createMessage(mapContactPoints(recipients), data)
    }

    await Promise.all(messages.map(message => addNewMessage(message)))

    return messages[0]
  }

  const { video } = upload
  if (!video) throw new Error('Upload is failed')

  const videoId = video.id
  const data = {
    videoId,
    type: 'video',
  } as const

  if (recipientWithMessage && recipientWithMessage.thread && recipientWithMessage.message) {
    messages = [await messagesAPI.createReplyMessage(recipientWithMessage.message.id, data)]
  } else {
    messages = await messagesAPI.createMessage(mapContactPoints(recipients), data)
  }
  const message = messages[0]

  if (thumb) {
    messages.forEach(message => {
      message.thumbnailURL = URL.createObjectURL(thumb)
      if (message.video) {
        message.video.width = message.video.width || video.width
        message.video.height = message.video.height || video.height
      }
    })
  }

  videos.update(value => ({ ...value, [video.id]: video }))
  await Promise.all(messages.map(message => addNewMessage(message)))
  thumb && uploadThumb(message.id, thumb)
  const finishStates = [UploadState.READY_TO_PLAY, ...(isElectron ? [UploadState.UPLOADED] : [])]
  const maxProgress = isElectron ? 1 : 0.99

  if (finishStates.indexOf(upload.state) === -1 && (!isElectron || upload.progress < 0.9)) {
    newMessages.update(messages => ({
      ...messages,
      [message.id]: {
        upload,
        message,
        progress: Math.min(upload.progress, maxProgress),
      },
    }))
    upload.subscribe('error', (event, { error }) => {
      console.error(
        `Failed to upload video: ${error}`,
        JSON.stringify({
          videoId: upload.video.id,
          state: upload.state,
          progress: upload.progress,
          online: navigator.onLine,
        }),
      )
    })
    upload.subscribe('progress', (event, { progress }) => {
      updateNewMessage(message.id, { progress: Math.min(progress, maxProgress) })
    })
    upload.subscribe('stateChanged', (event, { state }) => {
      if (finishStates.indexOf(state) !== -1) {
        newMessages.update(messages => {
          const updated = { ...messages }
          delete updated[message.id]
          return updated
        })
        upload.unsubscribe('*')
      }
    })
  }

  return message
}

export async function uploadThumb(messageId: string, thumb: Blob): Promise<void> {
  const { id } = await uploadsAPI.upload(thumb)
  await messagesAPI.updateMessage(messageId, { thumbnailId: id })
}

export function updateNewMessage(messageId: string, newData: Partial<NewMessage>): void {
  newMessages.update(messages => ({
    ...messages,
    ...(messages[messageId] ? { [messageId]: { ...messages[messageId], ...newData } } : {}),
  }))
}

export async function forwardMessage(message: Message, recipients: ContactPoint[]): Promise<void> {
  const forwardMessages = await messagesAPI.forward(message.id, mapContactPoints(recipients))
  await Promise.all(forwardMessages.map(message => addNewMessage(message)))
}

export async function deleteMessage(message: Message): Promise<void> {
  await messagesAPI.deleteMessage(message.id)
  handleDeletedMessage(message.threadId, message.id)
}

export async function addNewMessage(message: Message): Promise<void> {
  updateFeedMessages(message, true)

  const thread = (threads.get() || []).find(thread => thread.id === message.threadId)
  if (thread) {
    if (message.repliedTo) {
      const repliedToMessage = thread.messages?.find(m => m.id === message.repliedTo)

      addRepliesToMessage(
        message.threadId,
        message.repliedTo,
        [message],
        repliedToMessage ? { repliesCount: repliedToMessage.repliesCount ?? 0 + 1 } : {},
        { modifiedAt: new Date().toISOString() },
      )
    } else {
      addMessagesToThread(message.threadId, [message], { modifiedAt: new Date().toISOString() })
    }
  } else {
    if (message.threadId) {
      const thread = await threadsAPI.getThread(message.threadId)
      thread.messages = [message]
      updateThreads(thread)
    }
  }
}

export function addRepliesToMessage(
  threadId = '',
  messageId: string,
  replies: Message[],
  messageData: { [key: string]: any } = {},
  threadData: { [key: string]: any } = {},
): void {
  threads.update(threads => {
    if (!threads) return null

    replies = replies.filter(canPlayMessage)

    return threads
      .map(thread => {
        if (thread.id === threadId) {
          return {
            ...thread,
            ...threadData,
            messages: thread.messages?.map(message => {
              if (message.id === messageId) {
                const map: { [key: string]: boolean } = {}
                return {
                  ...message,
                  ...messageData,
                  replies: [...replies, ...(message.replies || [])]
                    .sort((l, r) => (l.createdAt > r.createdAt ? -1 : 1))
                    .filter((reply: Message) => {
                      if (map[reply.id]) {
                        return false
                      } else {
                        map[reply.id] = true
                        return true
                      }
                    }),
                }
              } else {
                return message
              }
            }),
          }
        } else {
          return thread
        }
      })
      .sort((l, r) => (l.modifiedAt > r.modifiedAt ? -1 : 1))
  })
}

export async function updateMessage(
  messageId: string,
  data: { [key: string]: any } = {},
): Promise<void> {
  const thread = threads.get()?.find(thread => thread.id === data.threadId)

  if (data.threadId && !thread) {
    const thread = await threadsAPI.getThread(data.threadId)
    updateThreads(thread)
  }

  threads.update(threads => {
    if (!threads) return null

    return threads
      .map(thread => {
        const currentMessage = thread.messages?.find(m => m.id === messageId)
        let messages: Message[] = []

        if (currentMessage && data.threadId !== thread.id && typeof data.threadId !== 'undefined') {
          data = { ...currentMessage, ...data }
          messages = (thread.messages || []).filter(m => m.id !== messageId)
          thread.modifiedAt = messages?.length > 0 ? messages[0].createdAt : thread.modifiedAt
        } else {
          messages =
            thread.messages?.map(message => ({
              ...message,
              ...(message.id === messageId ? data : {}),
              ...(message.replies
                ? {
                    replies: message.replies.map(reply => ({
                      ...reply,
                      ...(reply.id === messageId ? data : {}),
                    })),
                  }
                : {}),
            })) || []

          if (data.threadId === thread.id && !messages.find(m => m.id === messageId)) {
            messages = [data as Message, ...(messages || [])].sort((l, r) =>
              l.createdAt > r.createdAt ? -1 : 1,
            )
            thread.modifiedAt = messages?.length > 0 ? messages[0].createdAt : thread.modifiedAt
          }
        }

        return {
          ...thread,
          messages,
        }
      })
      .sort((l, r) => (l.modifiedAt > r.modifiedAt ? -1 : 1))
  })

  updateFeedMessages({ id: messageId, ...data })
}

export function addMessagesToThread(
  threadId = '',
  messages: Message[],
  threadData: { [key: string]: any } = {},
): void {
  threads.update(threads => {
    if (!threads) return null

    messages = messages.filter(canPlayMessage)

    return threads
      .map(thread => {
        if (thread.id === threadId) {
          const map: { [key: string]: boolean } = {}
          return {
            ...thread,
            messages: [...(thread.messages || []), ...messages]
              .sort((l, r) => (l.createdAt > r.createdAt ? -1 : 1))
              .filter((message: Message) => {
                if (map[message.id]) {
                  return false
                } else {
                  map[message.id] = true
                  return true
                }
              }),
            ...threadData,
          }
        } else {
          return thread
        }
      })
      .sort((l, r) => (l.modifiedAt > r.modifiedAt ? -1 : 1))
  })
}

export async function handleNewMessage(
  threadId = '',
  messageOrId: string | Message,
  muted = false,
  shouldGoTo = false,
): Promise<boolean> {
  let thread = threads.get()?.find(thread => thread.id === threadId)

  if (!thread) {
    thread = await threadsAPI.getThread(threadId)

    if (thread) {
      updateThreads(thread)
    } else {
      throw new Error('Thread is not found')
    }
  }

  const message =
    typeof messageOrId === 'string'
      ? thread.messages?.find(m => m.id === messageOrId) ||
        (await messagesAPI.getMessage(messageOrId))
      : messageOrId

  if (!canPlayMessage(message)) return false

  updateFeedMessages(message, true)

  if (!thread.messages?.find(m => m.id === message.id)) {
    if (message.repliedTo) {
      const parentMessage = thread.messages?.find(m => m.id === message.repliedTo)

      if (parentMessage) {
        if (!parentMessage.replies?.find(m => m.id === message.id)) {
          addRepliesToMessage(
            threadId,
            message.repliedTo,
            [message],
            {
              repliesCount: (parentMessage.repliesCount || 0) + 1,
              unwatchedRepliesCount:
                (parentMessage.unwatchedRepliesCount || 0) + (message.watched ? 0 : 1),
            },
            { modifiedAt: new Date().toISOString() },
          )
        }
      } else {
        const parentMessage = await messagesAPI.getMessage(message.repliedTo)

        if (!parentMessage) throw new Error('Message is not found')

        addMessagesToThread(threadId, [{ ...parentMessage, replies: [message] }], {
          modifiedAt: message.modifiedAt,
        })
      }
    } else {
      addMessagesToThread(threadId, [message], { modifiedAt: message.modifiedAt })
    }
  } else if (!shouldGoTo) {
    return false
  }

  if (!message) throw new Error('Message is not found')

  if (shouldGoTo) {
    const isFeed = /\/feed/.test(get(location))
    isFeed ? goToFeed(message) : goToMessage(thread, message)
  }

  if (message.watched === false && message.sender.id !== session.get()?.profile.id) {
    loadUnatchedMessages()
    return !muted
  }

  return false
}

export function updateFeedMessages(message: Message | Partial<Message>, addIfNeeded = false): void {
  feedMessages.update(messages => {
    if (messages?.find(m => m.id === message.id)) {
      return messages?.map(m => (m.id === message.id ? { ...m, ...message } : m)) || null
    } else {
      return addIfNeeded
        ? [message as Message, ...(messages || [])].sort((l, r) =>
            l.createdAt > r.createdAt ? -1 : 1,
          )
        : messages
    }
  })
}

export function handleUpdatedMessage(message: Message): void {
  const { video } = message
  const currentVideo = (videos.get() || {})[video?.id || '']

  if (currentVideo) {
    videos.update(value => ({
      ...value,
      [currentVideo.id]: {
        ...currentVideo,
        subtitlesUrl: video?.subtitlesJsonURL || video?.subtitlesURL,
        duration: video?.duration,
      } as VKVideo,
    }))
  }
  updateMessage(message.id, message)
}

export function handleDeletedMessage(threadId = '', messageId: string): void {
  const isFeed = /\/feed/.test(get(location))
  const message = get(currentMessage)
  const reply = get(currentReply)
  let thread = get(currentThread)
  const messageIndex = (thread?.messages || []).findIndex(m => m.id === message?.id) || 0
  const replyIndex =
    (thread?.messages || [])
      .find(m => m.id === reply?.repliedTo)
      ?.replies?.findIndex(r => r.id === reply?.id) || 0

  newMessages.update(messages => {
    const updated = { ...messages }
    delete updated[messageId]
    return updated
  })
  feedMessages.update(messages => messages?.filter(m => m.id !== messageId) || null)

  threads.update(threads => {
    if (!threads) return null

    return threads.map(thread => {
      if (thread.id === threadId) {
        const messages = thread.messages?.filter(threadMessage => threadMessage.id !== messageId)

        return {
          ...thread,
          modifiedAt: messages && messages.length > 0 ? messages[0].createdAt : thread.createdAt,
          messages: messages?.map(message => {
            if (message.replies) {
              const replies = message.replies.filter(reply => reply.id !== messageId)

              return {
                ...message,
                replies: replies.length > 0 ? replies : undefined,
                repliesCount: replies?.length || 0,
              }
            } else {
              return message
            }
          }),
        }
      } else {
        return thread
      }
    })
  })

  loadUnatchedMessages()

  if (message?.id === messageId || reply?.id === messageId) {
    thread = get(currentThread)

    if (thread) {
      const { messages } = thread

      if (reply?.id === messageId) {
        const message = messages?.find(m => m.id === reply.repliedTo)

        if (message) {
          const replies = message.replies || []

          goToMessage(thread, replies[Math.min(replyIndex, replies.length - 1)] || message)
        }
      } else {
        const message = messages && messages[Math.min(messageIndex, messages.length - 1)]
        isFeed ? goToFeed(message) : goToMessage(thread, message)
      }
    } else {
      isFeed ? goToFeed() : goToThreads()
    }
  }
}

export async function loadUnatchedMessages() {
  try {
    const unwatchedInfo = await threadsAPI.getUnwatchedMessagesInfo()
    threads.update(threads => {
      if (!threads) return threads
      return threads.map(thread => {
        const info = unwatchedInfo.find(info => info.threadId === thread.id)
        return {
          ...thread,
          unwatchedMessagesCount: info?.unwatchedMessagesCount || 0,
        }
      })
    })
  } catch (e) {}
}

export async function loadSubtitles(video: VKVideo): Promise<VKCue[][]> {
  let videoSubtitles = (subtitles.get() || {})[video.id]

  if (videoSubtitles) return videoSubtitles
  if (!video.subtitlesUrl) return []

  const cues = await SubtitlesManager.getSubtitles(video)
  videoSubtitles = makeParagraphsFromCues(cues)

  subtitles.update(subtitles => ({
    ...subtitles,
    [video.id]: videoSubtitles,
  }))

  return videoSubtitles || []
}

export function canPlayMessage(message: Message): boolean {
  if (message.type !== 'video') return true
  if ((videos.get() || {})[message.video?.id || '']) return true

  const hevcOnly =
    Object.entries(message.video?.assets || {}).filter(
      ([r, v]) => v && r !== 'hls' && !r.includes('.hevc'),
    ).length === 0

  return !hevcOnly || supportsHEVC
}
