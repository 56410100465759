<script lang="ts">
  export let progress: number

  function progressIndicatorStyleAt(progress: number) {
    // https://css-tricks.com/building-progress-ring-quickly/
    const radius = 12 // from SVG circle
    const circumference = radius * 2 * Math.PI
    const offset = circumference - progress * circumference

    return `stroke-dasharray: ${circumference} ${circumference}; stroke-dashoffset: ${offset}`
  }
</script>

<div class="upload-indicator">
  <svg class="ring" width="28" height="28">
    <circle
      class="ring-base"
      stroke="white"
      stroke-width="1.5"
      fill="transparent"
      r="12"
      cx="14"
      cy="14" />
    <circle
      class="ring-progress"
      stroke="#FF427E"
      stroke-width="2"
      fill="transparent"
      r="12"
      cx="14"
      cy="14"
      style={progressIndicatorStyleAt(progress)} />
    />
  </svg>
  <div class="value">{Math.round(progress * 100)}%</div>
</div>

<style lang="scss">
  .upload-indicator {
    position: relative;
    width: 28px;
    height: 28px;
  }

  .ring {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  .ring-base {
    stroke: inherit;
    opacity: 0.5;
  }

  .ring-progress {
    stroke: inherit;
  }

  .ring-progress {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: 0.35s stroke-dashoffset;
  }

  .value {
    position: relative;
    text-align: center;
    font-size: 7px;
    font-weight: 500;
    line-height: 29px;
  }
</style>
