<script lang="ts">
  import LogoIcon from 'assets/icons/logo.svg'
</script>

<div>
  <LogoIcon />
  <p>sent.</p>
</div>

<style lang="scss">
  div {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  p {
    width: 210px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 50px;
    font-weight: bold;
    margin: 0 0 20px;
    color: var(--primary-color);
  }
</style>
